import clsx from 'clsx';
import { useMemo } from 'react';

interface ProgressBarProps {
  progress: number;
  total: number;
}

const ProgressBar = ({ progress, total }: ProgressBarProps) => {
  const items = useMemo(
    () => Array.from({ length: total }, (_, i) => i + 1),
    [total],
  );
  return (
    <div className="flex h-[6px] items-end space-x-1">
      {items.map((_item, index) => (
        <div
          key={index}
          className={clsx(
            'h-1.5 flex-1 rounded transition-colors duration-300 ease-out',
            'transform transition-transform duration-300 ease-out',
            index <= progress
              ? 'animate-once-bounce bg-status-info-default'
              : 'bg-[#475569]',
          )}
        ></div>
      ))}
    </div>
  );
};

export default ProgressBar;
