import {
  VideocamOutline,
  FileTrayFullOutline,
  AppsOutline,
  CogOutline,
  NotificationsOutline,
  ExitOutline,
  BusinessOutline,
} from 'react-ionicons';
import { NavigateFunction } from 'react-router-dom';
import {
  TokenUserPermissions,
  TokenUserRoles,
} from '../../api/users/schemas/Users';
import { toSentenceCase } from 'shared/util/util';
import { FEATURE_FLAGS } from 'feature-flags';
import { hasPermission } from 'hooks/usePermissions';

const getDropdownOptionsByRole = ({
  navigate,
  logout,
  switchCompany,
  openCatalogModal,
  flags,
  email,
}: {
  name: string;
  companyName: string;
  navigate: NavigateFunction;
  logout: () => void;
  switchCompany: () => void;
  openFeedbackModal: () => void;
  openCatalogModal: () => void;
  openCompanyAdminsModal: () => void;
  flags: FEATURE_FLAGS;
  email?: string;
}) => [
  {
    options: [
      {
        label: 'My settings',
        icon: <CogOutline color="currentColor" width="16px" height="16px" />,
        onClick: () => navigate('/profile'),
        link: '/profile',
      },
    ],
  },
  {
    options: [
      {
        label: 'Notification preferences',
        icon: (
          <NotificationsOutline
            color="currentColor"
            width="16px"
            height="16px"
          />
        ),
        onClick: () => navigate('/notifications/preferences'),
        link: '/notifications/preferences',
      },
    ],
  },
  ...(flags.PRODUCT_TOUR_ENABLED
    ? [
        {
          options: [
            {
              label: 'Product tour',
              icon: (
                <VideocamOutline
                  color="currentColor"
                  width="16px"
                  height="16px"
                />
              ),
              onClick: openCatalogModal,
              className: 'product-tour-menu',
            },
          ],
        },
      ]
    : []),
  ...(flags.RESOURCES_ENABLED &&
  hasPermission({ permission: TokenUserPermissions.RESOURCES_READ, flags })
    ? [
        {
          options: [
            {
              label: 'Resources',
              icon: (
                <FileTrayFullOutline
                  color="currentColor"
                  width="16px"
                  height="16px"
                />
              ),
              onClick: () => navigate('/resources'),
              link: '/resources',
            },
          ],
        },
      ]
    : []),
  ...(hasPermission({ onlyMultipleGroups: true, flags })
    ? [
        {
          options: [
            {
              label: 'Switch company',
              icon: (
                <AppsOutline color="currentColor" width="16px" height="16px" />
              ),
              onClick: switchCompany,
              link: '/auth/select-company',
            },
          ],
        },
      ]
    : []),
  ...(hasPermission({ forGraniteUser: true, email, flags })
    ? [
        {
          options: [
            {
              label: 'Manage companies',
              icon: (
                <BusinessOutline
                  color="currentColor"
                  width="16px"
                  height="16px"
                />
              ),
              onClick: () => navigate('/companies'),
              link: '/companies',
            },
          ],
        },
      ]
    : []),
  {
    options: [
      {
        label: 'Log out',
        icon: <ExitOutline color={'currentColor'} width="16px" height="16px" />,
        onClick: () => logout(),
      },
    ],
  },
];

export const userRolesEnumToSelectOptions = () =>
  Object.keys(TokenUserRoles)
    .filter(
      (key) =>
        !isNaN(Number(key)) === false &&
        ![
          'SUPER_ADMIN',
          'GRANITE_BETA_TESTER',
          'EARLY_ADOPTER',
          'QR_ADMIN',
          'PROSPECT',
        ].includes(key),
    )
    .map((key) => ({
      value: TokenUserRoles[key as keyof typeof TokenUserRoles],
      label: toSentenceCase(splitRoleKey(key)),
    }));

export const splitRoleKey = (key: string, splitKey: '_' | '-' = '_') =>
  key
    .split(splitKey)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export default getDropdownOptionsByRole;
