import {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuthUser } from 'hooks/useAuthUser';
import { BusinessOutline, ChevronDown } from 'react-ionicons';
import clsx from 'clsx';
import Loader from 'components/Loader';
import { Modal } from 'components/Modal/Modal';
import { getG360Token } from 'api/auth/auth';
import NavbarTooltip from './NavbarTooltip';
import { OktaGroup } from 'api/companies/schema';
import { SubGroupDropdownMenu } from './SubGroupDropdownMenu';
import { clearAllBeforeUnloadListeners } from 'shared/util/beforeUnloadManager';

interface SubGroupDropdownProps {
  isNavbarExpanded: boolean;
  collapsibleButtonRef: React.RefObject<HTMLDivElement>;
}

const SubGroupDropdown: FC<SubGroupDropdownProps> = ({
  isNavbarExpanded,
  collapsibleButtonRef,
}) => {
  const [loading, setLoading] = useState(false);
  const { companyName, sub_groups, group_id } = useAuthUser();
  const [subGroup, setSubGroup] = useState<OktaGroup | null>(() => {
    const subGroup = sub_groups?.find((sb) => group_id === sb.id);
    return subGroup || null;
  });

  const [isOpenMenu, setOpenMenu] = useState<
    'sub-group-wide' | 'sub-group-portal' | null
  >(null);

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => setOpenMenu(null), [isNavbarExpanded]);

  const selectSubGroup = useCallback(async (sub_group: OktaGroup) => {
    setLoading(true);
    setSubGroup(sub_group);
    const data = await getG360Token(sub_group.id);
    localStorage.setItem('g360-token-storage', JSON.stringify(data));
    clearAllBeforeUnloadListeners();
    window.location.reload();
  }, []);

  const subGroupDropdown = useMemo(
    () =>
      sub_groups?.map((sb) => {
        const label = `${sb.profile.display_name?.split('-')[1]}`;
        return {
          title: label,
          isSelected: subGroup?.id === sb.id,
          onClick: () => subGroup?.id !== sb.id && selectSubGroup(sb),
        };
      }) ?? [],
    [sub_groups, subGroup, selectSubGroup],
  );

  if (!sub_groups?.length)
    return (
      <NavbarTooltip title={companyName} hide={isNavbarExpanded}>
        <div className="relative -mb-4 flex items-center gap-2 rounded border border-stroke-base-subdued p-2 text-content-base-default ">
          <div
            className={clsx(
              'flex w-6 flex-none justify-center transition-transform duration-300 ease-out',
              !isNavbarExpanded && 'translate-x-1',
            )}
          >
            <BusinessOutline color="currentColor" width="24px" height="24px" />
          </div>
          <span
            className={clsx(
              'overflow-hidden truncate text-xs font-bold transition-all duration-300 ease-out',
              isNavbarExpanded ? 'opacity-100' : 'opacity-0',
            )}
          >
            {companyName}
          </span>
        </div>
      </NavbarTooltip>
    );

  return (
    <>
      <Modal
        close={() => {}}
        isVisible={loading}
        hideCloseButton
        className="!bg-transparent !shadow-none"
      >
        <Loader />
        {subGroup?.profile?.display_name ? (
          <p className="mt-4">
            Redirecting to{' '}
            <span className="font-bold text-white">
              {subGroup.profile.display_name}
            </span>{' '}
            ...
          </p>
        ) : null}
      </Modal>
      <div className="-mb-4" ref={menuRef}>
        <SubGroupLabel
          companyName={companyName}
          isNavbarExpanded={isNavbarExpanded}
          subGroupDisplayName={subGroup?.profile.display_name}
          isSelected={isOpenMenu !== null}
          setOpenMenu={setOpenMenu}
        />
        <SubGroupDropdownMenu
          items={subGroupDropdown}
          isNavbarExpanded={isNavbarExpanded}
          onClickOutside={() => setOpenMenu(null)}
          outsideRefs={[menuRef, collapsibleButtonRef]}
          show={isOpenMenu}
        />
      </div>
    </>
  );
};

export default SubGroupDropdown;

interface SubGroupLabelProps {
  isNavbarExpanded: SubGroupDropdownProps['isNavbarExpanded'];
  subGroupDisplayName?: OktaGroup['profile']['display_name'];
  companyName: string;
  isSelected: boolean;
  setOpenMenu: Dispatch<
    React.SetStateAction<'sub-group-wide' | 'sub-group-portal' | null>
  >;
}

const SubGroupLabel: FC<SubGroupLabelProps> = ({
  isNavbarExpanded,
  companyName,
  subGroupDisplayName,
  isSelected,
  setOpenMenu,
}) => (
  <NavbarTooltip
    title={subGroupDisplayName || ''}
    hide={isNavbarExpanded || isSelected || !subGroupDisplayName?.length}
    placement="right-start"
    offset={[-3, 10]}
  >
    <div
      className={clsx(
        'group flex cursor-pointer select-none overflow-hidden p-2 duration-300',
        {
          'w-12 items-center justify-center rounded': !isNavbarExpanded,
          'w-[198px] flex-row items-center justify-between': isNavbarExpanded,
          'bg-[#333346]': isSelected,
          'rounded-t': isSelected && isNavbarExpanded,
          rounded: !isSelected && isNavbarExpanded,
          ' hover:bg-[#2B2B3C]': !isSelected,
        },
      )}
      onClick={() =>
        setOpenMenu((prev) =>
          prev !== null
            ? null
            : isNavbarExpanded
              ? 'sub-group-wide'
              : 'sub-group-portal',
        )
      }
    >
      <div
        className={clsx(
          'absolute text-white transition-transform duration-300 ease-out',
          !isNavbarExpanded ? 'opacity-100' : 'opacity-0',
        )}
      >
        <BusinessOutline color="currentColor" width="24px" height="24px" />
      </div>
      <div
        className={clsx('flex flex-col overflow-hidden', {
          'w-12 items-center justify-center': !isNavbarExpanded,
          'w-[160px]': isNavbarExpanded,
        })}
      >
        <p
          className={clsx(
            'w-full truncate text-left text-xs font-bold text-white transition-all duration-300 ease-out',
            isNavbarExpanded ? 'opacity-100' : 'opacity-0',
          )}
        >
          {companyName}
        </p>
        <p
          className={clsx(
            'h-4 w-full overflow-hidden truncate text-left text-xs font-semibold text-neutral-500 transition-all duration-300 ease-out',
            isNavbarExpanded ? 'opacity-100' : 'opacity-0',
          )}
        >
          {subGroupDisplayName || '--'}
        </p>
      </div>
      {isNavbarExpanded && (
        <span
          className={clsx(
            'text-neutral-500 opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100',
            isSelected && 'rotate-180 !opacity-100',
          )}
        >
          <ChevronDown width="16px" height="16px" color="currentColor" />
        </span>
      )}
    </div>
  </NavbarTooltip>
);
