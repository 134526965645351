import { formatCurrency } from 'shared/util/formatCurrency';
import { ReactComponent as RepeatSvg } from 'assets/images/repeat.svg';

const ProductPriceInfo = ({
  price,
  months,
  repeat,
}: {
  price: number;
  months: string;
  repeat?: boolean;
}) => {
  return (
    <div className="grid grid-cols-12 gap-2 text-xs font-semibold text-content-base-subdued">
      <span className="col-span-7">{formatCurrency(price)}</span>
      <span className="col-span-2 opacity-50">x&nbsp;{months}</span>
      {repeat ? (
        <RepeatSvg width="16px" height="16px" className="col-span-3 ml-1" />
      ) : null}
    </div>
  );
};

export default ProductPriceInfo;
