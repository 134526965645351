import FormSection from 'components/FormSection';
import GlowingBorder from '../BaseComponents/GlowingBorder';
import { BusinessOutline } from 'react-ionicons';
import { AccessLocation } from 'api/accessexpressv3/schemas/AccessCheckoutSchemas';

const ServiceAddressFormSection = ({
  location,
}: {
  location: AccessLocation;
}) => {
  return (
    <FormSection title="Service address" gridClassName="relative grid-cols-1">
      <GlowingBorder />
      <div className="service-address-checkout flex justify-between rounded p-4">
        <p className="flex gap-2">
          <BusinessOutline width="21px" height="21px" color="#F8FAFC" />
          <span className="font-bold text-content-base-default">
            {location.address_line_1}, {location.city}, {location.state}{' '}
            {location.zip}
          </span>
        </p>
        <p className="font-bold text-content-base-subdued">
          {location.cw_site_id
            ? `Account # ${location.cw_site_id}`
            : 'No account found'}
        </p>
      </div>
    </FormSection>
  );
};

export default ServiceAddressFormSection;
