import { useState } from 'react';
import { QueryParamConfig, useQueryParams } from './useQueryParams';

export const useQueryState = <T, K extends string>(
  key: K,
  config: {
    defaultValue: T;
    getParam: (value: string) => T;
    setParam: (value: T) => string;
  },
) => {
  type ConfigType = { [P in K]: T };

  const queryConfig = {
    [key]: config,
  } as QueryParamConfig<ConfigType>;

  const { getQueryParam, setQueryParam } =
    useQueryParams<ConfigType>(queryConfig);

  const [state, setState] = useState<T>(() => getQueryParam(key));

  const setQueryState = (value: T) => {
    setQueryParam(key, value);
    setState(value);
  };

  return [state, setQueryState] as const;
};
