import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { ArrowForward } from 'react-ionicons';

import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  ProductTourMode,
  useProductTourContext,
} from 'context/ProductTourContext';
import { TourStatus } from 'api/producttour/schemas';
import { useFeatureFlags } from 'feature-flags';
import { ModalParams } from 'hooks/useModal';
import Loader from 'components/Loader';

import { Completed, InProgress, NotStarted } from './icons';
import { TourSectionWithHierarchy } from './types';

export interface CatalogModal extends ModalParams {}

export const CatalogModal = observer(({ isOpen, close }: CatalogModal) => {
  const { flags } = useFeatureFlags();
  const productTour = useProductTourContext();

  const items = useMemo(() => {
    if (!productTour.requestState.load.isLoaded) {
      return [];
    }
    return productTour.getSectionsStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productTour,
    productTour.requestState.load.isLoaded,
    productTour.getSectionsStatuses,
  ]);

  const handleTourStart = useCallback(
    (n: number, mode: ProductTourMode) => {
      productTour.startTour(n, mode, true);
      close();
    },
    [close, productTour],
  );

  return (
    <Modal isVisible={isOpen} close={close} className="w-[640px]">
      <h1 className="flex flex-col justify-center gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6 text-[28px] font-bold leading-9 text-content-base-default">
        <p className="font-bold text-content-base-default">Product tour</p>
        <p className="text-base font-semibold text-content-base-subdued">
          Complete our interactive tutorial to learn about how to use Granite360
          and its features.
        </p>
      </h1>
      <div className="flex h-auto flex-col items-start justify-start gap-4 rounded bg-background-base-surface-2 p-8">
        <p className="text-2xl font-bold text-content-base-default">
          Where do you want to start?
        </p>
        <div className="flex w-full flex-col gap-12 bg-background-base-surface-2">
          <div className="vertical-scrollbar flex max-h-[290px] w-full flex-col items-start justify-start rounded bg-background-base-surface-1 px-4 py-2">
            {items.length ? (
              items.map((item) => (
                <ListItem
                  key={item.title}
                  {...item}
                  onClick={handleTourStart}
                />
              ))
            ) : (
              <div className="flex h-[290px] w-full items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
          <div className="flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Close
            </GraniteButton>

            {flags.DEVELOPER_MODE && (
              <GraniteButton
                variant="destructive"
                size="small"
                onClick={() => productTour.resetTour()}
                className="ml-auto mt-6 h-4"
              >
                Reset (dev only)
              </GraniteButton>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
});

interface ListItemProps extends TourSectionWithHierarchy {
  onClick: (step: number, mode: ProductTourMode) => void;
}

const ListItem = ({ title, items, status, onClick, step }: ListItemProps) => {
  return (
    <div className="flex w-full flex-col">
      <div
        className="group flex w-full cursor-pointer flex-row items-center justify-between py-2 text-content-base-default"
        onClick={() => onClick(step, 'Section')}
      >
        <div className="flex h-6 w-6 flex-none items-center justify-center">
          <div className="h-[5px] w-[5px] rounded-full bg-current"></div>
        </div>
        <div className="flex flex-grow flex-row items-center gap-1 font-bold">
          {title}
          <div className="-translate-x-2 transform opacity-0 transition-all duration-150 ease-out group-hover:translate-x-2 group-hover:opacity-100">
            <ArrowForward color="#82F0FF" width="20px" height="20px" />
          </div>
        </div>
        <Status status={status} variant="label" />
      </div>

      <div className="ml-6 flex flex-col">
        {items !== undefined &&
          items.map((subItem) => (
            <div
              key={subItem.title}
              className="group flex cursor-pointer flex-row items-center justify-between py-2"
              onClick={() => onClick(subItem.step, 'SubSection')}
            >
              <div className="flex h-5 w-5 flex-none items-center justify-center">
                <div className="h-1 w-1 rounded-full bg-current"></div>
              </div>
              <div className="flex flex-grow flex-row items-center gap-1 text-sm font-bold">
                {subItem.title}
                <div className="-translate-x-2 transform opacity-0 transition-all duration-150 ease-out group-hover:translate-x-2 group-hover:opacity-100">
                  <ArrowForward color="#82F0FF" width="20px" height="20px" />
                </div>
              </div>
              <Status status={subItem.status} variant="icon" />
            </div>
          ))}
      </div>
    </div>
  );
};

interface StatusProps {
  status?: TourStatus;
  variant: 'label' | 'icon';
}

const Status = ({ status, variant }: StatusProps) => {
  const Icon = useMemo(() => {
    switch (status) {
      case 'Completed':
        return Completed;
      case 'In Progress':
        return InProgress;
      default:
        return NotStarted;
    }
  }, [status]);

  const backgroundColor = useMemo(() => {
    switch (status) {
      case 'Completed':
        return 'bg-green-300';
      case 'In Progress':
        return 'bg-purple-300';
      default:
        return 'bg-content-base-subdued';
    }
  }, [status]);

  const textColor = useMemo(() => {
    switch (status) {
      case 'Completed':
        return 'text-green-300';
      case 'In Progress':
        return 'text-purple-300';
      default:
        return 'text-content-base-default';
    }
  }, [status]);

  const statusText = useMemo(() => {
    switch (status) {
      case 'Completed':
        return 'Completed';
      case 'In Progress':
        return 'In progress';
      default:
        return 'Not started';
    }
  }, [status]);

  return (
    <div className="-my-1">
      <div className="inline-flex h-6 items-center justify-start gap-6">
        <div className="flex items-start justify-start">
          <div
            className={clsx(
              'flex items-center justify-center rounded-lg bg-opacity-[0.16]',
              textColor,
              variant === 'label' && backgroundColor,
              variant === 'label' && 'w-25 h-6',
            )}
          >
            <div className="flex items-center justify-start gap-1 px-2">
              {variant === 'label' && (
                <div className="whitespace-nowrap text-xs font-bold leading-none">
                  {statusText}
                </div>
              )}
              <div className="flex w-3 items-center justify-center">
                <Icon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
