import { GraniteSelect } from 'components/Select/Select';
import { FormFragment } from 'screens/TicketDetail/TicketEdit/TicketForm';
import { Add, InformationCircle } from 'react-ionicons';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import Checkbox from 'components/Checkbox';
import AddEmailRecipient from 'screens/LetUsHelp/utils/AddEmailRecipient';
import FormSection from 'components/FormSection';
import { FileLike, FileUpload } from 'components/V2/FileUpload/FileUpload';
import { Fragment, useEffect, useState } from 'react';
import {
  EditNocTicketForm,
  EditTicketDetailsFormSchema,
  NetOpsResponseForm,
} from './form-schema';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from 'react-query';
import { editTicketNoc, postTicketAttachmentsNoc } from 'api/nocexpress/api';
import showToast from 'components/Toast/Toast';
import { editTicketToRequestNoc } from './util';
import ContactEmailTag from 'components/ContactEmailTag/ContactEmailTag';
import GraniteTextArea from 'components/TextArea/TextArea';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { LocationsTable } from 'screens/OpenNetOpsTicket/FindLocationOrService/LocationsTable';
import { BulkLocations } from 'screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/schemas';
import { useModal } from 'hooks/useModal';
import BulkAddLocationsDialog from 'screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/BulkAddLocationsDialog';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FieldTooltip } from 'components/FieldTooltip/FieldTooltip';
import { Link } from 'react-router-dom';
import { TicketSearchDropdown } from 'components/TicketSearchDropdown/TicketSearchDropdown';
import { ResponsiveContainer } from 'components/ResponsiveContainer/ResponsiveContainer';

const contactFiledsOptions = [
  { value: 'Local contact', label: 'Local contact' },
  { value: 'Technical contact', label: 'Technical contact' },
  { value: 'Other', label: 'Other' },
];

export const TicketForm = ({
  data,
  onSuccessSubmission,
  setIsSubmiting,
  attachments = [],
  ticket_issues_dropdown_values,
}: {
  data: NetOpsResponseForm;
  onSuccessSubmission: () => void;
  setIsSubmiting: (value: boolean) => void;
  attachments: { filename: string; id?: number | undefined }[] | undefined;
  ticket_issues_dropdown_values: string[] | undefined;
}) => {
  const foundIssue = ticket_issues_dropdown_values?.find(
    (item) => item === data.issue,
  );
  const defaultValues = {
    issue: foundIssue ? { label: foundIssue, value: foundIssue } : undefined,
    customer_ticket_number: data.customer_ticket_number || '',
    prior_ticket: data.prior_ticket?.toString() || '',
    access_hours: data.access_hours,
    technician_dispatch_approved: !!data.technician_dispatch_approved,
    local_contact_name: data.local_contact_name || '',
    local_contact_number: data.local_contact_number || '',
    additional_contacts: data.additional_contacts?.map((contact) => ({
      ...contact,
      number: contact.number.toString(),
      type: { label: contact.type, value: contact.type },
    })),
    ...(data.sites && data.sites.length > 1
      ? {
          canTestingBeDoneAnytime: undefined,
        }
      : {
          canTestingBeDoneAnytime:
            data?.sites &&
            data?.sites[0]?.maintenance_window === 'Mon-Fri, 8:00AM-5:00PM',
          maintenance_window: data?.sites && data?.sites[0]?.maintenance_window,
        }),
    recipients: (data.automatic_email_cc_address?.split(';') ?? [])
      .map((email) => email.trim())
      .filter((email) => email.length > 0)
      .map((email) => ({ email })),
    tns_impacted: data.tns_impacted || '',
  };

  const bulkLocationsFormatedForModal =
    data?.sites &&
    data.sites.map((item) => {
      return {
        maintenance_window: item.maintenance_window,
        ...(item.maintenance_window === 'Mon-Fri, 8:00AM-5:00PM'
          ? {
              canTestingBeDoneAnytime: true,
            }
          : {
              canTestingBeDoneAnytime: false,
            }),
        childAccount: item.account_name,
        site: {
          address_line_1: item.address_line_1,
          state: item.state,
          city: item.city,
          zip: item.zip,
        },
        id: item.id,
      };
    });

  const [bulkLocations, setBulkLocations] = useState<
    BulkLocations['locations']
  >(bulkLocationsFormatedForModal as unknown as BulkLocations['locations']);

  const {
    register,
    control,
    handleSubmit,
    watch,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<EditNocTicketForm>({
    resolver: zodResolver(EditTicketDetailsFormSchema),
    defaultValues,
  });

  const additionalContactsFields = useFieldArray({
    control,
    name: 'additional_contacts',
  });

  const queryClient = useQueryClient();

  const [canTestingBeDoneAnytime, maintenanceWindow] = watch([
    'canTestingBeDoneAnytime',
    'maintenance_window',
  ]);

  const [fileLikes, setFileLikes] = useState<FileLike[]>(attachments);

  useEffect(() => {
    if (canTestingBeDoneAnytime) {
      resetField('maintenance_window', { defaultValue: '' });
    }
  }, [canTestingBeDoneAnytime, maintenanceWindow, resetField]);

  useEffect(() => {
    // workaround to make maintenance_window optional
    if (data?.sites && data?.sites?.length === 0) {
      setValue('canTestingBeDoneAnytime', true);
    }
  }, [data?.sites, setValue]);

  const handleFileChange = (newFiles: FileLike[]) => {
    const fileLikesToAdd = newFiles.map((fileLike) => {
      if ('id' in fileLike) {
        return { ...fileLike, name: fileLike.filename, id: fileLike.id };
      } else return fileLike;
    });
    setFileLikes(fileLikesToAdd);
  };

  const handleFileRemove = (fileToRemove: FileLike, index: number) => {
    const updatedFileLikes = fileLikes.filter((_, i) => i !== index);
    setFileLikes(updatedFileLikes);
  };

  const handleNewFiles = async () => {
    const newFiles = fileLikes.filter(
      (file): file is File => file instanceof File,
    );

    if (newFiles.length > 0) {
      const attachmentsFormData = new FormData();
      newFiles.forEach((file) => {
        attachmentsFormData.append('file', file);
      });

      try {
        await postTicketAttachmentsNoc(data.id, attachmentsFormData);

        queryClient.setQueryData(
          [data?.id.toString(), 'ticket-attachments-noc'],
          (previousData = []) => {
            const existingFiles = Array.isArray(previousData)
              ? previousData
              : [];
            const newFileData = newFiles.map((item) => ({
              id: undefined,
              filename: item.name,
            }));
            return [...existingFiles, ...newFileData];
          },
        );
      } catch (error) {
        showToast.error({
          message: 'Failed to upload attachments!',
        });
      }
    }
  };

  const updateTicketMutation = useMutation(
    ({
      ticketId,
      editedTicket,
    }: {
      ticketId: number;
      editedTicket: EditNocTicketForm;
    }) => editTicketNoc(ticketId, editedTicket),
    {
      onSuccess: async () => {
        await handleNewFiles();

        queryClient.refetchQueries([data?.id.toString(), 'ticket-details-noc']);
        queryClient.refetchQueries([data?.id.toString(), 'ticket-noc-notes']);
        onSuccessSubmission();

        showToast.confirmation({
          message: 'Ticket has been successfully edited!',
        });
        setIsSubmiting(false);
      },
      onError: async () => {
        showToast.error({
          message: 'There was an error ',
        });
        setIsSubmiting(false);
      },
    },
  );

  const onSubmit = (payload: EditNocTicketForm) => {
    setIsSubmiting(true);
    updateTicketMutation.mutate({
      ticketId: data.id,
      //@ts-expect-error edit ticket payload
      editedTicket: editTicketToRequestNoc(
        payload,
        data.sites && data.sites.length === 1 ? data.sites[0].id : null,
        bulkLocations,
      ),
    });
  };

  const { open, ...bulkModalProps } = useModal();

  const onError = (err: unknown) => {
    console.log({ err });
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  return (
    <>
      <form
        id="ticket-form"
        className={`flex flex-col gap-16 rounded bg-background-base-surface-2`}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <FormFragment title="Service details">
          <Controller
            name="issue"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                className={`col-span-full`}
                onChange={onChange}
                label="What is the issue that needs to be fixed"
                value={value}
                options={
                  ticket_issues_dropdown_values
                    ? ticket_issues_dropdown_values.map((option) => ({
                        label: option,
                        value: option,
                      }))
                    : []
                }
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />

          {data.type_name === 'POTS' && (
            <Controller
              name="tns_impacted"
              control={control}
              rules={{
                required: 'Required',
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <GraniteTextArea
                  {...field}
                  className={`col-span-full`}
                  onChange={onChange}
                  value={value}
                  error={errors.tns_impacted?.message}
                  label="Telephone numbers impacted"
                  inputClassName="resize-y"
                />
              )}
            />
          )}
          <div className="col-span-full flex items-center justify-start gap-2 fill-status-info-default">
            <InformationCircle
              width={'17px'}
              height={'17px'}
              color={'inherit'}
            />
            <p className="text-sm font-semibold text-content-base-default">
              If changes need to be made to the description of this issue,
              return to view ticket and add a note in the Activity section.
            </p>
          </div>
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Ticket details" gridClassName="!grid-cols-none">
          <ResponsiveContainer
            breakpoints={{
              narrow: { max: 760 },
              wide: { min: 761 },
            }}
            className="grid gap-4 [&.narrow]:grid-cols-none [&.wide]:grid-cols-2"
          >
            <GraniteInput
              label="Customer ticket # (optional)"
              subtitle="Multiple entries should be separated with commas"
              className="col-span-1"
              {...register('customer_ticket_number')}
              error={errors.customer_ticket_number?.message}
            />
            <Controller
              name="prior_ticket"
              control={control}
              render={({ field }) => (
                <TicketSearchDropdown
                  {...field}
                  className="col-span-1"
                  label="Prior ticket # (optional)"
                  subtitle="Tickets that share info pertaining to this issue"
                  onTicketSelected={(ticketNumber) => {
                    setValue('prior_ticket', String(ticketNumber));
                    field.onChange(String(ticketNumber));
                  }}
                  error={errors.prior_ticket?.message}
                  configurationIds={(data?.configurations ?? [])
                    .map((configuration) => configuration.value)
                    .filter(Boolean)
                    .join(',')}
                />
              )}
            />
          </ResponsiveContainer>
        </FormFragment>
        {data?.sites && data?.sites.length > 1 && (
          <>
            <hr className="h-[1px] border-stroke-base-subdued" />
            <FormFragment
              title="Service locations"
              gridClassName="!grid-cols-1"
            >
              <LocationsTable
                hideTitle={true}
                open={open}
                hideClearAll={true}
                clearAll={() => {
                  return;
                }}
                locations={bulkLocations}
              />
            </FormFragment>
          </>
        )}
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormSection title="Contact" gridClassName="grid-cols-none">
          <div className="flex flex-col gap-y-12 xl:gap-y-4">
            <ResponsiveContainer
              breakpoints={{
                narrow: { max: 500 },
                wide: { min: 501 },
              }}
              className="grid gap-4 [&.narrow]:grid-cols-none [&.wide]:grid-cols-3"
            >
              <div>
                <GraniteSelect
                  value={
                    data?.type_name === 'Multi-Site Service Request'
                      ? contactFiledsOptions[1]
                      : contactFiledsOptions[0]
                  }
                  isDisabled={true}
                  options={contactFiledsOptions}
                  label="Contact Type"
                />
              </div>
              <div>
                <GraniteInput
                  {...register(`local_contact_name`)}
                  label="Name"
                  error={errors.local_contact_name?.message}
                  innerInputClassName="!min-w-0"
                />
              </div>
              <div>
                <PhoneNumberInput
                  error={errors.local_contact_number?.message}
                  name={`local_contact_number`}
                  control={control}
                  label="Phone number"
                  innerInputClassName="!min-w-0"
                />
              </div>
            </ResponsiveContainer>
            {additionalContactsFields.fields.map((field, index) => (
              <Fragment key={field.id}>
                <ResponsiveContainer
                  breakpoints={{
                    narrow: { max: 500 },
                    wide: { min: 501 },
                  }}
                  className="grid gap-4 [&.narrow]:grid-cols-none [&.wide]:grid-cols-3"
                >
                  <Controller
                    name={`additional_contacts.${index}.type`}
                    control={control}
                    render={({
                      field: { onChange, value, ...field },
                      fieldState: { error },
                    }) => (
                      <GraniteSelect
                        {...field}
                        error={error?.message}
                        value={value}
                        onChange={onChange}
                        options={contactFiledsOptions}
                        label="Contact Type"
                      />
                    )}
                  />
                  <GraniteInput
                    {...register(`additional_contacts.${index}.name`)}
                    label="Name"
                    error={
                      errors.additional_contacts?.length
                        ? errors.additional_contacts[index]?.name?.message
                        : ''
                    }
                    innerInputClassName="!min-w-0"
                  />
                  <PhoneNumberInput
                    error={
                      errors.additional_contacts?.length
                        ? errors.additional_contacts[index]?.number?.message
                        : ''
                    }
                    name={`additional_contacts.${index}.number`}
                    control={control}
                    label="Phone number"
                    innerInputClassName="!min-w-0"
                  />
                </ResponsiveContainer>
                <div>
                  <GraniteButton
                    variant="destructive"
                    className="max-w-fit"
                    onClick={() => additionalContactsFields.remove(index)}
                  >
                    Delete
                  </GraniteButton>
                </div>
              </Fragment>
            ))}
          </div>
          {additionalContactsFields.fields.length < 2 ? (
            <GraniteButton
              variant="secondary"
              className="!box-border max-w-fit whitespace-nowrap"
              onClick={() => {
                additionalContactsFields.append({
                  name: '',
                  number: '',
                });
              }}
            >
              Add another contact
              <Add width="20px" height="20px" color="inherit" />
            </GraniteButton>
          ) : null}
        </FormSection>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormSection title="Access hours" gridClassName="grid-cols-1">
          <Controller
            name="access_hours"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteTextArea
                {...field}
                onChange={onChange}
                value={value}
                label="Access hours"
                subtitle="Select the time frame in which an on-site contact will be available for access and/or troubleshooting if technician dispatch is required."
                inputClassName="resize-y"
                placeholder="E.g., Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST"
                error={errors.access_hours?.message}
              />
            )}
          />
          <div className="col-span-full">
            <h3 className="mb-2 flex gap-2 text-base font-bold text-content-base-subdued">
              Technician dispatch approval (optional){' '}
              <div className="">
                <FieldTooltip>
                  Technician dispatches are not always needed. This checkbox
                  allows you to pre-approve that if a technician dispatch is
                  required, Granite is authorized to schedule and dispatch the
                  technician to your location.
                </FieldTooltip>
              </div>
            </h3>
            <Controller
              name="technician_dispatch_approved"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Checkbox
                  {...field}
                  disabled={data.g360_status === 'Pending NI'}
                  label={
                    <p className="select-none">
                      I authorize a technician to be dispatched to this location
                      if necessary. See{' '}
                      <Link
                        to={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                      >
                        {`Granite's Terms and Conditions of Service`}
                      </Link>{' '}
                      and{' '}
                      <Link
                        to={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20Network%20Integration%20Services%20Terms%20of%20Service.pdf`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                      >
                        {' '}
                        Network Integration Services
                      </Link>
                    </p>
                  }
                  checked={value}
                />
              )}
            />
          </div>
        </FormSection>
        <hr className="h-[1px] border-stroke-base-subdued" />
        {data?.sites && data?.sites.length === 1 && (
          <FormSection title="Maintenance window" gridClassName="grid-cols-1">
            <GraniteTextArea
              {...register('maintenance_window')}
              label="Access hours"
              subtitle="For intrusive testing purposes, list your location’s maintenance window for each day of the week."
              inputClassName="resize-y"
              placeholder={`${
                canTestingBeDoneAnytime
                  ? 'Mon-Fri, 8:00AM-5:00PM'
                  : 'E.g., Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST'
              }`}
              error={errors.maintenance_window?.message}
              disabled={canTestingBeDoneAnytime}
            />
            <div className="col-span-full">
              <h3 className="mb-1 text-base font-bold text-content-base-subdued">
                Allow intrusive testing regardless of date or time
              </h3>
              <h4 className="mb-1 text-sm text-content-base-subdued">
                Check the box below if intrusive testing may be done at anytime
                Mon-Fri.
              </h4>
              <Controller
                name="canTestingBeDoneAnytime"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Checkbox
                    {...field}
                    label="Yes, intrusive testing can be done anytime"
                    className="col-span-full"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </FormSection>
        )}
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormSection title="Notifications">
          <div className="flex flex-col gap-y-4">
            <AddEmailRecipient appendFunc={append} />
            <div className="col-span-full flex">
              <div className="flex flex-wrap gap-2">
                {(fields?.length ?? 0) > 0 &&
                  fields?.map((field, i) => (
                    <ContactEmailTag
                      email={field.email}
                      index={i}
                      remove={remove}
                      key={i}
                    />
                  ))}
              </div>
            </div>
          </div>
        </FormSection>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Attachments">
          <FileUpload
            label="Upload any additional files (optional)"
            subtitle="Documents pertaining to dispatch such as floor plan, install guide, survey document, photos of broken items, etc."
            className="col-span-2"
            accept=".pdf,.docx,.doc,.xlsx,.csv,.png,.jpeg,.jpg"
            multiple
            value={fileLikes.map((fileLike) => ({
              ...fileLike,
              name: 'filename' in fileLike ? fileLike.filename : fileLike.name,
            }))}
            onChange={handleFileChange}
            onRemove={handleFileRemove}
          />
        </FormFragment>
        <BulkAddLocationsDialog
          {...bulkModalProps}
          getLocations={(locations) => setBulkLocations(locations)}
          locations={bulkLocations}
          isEditTicket={true}
        />
      </form>
    </>
  );
};
