import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Close } from 'react-ionicons';
import clsx from 'clsx';

import { TourSteps } from './steps';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useProductTourContext } from 'context/ProductTourContext';

export const CustomToolTip: FC<{
  step: number;
  title?: ReactNode;
  subtitle: ReactNode;
  disabledNextButton?: boolean;
  isLastStep?: boolean;
  isFirstStep?: boolean;
  className?: string;
}> = observer(({ title, subtitle, className, step }) => {
  const productTour = useProductTourContext();

  return (
    <div
      className={clsx(
        'w-[356px] max-w-[356px] rounded bg-[#222231]',
        className,
      )}
    >
      <div className="flex justify-end p-4 pb-0">
        {step !== TourSteps.REVISIT_LATER_STEP && (
          <Close
            width="24px"
            height="24px"
            color="#F8FAFC"
            onClick={() => productTour.showLastStep()}
            cssClasses="cursor-pointer"
          />
        )}
      </div>
      <div className="flex flex-col gap-8 p-4 text-center">
        <div className="flex flex-col gap-2">
          {title && (
            <div className="text-xl font-bold text-content-base-default">
              {title}
            </div>
          )}
          <div className="text-base text-content-base-default">{subtitle}</div>
        </div>
        {productTour.isLastStep ||
        productTour.currentStepNumber === TourSteps.LAST_STEP ? (
          <div className="flex items-center justify-center gap-5">
            <GraniteButton
              size="medium"
              variant="primary"
              type="submit"
              onClick={() => productTour.endTour()}
            >
              Got it
            </GraniteButton>
          </div>
        ) : (
          <div className="flex items-center justify-between gap-5">
            {/* {!productTour.isIndividualSectionRunning ? ( */}
            <GraniteButton
              variant="ghost"
              onClick={() => productTour.skipStep()}
            >
              Skip
            </GraniteButton>
            {/* ) : (
              <div></div>
            )} */}
            <div className="flex gap-2">
              {productTour.currentStepNumber !== 1 && (
                <GraniteButton
                  size="medium"
                  variant="secondary"
                  type="submit"
                  onClick={() => productTour.prevStep()}
                >
                  Back
                </GraniteButton>
              )}
              <GraniteButton
                size="medium"
                variant="primary"
                type="submit"
                onClick={() => productTour.nextStep()}
              >
                Next
              </GraniteButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
