import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

const FEATURE_FLAGS_KEYS = [
  'NOTIFICATIONS_ENABLED',
  'NOTIFICATIONS_EMAIL_ENABLED',
  'NOTIFICATIONS_SMS_ENABLED',
  'PRODUCT_TOUR_ENABLED',
  'RESOURCES_ENABLED',
  'ALERTING',
  'DEVELOPER_MODE',
  'INVENTORY_ACCOUNT',
  'ACCESS_EXPRESS_V3',
  'SUB_GROUPS_PERMISSIONS_ACCESS_NOT_ALLOWED',
  'GLOBAL_SEARCH',
  'RECENTLY_VIEWED_AND_PINNED_TICKETS',
  'ACCESS_EXPRESS_V3_MOBILITY',
] as const;

export type FEATURE_FLAG = (typeof FEATURE_FLAGS_KEYS)[number];

export type FEATURE_FLAGS = {
  [key in FEATURE_FLAG]: boolean;
};
type FEATURE_FLAG_CONTEXT = {
  flags: FEATURE_FLAGS;
};

const FeatureFlagContext = createContext<FEATURE_FLAG_CONTEXT>(
  {} as FEATURE_FLAG_CONTEXT,
);

export const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  const [flags] = useState<FEATURE_FLAGS>({
    NOTIFICATIONS_ENABLED: true,
    NOTIFICATIONS_EMAIL_ENABLED: false,
    NOTIFICATIONS_SMS_ENABLED: false,
    PRODUCT_TOUR_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    RESOURCES_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    ALERTING: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    DEVELOPER_MODE: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    INVENTORY_ACCOUNT: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    ACCESS_EXPRESS_V3: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    SUB_GROUPS_PERMISSIONS_ACCESS_NOT_ALLOWED:
      process.env.REACT_APP_CURRENT_ENV === 'prod',
    GLOBAL_SEARCH: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    RECENTLY_VIEWED_AND_PINNED_TICKETS:
      process.env.REACT_APP_CURRENT_ENV !== 'prod',
    ACCESS_EXPRESS_V3_MOBILITY: process.env.REACT_APP_CURRENT_ENV !== 'prod',
  });

  return (
    <FeatureFlagContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);
