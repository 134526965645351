import { TokenUserPermissions } from '../api/users/schemas/Users';
import { useMemo } from 'react';
import { useAuthUser } from './useAuthUser';
import { G360Token } from 'api/auth/schema';
import { useAuth } from 'authContext';
import { FEATURE_FLAGS, useFeatureFlags } from 'feature-flags';

export interface PermissionsProps {
  permission?: TokenUserPermissions | TokenUserPermissions[];
  onlyForSuperAdmin?: boolean;
  onlyMultipleGroups?: boolean;
  forGraniteUser?: boolean;
  email?: string;
  flags?: FEATURE_FLAGS;
}

const subGroupsPermissionsAccessNotAllowed = [
  // AccessExpress
  TokenUserPermissions.ACCESS_EXPRESS_READ,
  TokenUserPermissions.ACCESS_EXPRESS_WRITE,
  TokenUserPermissions.ACCESS_EXPRESS_MANAGE,
  // Inventory
  TokenUserPermissions.INVENTORY_READ,
  // User Management
  TokenUserPermissions.USER_MANAGEMENT,
];

export const hasPermission = (args: PermissionsProps | undefined): boolean => {
  const g360Token: G360Token = JSON.parse(
    localStorage.getItem('g360-token-storage') ?? '{}',
  );

  const {
    permissions: userPermissions,
    super_admin,
    sub_groups,
    multiple_groups,
  } = g360Token;

  if (args?.onlyForSuperAdmin) return !!super_admin;
  else {
    if (super_admin) return true;

    if (args?.forGraniteUser && args?.email)
      return args?.email.endsWith('@granitenet.com');

    let _permission: TokenUserPermissions[] = [];
    if (typeof args?.permission === 'string') _permission.push(args.permission);
    else if (Array.isArray(_permission))
      _permission = [...(args?.permission?.length ? args.permission : [])];

    if (args?.onlyMultipleGroups) return multiple_groups;

    if (
      args?.flags?.SUB_GROUPS_PERMISSIONS_ACCESS_NOT_ALLOWED &&
      sub_groups?.length > 0 &&
      _permission.some((p) => subGroupsPermissionsAccessNotAllowed.includes(p))
    )
      return false;

    return _permission.some((perm) => userPermissions?.includes(perm));
  }
};

export const usePermissions = (
  props: PermissionsProps | undefined = undefined,
) => {
  const { permissions: userPermissions, super_admin } = useAuthUser();
  const { flags } = useFeatureFlags();
  const { user } = useAuth();

  const { email } = user || {};

  const _hasPermission = useMemo(
    () => hasPermission({ ...props, email, flags }),
    [props, email, flags],
  );

  return {
    userPermissions,
    isSuperAdmin: !!super_admin,
    hasPermission: _hasPermission,
  };
};
