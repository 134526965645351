import Divider from 'components/Divider';
import { observer } from 'mobx-react-lite';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import { useNavigate } from 'react-router-dom';

const LocationFooter = observer(() => {
  const cart = useCartContext();
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate('checkout');
  };

  return (
    <div className="flex flex-col gap-8">
      {cart.isEditingLocations ? (
        <>
          <GraniteButton
            variant="secondary"
            onClick={() => cart.doneEditingLocations()}
          >
            Back
          </GraniteButton>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Divider />
            <div className="flex justify-between">
              <GraniteButton
                size="small"
                variant="ghost"
                className="!text-content-base-subdued hover:!border-accent-primary hover:!text-accent-primary"
                onClick={() => cart.editLocations()}
              >
                Edit locations
              </GraniteButton>
              <GraniteButton
                size="small"
                variant="ghost"
                className="!text-content-base-subdued hover:!border-accent-primary hover:!text-accent-primary"
                onClick={() => {}}
              >
                Clear all
              </GraniteButton>
            </div>
          </div>

          {cart.totalItems !== 0 && (
            <GraniteButton variant="tertiary" onClick={handleCheckout}>
              Checkout ({cart.totalItems})
            </GraniteButton>
          )}
        </>
      )}
    </div>
  );
});

export default LocationFooter;
