import { useSearchParams } from 'react-router-dom';

export type QueryParamConfig<T> = {
  [K in keyof T]: {
    defaultValue: T[K];
    getParam: (value: string) => T[K];
    setParam: (value: T[K]) => string;
  };
};

export const useQueryParams = <T extends Record<string, unknown>>(
  config: QueryParamConfig<T>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParam = <K extends keyof T>(key: K): T[K] => {
    const rawValue = searchParams.get(key as string);
    return rawValue !== null
      ? config[key].getParam(rawValue)
      : config[key].defaultValue;
  };

  const setQueryParam = <K extends keyof T>(key: K, value: T[K]) => {
    const currentValue = getQueryParam(key);
    if (currentValue === value) return;

    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev);

        if (
          value === '' ||
          value === null ||
          value === undefined ||
          (Array.isArray(value) && value.length === 0)
        ) {
          params.delete(key as string);
        } else {
          params.set(key as string, config[key].setParam(value));
        }

        return params;
      },
      { replace: true, preventScrollReset: true },
    );
  };

  const removeQueryParam = <K extends keyof T>(key: K) => {
    if (!searchParams.has(key as string)) return;

    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev);
        params.delete(key as string);
        return params;
      },
      { replace: true, preventScrollReset: true },
    );
  };

  const resetQueryParams = ({ exclude }: { exclude?: string[] } = {}) => {
    const newParams = new URLSearchParams();

    (Object.keys(config) as (keyof T)[]).forEach((key) => {
      if (exclude?.includes(key as string)) {
        const value = searchParams.get(key as string);
        if (value !== null) {
          newParams.set(key as string, value);
        }
      }
    });

    setSearchParams(newParams, { replace: true, preventScrollReset: true });
  };

  return { getQueryParam, setQueryParam, removeQueryParam, resetQueryParams };
};
