import clsx from 'clsx';
import Skeleton from 'components/Skeleton/Skeleton';
import { ReactComponent as DIAProduct } from 'assets/images/dia_product.svg';
import { ReactComponent as CradlepointProduct } from 'assets/images/cradlepoint_product.svg';
import starlinkBg from 'assets/images/starfield_simulation.gif';
import { useCallback } from 'react';
import {
  OfferingType,
  ServiceSummaryData,
  TagType,
} from 'screens/AccessExpressV3/accessExpress.types';
import DiaOrCradlepoint from '../alternatives/DiaOrCradlepoint';
import Starlink from '../alternatives/Starlink';
import GlowingBorder from '../../BaseComponents/GlowingBorder';
import OfferBadge from './OfferBadge';
import CuratedOfferDetails from './CuratedOfferDetails';

const OfferCard = ({
  tag,
  isLoading,
  selectedOffer,
  onAddToCart,
  offering,
  serviceSummaryData,
}: {
  tag: TagType;
  isLoading: boolean;
  selectedOffer: string | null;
  onAddToCart: (tag: string) => void;
  offering: OfferingType | undefined;
  serviceSummaryData?: ServiceSummaryData;
}) => {
  const isSelected = selectedOffer === tag;

  const isStarlink = !isLoading && tag === 'Best offer' && !offering;
  const isDia = !isLoading && tag === 'Premium choice' && !offering;
  const isCradlepoint = !isLoading && tag === 'Lowest price' && !offering;

  const isAlternativeOffer = isStarlink || isDia || isCradlepoint;

  const getOfferContent = useCallback(() => {
    if (isLoading) return <Skeleton className="h-[152px] w-full" />;
    if (isStarlink) return <Starlink />;
    // alternative offers
    if (isDia || isCradlepoint) {
      return (
        <DiaOrCradlepoint
          carrier={isDia ? 'Dia' : 'Cradlepoint E100'}
          price={isDia ? '$389.90' : '$35.99'}
          image={isDia ? DIAProduct : CradlepointProduct}
          type={isDia ? 'dia' : 'cradlepoint'}
        />
      );
    }
    // curated offers
    if (offering)
      return (
        <CuratedOfferDetails
          tag={tag}
          offering={offering}
          isSelected={isSelected}
          onAddToCart={onAddToCart}
          serviceSummaryData={serviceSummaryData}
        />
      );
    return null;
  }, [
    isCradlepoint,
    isDia,
    isLoading,
    isSelected,
    isStarlink,
    offering,
    onAddToCart,
    serviceSummaryData,
    tag,
  ]);

  return (
    <div
      className={clsx('relative min-h-[250px] min-w-0 sm:w-full')}
      onClick={() => {
        if (!isAlternativeOffer) {
          onAddToCart(tag);
        }
      }}
    >
      {tag === 'Best offer' && <GlowingBorder />}
      <div
        className={clsx(
          'flex h-full w-full flex-col gap-4 rounded-t-[4px] border border-[#47556980] p-6 hover:bg-[#121821]',
          tag !== 'Best offer' && '!rounded-t-none !border-t-0',
          tag === 'Best offer' && '!border-2 border-transparent',
          isSelected && 'bg-[#9796F30F]',
        )}
        style={
          isStarlink
            ? {
                backgroundImage: `url(${starlinkBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                imageRendering: 'pixelated',
              }
            : {}
        }
      >
        <OfferBadge
          tag={tag}
          isLoading={isLoading}
          isAlternativeOffer={isAlternativeOffer}
        />
        {getOfferContent()}
      </div>
    </div>
  );
};

export default OfferCard;
