import { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { ModalParams } from '../../../../hooks/useModal';
import { GraniteButton } from '../../../../components/V2/Button/GraniteButton';
import Checkbox from 'components/Checkbox';
import { formatCurrency } from 'shared/util/formatCurrency';
import PriceBreakdown from './PriceBreakdown';
import ProductPriceInfo from './ProductPriceInfo';
import ProductTypeTag from './ProductTypeTag';

export type ProductType = {
  id: number;
  manufacturer: string;
  title: string;
  from_price: number;
  monthly_price: number;
  months: string;
  img: string;
};

export interface ReviewOrderForm {
  productType: string;
  products: ProductType[];
  locations: string[];
  services: string[];
  nrc: {
    name: string;
    price: number;
  }[];
  rc: {
    name: string;
    price: number;
  }[];
}

export interface ReviewOrderModalProps extends ModalParams {
  formData?: ReviewOrderForm;
  onConfirmation: (formData: ReviewOrderForm) => void;
}

export const ReviewOrderModal = ({
  isOpen,
  close,
  formData,
  onConfirmation,
}: ReviewOrderModalProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal
      isVisible={isOpen}
      close={close}
      className="vertical-scrollbar max-h-[90vh] max-w-[624px] overflow-y-auto "
    >
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6">
        <h1 className="text-[28px] leading-9 text-content-base-default">
          Review & finalize order
        </h1>
        <p className="font-semibold leading-[22px] text-content-base-subdued">
          Take a moment to review the details of your order. This includes your
          selected plans, any additional services, and the total cost.
        </p>
      </div>
      {formData && (
        <div className="flex flex-col gap-3 p-8">
          <div className="flex flex-col gap-2">
            <h1 className="text-base font-bold text-content-base-default">
              Your cart ({formData.products.length})
            </h1>
            <div className="mb-[20px] flex  h-full flex-col gap-2 overflow-x-scroll rounded bg-[#28283B] p-2 scrollbar-none">
              <ProductTypeTag type={formData.productType} />
              <div className="flex h-full gap-4">
                {formData.products.map((product) => (
                  <div
                    key={product.id}
                    className="flex w-[90px] flex-col gap-2"
                  >
                    <img
                      src={product.img}
                      alt="product"
                      className="h-full w-full"
                    />
                    <div className="flex flex-col gap-2">
                      <h1
                        title={product.title}
                        className="max-h-4 w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-bold text-content-base-default"
                      >
                        {product.title}
                      </h1>

                      <ProductPriceInfo
                        price={product.monthly_price}
                        months={product.months}
                        repeat={true}
                      />
                      <ProductPriceInfo
                        price={product.from_price}
                        months={product.months}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <PriceBreakdown
              info={formData.locations.length}
              title="Total locations"
            >
              <div className="flex flex-col gap-[10px]">
                {formData.locations.map((location, index) => (
                  <span
                    key={index}
                    className="text-base font-bold text-content-base-default"
                  >
                    {index + 1}.&nbsp;{location}
                  </span>
                ))}
              </div>
            </PriceBreakdown>
            <PriceBreakdown
              info={formData.services.length}
              title="Total services"
            >
              <div className="flex flex-col gap-[10px]">
                {formData.services.map((service, index) => (
                  <span
                    key={index}
                    className="text-base font-bold text-content-base-default"
                  >
                    {service}
                  </span>
                ))}
              </div>
            </PriceBreakdown>
            <PriceBreakdown
              info={
                <span className="font-bold text-content-accent-default">
                  {formatCurrency(1947.0)}
                </span>
              }
              title="Total non-recurring charges"
            >
              <div className="flex flex-col gap-[10px] text-base font-bold">
                {formData.nrc.map((item, index) => (
                  <div key={index} className="flex justify-between">
                    <span className="text-content-base-default">
                      {item.name}
                    </span>
                    <span className="text-content-base-subdued">
                      {formatCurrency(item.price)}
                    </span>
                  </div>
                ))}
              </div>
            </PriceBreakdown>
            <PriceBreakdown
              info={
                <span className="font-bold text-content-accent-default">
                  {formatCurrency(922.0)}
                </span>
              }
              title="Total recurring charges"
            >
              <div className="flex flex-col gap-[10px] text-base font-bold">
                {formData.rc.map((item, index) => (
                  <div key={index} className="flex justify-between">
                    <span className="text-content-base-default">
                      {item.name}
                    </span>
                    <span className="text-content-base-subdued">
                      {formatCurrency(item.price)}
                    </span>
                  </div>
                ))}
              </div>
            </PriceBreakdown>
          </div>
          <div className="mt-3 flex flex-col gap-2">
            <p className="text-base font-bold text-content-base-subdued">
              By clicking the following, you are confirming that you have read,
              understood, and agree to{' '}
              <a
                href={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
              >
                {"Granite's"} Terms & Conditions.
              </a>
            </p>
            <Checkbox
              label="I agree to Granite’s Terms & Conditions"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="mt-2 flex flex-col gap-5 md:flex-row">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>
              <GraniteButton
                size="large"
                disabled={!isChecked}
                onClick={() => {
                  if (formData && onConfirmation) {
                    onConfirmation(formData);
                    close();
                  }
                }}
              >
                Agree & submit
              </GraniteButton>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
