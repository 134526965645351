import { Outlet } from 'react-router-dom';

import Cart from './Cart';
import { CartContextProvider } from './CartProvider';
import { AccessCheckoutProvider } from './context/AccessCheckoutProvider';

const AccessExpressV3 = () => {
  return (
    <div className="mr-16 flex flex-1 xl:mr-0">
      <CartContextProvider>
        <AccessCheckoutProvider>
          <Outlet />
          <Cart />
        </AccessCheckoutProvider>
      </CartContextProvider>
    </div>
  );
};

export default AccessExpressV3;
