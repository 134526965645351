import clsx from 'clsx';
import IconButton from 'components/IconButton';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

interface AccordionProps {
  title: string;
  info: number | ReactNode;
  children: ReactNode;
}

const Accordion = ({ title, info, children }: AccordionProps) => {
  const [expand, setExpand] = useState(false);

  const onExpand = () => setExpand((prev) => !prev);

  return (
    <div onClick={onExpand}>
      <div className="flex justify-between gap-4">
        <p className="flex cursor-pointer gap-2 font-bold">
          <span className="text-content-base-subdued">{title}</span>{' '}
          <IconButton
            icon={'chevronDown'}
            className={clsx({ 'rotate-180': expand })}
            buttonType="ghost"
            color="currentColor"
          />
        </p>
        <p className="text-white">{info}</p>
      </div>
      <motion.div
        className="overflow-hidden"
        initial={{ height: 0, opacity: 0, marginTop: 0 }}
        animate={
          expand
            ? { height: 'auto', opacity: 1, marginTop: `8px` }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 15,
        }}
      >
        <div className="flex flex-col gap-4 rounded border border-[#47556980] p-4">
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export { Accordion as ReviewOrderAccordion };
