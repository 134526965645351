import React, { createContext, useContext, useMemo } from 'react';
import { makeAutoObservable } from 'mobx';
import { ProductType } from './accessExpress.types';
import { SiteAddress } from 'api/addresssearch/schema';

export interface ICartItem {
  id: number;
  category: string;
  subCategory?: string;
  name: string;
  price: number;
  quantity: number;
  outOfStock?: boolean;
}

// const mockItems: ICartItem[] = [
//   {
//     id: 1,
//     category: 'Access',
//     subCategory: 'MNS',
//     name: 'Broadband Cable Circuit with MNS',
//     price: 236.0,
//     quantity: 1,
//   },
//   {
//     id: 2,
//     category: 'Mobility',
//     name: 'Apple iPad Mini 128GB Purple (7th Gen)',
//     price: 649.0,
//     quantity: 1,
//   },
//   {
//     id: 3,
//     category: 'Voice',
//     name: 'Hosted Voice (Base Seat)',
//     price: 108.0,
//     quantity: 1,
//   },
// ];

export class CartStore {
  private _isOpen = false;
  private _isEditingLocations = false;
  private _locations: Array<SiteAddress> = [
    // TODO: Remove mock data
    // {
    //   name: '04683868 - 060087 - Grove City, OH [120801]',
    //   address_line_1: '02997 Meadow Pond Ct',
    //   address_line_2: null,
    //   parent_macnum: 'Valvoline',
    //   parent_name: '03479292',
    //   city: 'Grove City',
    //   state: 'OH',
    //   zip: '43123',
    //   id: 3570048,
    // },
    // {
    //   name: '04683868 - 060087 - Grove City, OH [120801]',
    //   address_line_1: '12997 Meadow Pond Ct',
    //   address_line_2: null,
    //   parent_macnum: 'Valvoline',
    //   parent_name: '03479292',
    //   city: 'Grove City',
    //   state: 'OH',
    //   zip: '43123',
    //   id: 3570048,
    // },
    // {
    //   name: '04683868 - 060087 - Grove City, OH [120801]',
    //   address_line_1: '12997 Meadow Pond Ct',
    //   address_line_2: null,
    //   parent_macnum: 'Valvoline',
    //   parent_name: '03479292',
    //   city: 'Grove City',
    //   state: 'OH',
    //   zip: '43123',
    //   id: 3570048,
    // },
  ];

  items: ICartItem[] = []; // mockItems;
  selectedAccessProduct: ProductType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    console.log('Cart Provider initialized');
  }

  get locations() {
    return this._locations;
  }

  get totalItems() {
    return this.items.length;
  }

  get isOpen() {
    return this._isOpen;
  }

  get isEditingLocations() {
    return this._isEditingLocations;
  }

  setLocations(newLocations: Array<SiteAddress>) {
    this._locations = newLocations;
  }

  editLocations() {
    this._isEditingLocations = true;
  }

  doneEditingLocations() {
    this._isEditingLocations = false;
  }

  open() {
    this.selectedAccessProduct = null;
    this._isOpen = true;
  }

  close() {
    this._isOpen = false;
  }

  selectProduct(product: ProductType) {
    this.open();
    this.selectedAccessProduct = product;
  }

  clearAll() {
    this.items = [];
  }

  checkout() {}

  removeItem(id: number) {
    this.items = this.items.filter((item) => item.id !== id);
  }

  updateItemQuantity(id: number, newQuantity: number) {
    if (newQuantity < 1) return;
    this.items = this.items.map((item) =>
      item.id === id ? { ...item, quantity: newQuantity } : item,
    );
  }

  addItem(item: ICartItem) {
    this.open();
    const existingItem = this.items.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      const samePrice = existingItem.price === item.price;
      if (!samePrice) {
        existingItem.price = item.price;
        existingItem.quantity = item.quantity;
      } else existingItem.quantity += item.quantity;
    } else {
      this.items = [...this.items, item];
    }
  }
}

const CartContext = createContext<CartStore | null>(null);

export const CartContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const store = useMemo(() => new CartStore(), []);
  return <CartContext.Provider value={store}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCartStore must be used within a CartProvider');
  }
  return context;
};
