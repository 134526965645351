import { z } from 'zod';
import { DeepPartial } from 'react-hook-form';

export const TOUR_SECTIONS = z.enum([
  'Dashboard',
  'TechExpress',
  'NOCExpress',
  'Mobility',
  'User Management',
  'Inventory',
]);

export const TOUR_STATUSES = z.enum([
  'Not Started',
  'In Progress',
  'Completed',
  'Skipped',
]);

export type TourSection = z.infer<typeof TOUR_SECTIONS>;

export type TourStatus = z.infer<typeof TOUR_STATUSES>;

export const ProductTourBaseSchema = z.object({
  id: z.string(),
  user_id: z.string(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  step: z.number(),
  section: TOUR_SECTIONS,
  status: TOUR_STATUSES,
});

export type ProductTourStep = z.infer<typeof ProductTourBaseSchema>;

export const ProductTourSchema = z.array(ProductTourBaseSchema);

export type ProductTourResponse = z.infer<typeof ProductTourSchema>;

export type ProductTourRequest = DeepPartial<
  z.infer<typeof ProductTourBaseSchema>
>;
