import clsx from 'clsx';
import React from 'react';

export const CartBody = ({
  children,
  isOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    <div
      className={clsx(
        'relative flex w-full flex-grow flex-col gap-8 transition-opacity duration-500 xs:w-[352px]',
        isOpen ? 'opacity-100' : 'pointer-events-none opacity-0',
      )}
    >
      {children}
    </div>
  );
};

export default CartBody;
