import { ArrowBack } from 'react-ionicons';

const AccessBackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="flex items-center gap-2 text-xs font-bold text-neutral-500 hover:text-[#6C9DFF]"
      onClick={onClick}
    >
      <ArrowBack color="currentColor" width="18px" height="18px" />
      Back
    </button>
  );
};

export default AccessBackButton;
