import clsx from 'clsx';
import { formatCurrency } from 'shared/util/formatCurrency';
import {
  TermType,
  getServiceActivationFee,
} from 'screens/SelectServices/utils';
import { ProductType } from 'screens/SelectServices/schemas';

interface AccessServiceSummaryProps {
  data: (ProductType & { term: TermType }) | undefined;
  isPinnedSummary?: boolean;
  isDisabled?: boolean;
}

export const AccessServiceSummary = ({
  data,
  isPinnedSummary = false,
  isDisabled = false,
}: AccessServiceSummaryProps) => {
  const pricing = data?.formal_broadband_pricing || data?.formal_dia_pricing;

  const serviceActivationFee = getServiceActivationFee(data);

  return (
    <div className="font-bold">
      <div className="mb-1 text-xs text-content-base-default">
        Non-recurring charges
      </div>
      <div className="mb-4 flex w-full justify-between gap-4">
        <div>Service activation fee</div>
        <div
          className={clsx(
            'text-xl transition-colors duration-500',
            'text-content-base-subdued',
            isPinnedSummary && 'text-purple-300',
            isDisabled && '!text-content-base-default',
          )}
        >
          ${serviceActivationFee}
        </div>
      </div>
      <div className="mb-1 text-xs text-content-base-default">
        Monthly recurring charges
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>Connection</div>
        <div className="text-content-base-default">
          {formatCurrency(pricing?.circuit)}
        </div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>Modem</div>
        <div className="text-content-base-default">
          {formatCurrency(pricing?.modem || pricing?.equipment)}
        </div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>IP blocks</div>
        <div className="text-content-base-default">
          {formatCurrency(pricing?.ip)}
        </div>
      </div>
      <div className="mb-2 flex justify-between gap-4">
        <div>
          {data?.type === 'dia'
            ? 'Carrier surcharge recovery'
            : 'Internet access recovery'}
        </div>
        <div className="text-content-base-default">
          {formatCurrency(
            (pricing as { internet_access_recovery?: number })
              ?.internet_access_recovery ??
              (pricing as { carrier_surcharge_recovery?: number })
                ?.carrier_surcharge_recovery ??
              0,
          )}
        </div>
      </div>

      <div className="flex justify-between gap-4">
        <div className="">Monthly price</div>
        <div
          className={clsx(
            'text-xl transition-colors duration-500',
            'text-content-base-subdued',
            isPinnedSummary && 'text-purple-300',
            isDisabled && '!text-content-base-default',
          )}
        >
          {formatCurrency(pricing?.total_mrc ?? 0)}
        </div>
      </div>
    </div>
  );
};
