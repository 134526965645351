import { useCallback, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { OpenOutline } from 'react-ionicons';

import { HomeMenuItem } from './HomeMenu';
import { useClickOutside } from 'hooks/useClickOutside';

interface SubMenuProps {
  items: HomeMenuSubItem[];
  isWide: boolean;
  onClickOutside: () => void;
  outsideRefs: React.RefObject<HTMLDivElement>[];
  show: boolean;
}

export const HomeSubMenu = ({
  items,
  isWide,
  onClickOutside,
  outsideRefs,
  show,
}: SubMenuProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const subMenu = (portal: boolean, isWide: boolean) => (
    <div
      className={clsx(
        'pointer-events-auto relative p-2 pr-0 transition-all duration-300 ease-out xl:pr-2',
        !isWide && '-ml-1',
        portal &&
          'bg-opacity-85 rounded bg-[#262636] shadow-elevation5 backdrop-blur',
        portal && !isWide && 'relative left-[40px] overflow-hidden',
      )}
    >
      <div
        className={clsx(
          'flex flex-col gap-1 p-0 pb-0 pl-4 pt-0 transition-all duration-300 ease-out',
          portal && !isWide && 'relative ml-[-38px]',
        )}
      >
        {items.map((item, index) => (
          <HomeSubMenuItem
            key={item.title}
            isWide={isWide}
            to={item.to}
            target={item.target}
            onClick={item.onClick}
            title={''}
            isFirst={index === 0}
            isActive={item.isActive}
            className={item.className}
          >
            <div className="relative flex w-full">
              <div
                className={clsx(
                  'overflow-hidden truncate',
                  item.target === '_blank' && 'group-hover:pr-6',
                )}
              >
                {item.title}
              </div>
              {item.target && (
                <span className="absolute right-0 opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100">
                  <OpenOutline
                    width="16px"
                    height="16px"
                    color="currentColor"
                  />
                </span>
              )}
            </div>
          </HomeSubMenuItem>
        ))}
      </div>
    </div>
  );

  useClickOutside([...outsideRefs, menuRef], () => !isWide && onClickOutside());

  const { styles, attributes, forceUpdate } = usePopper(
    targetRef.current,
    menuRef.current,
    {
      placement: 'right',
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, -48] } },
        { name: 'eventListeners', options: { scroll: true, resize: true } },
        { name: 'zIndex', options: { padding: 1000 } },
      ],
    },
  );

  useEffect(() => {
    forceUpdate?.();
  }, [forceUpdate, show, isWide]);

  return (
    <div
      className={clsx(
        isWide && 'overflow-hidden transition-all duration-[300ms] ease-in-out',
        isWide
          ? show
            ? 'max-h-[150px] opacity-100'
            : 'max-h-0 opacity-0'
          : show
            ? 'visible'
            : 'hidden',
      )}
    >
      <div ref={targetRef}>{subMenu(false, isWide)}</div>
      <div
        ref={menuRef}
        style={styles.popper}
        {...attributes.popper}
        className="pointer-events-none z-[50]"
      >
        {!isWide && subMenu(true, isWide)}
      </div>
    </div>
  );
};

export interface HomeMenuSubItem extends Omit<HomeMenuItem, 'icon'> {
  onClick?: () => void;
}

interface SubMenuItemProps extends HomeMenuSubItem {
  children: React.ReactNode;
  isWide: boolean;
  isFirst: boolean;
}

const HomeSubMenuItem = ({
  children,
  to,
  target,
  onClick,
  isFirst,
  isActive,
  isWide,
  className,
}: SubMenuItemProps) => {
  const isSelected = isActive;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        event.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  return (
    <div
      className={clsx(
        'group relative flex h-8 w-[264px] flex-row items-start xl:w-[174px]',
        isWide ? 'pl-4' : 'pl-[18px]',
      )}
    >
      <div
        className={clsx(
          'absolute left-[0] mr-2 h-[39px] w-[8px]',
          isFirst ? 'top-[2px]' : 'top-[-28px]',
        )}
      >
        {isFirst ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="49"
            viewBox="0 0 8 49"
            fill="none"
          >
            <path
              d="M0.5 1C0.5 1 0.499999 8.501 0.499995 14.5021C0.499993 17.2628 4 18.5021 7 17.5021"
              stroke="#94A3B8"
              strokeLinecap="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="49"
            viewBox="0 0 8 49"
            fill="none"
          >
            <path
              d="M0.5 1C0.5 1 0.499999 38.501 0.499995 44.5021C0.499993 47.2628 4 48.5021 7 47.5021"
              stroke="#94A3B8"
              strokeLinecap="round"
            />
          </svg>
        )}
      </div>
      <NavLink
        className={clsx(
          'flex w-full flex-row items-stretch rounded p-2 transition-all duration-300 ease-out hover:bg-[#2B2B3C]',
          isSelected && '!bg-[#333346] text-content-base-default',
          !isWide && 'ml-[4px] w-[152px]',
          className,
        )}
        to={to || ''}
        onClick={handleClick}
        target={target}
      >
        <div
          className={clsx(
            'w-full items-center lg:leading-4',
            isSelected ? 'font-bold' : 'font-semibold',
          )}
        >
          {children}
        </div>
      </NavLink>
    </div>
  );
};
