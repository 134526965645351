import { FC, useMemo } from 'react';
import CardList from '../CardList';
import {
  CommonAttributesProps,
  MobilityProduct,
  VariantsType,
} from 'screens/AccessExpressV3/accessExpress.types';
import { formatMobilityPrice } from 'screens/AccessExpressV3/utils';

interface VariantProps
  extends CommonAttributesProps,
    Pick<MobilityProduct, 'variants' | 'productDesc'> {
  selected: VariantsType | null;
  setSelected: (v: VariantsType) => void;
}

export const VariantTitleBasedOnProductDesc = {
  'WIRELESS ROUTER': 'Choose Netcloud Essentials plan',
  'SIM CARD': '', // not supported form API
  TABLET: 'Choose storage size',
  'SMART PHONE': 'Choose storage size',
  'BASIC CELL PHONE': '', // not supported form API
  HOTSPOT: '', // not supported form API
  MODEM: 'Choose speed',
  UNIVERSAL: '', // not supported form API
  null: '', // handle in case of null!
};

const Variant: FC<VariantProps> = ({
  handleStep,
  step,
  variants,
  selected,
  setSelected,
  productDesc,
}) => {
  const onSelect = (item: VariantsType) => {
    handleStep && handleStep(step ?? 1);
    setSelected(item);
  };

  const removeMatchingPrefix = (alias: string, description: string) => {
    const aliasWords = alias.trim().split(/\s+/);
    const descriptionWords = description.trim().split(/\s+/);

    let i = 0;
    while (i < aliasWords.length && aliasWords[i] === descriptionWords[i]) {
      i++;
    }

    return descriptionWords
      .slice(i)
      .join(' ')
      .replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, ''); // Remove matched words and join the rest
  };

  const options = useMemo(
    () =>
      variants.map((variant, index) => {
        const purchased = variant.children.find(
          (child) => child.productGroupDesc === 'PURCHASED',
        );
        const financed =
          variant.children.find((child) => child.contractTerm_Months === 12) ||
          variant.children.find((child) => child.contractTerm_Months === 24) ||
          variant.children.find((child) => child.contractTerm_Months === 36);

        const purchasedPrice =
          purchased?.originalPrice ||
          (!purchased?.originalPrice && !financed?.originalPrice)
            ? `$${formatMobilityPrice(purchased?.originalPrice)}`
            : '';
        const or =
          purchased?.originalPrice && financed?.originalPrice ? `or` : '';
        const financePrice = financed?.originalPrice
          ? `${formatMobilityPrice(
              financed?.originalPrice,
            )} /month for ${financed?.contractTerm_Months} months`
          : '';
        const body = `${purchasedPrice} ${or} ${financePrice}`;
        return {
          id: `${index}`,
          title: removeMatchingPrefix(
            variant.productAlias,
            variant.productCodeDescription,
          ),
          body: body,
          item: { ...variant, id: `${index}` },
        };
      }),
    [variants],
  );

  return (
    <>
      <CardList
        title={VariantTitleBasedOnProductDesc[productDesc ?? 'null']}
        selected={selected?.id || null}
        onClick={(item) => onSelect(item as VariantsType)}
        options={options}
      />
    </>
  );
};

export default Variant;
