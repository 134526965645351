import clsx from 'clsx';
import { TagType } from 'screens/AccessExpressV3/accessExpress.types';
import { ReactComponent as BestOfferIcon } from 'assets/images/best-offer.svg';

const OfferBadge = ({
  tag,
  isLoading,
  isAlternativeOffer,
}: {
  tag: TagType;
  isLoading: boolean;
  isAlternativeOffer: boolean;
}) => {
  if (isAlternativeOffer) {
    return (
      <div className="flex h-6 w-[132px] items-center justify-center gap-1 rounded bg-background-base-surface-2 text-center text-destructive">
        {!isLoading && (
          <p className="text-xs font-bold text-content-base-default">
            You might also like
          </p>
        )}
      </div>
    );
  }
  return (
    <div
      className={clsx(
        'flex h-6 items-center justify-center gap-1 rounded text-center text-destructive',
        'bg-[linear-gradient(90deg,rgba(126,119,179,0.24)_0%,rgba(137,137,255,0.24)_25%,rgba(108,157,255,0.24)_49.9%,rgba(120,207,218,0.24)_75%,rgba(159,244,169,0.24)_100%),linear-gradient(0deg,rgba(255,255,255,0.12),rgba(255,255,255,0.12))]',
        tag === 'Best offer' ? 'w-full' : 'w-[120px] rounded-lg',
      )}
    >
      {!isLoading && (
        <>
          <p className="text-xs font-bold text-content-base-default">{tag}</p>
          {tag === 'Best offer' && <BestOfferIcon />}
        </>
      )}
    </div>
  );
};

export default OfferBadge;
