import clsx from 'clsx';
import IconButton from 'components/IconButton/IconButton';
import { useSingleServiceManager } from 'context/SingleServiceManagerContext';
import { motion } from 'framer-motion';

const ExpandOptionsButton = ({
  expand,
  onExpand,
  expandHeight,
}: {
  expand: boolean;
  onExpand: () => void;
  expandHeight: number;
}) => {
  const { cartState } = useSingleServiceManager();
  const addedOptionsCount = Object.values(cartState).filter(Boolean).length;
  return (
    <motion.div
      initial={{ y: 0 }}
      animate={{ y: expand ? -expandHeight : 0 }}
      transition={{ duration: 0.4, ease: 'easeInOut' }}
      className="relative w-full"
    >
      <div
        className={clsx(
          'flex h-[88px] w-full items-center justify-between gap-4 rounded-b-sm border border-[#47556980] p-4',
          !expand && 'border-t-0',
          expand && 'rounded-t-[4px]',
        )}
      >
        <p
          className="flex w-full cursor-pointer items-center justify-between gap-2 font-bold"
          onClick={onExpand}
        >
          <span className="text-content-base-subdued">All other options</span>
          <div className="flex gap-4">
            {!expand && addedOptionsCount > 0 && (
              <span className="text-base font-bold text-[#9796F3]">
                ({addedOptionsCount}) other option added to cart!
              </span>
            )}
            <IconButton
              icon={'chevronDown'}
              className={clsx({ 'rotate-180': expand })}
              buttonType="ghost"
              color="currentColor"
            />
          </div>
        </p>
      </div>
    </motion.div>
  );
};

export default ExpandOptionsButton;
