import { useCallback, useEffect, useState } from 'react';
import { getMobilityProducts } from '../utils';
import { useQuery, useQueryClient } from 'react-query';
import Products from '../BaseComponents/Products/Products';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BaseResponseMobilityAPI, ProductType } from '../accessExpress.types';

const PAGE_SIZE = 12;

const MobilityV3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [products, setProducts] = useState<ProductType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    location.state?.page ?? 0,
  );

  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData([
    'order-service-mobility',
    searchParams.get('type'),
  ]);

  const _getProducts = () => {
    setCurrentPage(location.state?.page ?? 0);
    setProducts([]);
    if (cachedData)
      return Promise.resolve(
        cachedData as BaseResponseMobilityAPI<ProductType[]>,
      );

    return getMobilityProducts({ type: searchParams.get('type') ?? '' });
  };

  const { data, isFetching } = useQuery(
    ['order-service-mobility', searchParams.get('type')],
    _getProducts,
    {
      onSuccess: (data) => {
        filteredData(data);
      },
    },
  );

  const sortProducts = useCallback(
    (products: ProductType[]) => {
      const order = searchParams.get('sort') ?? null;
      if (order === null) return products;

      return products.sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        // Sorting based on title
        if (order === 'asc') {
          return titleA.localeCompare(titleB);
        } else if (order === 'desc') {
          return titleB.localeCompare(titleA);
        }
        // Sorting by createData (newest or oldest)
        else if (order === 'newest') {
          // Ensure createData is not undefined
          const dateA = a.createdDate ? new Date(a.createdDate) : new Date(0);
          const dateB = b.createdDate ? new Date(b.createdDate) : new Date(0);
          return dateB.getTime() - dateA.getTime();
        } else if (order === 'oldest') {
          const dateA = a.createdDate ? new Date(a.createdDate) : new Date(0);
          const dateB = b.createdDate ? new Date(b.createdDate) : new Date(0);
          return dateA.getTime() - dateB.getTime();
        }
        return 0; // Default case
      });
    },
    [searchParams],
  );

  const filteredData = useCallback(
    (data: BaseResponseMobilityAPI<ProductType[]>) => {
      if ((searchParams.get('search') || searchParams.get('sort')) && data) {
        setCurrentPage(location.state?.page ?? 0);
        if (searchParams.get('search')?.length === 0)
          setProducts(sortProducts(data.data));
        else
          setProducts(() =>
            sortProducts(
              data.data.filter((product) =>
                product.title
                  .toLowerCase()
                  .includes(searchParams.get('search')?.toLowerCase() ?? ''),
              ),
            ),
          );
      } else {
        setProducts(data?.data ?? []);
      }
    },
    [searchParams, location.state?.page, sortProducts],
  );

  useEffect(() => {
    if (data) filteredData(data);
  }, [data, filteredData]);

  const onProductClick = ({ id }: ProductType) =>
    navigate(`../../product/${id}`, { state: { page: currentPage } });

  const onChangePageHistory = (page: number) =>
    navigate(location, { state: { page }, replace: true });

  return (
    <div className="mt-6">
      <Products
        type="initial"
        onProductClick={onProductClick}
        price_type="from"
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onChangePageHistory={onChangePageHistory}
        isLoading={isFetching}
        products={products ?? []}
        total_pages={Math.ceil((products?.length ?? 0) / PAGE_SIZE)}
        page={currentPage + 1}
        page_size={PAGE_SIZE}
      />
    </div>
  );
};

export default MobilityV3;
