import { SiteAddress } from 'api/addresssearch/schema';
import { apiClient } from 'api/apiClient';
import { BroadbandProductType, DIAProductType, LocationType } from './schemas';
import { DeepPartial } from 'react-hook-form';
import { CreateQuoteResponse } from 'api/accessexpress/schema';
import { useAuthUser } from 'hooks/useAuthUser';

export const simpleFlowLocation = (
  location: SiteAddress,
): DeepPartial<LocationType[]> => {
  return [
    {
      address_line_1: location.address_line_1,
      address_line_2: location.address_line_2,
      state: location.state,
      city: location.city,
      zip: location.zip,
      products: [
        {
          product_type: 'Broadband',
          term: '3',
          min_download: 5,
          max_download: 1500,
          ip_type: 'DYNAMIC',
        },
      ],
    },
  ];
};
export const revertShapeQuoteRequest = (
  shapedRequest: ReturnType<typeof shapeQuoteRequest>,
) => {
  const products = shapedRequest.locations[0].products?.map((product) => ({
    ...product,
    connection_type: product.connection_type,
    min_download: product.min_download
      ? parseFloat(product.min_download)
      : undefined,
    max_download: product.max_download
      ? parseFloat(product.max_download)
      : undefined,
  })) as (BroadbandProductType | DIAProductType)[];

  const broadbandProduct = products?.find(
    (product) => product.product_type === 'Broadband',
  ) as BroadbandProductType | undefined;
  const diaProduct = products?.find(
    (product) => product.product_type === 'DIA',
  ) as DIAProductType | undefined;

  if (diaProduct) {
    diaProduct.exclude_carriers = (
      diaProduct.exclude_carriers as unknown as string[]
    )?.join(',');
    diaProduct.include_carriers = (
      diaProduct.include_carriers as unknown as string[]
    )?.join(',');
    diaProduct.preferred_carriers = (
      diaProduct.preferred_carriers as unknown as string[]
    )?.join(',');
    diaProduct.diversity_needed =
      diaProduct.exclude_carriers.length > 0 ||
      diaProduct.include_carriers.length > 0 ||
      diaProduct.preferred_carriers.length > 0;
  }

  return {
    quoteName: shapedRequest.quote_name!,
    locations: shapedRequest.locations.map((location) => ({
      address_line_1: location.address1,
      address_line_2: location.address2,
      state: location.state!,
      city: location.city!,
      zip: location.zip_code!,
    })),
    products: {
      ...(broadbandProduct ? { broadband: broadbandProduct } : {}),
      ...(diaProduct ? { dia: diaProduct } : {}),
    },
  };
};

export const shapeQuoteRequest = (
  userInfo: ReturnType<typeof useAuthUser>,
  locations: DeepPartial<LocationType[]>,
  quoteName?: string,
  id?: string,
) => {
  return {
    id: id,
    quote_name: quoteName,
    customer_name: userInfo.companyName ?? '',
    requester: userInfo.name,
    business_unit: 'standard',
    email: userInfo.email || '',
    locations: locations.map((location) => ({
      address1: location?.street || location?.address_line_1,
      address2: location?.address_line_2,
      state: location?.state,
      city: location?.city,
      zip_code: location?.zip,
      products: location?.products?.map((product) => ({
        ...product,
        ...(product?.product_type === 'Broadband'
          ? { connection_type: product.connection_type || 'Cable' }
          : {}),
        min_download: product?.min_download?.toString(),
        max_download: product?.max_download?.toString(),
      })),
    })),
  };
};

export const loadDraft = async (draftId: string) => {
  const response = await apiClient.get(`/api/v1/quotes/draft/${draftId}`);
  return revertShapeQuoteRequest(response.data);
};

export const saveDraft = async (
  userInfo: ReturnType<typeof useAuthUser>,
  locations: DeepPartial<LocationType[]>,
  quoteName?: string,
  draftId?: string,
) => {
  const payload = shapeQuoteRequest(userInfo, locations, quoteName, draftId);

  const response = await apiClient.post('/api/v1/quotes/draft', payload);
  return response.data;
};

export const createQuoteRequest = async (
  userInfo: ReturnType<typeof useAuthUser>,
  locations: DeepPartial<LocationType[]>,
  quoteName?: string,
  quoteId?: string,
) => {
  const payload = shapeQuoteRequest(userInfo, locations, quoteName, quoteId);

  const response = await apiClient.post<CreateQuoteResponse>(
    '/api/v1/quotes/',
    payload,
  );
  return response.data;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
};
