import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';

// cart location schema
const AccessLocationSchema = z.object({
  address_line_1: z.string().transform(nullToUndefined).nullish(),
  address_line_2: z.string().transform(nullToUndefined).nullish(),
  street: z.string().transform(nullToUndefined).nullish(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  cw_site_id: z.string().transform(nullToUndefined).nullish(),
  name: z.string().transform(nullToUndefined).nullish(),
  parent_macnum: z.string().transform(nullToUndefined).nullish(),
  parent_name: z.string().transform(nullToUndefined).nullish(),
  id: z.number().transform(nullToUndefined).nullish(),
});

export type AccessLocation = z.infer<typeof AccessLocationSchema>;

const CreateChildAccountSchema = z.object({
  parent_account: OptionLabelValueSchema.optional(),
  location_name: z.string().transform(nullToUndefined).nullish(),
  billing_type: OptionLabelValueSchema.optional(),
  invoice_delivery_method: OptionLabelValueSchema.optional(),
  invoice_style: OptionLabelValueSchema.optional(),
});

export const AddressSchema = z
  .object({
    site_name: z.string().nonempty('Required'),
    macnum: z.string().nonempty('Required'),
    business_name: z.string().nonempty('Required'),
    local_contact_name: z.string().nonempty('Required'),
    local_contact_phone: z.string().nonempty('Required'),
    address_line_2: z.string().transform(nullToUndefined).nullish(),
  })
  .merge(AccessLocationSchema)
  .merge(CreateChildAccountSchema)
  .superRefine((data, ctx) => {
    if (!data.cw_site_id) {
      // If cw_site_id is missing, these fields should be required
      if (!data.parent_account) {
        ctx.addIssue({
          path: ['parent_account'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.location_name) {
        ctx.addIssue({
          path: ['location_name'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.billing_type) {
        ctx.addIssue({
          path: ['billing_type'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.invoice_delivery_method) {
        ctx.addIssue({
          path: ['invoice_delivery_method'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        });
      }
      if (!data.invoice_style) {
        ctx.addIssue({
          path: ['invoice_style'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        });
      }
    }
  });

export const AccessCheckoutFormSchema = z.object({
  locations: z.array(AddressSchema),
  order_name: z.string().transform(nullToUndefined).nullish(),
});

export type AccessCheckoutForm = z.infer<typeof AccessCheckoutFormSchema>;

export const AccessCheckoutRequestSchema = z.object({
  locations: z.array(
    z.object({
      address: AddressSchema._def.schema.extend({
        billing_type: z.string().transform(nullToUndefined).nullish(),
        invoice_style: z.string().transform(nullToUndefined).nullish(),
        invoice_delivery_method: z
          .string()
          .transform(nullToUndefined)
          .nullish(),
      }),

      order_items: z.any(),
    }),
  ),
  status: z.string(),
});

export type AccessCheckoutRequest = z.infer<typeof AccessCheckoutRequestSchema>;

export const AccessReviewOrderFormSchema = z.object({
  order_name: z.string().nonempty('Required'),
});

export type AccessReviewOrderForm = z.infer<typeof AccessReviewOrderFormSchema>;
