import { IconProps } from './types';

const EnterIcon = ({
  color = 'currentColor',
  width = '16',
  height = '11',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 11"
    fill={color}
  >
    <path d="M15.0063 0.358398C14.7536 0.358398 14.5113 0.458768 14.3327 0.637425C14.154 0.816083 14.0536 1.0584 14.0536 1.31106V5.12168C14.0536 5.37434 13.9533 5.61666 13.7746 5.79531C13.596 5.97397 13.3536 6.07434 13.101 6.07434H3.965L5.20346 4.84541C5.38284 4.66602 5.48362 4.42272 5.48362 4.16903C5.48362 3.91533 5.38284 3.67203 5.20346 3.49264C5.02407 3.31325 4.78076 3.21247 4.52707 3.21247C4.27338 3.21247 4.03007 3.31325 3.85068 3.49264L0.992712 6.35061C0.905981 6.44121 0.837995 6.54805 0.792654 6.66499C0.697371 6.89692 0.697371 7.15707 0.792654 7.38901C0.837995 7.50595 0.905981 7.61278 0.992712 7.70339L3.85068 10.5614C3.93924 10.6506 4.04461 10.7215 4.1607 10.7699C4.27679 10.8183 4.40131 10.8432 4.52707 10.8432C4.65283 10.8432 4.77735 10.8183 4.89344 10.7699C5.00953 10.7215 5.11489 10.6506 5.20346 10.5614C5.29275 10.4728 5.36362 10.3674 5.41198 10.2513C5.46035 10.1353 5.48525 10.0107 5.48525 9.88497C5.48525 9.75921 5.46035 9.63469 5.41198 9.5186C5.36362 9.40251 5.29275 9.29714 5.20346 9.20858L3.965 7.97966H13.101C13.859 7.97966 14.5859 7.67855 15.1219 7.14258C15.6578 6.6066 15.959 5.87967 15.959 5.12168V1.31106C15.959 1.0584 15.8586 0.816083 15.6799 0.637425C15.5013 0.458768 15.259 0.358398 15.0063 0.358398Z" />
  </svg>
);

export default EnterIcon;
