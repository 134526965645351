import { getOrderDetails } from 'api/accessexpressv3/api';
import { PageTitleGranite } from 'components';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getDistinctAccessOrderedServices } from '../utils';
import { format } from 'date-fns';
import Main from 'screens/OpenQuote/BaseComponents/MetaInfo/Main';
import Support from 'screens/OpenQuote/BaseComponents/MetaInfo/Support';
import Searchbar from 'components/Table/SearchBar';
import { ColumnFilter, flexRender } from '@tanstack/react-table';
import { Fragment } from 'react';
import { TableCell } from 'components/Table/Table.styles';
import clsx from 'clsx';
import { ChevronUp } from 'react-ionicons';
import Divider from 'components/Divider';
import Pagination from 'components/Table/Pagination';
import { ProductsToLocationsTable } from './styles';
import { useOrderTable } from 'hooks/useOrderDetailsTable';

const getRowIdx = (index: number) => (index + 1) * 2;

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useQuery(
    ['order-details', id],
    () => getOrderDetails(id ?? ''),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'Order services',
      onClick: () => navigate('/access-express/v3/order-services/access'),
    },
    {
      label: 'My orders',
    },
  ];

  const { table, columnFilters } = useOrderTable(data);

  const addressColumn = table.getColumn('address');
  const addressFilter: ColumnFilter | undefined = columnFilters.find(
    (filter) => filter.id === 'address',
  );

  const headerData = {
    includedProducts: getDistinctAccessOrderedServices(data),
    locations: data?.locations?.length.toString() ?? '0',
    dateSubmitted: data?.created_at
      ? format(new Date(data?.created_at), 'MM/dd/yyyy')
      : '',
    submittedBy: data?.requester ?? '',
  };

  return (
    <ContentLayout>
      <PageTitleGranite
        breadcrumbs={breadcrumbs}
        title={
          <div className="flex items-center justify-center gap-6">
            <h1 className="text-4xl font-bold text-content-base-default">
              {data?.order_name}
            </h1>
            <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
              <span className="h-auto text-center text-base font-bold text-content-base-default">
                QR #{data?.order_number}
              </span>
            </div>
          </div>
        }
      />
      <div className="mt-12">
        <Main
          {...headerData}
          title="Order submitted"
          statusColor="bg-[#82F0FF]"
        />
        <Support description="Granite will review this finalized quote and will be in touch with you soon to provide the details of your order." />
      </div>
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-8 !rounded bg-background-base-surface-2 p-6">
          <h1 className="m-0 text-2xl font-bold text-content-base-default">
            You may review the details of this requested quote but can no longer
            make adjustments.
          </h1>
          <div className="grid grid-cols-1 gap-12">
            <div className="flex w-full flex-wrap items-start justify-between gap-4">
              <div className="w-full sm:flex-1">
                <Searchbar
                  placeholder="Search by address"
                  clearAllValues={!addressFilter}
                  onQueryClear={() => addressColumn?.setFilterValue(undefined)}
                  onSearch={(query: string) =>
                    addressColumn?.setFilterValue(query)
                  }
                />
              </div>
            </div>
            <div>
              <ProductsToLocationsTable>
                {table.getHeaderGroups().map((headerGroup, index) => (
                  <Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <div
                        key={header.id}
                        style={{ gridColumnStart: index }}
                        className={clsx(
                          'group row-[1] mb-6 flex items-center text-center font-bold text-content-base-default',
                          header.column.getCanSort() &&
                            'cursor-pointer gap-2 fill-input-content-focus',
                          !header.column.columnDef.meta?.isProduct &&
                            'justify-between !gap-1',
                        )}
                        role="columnheader"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        {header.column.getCanSort() ? (
                          <ChevronUp
                            cssClasses={clsx(
                              'group-hover:text-content-base-default group-active:text-content-accent-default focus:text-content-accent-default',
                              header.column.getIsSorted() === 'desc'
                                ? 'rotate-180'
                                : '',
                              header.column.getIsSorted()
                                ? '!text-content-accent-default'
                                : '',
                            )}
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-subdued))"
                          />
                        ) : null}
                      </div>
                    ))}
                  </Fragment>
                ))}
                {table.getRowModel().rows.map((row, index, rows) => {
                  return (
                    <Fragment key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          style={{
                            gridRow: getRowIdx(index),
                          }}
                          $minWidth={0}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                      {index + 1 < rows.length && (
                        <Divider
                          style={{
                            gridRow: getRowIdx(index) + 1,
                            gridColumnStart: 1,
                            gridColumnEnd: `span ${
                              row.getVisibleCells().length + 1
                            }`,
                          }}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </ProductsToLocationsTable>
              <div className="mt-10 flex">
                <Pagination
                  variant="short"
                  pageCount={table.getPageCount()}
                  totalRows={data?.locations?.length as number}
                  currentPage={table.getState().pagination.pageIndex + 1 || 1}
                  onPageChange={(page: number) => table.setPageIndex(page - 1)}
                  currentRowsShown={table.getRowModel().rows.length}
                  pageSizeChanged={(page: number) =>
                    table.setPageSize(Number(page))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default OrderDetails;
