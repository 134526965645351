import { z } from 'zod';

export const TicketHistorySchema = z.object({
  history_type: z.object({
    id: z.number(),
    link_template: z.string(),
    pillar: z.string(),
    title_template: z.string(),
  }),
  id: z.string(),
  last_seen: z.string(),
  pinned: z.boolean(),
  resource_type: z.string(),
  resource_value: z.string(),
  title: z.string(),
});

export const UserHistoryResponseSchema = z.object({
  pinned: z.array(TicketHistorySchema),
  visited: z.array(TicketHistorySchema),
});

export const UserHistoryActionResponseSchema = z.object({
  message: z.string(),
});

export type TicketHistory = z.infer<typeof TicketHistorySchema>;
export type UserHistoryResponse = z.infer<typeof UserHistoryResponseSchema>;
export type UserHistoryActionResponse = z.infer<
  typeof UserHistoryActionResponseSchema
>;

export const HistoryTypeSchema = z.object({
  id: z.number(),
  link_template: z.string().nullable(),
  pillar: z.string(),
  title_template: z.string().nullable(),
});

export const TotalItemsLimitSchema = z.object({
  pinned: z.number().nullable(),
  visited: z.number().nullable(),
});

export const UserHistorySettingsResponseSchema = z.object({
  total_items_limit: TotalItemsLimitSchema,
  types: z.array(HistoryTypeSchema),
});

export type HistoryType = z.infer<typeof HistoryTypeSchema>;
export type TotalItemsLimit = z.infer<typeof TotalItemsLimitSchema>;
export type UserHistorySettingsResponse = z.infer<
  typeof UserHistorySettingsResponseSchema
>;
