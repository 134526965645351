import { observer } from 'mobx-react-lite';

import { SearchAddressBar } from 'components/SearchAddressBar/SearchAddressBar';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import { ProductBody } from '../../productFlow';
import Locations from '../../Locations/Locations';
import { SiteAddress } from 'api/addresssearch/schema';
import clsx from 'clsx';

const mockLocation = {
  name: '04683868 - 060087 - Grove City, OH [120801]',
  address_line_1: '02997 Meadow Pond Ct',
  address_line_2: null,
  parent_macnum: 'Valvoline',
  parent_name: '03479292',
  city: 'Grove City',
  state: 'OH',
  zip: '43123',
  id: 3570048,
};

const LocationBody = observer(() => {
  const cart = useCartContext();

  const handleLocationsChange = (locations: Array<SiteAddress>) => {
    cart.setLocations(locations);
  };

  if (cart.isEditingLocations) {
    return (
      <Locations locations={cart.locations} onChange={handleLocationsChange} />
    );
  }

  return (
    <>
      <div className={clsx('relative mb-8 flex flex-none flex-col gap-8')}>
        {cart.locations.map((location, index) => (
          <div key={location.id} className="flex flex-col gap-2">
            <SearchAddressBar
              onAddressSelected={() => {}}
              className="flex flex-grow [&_.search-bar-label]:w-[302px] [&_.search-bar-label]:py-0.5 [&_.search-bar-label]:text-sm [&_button]:hidden"
              value={mockLocation}
              prefixElement={
                <div className="-ml-2 flex w-4 flex-none justify-end text-sm text-content-base-subdued">
                  {index + 1}.
                </div>
              }
            />

            {cart.items.length > 0 ? (
              <>
                <ProductBody />
              </>
            ) : (
              <div className="rounded border border-dashed border-stroke-base-default p-2 text-center text-xs font-bold text-button-content-primary-disabled">
                No services added
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
});

export default LocationBody;
