// will be utilized for other product types as needed
const ProductTypeTag = ({ type }: { type: string }) => {
  return (
    <div className="max-w-[65px]">
      <div
        className={
          'flex h-6 items-center justify-center rounded-lg bg-[#6C9DFF14] px-2 text-xs font-bold text-[#6C9DFF]'
        }
      >
        <p className="w-max"> {type}</p>
      </div>
    </div>
  );
};

export default ProductTypeTag;
