import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useProductTourContext } from 'context/ProductTourContext';
import DropdownMenuOptions from './DropdownMenuOptions';

export interface IDropdownOption {
  label: string;
  icon?: React.ReactNode;
  subText?: string;
  onClick: () => void;
  link?: string;
  className?: string;
}

interface DropdownGroup {
  options: IDropdownOption[];
}

interface DropdownMenuProps {
  label: string | React.ReactNode;
  groups: DropdownGroup[];
  className?: string;
  icon?: boolean;
  menuPosition?: 'bottom-right' | 'top-right';
}

const DropdownMenu: React.FC<DropdownMenuProps> = observer(
  ({
    label,
    groups,
    className,
    menuPosition = 'bottom-right',
    // showIcon = true,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const productTour = useProductTourContext();

    const toggleDropdown = (event: React.MouseEvent) => {
      event.stopPropagation();
      setIsOpen(!isOpen);
    };

    const handleOptionClick = (groupIndex: number, optionIndex: number) => {
      groups[groupIndex].options[optionIndex].onClick();
      closeDropdown();
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          closeDropdown();
        }
      };

      window.addEventListener('click', handleOutsideClick);

      return () => {
        window.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    useEffect(() => {
      if (productTour.running && productTour.stepDetails?.userDropDownOpen) {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1000);
      }
    }, [productTour.running, productTour.stepDetails?.userDropDownOpen]);

    return (
      <div className={clsx('relative flex', className)} ref={dropdownRef}>
        <button
          onClick={toggleDropdown}
          className={`border-0 ${
            isOpen ? 'button-background-primary-default' : 'border-transparent'
          } font-body cursor-pointer ${
            isOpen ? 'text-button-background-primary-default' : 'text-white'
          } bg-background-medium rounded-[5px] font-bold hover:text-button-background-primary-default ${
            isOpen ? 'bg-dark' : 'background-base-surface-2'
          }`}
        >
          <div className="flex w-full items-center justify-center gap-2">
            {typeof label === 'string'
              ? label
              : React.cloneElement(label as React.ReactElement, {
                  isSelected: isOpen,
                })}
            {/* <IconMapper
            name={isOpen ? 'chevronUp' : 'chevronDown'}
            onClick={toggleDropdown}
          /> */}
          </div>
        </button>

        {productTour.running && productTour.stepDetails?.userDropDownOpen && (
          <DropdownMenuOptions
            options={groups}
            handleOptionClick={handleOptionClick}
            className={clsx(
              `hide bg-opacity-85 absolute -bottom-4 left-full z-[10] ml-1 flex list-none bg-[#262636] p-3 shadow-elevation3 backdrop-blur`,
            )}
          />
        )}
        {isOpen && !productTour.running && (
          <DropdownMenuOptions
            options={groups}
            handleOptionClick={handleOptionClick}
            className={clsx(
              `hide bg-opacity-85 absolute  left-full z-[1000] ml-2 flex list-none bg-[#262636] p-3 shadow-elevation3 backdrop-blur`,
              {
                'top-0': menuPosition === 'top-right',
                'bottom-0': menuPosition === 'bottom-right',
              },
            )}
          />
        )}
      </div>
    );
  },
);

export const DropdownOption = ({
  groupIndex,
  optionIndex,
  handleOptionClick,
  option,
  isActiveLink,
}: {
  groupIndex: number;
  optionIndex: number;
  handleOptionClick: (groupIndex: number, optionIndex: number) => void;
  option: IDropdownOption;
  isActiveLink?: boolean;
}) => {
  return (
    <li
      key={optionIndex}
      onClick={() => handleOptionClick(groupIndex, optionIndex)}
      className={clsx(
        `flex w-full cursor-pointer items-center gap-2 self-stretch rounded p-2 text-content-base-subdued transition-all duration-300 ease-out hover:cursor-pointer hover:bg-[#2B2B3C]`,
        option.className && option.className,
        isActiveLink &&
          'bg-[#2F515C] !text-content-base-default sm:bg-[#333346]',
      )}
    >
      {option.icon}
      <div className="flex cursor-pointer flex-col items-start justify-center gap-[-2]">
        <button
          className={clsx(
            'm-0 inline-flex w-full max-w-[200px] cursor-pointer text-left text-xs active:border-0',
            isActiveLink ? 'font-bold' : 'font-semibold',
          )}
        >
          <label className="cursor-pointer truncate">{option.label}</label>
        </button>
      </div>
    </li>
  );
};

export default DropdownMenu;
