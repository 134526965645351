import FormSection from 'components/FormSection';
import PageTitle from 'components/PageTitle';
import { SearchAddressBar } from 'components/SearchAddressBar/SearchAddressBar';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { Fragment, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CheckoutForm, CheckoutFormSchema, SpeedEnum } from './schemas';
import { useModal } from 'hooks/useModal';
import CreateChildAccountModal from 'screens/LetUsHelp/GetStarted/CreateChildAccount/CreateChildAccountModal';
import { SiteAddress } from 'api/addresssearch/schema';
import Divider from 'components/Divider';
import Checkbox from 'components/Checkbox/Checkbox';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import RadioButton from 'components/RadioButton';
import MultiOptionButton from 'components/MultiOptionButton';
import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { states } from 'screens/LetUsHelp/GetStarted/CreateChildAccount/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReviewOrderModal } from '../BaseComponents/ReviewOrderModal/ReviewOrderModal';
import { useNavigate } from 'react-router-dom';
import showToast from 'components/Toast/Toast';
import { reviewOrderDummyData } from '../utils';
import AccessBackButton from '../BaseComponents/AccessBackButton';

const Checkout = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    register,
  } = useForm<CheckoutForm>({
    resolver: zodResolver(CheckoutFormSchema),
    defaultValues: {
      sameShippingAddress: true,
    },
  });

  const { isOpen, open, close } = useModal();

  const onSubmit = async (formData: CheckoutForm) => {
    console.log({ formData });
    open();
  };

  const onError = (err: unknown) => console.log({ err });

  const [site, sameShippingAddress, state] = watch([
    'site',
    'sameShippingAddress',
    'state',
  ]);

  const { open: createChildAccountModalOpen, ...createChildAccountModalProps } =
    useModal();

  const formData = useMemo(() => {
    return reviewOrderDummyData;
  }, []);

  const handleConfirmation = () => {
    navigate('/access-express/v3/order-services/mobility');
    showToast.confirmation({
      title: 'Order placed!',
      message: `${formData.products.length} items, ${formData.site}`,
    });
  };

  const handleClose = useCallback(() => close(), [close]);

  const onGoBack = () => navigate(-1);

  return (
    <div className="flex w-full flex-col gap-6 bg-background-base-surface-0 p-6">
      <header className="flex flex-col items-start gap-3">
        <AccessBackButton onClick={onGoBack} />
        <PageTitle title="Checkout" />
      </header>
      <form
        id="checkout-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex h-full flex-col gap-6 rounded bg-gradient-to-b from-[rgba(71,71,104,0.08)] to-[rgba(51,51,80,0.08)] p-6"
      >
        <FormSection title="Service address" gridClassName="grid-cols-1">
          <Fragment>
            <div className="col-span-1">
              <GraniteLabel label="Address" />
              <Controller
                name="site"
                control={control}
                render={({ field }) => (
                  <SearchAddressBar
                    {...field}
                    value={site}
                    onAddressSelected={(address) => {
                      //@ts-expect-error err
                      setValue('site', address);
                    }}
                    name={'search address'}
                    error={errors?.site?.message && 'An address is required'}
                    className="col-span-2"
                    createChildAccountModalOpen={createChildAccountModalOpen}
                    variant="blue"
                  />
                )}
              />
            </div>
          </Fragment>
        </FormSection>
        <Divider />
        <FormSection title="Shipping details" gridClassName="grid-cols-1">
          <div className="col-span-full">
            <h3 className="mb-1 text-base font-bold text-content-base-subdued">
              Shipping address is the same as service address{' '}
            </h3>
            <Controller
              name="sameShippingAddress"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Checkbox
                  {...field}
                  label="Shipping address is the same as my service address"
                  className="col-span-full"
                  checked={value}
                  onChange={onChange}
                  variant="blue"
                />
              )}
            />
          </div>

          {!sameShippingAddress && (
            <FormSection gridClassName="grid-cols-none md:grid-cols-12 gap-y-4">
              <GraniteInput
                label="Street address"
                placeholder="123 Main Street"
                className="col-span-6"
                {...register('address_line_1')}
                innerInputClassName="!w-full !box-border"
                error={errors.address_line_1?.message}
                variant="blue"
              />
              <GraniteInput
                label="Flr/Unit"
                placeholder="John Doe"
                className="col-span-6"
                {...register('unit')}
                innerInputClassName="!w-full !box-border"
                error={errors.unit?.message}
                variant="blue"
              />
              <GraniteInput
                label="City"
                placeholder="City"
                className="col-span-6 md:col-span-4"
                {...register('city')}
                error={errors.city?.message}
                innerInputClassName="!w-full !box-border"
                variant="blue"
              />

              <GraniteSelect
                {...register('state')}
                options={states.map((state) => ({
                  label: state.name,
                  value: state.abbreviation,
                }))}
                label="State"
                onChange={(selectedOption: OptionType | null) => {
                  if (selectedOption) {
                    setValue('state', selectedOption);
                  }
                }}
                value={state}
                error={errors.state && 'Required'}
                className="col-span-6 md:col-span-4"
                variant="blue"
              />
              <GraniteInput
                label="Zip code"
                placeholder="Zip code"
                className="col-span-6 md:col-span-4"
                innerInputClassName="!w-full !box-border"
                {...register('zip_code')}
                error={errors.zip_code?.message}
                variant="blue"
              />
            </FormSection>
          )}
          <FormSection gridClassName="grid-cols-none md:grid-cols-12 gap-y-4">
            <GraniteInput
              {...register(`name`)}
              label="Name"
              error={errors.name?.message}
              variant="blue"
              className="col-span-4"
              innerInputClassName="!w-full !box-border"
            />

            <GraniteInput
              {...register(`business_name`)}
              label="Business name"
              error={errors.business_name?.message}
              variant="blue"
              className="col-span-4"
              innerInputClassName="!w-full !box-border"
            />

            <PhoneNumberInput
              error={errors.phone_number?.message}
              name={`phone_number`}
              control={control}
              label="Phone number"
              variant="blue"
              className="col-span-4"
              innerInputClassName="!w-full !box-border"
            />
          </FormSection>
        </FormSection>

        <Divider />
        <FormSection title="Shipping preferences" gridClassName="grid-cols-1">
          <Controller
            name="shipping_preference"
            control={control}
            render={({ field: { onChange, value } }) => (
              <GraniteLabel
                label="Shipment of out-of-stock items"
                className="col-start-1"
              >
                <RadioButton
                  options={[
                    {
                      value: 'ship_available_items',
                      label: 'Ship items as they become available',
                      sublabel:
                        '(out-of-stock items will ship separately later)',
                    },
                    {
                      value: 'ship_all',
                      label: 'Ship all items together',
                      sublabel: '(pause shipment until all items are in stock)',
                    },
                  ]}
                  selectedValue={value}
                  onChange={onChange}
                  className="text-base"
                  error={errors.shipping_preference?.message}
                  variant="blue"
                />
              </GraniteLabel>
            )}
          />
          <GraniteLabel
            label="Shipment of out-of-stock items"
            className="relative col-start-1 mb-[-25px]"
          />
          <Controller
            name="speed"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="grid gap-4 sm:grid-cols-3">
                <MultiOptionButton
                  label="Standard / ground"
                  option={SpeedEnum.enum.Standard}
                  value={value}
                  onClick={onChange}
                  subText="Ships within 5 business days"
                  className="!justify-start"
                  variant="blue"
                />
                <MultiOptionButton
                  label="2 business-day"
                  option={SpeedEnum.enum.TwoBusinessDay}
                  value={value}
                  onClick={onChange}
                  subText="Ships within 2 business days"
                  className="!justify-start"
                  variant="blue"
                />
                <MultiOptionButton
                  label="Overnight"
                  option={SpeedEnum.enum.Overnight}
                  value={value}
                  onClick={onChange}
                  subText="Ships within 1 business day"
                  className="!justify-start"
                  variant="blue"
                />
                {errors.speed && (
                  <ErrorSubtext
                    className="-mt-2"
                    error="Selecting a speed is required"
                    withTopMargin={false}
                  />
                )}
              </div>
            )}
          />
        </FormSection>
      </form>
      <div className="mt-auto flex self-end">
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="checkout-form"
          variant="tertiary"
        >
          Place order
        </GraniteButton>
      </div>

      <CreateChildAccountModal
        {...createChildAccountModalProps}
        onAccountSubmit={(data: SiteAddress) => {
          setValue('site', data);
        }}
      />
      <ReviewOrderModal
        onConfirmation={handleConfirmation}
        isOpen={isOpen}
        close={handleClose}
        formData={formData}
      />
    </div>
  );
};

export default Checkout;
