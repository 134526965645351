import { FC, useCallback, useState, KeyboardEvent } from 'react';
import { Command } from 'cmdk';
import clsx from 'clsx';

import Footer from './Footer';
import InputSearch from './InputSearch';
import { EnterIcon } from './icons';
import { useQuery } from 'react-query';
import { getSearchResults } from 'api/search/api';
import debounce from 'lodash/debounce';
import { SearchItem } from 'api/search/schema';
import { useNavigate } from 'react-router-dom';
import {
  AccessExpressIcon,
  NOCExpressIcon,
  TechExpressIcon,
} from 'components/Navbar/icons';

interface SearchModalProps {
  onExit: () => void;
}

const SearchModal: FC<SearchModalProps> = ({ onExit }) => {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        onExit();
      }
    },
    [onExit],
  );

  const { data, isLoading, refetch } = useQuery(
    ['searchResults', search],
    () => getSearchResults(search),
    {
      enabled: false,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query.trim()) {
        refetch();
      }
    }, 750),
    [],
  );

  const handleQueryChange = (query: string) => {
    setSearch(query);
    debouncedSearch(query);
  };

  const navigate = useNavigate();

  const handleSelect = useCallback(
    (value: string) => {
      navigate(value);
      onExit();
    },
    [navigate, onExit],
  );

  return (
    <div className="flex h-full w-full flex-col">
      <Command
        value={value}
        onValueChange={setValue}
        onKeyDown={handleKeyDown}
        className="flex h-full flex-col"
        shouldFilter={false}
      >
        <InputSearch value={search} onChange={handleQueryChange} />
        <div className="flex flex-1 flex-col gap-6 overflow-hidden p-6">
          <div className="vertical-scrollbar flex flex-1 flex-col overflow-x-hidden !scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued">
            <Command.List>
              {isLoading && (
                <Command.Loading>
                  <div className="text-center">Loading...</div>
                </Command.Loading>
              )}
              {data?.items.length === 0 ? (
                !isLoading ? (
                  <Command.Empty>No results found</Command.Empty>
                ) : (
                  'oops'
                )
              ) : (
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      {data?.items.map((item) => (
                        <Item
                          key={item.metadata.href}
                          value={item.metadata.href}
                          data={item}
                          onSelect={handleSelect}
                        >
                          {item.metadata.href}
                        </Item>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Command.List>
          </div>
          <Footer />
        </div>
      </Command>
    </div>
  );
};

function Item({
  value,
  onSelect,
  data,
}: {
  children: React.ReactNode;
  value: string;
  data: SearchItem;
  onSelect: (value: string) => void;
}) {
  const icon =
    data.metadata.type === 'techexpress-ticket'
      ? TechExpressIcon
      : data.metadata.type === 'noc-ticket'
        ? NOCExpressIcon
        : AccessExpressIcon;
  return (
    <Command.Item value={value} onSelect={onSelect}>
      <div
        className={clsx(
          'group flex cursor-pointer items-center gap-4 rounded p-2',
          '[[aria-selected=true]>&]:bg-[#272737]',
          '[[aria-selected=true]>&>.search-select-hint]:flex',
          '[[aria-selected=true]>&>.search-select-icon]:bg-content-accent-alt [[aria-selected=true]>&>.search-select-icon]:text-background-base-surface-3',
        )}
      >
        <div className="search-select-icon flex h-8 w-8 flex-none items-center justify-center rounded bg-background-base-surface-3 text-content-accent-alt">
          {icon()}
        </div>
        <div className="-my-2 flex-grow font-semibold text-content-base-default">
          <div className="flex flex-col text-sm">
            <div>
              {(data.data as { site_name: string }).site_name ?? ''}
              {(data.data as { quote_name: string }).quote_name ?? ''}
            </div>
            <div className="text-xs text-content-base-subdued">
              #{(data.data as { id: number }).id ?? ''}
            </div>
          </div>
        </div>
        <div className="search-select-hint hidden flex-row items-center gap-3 text-xs font-bold">
          Select
          <div className="flex h-[26px] w-[26px] items-center justify-center rounded-[5px] bg-[#2B2B3F] text-content-accent-alt">
            <EnterIcon />
          </div>
        </div>
      </div>
    </Command.Item>
  );
}

export default SearchModal;
