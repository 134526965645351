import { Modal } from './Modal';
import SearchModalContent from './SearchModalContent';

interface SearchModalProps {
  isOpen: boolean;
  close: () => void;
}

const SearchModal = (props: SearchModalProps) => {
  return (
    <Modal {...props}>
      <SearchModalContent onExit={props.close} />
    </Modal>
  );
};

export default SearchModal;
