type BeforeUnloadListener = {
  listener: EventListenerOrEventListenerObject;
  options?: boolean | AddEventListenerOptions;
};

const beforeUnloadListeners = new Map<
  EventListenerOrEventListenerObject,
  BeforeUnloadListener
>();

export const addBeforeUnloadListener = (
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
) => {
  const listenerObj: BeforeUnloadListener = { listener, options };
  beforeUnloadListeners.set(listener, listenerObj);
  window.addEventListener('beforeunload', listener, options);
};

export const removeBeforeUnloadListener = (
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
) => {
  const listenerObj = beforeUnloadListeners.get(listener);

  if (
    listenerObj &&
    JSON.stringify(listenerObj.options) === JSON.stringify(options)
  ) {
    beforeUnloadListeners.delete(listener);
    window.removeEventListener('beforeunload', listener, options);
  }
};

export const clearAllBeforeUnloadListeners = () => {
  beforeUnloadListeners.forEach(({ listener, options }) => {
    window.removeEventListener('beforeunload', listener, options);
  });
  beforeUnloadListeners.clear();
};
