import { AvatarGranite } from 'components';
import Tabs from 'components/Table/Tabs';
import { useNavigate } from 'react-router-dom';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useCallback, useMemo, useRef, useState } from 'react';
import PageTitle from 'components/PageTitle';
import Filters from 'components/Filters';
import Searchbar from 'components/Table/SearchBar';
import { TableTitle } from 'components/Table/Table.styles';
import {
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  Row,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/Table';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { plainTextToPhoneNumber } from 'shared/util/util';
import { useAuthUser } from '../../hooks/useAuthUser';
import { userRolesEnumToSelectOptions } from '../../components/Navbar/utils';
import { useMutation, useQuery } from 'react-query';
import format from 'date-fns/format';
import {
  cancelInvitation,
  getInvitedUsers,
  getUsers,
  inviteUser,
} from 'api/users/api';
import { useModal } from '../../hooks/useModal';
import { BulkImportUsersDialog } from '../../components/BulkImportUsersDialog/BulkImportUsersDialog';
import { DropdownButton } from '../../components/DropdownButton/DropdownButton';
import { People, Person } from 'react-ionicons';
import {
  GetUsersParams,
  TokenUserPermissions,
  TokenUserRoles,
  User,
  USERS_LIMIT,
} from '../../api/users/schemas/Users';
import { isAfterDate } from '../../components/Table/filters';
import { startOfDay, subMonths } from 'date-fns';
import {
  InvitationItem,
  InviteUserRequest,
  InviteUserResponse,
} from '../../api/users/schemas/Invitations';
import showToast from '../../components/Toast/Toast';
import { BEUserRole } from '../../api/users/schemas/UserRole';
import {
  INVITE_TABLE_QK,
  useInviteTableRefetch,
} from './useInviteTableRefetch';
import { mapFrontendRoleToBackendRole } from 'api/schema-utils';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { usePermissions } from 'hooks/usePermissions';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useFilterForTable } from 'hooks/useFilterForTable';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { SingleValue } from 'react-select';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import UserStatusColumn from '../../components/UserStatusColumn';
import { useSortedTable } from 'hooks/useSortedTable';
import { UserActions } from 'screens/UserDetails/UserActions';

const AvatarNameCell = ({ name }: { name: string }) => {
  return (
    <div className="flex items-center justify-start gap-4">
      <AvatarGranite
        width="w-8"
        height="h-8"
        name={name}
        fontSize={'text-xs'}
      />
      <h2 className="text-sm text-content-base-default">{name}</h2>
    </div>
  );
};

const UsersActions = ({ row }: { row: User }) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-4">
      <GraniteButton
        variant="secondary"
        size="small"
        onClick={() => navigate(`/users/${row.email}`)}
      >
        View
      </GraniteButton>
      <UserActions status={row.status} id={row.id} />
    </div>
  );
};

const UserManagementUsersDef: ColumnDef<User>[] = [
  {
    id: 'name',
    header: 'User',
    accessorKey: 'name',
    cell: (row) => {
      const user = row?.row?.original?.name;
      return <AvatarNameCell name={user} />;
    },
  },
  {
    id: 'role',
    header: 'Role',
    accessorKey: 'role',
    cell: (row) => {
      const role =
        row?.row?.original?.roles?.find((role) =>
          [
            TokenUserRoles.COMPANY_ADMIN,
            TokenUserRoles.USER,
            TokenUserRoles.VIEWER,
            TokenUserRoles.WELCOME_USER,
          ].includes(mapFrontendRoleToBackendRole(role) as TokenUserRoles),
        ) ?? 'Viewer';
      return <BadgeRole role={role} />;
    },
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: (row: { row: { original: { status: string } } }) => (
      <UserStatusColumn status={row?.row?.original?.status} />
    ),
  },
  {
    id: 'phone_number',
    header: 'Phone',
    enableSorting: false,
    cell: (row) =>
      row?.row?.original?.phone_number
        ? plainTextToPhoneNumber(row?.row?.original?.phone_number)
        : ' -- ',
    accessorKey: 'phone_number',
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
  },
  {
    id: 'date_added',
    header: 'Date created',
    cell: (row) => {
      const userData = row?.row?.original;
      const dateAdded = userData?.invited_at || userData?.created_at;
      return dateAdded ? format(new Date(dateAdded), 'MM/dd/yyyy') : ' -- ';
    },
    accessorKey: 'invited_at',
    filterFn: isAfterDate,
  },
  {
    header: 'Actions',
    enableSorting: false,
    cell: (cell) => <UsersActions row={cell.row.original} />,
    enableGlobalFilter: false,
  },
];

const InvitationManagementTableDef: ColumnDef<InvitationItem>[] = [
  {
    id: 'name',
    header: 'User',
    accessorKey: 'name',
    accessorFn: (row) => `${row.first_name} ${row.last_name}`,
    cell: (row) => (
      <AvatarNameCell
        name={`${row?.row?.original?.first_name ?? ''} ${
          row?.row?.original?.last_name ?? ''
        }`}
      />
    ),
  },
  {
    id: 'role',
    header: 'Role',
    accessorKey: 'role',
    cell: (row) => {
      const role = row?.row?.original?.role ?? 'Viewer';
      return <BadgeRole role={role} />;
    },
    enableGlobalFilter: false,
  },
  {
    id: 'phone_number',
    header: 'Phone',
    enableSorting: false,
    cell: (row) =>
      row?.row?.original?.phone_number
        ? plainTextToPhoneNumber(row?.row?.original?.phone_number)
        : ' -- ',
    accessorKey: 'phone_number',
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
  },
  {
    id: 'created_at',
    header: 'Date created',
    cell: (row) =>
      row?.row?.original?.created_at
        ? format(new Date(row?.row?.original?.created_at), 'MM/dd/yyyy')
        : ' -- ',
    accessorKey: 'created_at',
    filterFn: isAfterDate,
    enableGlobalFilter: false,
  },
  {
    header: 'Actions',
    enableSorting: false,
    cell: (cell) => <InvitationActions row={cell.row} />,
    enableGlobalFilter: false,
  },
];

const InvitationActions = ({ row }: { row: Row<InvitationItem> }) => {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const { name: inviterName } = useAuthUser();
  const refetchInviteList = useInviteTableRefetch();

  const cancelInvitationMutation = useMutation(cancelInvitation, {
    onSuccess: (response) => {
      if (!isSubmiting) {
        if (response) {
          refetchInviteList();
          showToast.confirmation({ message: 'Invitation revoked.' });
        } else {
          showToast.error({ message: 'Unknown error revoking invitation.' });
        }
      }
    },
  });

  const cancelInvitationMutationNoToastNotification =
    useMutation(cancelInvitation);

  const resendInvitationMutation = useMutation(
    (request: InviteUserRequest) => {
      return inviteUser(request);
    },
    {
      onSuccess: (response: InviteUserResponse[]) => {
        if (response.length !== 1) {
          showToast.error({ message: 'An unknown error occured' });
        }
        const status = response[0];
        if (status.result === 'Success') {
          showToast.confirmation({ message: 'Invite sent' });
        } else {
          showToast.error({ message: status.error_message });
        }
      },
      onSettled: () => {
        refetchInviteList();
        setIsSubmiting(false);
      },
    },
  );

  return (
    <div className="flex items-center justify-between gap-4 pr-2">
      <GraniteButton
        disabled={isSubmiting}
        variant="secondary"
        size="small"
        onClick={() => {
          setIsSubmiting(true);
          cancelInvitationMutationNoToastNotification.mutate(row.original.id);
          resendInvitationMutation.mutate([
            {
              first_name: row.original.first_name,
              last_name: row.original.last_name,
              email: row.original.email,
              phone_number: row.original.phone_number ?? '',
              role: row.original.role as BEUserRole,
              inviter_name: inviterName,
            },
          ]);
        }}
      >
        Resend
      </GraniteButton>
      <GraniteButton
        variant="destructive"
        disabled={isSubmiting}
        size="small"
        onClick={() => {
          setIsSubmiting(true);
          cancelInvitationMutation.mutate(row.original.id);
        }}
      >
        Revoke
      </GraniteButton>
    </div>
  );
};

const statusOptions = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'DEPROVISIONED',
    label: 'Deprovisioned',
  },
  {
    value: 'LOCKED_OUT',
    label: 'Locked out',
  },
  {
    value: 'PASSWORD_EXPIRED',
    label: 'Password expired',
  },
  {
    value: 'PROVISIONED',
    label: 'Provisioned',
  },
  {
    value: 'RECOVERY',
    label: 'Recovery',
  },
  {
    value: 'STAGED',
    label: 'Staged',
  },
  {
    value: 'SUSPENDED',
    label: 'Suspended',
  },
];

const filterByDate = [
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 1))),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(startOfDay(subMonths(new Date(), 3))),
    label: 'Past 3 months',
  },
];

const UserManagement = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<
    'existingUsers' | 'pendingInvitations'
  >('existingUsers');

  const tableRef = useRef<ReactTable<unknown>>();
  const [filterState, setFilterState] = useState<ColumnFiltersState>([]);
  const [search, setSearch] = useState<string | undefined>();
  const [dateFilter, setDateFilter] = useState<SingleValue<OptionType>>(null);

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'created_at', desc: true }],
  });

  const clearFilters = () => {
    setFilterState([]);
    clearroleFilter();
    clearStatusFilter();
    setDateFilter(null);
  };

  const {
    queryParamFilter: roleFilter,
    clearFilter: clearroleFilter,
    ...roleFilterProps
  } = useFilterForTable({ queryParamKey: 'role' });

  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilter,
    ...statusFilterProps
  } = useFilterForTable({ queryParamKey: 'status' });

  const getUsersQueryFn = useCallback(
    (args?: GetUsersParams) =>
      getUsers({
        ...(search ? { search } : {}),
        ...roleFilter,
        ...sortingQueryParams,
        ...statusFilter,
        ...(dateFilter
          ? {
              date_added: dateFilter.value,
            }
          : {}),
        ...args,
      }),
    [dateFilter, roleFilter, search, sortingQueryParams, statusFilter],
  );

  const {
    data: tablePageData,
    refetch,
    ...paginatedTableProps
  } = usePaginatedTable(
    getUsersQueryFn,
    {
      search,
      dateFilter,
      roleFilter,
      statusFilter,
      sortingQueryParams,
    },
    [
      'users',
      {
        search,
        dateFilter,
        roleFilter,
        statusFilter,
        sortingQueryParams,
      },
    ],
    { refetchOnMount: true },
  );

  const tabs = [
    {
      title: 'Existing users',
      onClick: () => {
        setActiveTab('existingUsers');
        clearFilters();
      },
      active: false,
    },
    {
      title: 'Pending invitations',
      onClick: () => {
        setActiveTab('pendingInvitations');
        clearFilters();
      },
      active: false,
      className: 'product-tour-user-management-tab',
    },
  ];

  const roleOptions = useMemo(() => userRolesEnumToSelectOptions(), []);

  const { open: bulkInviteDialogOpen, ...bulkInviteDialogProps } = useModal();
  return (
    <div className="w-full p-8">
      <div className="user-management mx-auto flex w-full flex-col items-start justify-start">
        <div className="mb-6 flex w-full items-center justify-between">
          <PageTitle title="User management" heightSpace={true} />
          <div className="flex items-center">
            <div className="product-tour-user-management-invite-user flex w-full items-center justify-start gap-4">
              <DisplayForPermission onlyForSuperAdmin>
                <GraniteButton
                  size="large"
                  variant="secondary"
                  onClick={() => navigate('/users/roles')}
                >
                  Manage roles
                </GraniteButton>
              </DisplayForPermission>
              <DisplayForPermission
                permission={TokenUserPermissions.USER_MANAGEMENT}
              >
                <DropdownButton
                  className="product-tour-user-management product-tour-my-company-inviting-users"
                  label="Invite user"
                  options={[
                    {
                      label: (
                        <>
                          <Person
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-default))"
                          />
                          <span className="ml-3">Invite user</span>
                        </>
                      ),
                      onClick: () => navigate('/users/invite-user/'),
                    },
                    {
                      label: (
                        <>
                          <People
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-default))"
                          />
                          <span className="ml-3">Bulk invite</span>
                        </>
                      ),
                      onClick: bulkInviteDialogOpen,
                    },
                  ]}
                />
              </DisplayForPermission>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-start justify-start">
          <TableTitle className="!mb-3">Users</TableTitle>
          <div className="mb-8 w-full">
            <Tabs tabs={tabs} />
          </div>
          <div className="mb-12 flex w-full flex-wrap items-start justify-between gap-4">
            <div className="w-full sm:flex-1">
              <Searchbar
                placeholder="Search by name, email, or phone number"
                clearAllValues={!search}
                onQueryClear={() => setSearch(undefined)}
                onSearch={setSearch}
              />
            </div>
            <Filters clearFilters={clearFilters}>
              <GraniteSelect
                placeholder="Filter user role"
                options={roleOptions}
                isMulti
                {...roleFilterProps}
              />
              {activeTab === 'existingUsers' && (
                <GraniteSelect
                  placeholder="Filter user status"
                  options={statusOptions}
                  isMulti
                  {...statusFilterProps}
                />
              )}
              <GraniteSelect
                options={filterByDate}
                className="col-span-2 md:col-span-1 xl:col-span-1"
                placeholder="Filter ticket by date"
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                onChange={setDateFilter}
                value={dateFilter}
              />
            </Filters>
          </div>
          {activeTab === 'existingUsers' ? (
            <div className="product-tour-my-company-edit-and-delete-users w-full">
              <ServerPaginatedTable
                data={tablePageData}
                columns={UserManagementUsersDef}
                title="Users"
                sortingState={sortingState}
                onSortingChange={onSortingChange}
                {...paginatedTableProps}
              />
            </div>
          ) : (
            <InviteManagementTable
              columnFilterState={[
                ...filterState,
                ...(roleFilter?.role
                  ? [{ id: 'role', value: roleFilter.role }]
                  : []),
                ...(dateFilter?.value
                  ? [{ id: 'created_at', value: dateFilter.value }]
                  : []),
              ]}
              onColumnFiltersChange={setFilterState}
              globalFilterState={search}
              onGlobalFilterChange={setSearch}
              onTableLoaded={(table) =>
                (tableRef.current = table as ReactTable<unknown>)
              }
            />
          )}
        </div>
      </div>
      <BulkImportUsersDialog {...bulkInviteDialogProps} />
    </div>
  );
};

interface UserManagementTableProps<T> {
  columnFilterState: ColumnFiltersState;
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>;
  onTableLoaded: (table: ReactTable<T>) => void;
  globalFilterState: string | undefined;
  onGlobalFilterChange: OnChangeFn<string | undefined>;
}

const InviteManagementTable = ({
  columnFilterState,
  onColumnFiltersChange,
  onTableLoaded,
  globalFilterState,
  onGlobalFilterChange,
}: UserManagementTableProps<InvitationItem>) => {
  const { hasPermission } = usePermissions({
    permission: TokenUserPermissions.MANAGE_GROUPS,
  });
  const { data, isFetching } = useQuery(
    INVITE_TABLE_QK,
    () =>
      getInvitedUsers({
        page_size: USERS_LIMIT,
      }),
    {
      refetchOnMount: true,
    },
  );

  const formattedData =
    hasPermission && !isFetching
      ? data?.invitations
      : data?.invitations.filter(
          (item) => !item.email.endsWith('@granitenet.com'),
        );

  return (
    <Table
      data={formattedData ?? []}
      columns={InvitationManagementTableDef}
      title="Users"
      columnFiltersState={columnFilterState}
      onColumnFiltersChange={onColumnFiltersChange}
      onTableLoaded={onTableLoaded}
      isLoading={isFetching}
      globalFilterState={globalFilterState}
      onGlobalFilterChange={onGlobalFilterChange}
    />
  );
};

export default UserManagement;
