import { Tabs } from 'components/V2/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { CaretDown } from 'react-ionicons';
import Dropdown, { DropdownItem } from './Dropdown';
import { useSearchParams } from 'react-router-dom';

enum DeviceType {
  basicPhone = 'BASIC CELL PHONE', // * DEVICE
  smartPhone = 'SMART PHONE', // * DEVICE
  tablet = 'TABLET', // * DEVICE
  hotspot = 'HOTSPOT', // * DEVICE
  sim = 'SIM CARD', // * ACCESSORY
  modem = 'MODEM', // * DEVICE
  wirelessRouter = 'WIRELESS ROUTER', // * DEVICE
}

enum Options {
  personal_connectivity = 'Personal connectivity',
  fixed_device = 'Fixed device',
}

const LocalNav = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = useState<
    'personal_connectivity' | 'fixed_device'
  >('personal_connectivity');
  const [isOpen, setIsOpen] = useState(false);

  const onChangeDeviceType = (
    prevParams: URLSearchParams,
    value: DeviceType,
  ) => {
    const newParams = new URLSearchParams(prevParams);
    if (decodeURIComponent(searchParams.get('type') || '') === value)
      newParams.delete('type');
    else newParams.set('type', encodeURIComponent(value));
    return newParams;
  };

  const decodeType = decodeURIComponent(searchParams.get('type') || '');

  const subOptions = {
    personal_connectivity: [
      {
        active: decodeType === DeviceType.basicPhone,
        onClick: () =>
          setSearchParams(
            (prevParams) =>
              onChangeDeviceType(prevParams, DeviceType.basicPhone),
            { replace: true },
          ),
        title: 'Basic phone',
        className: 'whitespace-nowrap',
      },
      {
        active: decodeType === DeviceType.smartPhone,
        onClick: () =>
          setSearchParams(
            (prevParams) =>
              onChangeDeviceType(prevParams, DeviceType.smartPhone),
            { replace: true },
          ),
        title: 'Smart phone',
        className: 'whitespace-nowrap',
      },
      {
        active: decodeType === DeviceType.tablet,
        onClick: () =>
          setSearchParams(
            (prevParams) => onChangeDeviceType(prevParams, DeviceType.tablet),
            { replace: true },
          ),
        title: 'Tablet',
        className: 'whitespace-nowrap',
      },
      {
        active: decodeType === DeviceType.hotspot,
        onClick: () =>
          setSearchParams(
            (prevParams) => onChangeDeviceType(prevParams, DeviceType.hotspot),
            { replace: true },
          ),
        title: 'Hotspot',
        className: 'whitespace-nowrap',
      },
      {
        active: decodeType === DeviceType.sim,
        onClick: () =>
          setSearchParams(
            (prevParams) => onChangeDeviceType(prevParams, DeviceType.sim),
            { replace: true },
          ),
        title: 'SIM',
        className: 'whitespace-nowrap',
      },
    ],
    fixed_device: [
      {
        active: decodeType === DeviceType.modem,
        onClick: () =>
          setSearchParams(
            (prevParams) => onChangeDeviceType(prevParams, DeviceType.modem),
            { replace: true },
          ),
        title: 'Modem / IoT',
        className: 'whitespace-nowrap',
      },
      {
        active: decodeType === DeviceType.wirelessRouter,
        onClick: () =>
          setSearchParams(
            (prevParams) =>
              onChangeDeviceType(prevParams, DeviceType.wirelessRouter),
            { replace: true },
          ),
        title: 'Wireless router',
        className: 'whitespace-nowrap',
      },
    ],
  };

  useEffect(() => {
    if (
      decodeType === DeviceType.wirelessRouter ||
      decodeType === DeviceType.modem
    )
      setSelected('fixed_device');
  }, [decodeType]);

  return (
    <div className="flex w-full flex-col flex-wrap gap-4 md:h-8 md:flex-row md:items-center">
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        triggerClassName="w-[220px] justify-start whitespace-nowrap text-white"
        label={
          <>
            {Options[selected]}
            <CaretDown width="20px" height="20px" color="currentColor" />
          </>
        }
      >
        <DropdownItem
          active={selected === 'fixed_device'}
          label="Fixed device"
          onClick={() => {
            setSelected('fixed_device');
            setIsOpen(false);
          }}
        />
        <DropdownItem
          active={selected === 'personal_connectivity'}
          label="Personal connectivity"
          onClick={() => {
            setSelected('personal_connectivity');
            setIsOpen(false);
          }}
        />
      </Dropdown>

      <div className="h-1 w-full border-b border-neutral-700 md:h-8 md:w-0 md:border-r" />
      <Tabs
        variant="pill-accent-primary"
        className="flex-1 flex-wrap gap-4 overflow-x-auto p-0.5 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued md:flex-nowrap"
        size="medium"
        tabs={subOptions[selected]}
        withBorder={false}
      />
    </div>
  );
};

export default LocalNav;
