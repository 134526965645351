import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useForm } from 'react-hook-form';
import {
  CheckAvailabilityForm,
  CheckAvailabilitySchema,
} from '../AddProducts/schemas';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Loader from 'components/Loader';
import AllSetDialog from './AllSetDialog';
import { useQueryClient } from 'react-query';

const CheckAvailabilityDialog = ({
  isOpen,
  close,
  onConfirmation,
  quoteName,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirmation: (data: CheckAvailabilityForm) => Promise<void>;
  quoteName?: string;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (quoteName) setStep(2);
  }, [quoteName]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CheckAvailabilityForm>({
    resolver: zodResolver(CheckAvailabilitySchema),
    defaultValues: {
      quoteName: quoteName,
    },
  });

  const onSubmit = async (data: CheckAvailabilityForm) => {
    if (loading) return;
    setLoading(true);
    try {
      await onConfirmation(data);
    } finally {
      setLoading(false);
    }
  };

  const onError = (err: unknown) => console.log(err);

  const closeModal = () => {
    close();
    reset({});
  };

  const onCloseClick = () => {
    closeModal();
    queryClient.invalidateQueries('quote-index-table');
    setTimeout(() => {
      navigate('/access-express/index');
    }, 200);
  };

  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      {step === 1 && (
        <>
          <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-3 p-8">
            <h1 className="rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
              Ready to see what’s available?{' '}
            </h1>
          </div>
          <div className="px-8 py-6">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <GraniteInput
                label="Give this quote request a name so that you can reference it later"
                className="col-span-1"
                {...register('quoteName')}
                error={errors.quoteName?.message}
              />
              <div className="mt-12 flex gap-5">
                <GraniteButton size="large" variant="secondary" onClick={close}>
                  Cancel
                </GraniteButton>
                <GraniteButton
                  size="large"
                  variant="primary"
                  type="submit"
                  className={clsx(loading && 'loading-button')}
                >
                  Check availability
                  {loading && <Loader animationClassname="!w-3 !h-3" />}
                </GraniteButton>
              </div>
            </form>
          </div>
        </>
      )}
      {step === 2 && <AllSetDialog onClose={onCloseClick} />}
    </Modal>
  );
};

export default CheckAvailabilityDialog;
