import { ChevronDown, InformationCircle } from 'react-ionicons';
import { useNavigate } from 'react-router-dom';
import Pricing from '../ProductDetails/Pricing';
import { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import AppleBusiness from 'assets/images/apple_business.png';
import { AccessProductType, ProductType } from '../accessExpress.types';
import Skeleton from 'components/Skeleton/Skeleton';
import AccessBackButton from './AccessBackButton';

type BaseProduct = {
  id: string;
};

type ProductSummaryProps<T> = {
  product: T;
  isAccessProduct?: boolean;
  isLoading?: boolean;
};

const ProductSummary: FC<
  ProductSummaryProps<BaseProduct & (ProductType | AccessProductType)>
> = ({ product, isAccessProduct = false, isLoading }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const onGoBack = () => navigate(-1);
  const toggleShow = () => setShow((prev) => !prev);
  const ref = useRef<HTMLDivElement>(null);
  const height = ref.current ? `${ref.current.scrollHeight + 24}px` : 'auto';

  return (
    <div className="h-[124px] lg:h-[92px]">
      <motion.div
        ref={ref}
        transition={{ type: 'spring', mass: 1, stiffness: 100, damping: 15 }}
        animate={{
          height: show ? height : '148px',
          opacity: show ? 1 : 0.95,
          background: show
            ? 'linear-gradient(180deg, #0D1219 0%, #1A1E24 100%);'
            : 'transparent',
          boxShadow: show
            ? '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)'
            : 'none',
        }}
        className={clsx('absolute inset-0 z-20 px-4 pb-6 pt-4 sm:px-8 sm:pt-8')}
      >
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center lg:gap-0">
          <div className="space-y-2">
            <AccessBackButton onClick={onGoBack} />
            <div className="flex gap-4">
              {isLoading ? (
                <Skeleton
                  className={clsx(
                    'h-10 w-[200px] transition-opacity duration-500 ease-out',
                    isLoading
                      ? 'opacity-100'
                      : 'pointer-events-none !opacity-0',
                  )}
                />
              ) : (
                <p className="max-w-full truncate whitespace-nowrap text-2xl font-bold text-white">
                  {product?.title}
                </p>
              )}

              {!isAccessProduct && (product as ProductType).business && (
                <div className="flex w-[70px] items-center">
                  <img
                    src={AppleBusiness}
                    className="h-5 object-contain "
                    alt=""
                  />
                </div>
              )}
              <button
                className="flex items-center gap-2 text-sm font-bold text-neutral-500 hover:text-[#6C9DFF]"
                onClick={toggleShow}
                disabled={isLoading}
              >
                <span className="hidden sm:block">
                  {show ? 'Hide' : 'Show'} details
                </span>{' '}
                <span
                  className={clsx('transform-gpu duration-500', {
                    '-rotate-180': show,
                  })}
                >
                  <ChevronDown
                    color="currentColor"
                    width="18px"
                    height="18px"
                  />
                </span>
              </button>
            </div>
            {!isAccessProduct && (product as ProductType).stock === 0 && (
              <div className="flex gap-2 text-sm font-semibold text-white">
                <span className="text-purple-300">
                  <InformationCircle
                    color="currentColor"
                    width="20px"
                    height="20px"
                  />
                </span>
                This item will ship once restocked
              </div>
            )}
          </div>
          {!isAccessProduct && (
            <div className="self-end">
              <Pricing
                mrc={{
                  price: (product as ProductType).mrc,
                  pricingConfirmed: false,
                }}
                nrc={{
                  price: (product as ProductType).nrc,
                  pricingConfirmed: false,
                }}
              />
            </div>
          )}
        </div>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          className="mt-6 text-xs font-semibold text-white"
          animate={{
            opacity: show ? 1 : 0,
            height: show ? 'auto' : 0,
          }}
          transition={{
            type: 'spring',
            mass: 1,
            stiffness: 100,
            damping: 15,
          }}
        >
          {product?.content}
        </motion.div>
      </motion.div>
      <motion.div
        animate={{
          opacity: show ? 1 : 0,
        }}
        transition={{ type: 'spring', mass: 1, stiffness: 100, damping: 15 }}
        className={clsx('h-full w-full bg-black/90 backdrop-blur-xl', {
          'absolute inset-0 z-10': show,
        })}
      />
    </div>
  );
};

export default ProductSummary;
