import { ComponentProps, useMemo } from 'react';
import {
  CANCELLED,
  COMPLETE,
  ExtendedTicket,
  ON_HOLD,
  PENDING_CUSTOMER,
  PENDING_TECH_ASSIGNMENT,
  TECH_ASSIGNED,
  TECH_CHECKED_IN,
} from '../../api/techexpress/schema';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import { Badge } from '../../components/V2/Badge/Badge';
import { getPriorityLabel } from './ticketdetail-utils';
import { clsx } from 'clsx';
import { WizardStateBlock } from '../../components/StepperWizard/WizardStateBlock';

const getTicketLevelByStatus = (status?: string) => {
  switch (status) {
    case CANCELLED:
      return 0;
    case PENDING_TECH_ASSIGNMENT:
      return 1;
    case TECH_ASSIGNED:
      return 2;
    case TECH_CHECKED_IN:
      return 3;
    case COMPLETE:
      return 4;
    case PENDING_CUSTOMER:
      return 1;
    case ON_HOLD:
      return 2;
    default:
      return 0;
  }
};

export interface TicketDetailHeaderProps extends ComponentProps<'div'> {
  ticket?: ExtendedTicket;
  buttons?: React.ReactNode | null;
}

export const TicketDetailHeader = ({
  ticket,
  buttons,
}: TicketDetailHeaderProps) => {
  const navigate = useNavigate();

  const priorityBgColor = useMemo(
    () =>
      ({
        P1: 'bg-ticket-priority-1',
        P2: 'bg-ticket-priority-2',
        P3: 'bg-ticket-priority-3',
        PD: 'bg-ticket-priority-premium',
      })[ticket?.sub_type ?? ''],
    [ticket?.sub_type],
  );

  const priorityTextColor = useMemo(
    () =>
      ({
        P1: 'text-content-base-default',
        P2: 'text-content-base-default',
        P3: 'text-content-flip-default',
        PD: 'text-content-base-default',
      })[ticket?.sub_type ?? ''],
    [ticket?.sub_type],
  );

  return (
    <div className="flex flex-col gap-6 max-xs:w-full">
      <div className="flex flex-wrap items-center justify-between gap-4 max-xs:w-full">
        <div className="flex flex-col max-xs:w-full">
          <Breadcrumb
            breadcrumbs={[
              {
                icon: 'home',
                label: 'TechExpress',
                onClick: () => navigate('/tech-express'),
              },
            ]}
          />
          <div className="flex flex-wrap gap-4 max-xs:w-full max-xs:justify-between xs:gap-x-6">
            <h1 className="flex flex-row text-3xl font-bold leading-9 text-content-base-default xs:text-4xl">
              #{ticket?.id}
            </h1>
            {ticket?.sub_type && (
              <Badge
                className={clsx(
                  priorityBgColor,
                  priorityTextColor,
                  'max-xs:px-3',
                )}
              >
                <span className="whitespace-nowrap text-base font-bold max-xs:text-sm">
                  {getPriorityLabel(ticket?.sub_type)}
                </span>
              </Badge>
            )}
          </div>
        </div>
        {buttons && (
          <div className="flew-wrap flex gap-3 max-xs:w-full max-xs:flex-col">
            {buttons}
          </div>
        )}
      </div>
      <div className="flex flex-row items-end gap-1 overflow-x-auto overflow-y-hidden scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued">
        <div className="basis-1/4">
          <WizardStateBlock
            label="Pending tech assignment"
            isActive={getTicketLevelByStatus(ticket?.status_name) >= 1}
            isCompleted={getTicketLevelByStatus(ticket?.status_name) > 1}
            classNames="max-xs:hidden"
          />
        </div>
        <div className="basis-1/4">
          <WizardStateBlock
            label="Tech assigned"
            isActive={getTicketLevelByStatus(ticket?.status_name) >= 2}
            isCompleted={getTicketLevelByStatus(ticket?.status_name) > 2}
            classNames="max-xs:hidden"
          />
        </div>
        <div className="basis-1/4">
          <WizardStateBlock
            label="Tech checked in"
            isActive={getTicketLevelByStatus(ticket?.status_name) >= 3}
            isCompleted={getTicketLevelByStatus(ticket?.status_name) > 3}
            classNames="max-xs:hidden"
          />
        </div>
        <div className="basis-1/4">
          <WizardStateBlock
            label="Completed"
            isActive={getTicketLevelByStatus(ticket?.status_name) >= 4}
            isCompleted={getTicketLevelByStatus(ticket?.status_name) === 4}
            classNames="max-xs:hidden"
          />
        </div>
      </div>
    </div>
  );
};
