import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { motion, PanInfo } from 'framer-motion';
import Product, { ProductProps } from './Product';
import { ProductType } from '../../accessExpress.types';
import BeamTracker from './BeamTracker';
import Skeleton from 'components/Skeleton/Skeleton';
import clsx from 'clsx';

interface ProductsProps extends Pick<ProductProps, 'price_type'> {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  onChangePageHistory: (page: number) => void;
  isLoading: boolean;
  products: ProductType[];
  total_pages: number;
  page?: number;
  page_size: 12 | 9 | 6;
  onProductClick: ProductProps['onClick'];
  type?: 'pagination' | 'initial';
}

const Products: React.FC<ProductsProps> = ({
  currentPage,
  setCurrentPage,
  total_pages,
  products,
  isLoading,
  page,
  page_size,
  onProductClick,
  price_type,
  onChangePageHistory,
  type = 'pagination',
}) => {
  const [store, setStore] = useState(() => ({
    prevPage: currentPage,
    showLoading: false,
  }));
  const [data, setData] = useState<ProductType[][]>([]);
  const [isCursorInside, setIsCursorInside] = useState<boolean>(false);
  const [dragging, setDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (type === 'pagination')
      setStore((prev) => {
        if (prev.prevPage === currentPage && isLoading)
          return { ...prev, showLoading: true };
        else return { prevPage: currentPage, showLoading: false };
      });
  }, [isLoading, currentPage, type]);

  // Handle Arrow Key Navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (!isCursorInside && !data.length) return;

      if (e.key === 'ArrowLeft')
        setCurrentPage((prev) => {
          onChangePageHistory(Math.max(0, prev - 1));
          return Math.max(0, prev - 1);
        });
      else if (e.key === 'ArrowRight')
        setCurrentPage((prev) => {
          onChangePageHistory(Math.min(total_pages - 1, prev + 1));
          return Math.min(total_pages - 1, prev + 1);
        });
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    isCursorInside,
    setCurrentPage,
    total_pages,
    data.length,
    onChangePageHistory,
  ]);

  // Handle Drag End with Snap-to-Page
  const handleDragEnd = (_: unknown, info: PanInfo) => {
    setDragging(false);
    const { offset, velocity } = info;
    const swipeThreshold = (containerRef.current?.clientWidth || 0) / 3.5;
    const direction = velocity.x > 0 ? -1 : 1;

    let nextPage = currentPage;

    if (Math.abs(offset.x) > swipeThreshold || Math.abs(velocity.x) > 0.5)
      nextPage = Math.min(
        Math.max(currentPage + direction, 0),
        total_pages - 1,
      );
    onChangePageHistory(nextPage);
    setCurrentPage(nextPage);
  };

  // Handle Mouse Enter/Leave to enable keyboard navigation
  const handleMouseEnter = () => setIsCursorInside(true);
  const handleMouseLeave = () => setIsCursorInside(false);

  // Handle Trackpad Wheel Scroll with Snap to One Page
  useEffect(() => {
    let lastDeltaX = 0;
    let isScrolling = false; // Track if scrolling is in progress
    let animationFrameId: number | null = null;

    const handleWheel = (event: WheelEvent) => {
      if (!isCursorInside || isScrolling || !data.length) return; // Ignore new scroll if already scrolling

      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        lastDeltaX += event.deltaX;

        if (animationFrameId) cancelAnimationFrame(animationFrameId);

        animationFrameId = requestAnimationFrame(() => {
          const threshold = (containerRef.current?.clientWidth || 0) / 4; // Sensitivity tuning

          if (Math.abs(lastDeltaX) >= threshold) {
            const direction = lastDeltaX > 0 ? 1 : -1;

            setCurrentPage((prevPage) => {
              const nextPage = Math.min(
                Math.max(prevPage + direction, 0),
                total_pages - 1,
              );
              onChangePageHistory(nextPage !== prevPage ? nextPage : prevPage);
              return nextPage !== prevPage ? nextPage : prevPage;
            });
            // Reset after executing once
            lastDeltaX = 0;
            // Lock scrolling to prevent multiple triggers
            isScrolling = true;
            // Unlock scrolling after a short delay
            setTimeout(() => {
              isScrolling = false; // Allow next scroll after momentum slows
            }, 300); // You can adjust this timeout value for smoother behavior
          }
        });
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [
    isCursorInside,
    setCurrentPage,
    onChangePageHistory,
    total_pages,
    data.length,
  ]);

  useEffect(() => {
    if (type === 'pagination') {
      if (page === undefined || !products.length) return;

      setData((prev) => {
        if (prev[page - 1] === products) return prev;

        const updatedData = [...prev];
        updatedData[page - 1] = products;

        return updatedData;
      });
    }
  }, [products, total_pages, page, type]);

  useEffect(() => {
    if (type === 'initial') {
      setData(() => {
        const updatedData = [];
        for (let i = 0; i < products.length; i += page_size) {
          updatedData.push(products.slice(i, i + page_size));
        }
        return updatedData;
      });
    }
  }, [products, total_pages, page_size, type]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex h-full flex-col justify-between"
    >
      <div ref={containerRef} className="relative w-full overflow-hidden">
        <motion.div
          className="flex"
          {...(data.length && { drag: 'x' })}
          dragConstraints={{
            left: -(containerRef.current?.clientWidth || 0) * (total_pages - 1),
            right: 0,
          }}
          dragElastic={0.1}
          dragMomentum={false}
          onDragStart={() => setDragging(true)}
          onDragEnd={handleDragEnd}
          animate={{
            x: -currentPage * (containerRef.current?.clientWidth || 0),
          }}
          transition={{ type: 'spring', mass: 1, stiffness: 45, damping: 15 }}
        >
          {[...Array(total_pages === 0 ? 1 : total_pages)].map((_, index) => (
            <motion.div
              key={index}
              className={clsx(
                'grid w-full shrink-0 grid-cols-1 grid-rows-3 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
                { 'pointer-events-none': dragging },
              )}
              initial={false}
              animate={{ opacity: currentPage === index ? 1 : 0 }}
              transition={{ duration: 0.7, ease: 'easeInOut' }}
            >
              {data[index] &&
              data[index]?.length !== 0 &&
              !store.showLoading ? (
                Array.from({ length: page_size }).map((_, i) =>
                  data[index][i] ? (
                    <Product
                      price_type={price_type}
                      key={i}
                      product={data[index][i]}
                      onClick={onProductClick}
                    />
                  ) : (
                    <div key={i} className="h-[229px] w-full" />
                  ),
                )
              ) : (
                <></>
              )}

              {(isLoading && !data[index]) ||
              (isLoading && store.showLoading) ? (
                Array.from({ length: page_size }).map((_, i) => (
                  <Skeleton className="h-[229px] w-full !rounded" key={i} />
                ))
              ) : (
                <></>
              )}
            </motion.div>
          ))}
        </motion.div>
      </div>
      {data.length ? (
        <BeamTracker
          currentPage={currentPage}
          onChangePageHistory={onChangePageHistory}
          setCurrentPage={setCurrentPage}
          totalPage={total_pages}
        />
      ) : (
        isLoading && (
          <div className="mt-3.5 flex h-[13px] items-center">
            <div className="h-[3px] w-full bg-neutral-700/50" />
          </div>
        )
      )}
    </div>
  );
};

export default Products;
