import { useCallback } from 'react';

import { getOS } from '../../shared/util/util';
import { CmdIcon } from './icons';
import clsx from 'clsx';

interface SearchShortcutIconProps {
  className?: string;
}

export const SearchShortcutIcon = ({ className }: SearchShortcutIconProps) => {
  const os = useCallback(getOS, [])();

  switch (os) {
    case 'Windows':
    case 'Linux':
      return (
        <div
          className={clsx(
            'flex h-[26px] items-center justify-center gap-0.5 rounded-md bg-background-base-surface-3 px-1.5',
            className,
          )}
        >
          <span className="whitespace-nowrap text-[11px] font-bold">
            Ctrl + K
          </span>
        </div>
      );
    case 'Mac':
      return (
        <div
          className={clsx(
            'flex h-[26px] w-[26px] items-center justify-center gap-0.5 rounded-md bg-background-base-surface-3',
            className,
          )}
        >
          <CmdIcon width="9" height="8" />
          <span className="text-[11px] font-bold leading-[8px]">K</span>
        </div>
      );
    default:
      return null;
  }
};
