import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { getUserHistorySettings } from 'api/users/api';
import { UserHistorySettingsResponse } from 'api/users/schemas/UserHistory';

const useAutoRefetchUserHistory = () => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const { data: userHistorySettings } = useQuery<
    UserHistorySettingsResponse,
    Error
  >(['userHistorySettings'], getUserHistorySettings);

  useEffect(() => {
    if (!userHistorySettings) return;

    const linkTemplates = userHistorySettings.types
      .map((type) => type.link_template)
      .filter((template): template is string => template !== null);

    const isPathMatching = linkTemplates.some((template) => {
      const regex = new RegExp(template.replace('{{ ticket_id }}', '\\d+'));
      return regex.test(location.pathname);
    });

    if (isPathMatching) {
      queryClient.invalidateQueries(['userHistory']);
    }
  }, [location.pathname, userHistorySettings, queryClient]);
};

export default useAutoRefetchUserHistory;
