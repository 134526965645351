import React from 'react';
import clsx from 'clsx';
import { CashOutline, ChevronDown, PricetagsOutline } from 'react-ionicons';

import Divider from 'components/Divider';
import { ICartItem } from 'screens/AccessExpressV3/CartProvider';
import Accordion from './Accordion';
import ImagePlaceholder from '../ImagePlaceholder';
import ProductInfo from '../ProductInfo';

export interface CheckoutCardProps {
  items: ICartItem[];
  savings?: string;
  subtotal: string;
  discount?: string;
  taxes: string;
  shipping: string;
  total: string;
}

const CheckoutCard: React.FC<CheckoutCardProps> = ({
  items,
  savings,
  subtotal,
  discount,
  taxes,
  shipping,
  total,
}) => {
  return (
    <>
      <div className="flex flex-col gap-4 rounded bg-gradient-to-b from-[rgba(71,71,104,0.32)] to-[rgba(51,51,80,0.32)] p-4">
        {items.length > 0 && (
          <Accordion
            title={(isOpen) => (
              <div className="flex flex-row items-center gap-1 font-bold text-content-base-default">
                {items.length} items
                <span
                  className={clsx('', {
                    'rotate-180 transform': isOpen,
                  })}
                >
                  <ChevronDown
                    color="currentColor"
                    width="18px"
                    height="18px"
                  />
                </span>
              </div>
            )}
          >
            <div className="flex flex-col gap-2">
              {items.map((item) => (
                <div className="flex gap-3" key={item.id}>
                  <div>
                    <ImagePlaceholder />
                  </div>
                  <ProductInfo name={item.name} price={item.price} />
                </div>
              ))}
            </div>
          </Accordion>
        )}

        {savings && (
          <div className="flex items-center justify-between font-bold text-[#9FF4A9]">
            <span className="flex flex-row items-center gap-1 text-xs">
              Savings
              <CashOutline color="currentColor" width="16px" height="16px" />
            </span>
            <span>{savings}</span>
          </div>
        )}
        <Divider />
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between font-bold">
            <span className="text-xs">Subtotal</span>
            <span className="text-content-base-default">{subtotal}</span>
          </div>
          {discount && (
            <div className="flex items-center justify-between font-bold">
              <span className="text-xs">Discount</span>
              <span className="flex items-center gap-1 whitespace-nowrap rounded-lg bg-purple-300 bg-opacity-[0.16] px-2 py-1 text-xs text-purple-300">
                {discount}
                <PricetagsOutline
                  color="currentColor"
                  width="14px"
                  height="14px"
                />
              </span>
            </div>
          )}
          <div className="flex items-center justify-between font-bold">
            <span className="text-xs">Taxes</span>
            <span className="text-content-base-default">{taxes}</span>
          </div>
          <div className="flex items-center justify-between font-bold">
            <span className="text-xs">Shipping</span>
            <span className="text-content-base-default">{shipping}</span>
          </div>
        </div>
        <Divider />
        <div className="flex items-center justify-between font-bold">
          <span>Total</span>
          <span className="text-xl text-accent-primary">{total}</span>
        </div>
      </div>
    </>
  );
};

export default CheckoutCard;
