import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import Divider from 'components/Divider';
import RadioButton from 'components/RadioButton';
import { GraniteSelect } from 'components/Select/Select';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ReactComponent as Pin } from 'assets/images/pin.svg';
import clsx from 'clsx';
import {
  ProductOfferingType,
  ProductType,
} from 'screens/SelectServices/schemas';
import {
  TermType,
  buildNestedObjectWithIds,
  formatBandwidth,
  formatTerm,
  groupByMultipleKeys,
} from 'screens/SelectServices/utils';
import IconButton from 'components/IconButton';
import { motion } from 'framer-motion';
import { AccessServiceSummary } from './AccessServiceSummary';
import { useSingleServiceManager } from 'context/SingleServiceManagerContext';
import { useCartContext } from '../CartProvider';
import ConstructionBanner from './ConstructionBanner/ConstructionBanner';

const keys = [
  'provider',
  'term',
  'bandwidth_download',
  'bandwidth_upload',
  'ip_type',
  'ip_block',
];

const SEPARATOR = '|||';

interface OfferSettingsProps {
  data: {
    address: string;
    id: string;
    offerings: ProductOfferingType[];
    type?: string;
  };
  isPinnedSummary?: boolean;
  classname?: string;
}

interface SelectOptions {
  [key: string]: Array<{ label: string; value: string }> | undefined;
}

export const OfferSettings: React.FC<OfferSettingsProps> = ({
  data,
  isPinnedSummary,
  classname,
}) => {
  const {
    pinService,
    unpinService,
    addToCart,
    cartState,
    selectedValues: singleSelectedValues,
    pinnedSelectedValues,
    updateSelectedValues,
    removeFromCart,
  } = useSingleServiceManager();

  const cart = useCartContext();

  const selectedValues = isPinnedSummary
    ? pinnedSelectedValues
    : singleSelectedValues;

  const normData = data?.offerings
    ?.map((d) => ({
      ...d,
      ip_block: d.ip_block ?? 'N/A',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ip_type: d?.ip_type ?? 'STATIC',
    }))
    .map((d) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (d.bandwidth) {
        return {
          ...d,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_download: d.bandwidth,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_upload: d.bandwidth,
        };
      }
      return d;
    });

  const sorted = sortBy(normData, [
    (item) => (item.term === '3 YR' ? 0 : 1),
    (item) => -item.priority || 0,
  ]);

  const grouped = groupByMultipleKeys(sorted, keys);

  const keysOptionsList = Object.entries(grouped).map(([key, values]) => {
    return { key, id: values[0].id };
  });

  const nestedList = buildNestedObjectWithIds(keysOptionsList);

  const [options, setOptions] = useState<SelectOptions>({
    provider: [],
    term: [],
    bandwidth_download: [],
    bandwidth_upload: [],
    ip_block: [],
  });

  useEffect(() => {
    preselectOptions('provider', {}, !isPinnedSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preselectOptions = (
    key: string,
    newSelectedValues: Record<string, string>,
    shouldRunUpdate: boolean = true,
  ) => {
    const start = keys.indexOf(key);
    const newList: Record<string, string> = {};

    const selectedOptions: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let next: any = nestedList;

    for (let i = 0; i < keys.indexOf(key); i++) {
      selectedOptions.push(newSelectedValues[keys[i]]);
      next = next[newSelectedValues[keys[i]]];
    }

    const newOptions: Record<
      string,
      Array<{ label: string; value: string }>
    > = {};

    for (let i = start; i < keys.length; i++) {
      newOptions[keys[i]] = Object.keys(next).map((opt) => ({
        value: opt,
        label: opt ?? 'N/A',
      }));

      newList[keys[i]] = Object.keys(next)[0];

      next = next[newList[keys[i]]];
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));

    if (shouldRunUpdate) updateSelectedValues(newList, !!isPinnedSummary);
  };

  const handleSelectChange = (
    key: string,
    value: string,
    // autoSelectNext = false,
  ) => {
    const newSelectedValues = {
      ...selectedValues,
      [key]: value,
    };
    const nextIndex = keys.indexOf(key) + 1;

    if (nextIndex < keys.length) {
      preselectOptions(keys[nextIndex], newSelectedValues, true);
    }

    updateSelectedValues(
      {
        [key]: value,
      },
      !!isPinnedSummary,
    );
  };

  const summaryData = useMemo(() => {
    const key = keys.map((key) => selectedValues[key] || 'N/A').join(SEPARATOR);
    const product = grouped[key]?.[0] as unknown as ProductType;
    const term = grouped[key]?.[0].term as TermType;
    // onChange(data.id, data.type!, isPinnedSummary!, product);
    return {
      ...product,
      term,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const accessTypeOptions = [
    { value: 'fe', label: 'FE' },
    {
      value: summaryData?.type === 'broadband' ? 'cable' : 'ge',
      label: summaryData?.type === 'broadband' ? 'Cable' : 'GE',
    },
  ];
  const handlePin = useCallback(() => {
    pinService();
  }, [pinService]);

  const handleUnpin = useCallback(() => {
    unpinService();
  }, [unpinService]);

  const handleAddToCart = useCallback(() => {
    cart.addItem({
      id: Number(isPinnedSummary ? data.id + 1 : data.id),
      category: 'Access',
      subCategory: data.type ?? '',
      name: 'Service Selection',
      price: 100,
      quantity: 1,
    });
    addToCart(!!isPinnedSummary);
  }, [addToCart, cart, data.id, data.type, isPinnedSummary]);

  const handleRemoveFromCart = useCallback(() => {
    removeFromCart(!!isPinnedSummary);
    cart.removeItem(Number(isPinnedSummary ? data.id + 1 : data.id));
  }, [cart, data.id, isPinnedSummary, removeFromCart]);

  const isAddedToCart = useMemo(
    () =>
      (!!isPinnedSummary && cartState.pinned) ||
      (!isPinnedSummary && cartState.unpinned),
    [cartState.pinned, cartState.unpinned, isPinnedSummary],
  );

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={clsx(
        'relative flex h-full max-h-full w-full flex-col gap-4 p-6 sm:w-1/2',
        classname,
      )}
    >
      <div className="flex flex-col justify-between gap-6 lg:flex-row">
        <p className="flex flex-col justify-center font-bold">
          <span className="flex h-[38px] items-center gap-1">
            <span className="text-[28px] text-content-base-default">100</span>
            <span className="self-center text-xl text-content-base-subdued">
              /month
            </span>
            <IconButton
              icon="chevronDown"
              className={isExpanded ? 'rotate-180' : ''}
              buttonType="ghost"
              color="currentColor"
              onClick={() => setIsExpanded(!isExpanded)}
              type="button"
            />
          </span>
          <span className="text-xs">+0 service activation fee</span>
        </p>
        <div className="flex w-auto max-w-[190px] flex-col gap-2 lg:self-end">
          <GraniteButton
            variant="tertiary"
            size="medium"
            onClick={(e) => {
              e.stopPropagation();
              !isAddedToCart ? handleAddToCart() : handleRemoveFromCart();
            }}
            className="!cursor-pointer md:min-w-[185px]"
          >
            {isAddedToCart ? 'Added to cart' : 'Add to cart'}
          </GraniteButton>
          <GraniteButton
            variant="secondary"
            size="medium"
            className="hidden md:flex"
            onClick={(e) => {
              e.stopPropagation();
              isPinnedSummary ? handleUnpin() : handlePin();
            }}
          >
            {!isPinnedSummary ? 'Pin to compare' : 'Unpin'}
            <Pin color="inherit" />
          </GraniteButton>
        </div>
      </div>
      <motion.div
        key="other-options"
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: isExpanded ? 1 : 0,
        }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
        className="flex w-full flex-col gap-4"
      >
        <AccessServiceSummary
          data={summaryData}
          isPinnedSummary={isPinnedSummary}
        />
      </motion.div>
      <motion.div
        initial={{ y: 0, height: 0 }}
        animate={{ y: isExpanded ? 250 : 0, height: 'auto' }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
        className="w-full"
      >
        <Divider />
      </motion.div>
      {!isExpanded && !isPinnedSummary && (
        <ConstructionBanner text="$25,000 est. construction" />
      )}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{
          opacity: isExpanded ? 0 : 1,
        }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
        className="w-full"
      >
        <div className={clsx('grid grid-cols-2 gap-6')}>
          <GraniteLabel
            className={summaryData?.type === 'broadband' ? 'hidden' : ''}
            label="Connection type"
            element="div"
          >
            <GraniteSelect
              className="w-full"
              value={accessTypeOptions[1]}
              options={accessTypeOptions}
              isDisabled
              variant="blue"
            />
          </GraniteLabel>
          <GraniteLabel label="Carrier name" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('provider', selectedOption?.value || '')
              }
              value={
                selectedValues.provider
                  ? {
                      value: selectedValues.provider,
                      label: selectedValues.provider,
                    }
                  : null
              }
              name="provider"
              options={options.provider}
              variant="blue"
            />
          </GraniteLabel>
          <GraniteLabel label="Term length" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('term', selectedOption?.value || '')
              }
              value={
                selectedValues.term
                  ? {
                      value: selectedValues.term,
                      label: formatTerm(selectedValues.term),
                    }
                  : null
              }
              name="term"
              options={sortBy(
                options.term?.map((option) => ({
                  value: option.value,
                  label: formatTerm(option.label),
                })),
                (option) => option.label.toLowerCase(),
              )}
              isDisabled={!selectedValues.provider}
              variant="blue"
            />
          </GraniteLabel>
          <GraniteLabel label="Download" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_download',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_download
                  ? {
                      value: selectedValues.bandwidth_download,
                      label: formatBandwidth(selectedValues.bandwidth_download),
                    }
                  : null
              }
              name="bandwidth_download"
              options={options.bandwidth_download?.map((option) => ({
                value: option.value,
                label: formatBandwidth(option.label),
              }))}
              isDisabled={!selectedValues.term}
              variant="blue"
            />
          </GraniteLabel>

          <GraniteLabel label="Upload" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_upload',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_upload
                  ? {
                      value: selectedValues.bandwidth_upload,
                      label: formatBandwidth(selectedValues.bandwidth_upload),
                    }
                  : null
              }
              name="bandwidth_upload"
              options={options.bandwidth_upload?.map((option) => ({
                value: option.value,
                label: formatBandwidth(option.label),
              }))}
              variant="blue"
            />
          </GraniteLabel>
          <GraniteLabel label="IP type" element="div">
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'DYNAMIC')}
              options={[{ value: 'DYNAMIC', label: 'Dynamic IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'DYNAMIC')}
              className="mb-2"
              variant="blue"
            />
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'STATIC')}
              options={[{ value: 'STATIC', label: 'Static IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'STATIC')}
              variant="blue"
            />
          </GraniteLabel>
          <GraniteLabel
            label="IP block"
            element="div"
            className={selectedValues.ip_type === 'DYNAMIC' ? 'hidden' : ''}
          >
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('ip_block', selectedOption!.value)
              }
              value={
                selectedValues.ip_block
                  ? {
                      value: selectedValues.ip_block,
                      label: selectedValues.ip_block,
                    }
                  : null
              }
              name="ip_block"
              options={options.ip_block?.map((i) => ({
                ...i,
                label: i.label,
              }))}
            />
          </GraniteLabel>
        </div>
      </motion.div>
    </div>
  );
};
