import clsx from 'clsx';
import { Cart, Close } from 'react-ionicons';

interface CartHeaderProps {
  onIconClick: () => void;
  isOpen?: boolean;
  title: string;
}

const CartHeader = ({
  onIconClick,
  isOpen = false,
  title,
}: CartHeaderProps) => {
  return (
    <div className="flex flex-none flex-row items-center justify-between">
      <div
        className={clsx(
          'transition-delay text-2xl font-bold text-content-base-default transition-opacity duration-500',
          isOpen ? 'opacity-100' : 'opacity-0',
        )}
      >
        {title}
      </div>
      <div
        className={clsx(
          'absolute right-6 top-8 cursor-pointer transition-all duration-200',
          isOpen ? 'text-content-base-subdued' : 'text-purple-300',
          !isOpen && 'translate-x-1',
        )}
        onClick={onIconClick}
      >
        <span
          className={clsx(
            'absolute right-0 top-1 transition-opacity duration-200',
            isOpen ? 'opacity-100' : 'opacity-0',
          )}
        >
          <Close color="currentColor" width="24px" height="24px" />
        </span>
        <span
          className={clsx(
            'absolute right-0 top-1 transition-opacity duration-200',
            isOpen ? 'opacity-0' : 'opacity-100',
          )}
        >
          <Cart color="currentColor" width="24px" height="24px" />
        </span>
      </div>
    </div>
  );
};

export default CartHeader;
