import { SortableContainer } from 'react-sortable-hoc';

import LocationItem, { DragHandle } from './LocationItem';
import { useState } from 'react';
import CreateChildAccountModal from 'screens/LetUsHelp/GetStarted/CreateChildAccount/CreateChildAccountModal';
import { useModal } from 'hooks/useModal';

import { SearchAddressBar } from 'components/SearchAddressBar/SearchAddressBar';
import { SiteAddress } from 'api/addresssearch/schema';

interface LocationsListProps {
  items: Array<SiteAddress>;
  onChange: (index: number, value?: SiteAddress) => void;
}

const LocationsList = SortableContainer<LocationsListProps>(
  ({ items, onChange }: LocationsListProps) => {
    const [location, setLocation] = useState<SiteAddress>();

    const handleAdd = (value?: SiteAddress) => {
      onChange(items.length, value);
      setLocation(undefined);
    };

    const {
      open: createChildAccountModalOpen,
      ...createChildAccountModalProps
    } = useModal();

    return (
      <div className="flex select-none flex-col">
        {items.map((value, index) => (
          <LocationItem
            key={`item-${value}`}
            value={value}
            onChange={onChange}
            index={index}
            index_={index}
          />
        ))}

        <div className="relative mb-8 flex flex-none items-center gap-1">
          <DragHandle />
          <SearchAddressBar
            value={location}
            onAddressSelected={handleAdd}
            className="flex flex-1 [&>.search-bar-wrapper]:w-full [&_input]:text-sm"
            createChildAccountModalOpen={createChildAccountModalOpen}
            variant="blue"
            prefixElement={
              <div className="-ml-3 flex w-5 flex-none justify-end text-sm text-content-base-subdued">
                {items.length + 1}.
              </div>
            }
          />

          <CreateChildAccountModal
            {...createChildAccountModalProps}
            onAccountSubmit={handleAdd}
          />
        </div>
      </div>
    );
  },
);

export default LocationsList;
