import { useMemo } from 'react';
import Tabs from 'components/Table/Tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { CallOutline, PhonePortraitOutline, Wifi } from 'react-ionicons';
import { useFeatureFlags } from 'feature-flags';

enum TabsEnum {
  'access' = 0,
  'mobility' = 1,
  'voice' = 2,
}

const NavigationTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { flags } = useFeatureFlags();

  const tabs = useMemo(() => {
    return [
      {
        icon: (
          <Wifi
            width="24px"
            height="24px"
            color="currentColor"
            style={{ fill: 'currentColor' }}
          />
        ),
        title: 'Access',
        onClick: () => navigate('access'),
        className: '!pb-6 !border-b',
      },
      ...(flags.ACCESS_EXPRESS_V3_MOBILITY
        ? [
            {
              icon: (
                <PhonePortraitOutline
                  width="24px"
                  height="24px"
                  color="currentColor"
                />
              ),
              title: 'Mobility',
              onClick: () => navigate('mobility'),
              className: '!pb-6 !border-b',
            },
            {
              icon: (
                <CallOutline width="24px" height="24px" color="currentColor" />
              ),
              title: 'Voice',
              onClick: () => {},
              className: '!pb-6 !border-b',
              disabled: true,
            },
          ]
        : []),
    ];
  }, [navigate, flags.ACCESS_EXPRESS_V3_MOBILITY]);

  const activeTab = useMemo(() => {
    if (pathname.includes('/mobility')) return TabsEnum.mobility;
    if (pathname.includes('/voice')) return TabsEnum.voice;
    return TabsEnum.access;
  }, [pathname]);

  return (
    <Tabs
      variant="medium"
      className="mt-2 max-w-[320px] flex-1 overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued xs:max-w-full"
      withUnderline={false}
      defaultActiveTab={activeTab}
      tabs={tabs}
      type="accent-primary"
    />
  );
};

export default NavigationTabs;
