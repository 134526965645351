import React, { useState } from 'react';
import { SwapVertical } from 'react-ionicons';
import Dropdown, { DropdownItem } from './Dropdown';
import { useLocation, useSearchParams } from 'react-router-dom';

const Sort = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSort = (value: 'newest' | 'oldest' | 'asc' | 'desc') =>
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (decodeURIComponent(searchParams.get('sort') || '') === value)
        newParams.delete('sort');
      else newParams.set('sort', encodeURIComponent(value));
      return newParams;
    });

  return (
    <div>
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        triggerClassName="text-neutral-500 !px-0"
        label={
          <>
            Sort{' '}
            <SwapVertical width="20px" height="20px" color="currentColor" />
          </>
        }
      >
        {pathname.includes('mobility') ? (
          <>
            <DropdownItem
              active={searchParams.get('sort') === 'newest'}
              label="Newest"
              onClick={() => {
                onChangeSort('newest');
                setIsOpen(false);
              }}
            />
            <DropdownItem
              active={searchParams.get('sort') === 'oldest'}
              label="Oldest"
              onClick={() => {
                onChangeSort('oldest');
                setIsOpen(false);
              }}
            />
          </>
        ) : null}
        <DropdownItem
          active={searchParams.get('sort') === 'asc'}
          label="A to Z"
          onClick={() => {
            onChangeSort('asc');
            setIsOpen(false);
          }}
        />
        <DropdownItem
          active={searchParams.get('sort') === 'desc'}
          label="Z to A"
          onClick={() => {
            onChangeSort('desc');
            setIsOpen(false);
          }}
        />
      </Dropdown>
    </div>
  );
};

export default Sort;
