import { observer } from 'mobx-react-lite';

import Locations from '../../Locations/Locations';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import { SiteAddress } from 'api/addresssearch/schema';

const LocationBody = observer(() => {
  const cart = useCartContext();

  const handleLocationsChange = (locations: Array<SiteAddress>) => {
    cart.setLocations(locations);
  };

  return (
    <>
      <Locations locations={cart.locations} onChange={handleLocationsChange} />
    </>
  );
});

export default LocationBody;
