import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OpenOutline } from 'react-ionicons';

const DiaOrCradlepoint = ({
  carrier,
  price,
  image: Image,
  type,
}: {
  carrier: string;
  price: string;
  image: React.ElementType;
  type: 'dia' | 'cradlepoint';
}) => {
  return (
    <div className="flex flex-col justify-between xl:flex-row">
      <div className="flex flex-col gap-3">
        <h1 className={clsx('text-4xl font-bold text-content-base-default')}>
          {carrier}
        </h1>
        <p className="flex flex-col justify-center font-bold">
          <span className="flex h-[38px] gap-1">
            <span className="text-[28px] text-content-base-default">
              {price}
            </span>
            <span className="self-center text-base text-content-base-subdued xl:text-xl">
              /month for 36 months
            </span>
          </span>
          <span className="text-xs">
            {type === 'dia'
              ? '+0 service activation fee'
              : '+ individual or pooled data plan'}
          </span>
        </p>
        <Button type={type} className="hidden self-end xl:flex" />
      </div>
      <div className="flex items-start justify-between gap-8">
        <Image />
      </div>
      <Button type={type} className="mt-3 flex self-end xl:hidden" />
    </div>
  );
};

const Button = ({
  type,
  className,
}: {
  type: 'dia' | 'cradlepoint';
  className: string;
}) => {
  return (
    <GraniteButton
      variant="secondary"
      size="medium"
      className={clsx('w-full !cursor-pointer', className)}
    >
      {type === 'dia' ? 'Check availability' : 'View'}
      <OpenOutline width="18px" height="18px" color="inherit" />
    </GraniteButton>
  );
};

export default DiaOrCradlepoint;
