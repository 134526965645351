import { apiClient } from 'api/apiClient';
import { SearchResponse } from './schema';

export const getSearchResults = async (query: string) => {
  try {
    const response = await apiClient.get(
      `/api/v1/search?query=${encodeURIComponent(query)}`,
    );
    return response.data as SearchResponse;
  } catch {
    throw new Error('Failed to fetch search results');
  }
};

export const postSearchIndexing = async () => {
  try {
    const response = await apiClient.post('/api/v1/search/indexing', {
      indexes: ['quotes', 'noc-tickets', 'techexpress-tickets'],
    });
    return response.data;
  } catch {
    throw new Error('Failed to post search indexing');
  }
};
