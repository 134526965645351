import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface CheckboxProps {
  label?: string | React.ReactNode;
  checked?: boolean;
  isPartiallChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  title?: string;
  readOnly?: boolean;
  error?: boolean;
  variant?: 'default' | 'blue';
}

export const Checked = ({
  disabled,
  error,
  variant = 'default',
}: {
  disabled?: boolean;
  error?: boolean;
  variant?: 'default' | 'blue';
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        { 'fill-red-500 stroke-input-content-selected': error },
        disabled
          ? 'fill-input-background-disabled stroke-input-stroke-filled'
          : variant === 'default'
            ? 'fill-button-background-primary-default stroke-input-content-selected'
            : '!fill-[#6C9DFF] stroke-input-content-selected',
      )}
    >
      <rect x="2.5" y="2" width="20" height="20" rx="4" stroke="none" />
      <path
        d="M8.5 12L11.5 15L16.5 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Indeterminate = ({
  disabled,
  variant = 'default',
}: {
  disabled?: boolean;
  variant?: 'default' | 'blue';
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="1 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        disabled
          ? 'fill-input-background-disabled stroke-input-stroke-filled'
          : variant === 'default'
            ? 'fill-button-background-primary-default stroke-input-content-selected'
            : '!fill-[#6C9DFF] stroke-input-content-selected',
      )}
    >
      <rect x="2.5" y="2" width="20" height="20" rx="4" stroke="none" />
      <path
        d="M8.5 12H16.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      checked,
      isPartiallChecked,
      className,
      disabled,
      title,
      error,
      variant = 'default',
      ...props
    },
    ref,
  ) => {
    return (
      <label
        className={clsx(
          'group flex items-center text-base font-light',
          disabled
            ? 'cursor-default select-none text-button-content-primary-disabled'
            : 'cursor-pointer text-white',
          className,
        )}
      >
        {title && (
          <p className="w-full text-left text-base font-bold text-content-base-subdued">
            {title}
          </p>
        )}
        <div className="flex items-center text-base font-light">
          <input
            {...props}
            ref={ref}
            type="checkbox"
            checked={checked}
            className="peer absolute h-5 w-5 cursor-pointer opacity-0"
            disabled={disabled}
          />
          <div
            className={clsx(
              `h-5 w-5 flex-none rounded border border-solid bg-background-base-surface-1`,
              {
                'group-hover:border-teal-400 group-hover:bg-blue-800':
                  !checked && !isPartiallChecked && variant === 'default',
                'ring-offset-2 ring-offset-blue-900 peer-focus:ring-2 peer-focus:ring-teal-400':
                  !disabled && !error && variant === 'default',
                'border-teal-400 bg-input-background-selected':
                  (checked || isPartiallChecked) && variant === 'default',
                '!border-input-stroke-disabled !bg-input-background-disabled':
                  disabled,
                'border-red-500': error,
                'bg-red-500': error && checked,

                // blue variant
                'border-[#6C9DFF] bg-[#6C9DFF]':
                  variant === 'blue' && (checked || isPartiallChecked),
                'group-hover:border-[#6C9DFF] group-hover:bg-[#6C9DFF40]':
                  !checked && !isPartiallChecked && variant === 'blue',
                'ring-offset-2 ring-offset-blue-900 peer-focus:ring-2 peer-focus:ring-[#6C9DFF]':
                  !disabled && !error && variant === 'blue',
              },
            )}
          >
            {checked && !isPartiallChecked && (
              <Checked disabled={disabled} error={error} variant={variant} />
            )}
            {!checked && isPartiallChecked && (
              <Indeterminate disabled={disabled} variant={variant} />
            )}
          </div>
          {label && <span className={clsx('ml-2')}>{label}</span>}
        </div>
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
