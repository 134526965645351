import { useBannerVisibility } from 'hooks/useBannerVisibility';
import { CloseCircle } from 'react-ionicons';

const UnavailableServiceBanner = ({ id }: { id: string }) => {
  const { isVisible: isNotAvailableBannerVisible, handleClose } =
    useBannerVisibility(`access-product-${id}`);

  if (!isNotAvailableBannerVisible) return null;

  return (
    <div className="flex items-center gap-2 rounded border border-[#475569] bg-[linear-gradient(180deg,_rgba(144,158,177,0.08)_0%,_rgba(36,36,54,0.08)_148.21%)] p-4">
      <CloseCircle
        width="22px"
        height="22px"
        color="#94A3B8"
        onClick={handleClose}
        cssClasses="cursor-pointer"
      />
      <span className="text-base font-bold text-content-base-default">
        Sorry! Broadband services are not currently available at this location.
      </span>
    </div>
  );
};

export default UnavailableServiceBanner;
