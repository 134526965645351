import React, { useEffect, useState } from 'react';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { CloseCircle, Search } from 'react-ionicons';

interface SearchbarProps {
  placeholder: string;
  onSearch: (query: string) => void;
  onQueryClear: () => void;
  clearAllValues?: boolean;
  defaultQuery?: string;
}

const Searchbar = ({
  placeholder,
  onSearch,
  onQueryClear,
  clearAllValues,
  defaultQuery = '',
}: SearchbarProps) => {
  const [query, setQuery] = useState<string>(defaultQuery);
  const [hasSearched, setHasSearched] = useState<boolean>(!!defaultQuery);

  useEffect(() => {
    if (clearAllValues && hasSearched) {
      setQuery('');
      setHasSearched(false);
    }
  }, [clearAllValues, hasSearched]);

  const handleInputChange = (value: string) => {
    setQuery(value);
  };

  const handleSearch = () => {
    if (!query.length) return;
    if (hasSearched) {
      setHasSearched(false);
      setQuery('');
      return;
    }
    setHasSearched(true);
    onSearch(query);
  };

  const handleQueryClear = () => {
    setQuery('');
    setHasSearched(false);
    onQueryClear();
  };

  return (
    <div className="flex h-[48px] w-full items-center">
      <div className="relative flex grow self-stretch">
        <div className="absolute left-[15px] top-4 text-content-base-subdued">
          <Search color="#fff" width="16px" height="16px" />
        </div>
        {hasSearched && (
          <div className="flex grow items-center rounded-l border border-input-stroke-unfilled">
            <div
              onDoubleClick={() => setHasSearched(false)}
              className="ml-10 flex w-max items-center gap-1 rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-3 text-base font-light text-content-base-default"
            >
              {query}
              <button
                className="cursor-pointer fill-content-base-subdued"
                title="Remove search query"
                role="button"
                onClick={handleQueryClear}
              >
                <CloseCircle color="inherit" width="16px" height="16px" />
              </button>
            </div>
          </div>
        )}
        {!hasSearched && (
          <input
            type="text"
            className="granite-input box-border h-full w-full !rounded-l !rounded-r-none !px-[15px] !pl-10"
            placeholder={placeholder}
            value={query}
            name="search address"
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' ||
                (e.key === 'Enter' && e.code === 'NumpadEnter')
              )
                handleSearch();
            }}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        )}
      </div>

      <GraniteButton
        className="!box-border h-full rounded-l-none rounded-r !border-l-0 px-8 text-base focus:relative"
        variant="secondary"
        size="large"
        onClick={hasSearched ? handleQueryClear : handleSearch}
      >
        {hasSearched ? 'Reset Search' : 'Search'}
      </GraniteButton>
    </div>
  );
};

export default Searchbar;
