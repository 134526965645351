import { Modal } from '../../../components/Modal/Modal';
import { ModalParams } from '../../../hooks/useModal';
import format from 'date-fns/format';
import React from 'react';
import { GraniteButton } from '../../../components/V2/Button/GraniteButton';

import { EstimatedCost } from '../../../components/EstimatedCost/EstimatedCost';
import { ReviewTicketForm } from './schemas';
import { DetailField } from '../../TicketDetail/TicketDetailUtil';
import { PriorityBadge } from '../../../components/PriorityBadge/PriorityBadge';
import { EstimatedCostResponse } from '../../../api/techexpress/schemas/EstimatedCostSchemas';

export interface OpenTicketConfirmationDialogProps extends ModalParams {
  formData?: ReviewTicketForm;
  estimatedCost?: EstimatedCostResponse;
  onConfirmation?: (data: ReviewTicketForm) => void;
  ticketType: string;
  product: string;
  priority: 'P1' | 'P2' | 'P3';
  enableScrolling?: boolean;
}

export const ReviewTicketConfirmationDialog = ({
  formData,
  estimatedCost,
  isOpen,
  close,
  onConfirmation,
  ticketType,
  product,
  priority,
  enableScrolling = false,
}: OpenTicketConfirmationDialogProps) => {
  const getAccessTime = ({ accessTime }: ReviewTicketForm) => {
    if (accessTime.scheduling_type === 'Hard Start') {
      return `Start at ${format(accessTime.start_date, 'hh:mm a')}`;
    } else {
      return `Start between ${format(
        accessTime.start_date,
        'hh:mm a',
      )}-${format(accessTime.end_date, 'hh:mm a')}`;
    }
  };
  const getAddress = ({ site }: ReviewTicketForm) => {
    return `${site.address_line_1 ?? ''} ${site.city ?? ''}, ${
      site.state ?? ''
    } ${site.zip ?? ''}`;
  };

  return (
    <Modal
      isVisible={isOpen}
      close={close}
      className="max-h-[90vh] max-w-[624px] overflow-y-auto"
      enableScrolling={!!enableScrolling}
    >
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-3 p-8">
        <h1 className="text-[28px] leading-9 text-content-base-default">
          Review & confirm dispatch
        </h1>
        <p className="font-semibold leading-[22px] text-content-base-subdued">
          Take a final moment to review your dispatch details. Keep in mind this
          is an estimated price.
        </p>
      </div>
      <div className="flex flex-col gap-3 p-8">
        <div className="mb-1 flex items-start justify-between gap-4">
          <div className="text-2xl font-bold text-content-base-default">
            {ticketType} {product}
          </div>
          <PriorityBadge priority={priority} />
        </div>
        <DetailField
          description="Date & time"
          value={`${
            formData?.dispatchDate &&
            format(formData.dispatchDate, 'MM/dd/yyyy')
          } - ${formData?.accessTime && getAccessTime(formData)}`}
        />
        <DetailField
          description="Location"
          className="mb-3"
          value={formData && getAddress(formData)}
        />

        <EstimatedCost estimatedCost={estimatedCost} />
        <div className="mt-9">
          <p className="medium text-sm text-content-base-subdued">
            By clicking {'"Agree & submit,"'} you are: {'(1)'} confirming the
            above appointment; {'(2)'} agreeing that{' '}
            <a
              href={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
              target="_blank"
              rel="noreferrer"
              download
              className="terms-conditions cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
            >
              {' '}
              {"Granite's"} Terms and Conditions of Service
            </a>
            , including those applicable to{' '}
            <a
              href={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20Network%20Integration%20Services%20Terms%20of%20Service.pdf`}
              target="_blank"
              rel="noreferrer"
              download
              className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
            >
              Network Integration Services
            </a>
            , govern the above appointment{' '}
            {
              '(except to the extent Customer and Granite have separately agreed to any other terms governing Granite technician dispatches) '
            }
            and {'(3)'} representing and warranting that, as a representative of
            Customer, you are authorized to sign on {"Customer's"} behalf and
            bind it to these terms.
          </p>
          <div className="mt-4 flex gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Back
            </GraniteButton>
            <GraniteButton
              size="large"
              onClick={() => {
                if (formData && onConfirmation) {
                  onConfirmation(formData);
                  close();
                }
              }}
            >
              Agree & submit
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
