import clsx from 'clsx';

const CartContainer = ({
  children,
  isOpen,
  onClick,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={clsx(
        'flex-none overflow-hidden bg-blue-900 shadow-[-15px_4px_24px_0px_rgba(0,0,0,0.25)] transition-all duration-700 xl:block',
        'fixed right-0 top-16 z-[49] h-[calc(100vh-64px)] xl:sticky xl:top-0 xl:h-full xl:max-h-screen',
        isOpen ? 'w-full xs:w-[400px]' : 'w-[64px]',
      )}
    >
      <div
        className={clsx(
          'right-0 top-16 flex h-full flex-col gap-[92px] overflow-hidden px-6 py-8 transition-all duration-700 xl:top-0',
          isOpen ? 'w-full xs:w-[400px]' : 'w-[64px]',
        )}
      >
        {children}
      </div>
      <div
        onClick={onClick}
        className={clsx(
          'absolute bottom-0 left-0 top-0 w-[64px]',
          isOpen ? 'pointer-events-none' : 'cursor-pointer',
        )}
      ></div>
    </div>
  );
};

export default CartContainer;
