import { Cart } from 'react-ionicons';

const EmptyCart = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-[#1D1D2A] text-content-flip-subdued">
      <Cart color="currentColor" width="48px" height="48px" />
      <span className="font-bold">Cart is empty</span>
    </div>
  );
};

export default EmptyCart;
