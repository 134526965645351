import {
  AccessCheckoutForm,
  AccessCheckoutRequest,
} from 'api/accessexpressv3/schemas/AccessCheckoutSchemas';
import { checkoutOrderItems } from '../utils';

export const accessCheckoutToRequest = (
  data: AccessCheckoutForm,
): AccessCheckoutRequest => {
  return {
    ...data,
    locations: data.locations.map((location) => {
      return {
        address: {
          ...location,
          ...(!location.cw_site_id
            ? {
                parent_macnum: location.parent_account?.value,
                parent_name: location.parent_account?.label,
              }
            : {}),
          billing_type: location.billing_type?.value ?? null,
          invoice_style: location.invoice_style?.value ?? null,
          invoice_delivery_method:
            location.invoice_delivery_method?.value ?? null,
        },
        order_items: checkoutOrderItems,
      };
    }),
    status: 'draft',
  };
};
