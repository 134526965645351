import { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { ModalParams } from '../../../../hooks/useModal';
import { GraniteButton } from '../../../../components/V2/Button/GraniteButton';
import Checkbox from 'components/Checkbox';
import { formatCurrency } from 'shared/util/formatCurrency';
import PriceBreakdown from './PriceBreakdown';
import { useForm } from 'react-hook-form';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AccessCheckoutForm,
  AccessReviewOrderForm,
  AccessReviewOrderFormSchema,
} from 'api/accessexpressv3/schemas/AccessCheckoutSchemas';

export interface IAccessReviewOrder {
  locations: AccessCheckoutForm['locations'];
  services: string[];
  nrc: {
    name: string;
    price: number;
  }[];
  rc: {
    name: string;
    price: number;
  }[];
}

export interface AccessReviewOrderModalProps extends ModalParams {
  formData?: IAccessReviewOrder;
  onConfirmation: (formData: AccessReviewOrderForm) => void;
}

export const AccessReviewOrderModal = ({
  isOpen,
  close,
  formData,
  onConfirmation,
}: AccessReviewOrderModalProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AccessReviewOrderForm>({
    resolver: zodResolver(AccessReviewOrderFormSchema),
  });

  const onSubmit = (data: AccessReviewOrderForm) => {
    if (formData && onConfirmation) {
      onConfirmation(data);
      close();
    }
  };

  return (
    <Modal
      isVisible={isOpen}
      close={close}
      className="vertical-scrollbar z-50 max-h-[90vh] max-w-[624px] overflow-y-auto"
    >
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6">
        <h1 className="text-[28px] leading-9 text-content-base-default">
          Review & finalize order
        </h1>
        <p className="font-semibold leading-[22px] text-content-base-subdued">
          Take a moment to review the details of your order. This includes your
          selected plans, any additional services, and the total cost.
        </p>
      </div>
      {formData && (
        <div className="flex flex-col p-8">
          <PriceBreakdown
            info={formData.locations.length}
            title="Total locations"
            className="!p-4 !pb-0"
          >
            <div className="flex flex-col gap-[10px]">
              {formData.locations.map((location, index) => (
                <span
                  key={index}
                  className="text-base font-bold text-content-base-default"
                >
                  {index + 1}.&nbsp;
                  {[
                    location.address_line_1,
                    location.city,
                    location.state,
                    location.zip,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </span>
              ))}
            </div>
          </PriceBreakdown>
          <PriceBreakdown
            info={formData.services.length}
            title="Total services"
            className="!p-4 !pb-0"
          >
            <div className="flex flex-col gap-[10px]">
              {formData.services.map((service, index) => (
                <span
                  key={index}
                  className="text-base font-bold text-content-base-default"
                >
                  {service}
                </span>
              ))}
            </div>
          </PriceBreakdown>
          <PriceBreakdown
            info={
              <span className="font-bold text-content-accent-default">
                {formatCurrency(1947.0)}
              </span>
            }
            title="Total non-recurring charges"
            className="!p-4 !pb-0"
          >
            <div className="flex flex-col gap-[10px] text-base font-bold">
              {formData.nrc.map((item, index) => (
                <div key={index} className="flex justify-between">
                  <span className="text-content-base-default">{item.name}</span>
                  <span className="text-content-base-subdued">
                    {formatCurrency(item.price)}
                  </span>
                </div>
              ))}
            </div>
          </PriceBreakdown>
          <PriceBreakdown
            info={
              <span className="font-bold text-content-accent-default">
                {formatCurrency(922.0)}
              </span>
            }
            title="Total recurring charges"
            className="!p-4"
          >
            <div className="flex flex-col gap-[10px] text-base font-bold">
              {formData.rc.map((item, index) => (
                <div key={index} className="flex justify-between">
                  <span className="text-content-base-default">{item.name}</span>
                  <span className="text-content-base-subdued">
                    {formatCurrency(item.price)}
                  </span>
                </div>
              ))}
            </div>
          </PriceBreakdown>
          <form
            className="mt-6 flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <GraniteInput
              label="Order name"
              subtitle="Assign a unique name to this order to help you recognize it later"
              placeholder="E.g., HQ Primary Circuit, Store #2 Access"
              variant="blue"
              className="col-span-1"
              innerInputClassName="!w-full !box-border"
              {...register(`order_name`)}
              error={errors.order_name?.message}
            />
            <p className="mt-8 text-base font-bold text-content-base-subdued">
              By clicking the following, you are confirming that you have read,
              understood, and agree to{' '}
              <a
                href={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
              >
                {"Granite's"} Terms & Conditions.
              </a>
            </p>
            <Checkbox
              label="I agree to Granite’s Terms & Conditions"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="mt-8 flex flex-col gap-5 md:flex-row">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>
              <GraniteButton size="large" disabled={!isChecked} type="submit">
                Agree & submit
              </GraniteButton>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
