import { FC, useEffect, useState } from 'react';
import DesktopSVG from './Assets/desktop.svg';
import TabletPortraitSVG from './Assets/tablet-portrait.svg';
import TabletLandscapeSVG from './Assets/tablet-landscape.svg';
import PhoneSVG from './Assets/phone.svg';
import { motion } from 'framer-motion';
import clsx from 'clsx';

const DeviceOrder = [
  TabletLandscapeSVG,
  PhoneSVG,
  TabletPortraitSVG,
  DesktopSVG,
];

export interface DeviceAnimationProps {
  tierTwo?: boolean;
}

const DeviceAnimation: FC<DeviceAnimationProps> = ({ tierTwo = false }) => {
  const [deviceIndex, setDeviceIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => setDeviceIndex((prevIndex) => (prevIndex + 1) % DeviceOrder.length),
      1000,
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <motion.img
        src={DeviceOrder[deviceIndex]}
        className="h-14 w-14"
        animate={{
          y: ['10%', '-50%', '10%'],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: 'linear',
        }}
      />
      <motion.div
        className="rounded-full"
        initial={{ width: 48, opacity: 0.9 }}
        animate={{
          width: [48, 43.2, 38.4, 33.6, 28.8, 24, 48],
          opacity: [0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.9],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: 'linear',
        }}
        style={{
          height: '8px',
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(187, 187, 187, 0.5) 0%, rgba(205, 205, 205, 0) 100%)',
        }}
      />
      <div
        className={clsx(
          'mt-5 text-sm font-bold text-neutral-500 duration-700 ',
          {
            'translate-y-0 opacity-100': tierTwo,
            '-translate-y-6 opacity-0': !tierTwo,
          },
        )}
      >
        Hang on! We’re expanding our search...
      </div>
    </div>
  );
};

export default DeviceAnimation;
