import { FC, useCallback, useMemo, useState } from 'react';
import ProgressBar from './ProgressBar';
import ProgressList from './ProgressList';
import { useProductTourContext } from 'context/ProductTourContext';
import { observer } from 'mobx-react-lite';
import { TourSteps } from '../steps';
import ConfettiOverlay from '../ConfettiOverlay';
import { ChevronDown, ChevronUp } from 'react-ionicons';
import clsx from 'clsx';

interface ProgressTrackerProps {}

const ProgressTracker: FC<ProgressTrackerProps> = observer(() => {
  const productTour = useProductTourContext();
  const items = productTour.getSectionsStatuses(true);

  const progressBarData = useMemo(() => {
    const total =
      items
        .find((section) => section.isActive)
        ?.items?.find((subSection) => subSection.isActive)?.steps?.length || 0;
    const progress =
      items
        .find((section) => section.isActive)
        ?.items?.find((subSection) => subSection.isActive)
        ?.steps?.findIndex(
          (step) => step.step === productTour.currentStepNumber,
        ) || 0;
    return {
      progress,
      total,
    };
  }, [items, productTour.currentStepNumber]);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseToggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);
  return (
    <>
      {productTour.currentStepNumber === TourSteps.LAST_STEP && (
        <ConfettiOverlay />
      )}
      <div
        className={clsx(
          'border-radius group flex cursor-pointer flex-col gap-6 rounded-t bg-background-base-surface-3 px-8 py-6',
          isCollapsed ? 'rounded-b' : 'rounded-b-none',
        )}
        onClick={handleCollapseToggle}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-bold text-content-base-default">
            Your progress
          </div>
          <div className="color flex flex-row items-center gap-2 text-xs text-content-base-subdued transition-colors duration-150 ease-out group-hover:text-content-base-default">
            <div className="">{isCollapsed ? 'Expand' : 'Collapse'}</div>
            {isCollapsed ? (
              <ChevronDown color="currentColor" width="16px" height="16px" />
            ) : (
              <ChevronUp color="currentColor" width="16px" height="16px" />
            )}
          </div>
        </div>
        {progressBarData.total > 0 && <ProgressBar {...progressBarData} />}
      </div>

      <div
        className={clsx(
          isCollapsed ? 'max-h-0' : 'max-h-[350px]',
          'overflow-hidden transition-all duration-[500ms] ease-in-out',
        )}
      >
        <ProgressList items={items} />
      </div>
    </>
  );
});

export default ProgressTracker;
