const NotStarted = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path d="M4.375 7.875C4.85825 7.875 5.25 7.48325 5.25 7C5.25 6.51675 4.85825 6.125 4.375 6.125C3.89175 6.125 3.5 6.51675 3.5 7C3.5 7.48325 3.89175 7.875 4.375 7.875Z" />
        <path d="M7 7.875C7.48325 7.875 7.875 7.48325 7.875 7C7.875 6.51675 7.48325 6.125 7 6.125C6.51675 6.125 6.125 6.51675 6.125 7C6.125 7.48325 6.51675 7.875 7 7.875Z" />
        <path d="M9.625 7.875C10.1082 7.875 10.5 7.48325 10.5 7C10.5 6.51675 10.1082 6.125 9.625 6.125C9.14175 6.125 8.75 6.51675 8.75 7C8.75 7.48325 9.14175 7.875 9.625 7.875Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99538 1.75C4.07993 1.75 1.74124 4.05425 1.75002 6.87121L1.75003 6.8719V6.87259C1.74999 7.8525 2.0364 8.81096 2.5739 9.6301C2.6046 9.67204 2.63332 9.71539 2.65996 9.76002L2.66208 9.76356L2.66413 9.76714C2.70726 9.84259 2.75841 9.94423 2.79366 10.0565C2.82592 10.1592 2.86308 10.3266 2.81453 10.5053L2.81427 10.5063L2.3624 12.1408L3.92504 11.5768C4.0804 11.5129 4.24688 11.4803 4.41495 11.4808L4.41579 11.4808L4.41413 11.9183L4.41495 11.4808C4.5723 11.481 4.72828 11.5101 4.87516 11.5666L4.8758 11.5668C4.96448 11.601 5.31776 11.7323 5.66708 11.8301C6.01049 11.9262 6.64465 12.0427 7.09327 12.0427C9.99417 12.0427 12.2499 9.71356 12.25 6.87683C12.2499 6.52493 12.2126 6.17387 12.1389 5.82978L12.1387 5.82887C11.6467 3.50931 9.53597 1.75 6.99538 1.75ZM0.875027 6.87326C0.86505 3.55187 3.61653 0.875 6.99538 0.875C9.94409 0.875 12.4155 2.91834 12.9945 5.64667C13.0811 6.05089 13.1249 6.46313 13.125 6.87653C13.125 10.1872 10.4868 12.9177 7.09327 12.9177C6.5411 12.9177 5.82448 12.7827 5.43125 12.6727C5.04442 12.5644 4.66263 12.4224 4.56097 12.3832C4.51394 12.3652 4.46401 12.3559 4.41364 12.3558L4.41305 12.3558L4.41246 12.3558C4.35807 12.3556 4.3042 12.3664 4.25412 12.3876L4.24321 12.3923L2.38767 13.0619C2.31063 13.0935 2.22945 13.114 2.14659 13.1226L2.12326 13.1251L2.0998 13.125C2.00719 13.1247 1.91556 13.1061 1.83022 13.0701C1.74487 13.0342 1.6675 12.9817 1.6026 12.9156C1.5377 12.8495 1.48654 12.7713 1.45211 12.6853C1.41767 12.5993 1.40064 12.5074 1.40199 12.4148L1.40218 12.4016L1.40316 12.3885C1.40764 12.3287 1.41829 12.2694 1.43495 12.2118L1.95857 10.3177C1.94903 10.2877 1.9308 10.2479 1.9066 10.2051C1.89343 10.1833 1.87921 10.1622 1.864 10.1417L1.85652 10.1317L1.84963 10.1212C1.21398 9.15724 0.875122 8.02794 0.875027 6.87326Z"
        />
      </g>
    </svg>
  );
};

export default NotStarted;
