import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useCartContext } from '../../../CartProvider';

export const ProductFooter = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const cart = useCartContext();

  const onCheckout = () => {
    if (location.pathname.includes('/access')) {
      navigate('/access-express/v3/checkout/access');
    } else navigate('/access-express/v3/checkout');
  };
  const onClearAll = () => cart.clearAll();
  const onKeepShopping = () => cart.close();

  return (
    <div className="flex w-full flex-col gap-8">
      {cart.totalItems > 0 && (
        <div className="flex flex-col gap-2">
          <Divider />
          <div className="flex justify-end">
            <GraniteButton
              size="small"
              variant="ghost"
              className="!text-content-base-subdued hover:!border-accent-primary hover:!text-accent-primary"
              onClick={onClearAll}
            >
              Clear all
            </GraniteButton>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-4">
        <GraniteButton
          variant="tertiary"
          onClick={onCheckout}
          disabled={cart.totalItems === 0}
        >
          Checkout ({cart.totalItems})
        </GraniteButton>
        <GraniteButton variant="secondary" onClick={onKeepShopping}>
          Keep shopping
        </GraniteButton>
      </div>
    </div>
  );
});

export default ProductFooter;
