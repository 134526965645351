import { forwardRef, useMemo, useState } from 'react';
import TextAreaGranite from '../../components/TextArea';
import { GraniteButton } from '../../components/V2/Button/GraniteButton';
import { Tabs } from '../../components/V2/Tabs/Tabs';
import {
  isTicketCompletedOrCancelled,
  isTicketReadonlyFn,
} from './ticketdetail-utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  CANCELLED,
  COMPLETE,
  ExtendedTicket,
} from '../../api/techexpress/schema';
import {
  createTicketNote,
  getTicketNotes,
  getTicketUpdates,
  markNoteAsSeen,
} from 'api/techexpress/api';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { toSentenceCase } from 'shared/util/util';
import {
  TicketNoteRequest,
  TicketNoteRequestSchema,
  TicketNoteResponse,
} from '../../api/techexpress/schemas/TicketNoteSchemas';
import { useProductTourContext } from 'context/ProductTourContext';
import {
  RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
  useResizableSidebar,
} from 'layouts/ResizableLayout/ResizableSidebar';
import { ChatbubbleEllipses } from 'react-ionicons';
import clsx from 'clsx';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { TicketActivityItem } from 'components/TicketActivityItem/TicketActivityItem';
import { useTicketFormattedNotes } from 'hooks/useTicketFormattedNotes';

export interface TicketDetailActivityProps {
  ticket?: ExtendedTicket;
  isEdit: boolean;
}

export const TicketDetailActivity = forwardRef<
  HTMLTextAreaElement | null,
  TicketDetailActivityProps
>(function TicketDetailActivity({ ticket, isEdit = false }, ref) {
  const [currentTab, setCurrentTab] = useState<'chat' | 'ticketUpdates'>(
    'chat',
  );
  const { ticketId } = useParams();
  const { running } = useProductTourContext();

  const [newActivityId, setNewActivityId] = useState<number>();

  const { mutate: markAsSeen } = useMutation(
    async ({ ticketId, noteId }: { ticketId: number; noteId: number }) => {
      return markNoteAsSeen(ticketId, noteId);
    },
  );

  const { data } = useQuery(
    [ticketId, 'ticket-notes'],
    () => getTicketNotes(ticketId),
    {
      enabled: !!ticketId && !running && ticketId !== '1',
      onSuccess: (data) => {
        const firstUnseenNote = data.find((note) => !note.seen);
        if (firstUnseenNote) {
          if (!newActivityId) {
            setNewActivityId(firstUnseenNote.id);
          }
          const lastUnseenNote = data[data.length - 1];
          if (lastUnseenNote.ticket_id && lastUnseenNote.id) {
            markAsSeen({
              ticketId: lastUnseenNote.ticket_id,
              noteId: lastUnseenNote.id,
            });
          }
        }
      },
    },
  );

  const { isExpanded } = useResizableSidebar();

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="relative z-10 flex flex-none flex-col gap-9 bg-background-base-surface-1 pt-4">
        <div className="flex">
          <h2
            className={clsx(
              'overflow-hidden text-2xl font-bold text-content-base-default',
              RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
              !isExpanded ? 'w-0 opacity-0' : 'w-[117px]',
            )}
          >
            Activity
          </h2>
          <div
            className={clsx(
              '!min-h-10 flex w-max whitespace-nowrap rounded bg-[#E2E8F0] bg-opacity-20 px-3 py-2 text-sm font-bold leading-5 text-[#E2E8F0] !outline-none transition-opacity ',
              newActivityId &&
                'animate-new-activity-shake bg-green-300 text-green-300',
              isExpanded && !newActivityId && 'opacity-0',
            )}
          >
            <span>
              <ChatbubbleEllipses
                color="currentColor"
                width="20px"
                height="20px"
              />
            </span>
            <span
              className={clsx(
                'color-green-300 overflow-hidden',
                RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
                !isExpanded ? 'w-0 p-0' : 'w-[96px] pl-2',
              )}
            >
              New activity
            </span>
          </div>
        </div>
        <div
          className={clsx(
            RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
            !isExpanded && 'opacity-0',
          )}
        >
          <Tabs
            className="mb-6"
            tabs={[
              {
                title: 'Notes',
                active: currentTab === 'chat',
                onClick: () => setCurrentTab('chat'),
              },
              {
                title: 'Ticket updates',
                active: currentTab === 'ticketUpdates',
                onClick: () => setCurrentTab('ticketUpdates'),
              },
            ]}
          />
        </div>
      </div>
      <div
        className={clsx(
          'flex flex-1 overflow-hidden',
          RESIZABLE_SIDEBAR_TRANSITION_CLASSES,
          !isExpanded && 'opacity-0',
        )}
      >
        {currentTab === 'chat' && (
          <Chat
            ticket={ticket}
            notes={data}
            isEdit={isEdit}
            ref={ref}
            isExpanded={isExpanded}
            newActivityId={newActivityId}
            onSubmitted={() => setNewActivityId(undefined)}
          />
        )}
        {currentTab === 'ticketUpdates' && (
          <TicketUpdates ticketId={ticketId} />
        )}
      </div>
    </div>
  );
});

interface ChatProps {
  ticket?: ExtendedTicket;
  notes?: TicketNoteResponse[];
  isEdit: boolean;
  isExpanded?: boolean;
  newActivityId?: number;
  onSubmitted?: () => void;
}

export const Chat = forwardRef<HTMLTextAreaElement | null, ChatProps>(
  function Chat(
    { isEdit, notes, ticket, isExpanded, newActivityId, onSubmitted },
    ref,
  ) {
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<TicketNoteRequest>({
      resolver: zodResolver(TicketNoteRequestSchema),
    });

    const isTicketReadonly = useMemo(
      () => (!ticket ? false : isTicketReadonlyFn(ticket)),
      [ticket],
    );

    const queryClient = useQueryClient();

    const createTicketNoteMutation = useMutation(
      ({
        ticketId,
        newTicketNote,
      }: {
        ticketId: string | undefined;
        newTicketNote: { text: string };
      }) => createTicketNote(newTicketNote, ticketId),
      {
        onSuccess: () => {
          queryClient.refetchQueries([ticket?.id.toString(), 'ticket-notes']);
        },
      },
    );

    const onSubmit = (data: { text: string }) => {
      createTicketNoteMutation.mutate(
        {
          ticketId: ticket?.id.toString(),
          newTicketNote: { text: data.text },
        },
        {
          onSuccess: onSubmitted,
        },
      );
      reset({ text: '' });
    };

    const formattedNotes = useTicketFormattedNotes(notes ?? []);
    return (
      <div className="relative flex w-full flex-col gap-6">
        <div className="flex-end vertical-scrollbar flex flex-1 flex-col gap-6 rounded bg-background-base-surface-1 p-4">
          {(formattedNotes ?? [])?.map((cm, index) => (
            <TicketActivityItem
              key={cm.id}
              datetime={cm.date_entered}
              title={cm.created_by}
              body={cm.text}
              isActive={
                isExpanded && index === (formattedNotes ?? []).length - 1
              }
              hasNewActivityDivider={!!newActivityId && cm.id === newActivityId}
              color={cm.color}
            />
          ))}
        </div>
        <div className="flex-none px-0.5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <span className="text-base font-bold text-teal-400">
              Add a note
            </span>
            <Controller
              disabled={isTicketCompletedOrCancelled(ticket)}
              name="text"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ref: _ref, ...field } }) => (
                <TextAreaGranite
                  {...field}
                  ref={ref}
                  className="mb-4 mt-2 !ring-0 !ring-offset-0"
                  error={errors.text?.message}
                  disabled={
                    isTicketReadonly ||
                    isEdit ||
                    isTicketCompletedOrCancelled(ticket)
                  }
                />
              )}
            />
            <div className="flex justify-end">
              <DisplayForPermission
                permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                upgradeTooltip
              >
                <GraniteButton
                  type="submit"
                  disabled={
                    isTicketReadonly ||
                    isEdit ||
                    createTicketNoteMutation.isLoading ||
                    isTicketCompletedOrCancelled(ticket)
                  }
                >
                  Submit
                </GraniteButton>
              </DisplayForPermission>
            </div>
          </form>
        </div>
      </div>
    );
  },
);

export const TicketUpdates = ({ ticketId }: { ticketId?: string }) => {
  const { data: updates } = useQuery(
    [ticketId, 'ticket-updates'],
    () => getTicketUpdates(ticketId),
    {
      enabled: !!ticketId,
    },
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex-end flex flex-col gap-6 overflow-y-scroll rounded bg-background-base-surface-1 p-4 scrollbar-none">
        {updates?.map((update) => (
          <TicketActivityItem
            key={update.date_entered}
            datetime={update.date_entered}
            title={
              !update.old_status && update.new_status
                ? 'Ticket opened'
                : update.new_status === CANCELLED
                  ? 'Ticket cancelled'
                  : update.new_status === COMPLETE
                    ? 'Ticket completed'
                    : 'Status update'
            }
            body={
              !update.old_status && update.new_status
                ? `Ticket #${ticketId} was created`
                : update.new_status === CANCELLED
                  ? `Ticket #${ticketId} was cancelled`
                  : update.new_status === COMPLETE
                    ? `Ticket #${ticketId} was completed`
                    : `Ticket status changed from ${toSentenceCase(
                        update.old_status,
                      )} to ${toSentenceCase(update.new_status)}`
            }
          />
        ))}
      </div>
    </div>
  );
};
