import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { useCartContext } from './CartProvider';
import CartHeader from './BaseComponents/Cart/layout/CartHeader';
import {
  CartBody,
  CartFooter,
  CartContent,
  CartContainer,
} from './BaseComponents/Cart/layout';
import {
  LocationOrderBody,
  LocationOrderFooter,
  LocationProductBody,
  LocationProductFooter,
} from './BaseComponents/Cart/locationFlow';
import { ProductBody, ProductFooter } from './BaseComponents/Cart/productFlow';
import CheckoutBody from './BaseComponents/Cart/checkoutFlow/CheckoutBody';
import CheckoutFooter from './BaseComponents/Cart/checkoutFlow/CheckoutFooter';

const Cart = observer(() => {
  const cart = useCartContext();

  const handleClick = useCallback(() => {
    cart.isOpen ? cart.close() : cart.open();
  }, [cart]);

  const location = useLocation();
  const isAccessServicePage = location.pathname.includes(
    '/order-services/access',
  );
  const isMobilityServicePage = location.pathname.includes(
    '/order-services/mobility',
  );
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isAccessProductPage = location.pathname.includes('/access/product');
  const isMobilityProductDetailsPage =
    location.pathname.includes('/product') && !isAccessProductPage;

  return (
    <CartContainer isOpen={cart.isOpen} onClick={handleClick}>
      <CartHeader
        onIconClick={handleClick}
        isOpen={cart.isOpen}
        title={
          isAccessServicePage
            ? 'Locations'
            : isCheckoutPage
              ? 'Summary'
              : 'Cart'
        }
      />
      <CartBody isOpen={cart.isOpen}>
        <CartContent>
          {isAccessServicePage ? (
            <LocationOrderBody />
          ) : isAccessProductPage ? (
            <LocationProductBody />
          ) : isMobilityServicePage ? (
            <ProductBody />
          ) : isMobilityProductDetailsPage ? (
            <ProductBody />
          ) : isCheckoutPage ? (
            <CheckoutBody />
          ) : (
            <></>
          )}
        </CartContent>
        <CartFooter>
          {isAccessServicePage ? (
            <LocationOrderFooter />
          ) : isAccessProductPage ? (
            <LocationProductFooter />
          ) : isMobilityServicePage ? (
            <ProductFooter />
          ) : isMobilityProductDetailsPage ? (
            <ProductFooter />
          ) : isCheckoutPage ? (
            <CheckoutFooter />
          ) : (
            <></>
          )}
        </CartFooter>
      </CartBody>
    </CartContainer>
  );
});

export default Cart;
