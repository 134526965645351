import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { getAccessProductsGrid } from '../utils';
import { BasePagination } from 'api/common-schemas';
import Products from '../BaseComponents/Products/Products';
import { ProductType } from '../accessExpress.types';
import { useCartContext } from '../CartProvider';

const PAGE_SIZE = 12;

const Access = observer(() => {
  const cart = useCartContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(
    location.state?.page ?? 0,
  );
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData([
    'order-service-access',
    currentPage + 1,
    searchParams.get('search'),
    searchParams.get('sort'),
  ]);

  const _getProducts = () => {
    if (cachedData)
      return Promise.resolve(cachedData as BasePagination<ProductType>);

    return getAccessProductsGrid({
      page: currentPage + 1,
      page_size: PAGE_SIZE,
      search: searchParams.get('search') ?? '',
      sort_order: (searchParams.get('sort') as 'asc' | 'desc') ?? 'asc',
    });
  };

  const { data, isFetching } = useQuery(
    [
      'order-service-access',
      currentPage + 1,
      searchParams.get('search'),
      searchParams.get('sort'),
    ],
    _getProducts,
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const onProductClick = (product: ProductType) => {
    cart.selectProduct(product);
  };

  const onChangePageHistory = (page: number) => {
    navigate(location, { state: { page }, replace: true });
  };

  return (
    <div className="mt-6">
      <Products
        price_type="starting"
        onProductClick={onProductClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isFetching}
        onChangePageHistory={onChangePageHistory}
        products={data?.data ?? []}
        total_pages={data?.metadata?.total_pages ?? 0}
        page={data?.metadata.page}
        page_size={PAGE_SIZE}
      />
    </div>
  );
});

export default Access;
