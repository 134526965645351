const colors = {
  primary: {
    100: '#61dafb',
  },
  text: {
    white: '#F8FAFC',
    primary: '#94A3B8',
    dark: '#191925',
    disabled: '#334155',
    secondary: '#6C9DFF',
    error: '#EC4649',
    tertiary: '#9796f3',
    blue: '#6C9DFF',
  },
  background: {
    light: '#fff',
    medium: '#222231',
    dark: '#191925',
    row: '#2B2B3F',
    rowHover: '#334155',
    readonly: '#1e293b',
    baseSurface: '#0d1219',
    baseSurface1: '#191925',
    baseSurface2: '#222231',
    baseSurface3: '#2b2b3f',
    primary: '#82F0FF',
  },
  bar: {
    priority1: '#1A4BAB',
    priority2: '#3D79EF',
    priority3: '#96BAFF',
  },
  donut: {
    techCheckedIn: '#82FF91',
    techAssigned: '#9796F3',
    pendingTechAssignment: '#FBB979',
    pendingCustomer: '#FF315E',
    onHold: '#F9CB34',
  },
  stroke: {
    readonly: '#475569',
    focus: '#82F0FF',
    error: '#FF315E',
    filled: '#F8FAFC',
    unfilled: '#94A3B8',
    secondary: '#CBD5E1',
  },
  content: {
    readonly: '#94A3B8',
    error: '#FF315E',
  },
  border: {
    light: '#94A3B8',
    medium: '#d6d8dB',
    dark: '#d8d8d9',
    hr: '#475569',
    error: '#EC4649',
  },
  button: {
    primary: '#82F0FF',
    destructive: '#EC4649',
    primaryHover: '#A7F4FF',
    disabled: '#94A3B8',
    secondary: '#E2E8F0',
    active: '#479CA8',
    hoverColor: '#191925',
    tertiary: '#9796f3',
    blue: '#6C9DFF',
  },
  tab: {
    active: '#37808A',
  },
  iconButton: {
    primary: {
      backgroundColor: '#82F0FF',
      color: '#191925',
      hover: '#A7F4FF',
      disabled: '#94A3B8',
      active: '#479CA8',
    },
    secondary: {
      borderColor: '#F8FAFC',
      color: '#F8FAFC',
      hover: '#82F0FF',
      hoverColor: '#191925',
      disabled: '#94A3B8',
      active: '#479CA8',
    },
    destructive: {
      borderColor: '#CBD5E1',
      backgroundColor: '#EC4649',
      color: '#F8FAFC',
      hover: '#F67274',
      disabled: '#94A3B8',
      active: '#B71F23',
    },
    ghost: {
      color: '#F8FAFC',
      hover: '#82F0FF',
      disabled: '#94A3B8',
      active: '#479CA8',
    },
  },
  scroll: {
    background: '#191925',
    thumb: '#94a3b8',
  },
  segment: {
    light: '#82F0FF',
    primary: '#82F0FF',
    tertiary: '#9796f3',
    blue: '#6C9DFF',
  },
  status: {
    status_success_default: '#82FF91',
    status_success_subdued: '#378A41',
    status_error_default: '#EC4649',
    status_error_subdued: '#B71F23',
    status_warning_default: '#FBB979',
    status_warning_subdued: '#C0421B',
    status_info_default: '#9796F3',
    status_info_subdued: '#2A2C89',
    status_neutral_default: '#222231',
  },
};

const text = {
  size: {
    '2xs': '8px',
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '40px',
    '5xl': '52px',
  },
};

const heading = {
  size: {
    '3xl': '44px',
    '2xl': '36px',
    xl: '32px',
    lg: '28px',
    sm: '20px',
    md: '24px',
    xs: '16px',
  },
};

const spacing = {
  factorOf: (factor: number) => {
    const defaultSpacing = 4;
    return `${factor * defaultSpacing}px`;
  },
};

const breakpoints = {
  mobile: 375,
  tablet: 768,
};

const media = `@media (max-width: ${breakpoints.tablet}px)`;

const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'Poppins, sans-serif',
  special: 'Helvetica, serif',
};

const fontWeights = {
  bold: 600,
  semibold: 500,
  medium: 400,
  regular: 300,
};

const customTheme = {
  colors,
  text,
  spacing,
  breakpoints,
  media,
  fonts,
  fontWeights,
  heading,
};

export default customTheme;
