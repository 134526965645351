import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AccessCheckoutForm,
  AccessCheckoutFormSchema,
  AccessCheckoutRequest,
} from 'api/accessexpressv3/schemas/AccessCheckoutSchemas';
import { checkoutDummyAddresses } from '../utils';
import { useMutation } from 'react-query';
import { postOrder } from 'api/accessexpressv3/api';
import showToast from 'components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { accessCheckoutToRequest } from '../AccessCheckout/accessCheckoutToRequest';

interface AccessCheckoutContextType {
  currentIndex: number;
  locations: AccessCheckoutForm['locations'];
  form: UseFormReturn<AccessCheckoutForm>;
  goToNext: () => Promise<void>;
  goToPrevious: () => void;
  isCheckoutFormValid: () => Promise<boolean>;
  onSubmit: (data: AccessCheckoutForm) => Promise<void>;
}

const AccessCheckoutContext = createContext<AccessCheckoutContextType | null>(
  null,
);

export const AccessCheckoutProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const [locations, setLocations] = useState<AccessCheckoutForm['locations']>(
    checkoutDummyAddresses.map((address) => ({
      ...address,
      business_name: '',
      local_contact_name: '',
      local_contact_phone: '',
      address_line_2: '',
    })),
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const form = useForm<AccessCheckoutForm>({
    resolver: zodResolver(AccessCheckoutFormSchema),
    defaultValues: { locations },
    mode: 'onChange',
  });

  const { setValue, getValues, trigger } = form;

  const totalLocations = locations.length;

  const currentLocation = useMemo(
    () => locations[currentIndex],
    [currentIndex, locations],
  );

  // Update form values when the current location changes
  useEffect(() => {
    Object.entries(currentLocation).forEach(([key, value]) => {
      setValue(
        `locations.${currentIndex}.${
          key as keyof AccessCheckoutForm['locations'][number]
        }`,
        value,
      );
    });
  }, [currentIndex, currentLocation, setValue]);

  // Update the locations state with the current form values
  const updateLocationState = useCallback(() => {
    setLocations((prevLocations) => {
      const updatedLocations = [...prevLocations];
      updatedLocations[currentIndex] = {
        ...getValues(`locations.${currentIndex}`),
      };
      return updatedLocations;
    });
  }, [currentIndex, getValues, setLocations]);

  const goToPrevious = useCallback(() => {
    updateLocationState();
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  }, [updateLocationState]);

  const goToNext = useCallback(async () => {
    const isValid = await trigger(`locations.${currentIndex}`);
    if (!isValid) return;

    updateLocationState();
    setCurrentIndex((prev) => Math.min(prev + 1, totalLocations - 1));
  }, [updateLocationState, trigger, currentIndex, totalLocations]);

  const postOrderMutation = useMutation(
    (data: AccessCheckoutRequest) => postOrder(data),
    {
      onSuccess: () => {
        showToast.confirmation({
          message: 'Order placed!',
        });
        navigate('/access-express/v3/order-services/access');
      },
    },
  );

  const onSubmit = async (data: AccessCheckoutForm) => {
    postOrderMutation.mutate(accessCheckoutToRequest(data));
  };

  const isCheckoutFormValid = useCallback(async () => {
    const isValid = await Promise.all(
      locations.map((_, index) => trigger(`locations.${index}`)),
    ).then((results) => results.every(Boolean));

    return isValid;
  }, [locations, trigger]);

  return (
    <AccessCheckoutContext.Provider
      value={{
        form,
        locations,
        goToNext,
        goToPrevious,
        currentIndex,
        onSubmit,
        isCheckoutFormValid,
      }}
    >
      {children}
    </AccessCheckoutContext.Provider>
  );
};

export const useAccessCheckoutContext = () => {
  const context = useContext(AccessCheckoutContext);
  if (!context) {
    throw new Error(
      'useAccessCheckoutContext must be used within a CheckoutProvider',
    );
  }
  return context;
};
