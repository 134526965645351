import { observer } from 'mobx-react-lite';

import { CartItemList } from '../CartItemList';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import EmptyCart from './EmptyCart';

const ProductBody = observer(() => {
  const cart = useCartContext();

  const handleEdit = (id: number) => {
    console.log('Edit item:', id);
  };

  const handleDelete = (id: number) => {
    cart.removeItem(id);
  };

  const handleQuantityChange = (id: number, newQty: number) => {
    cart.updateItemQuantity(id, newQty);
  };

  return (
    <>
      {cart.items.length ? (
        <CartItemList
          items={cart.items}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onQuantityChange={handleQuantityChange}
        />
      ) : (
        <EmptyCart />
      )}
    </>
  );
});

export default ProductBody;
