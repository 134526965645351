const Completed = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78294 0.370144C6.91744 0.293285 7.08256 0.293285 7.21706 0.370144L13.3421 3.87014C13.4784 3.94804 13.5625 4.093 13.5625 4.25V9.0625C13.5625 9.30412 13.3666 9.5 13.125 9.5C12.8834 9.5 12.6875 9.30412 12.6875 9.0625V5.00389L11.375 5.75389V9.0625C11.375 9.22139 11.2889 9.36778 11.15 9.44494L7.21247 11.6324C7.08033 11.7059 6.91967 11.7059 6.78753 11.6324L2.85003 9.44494C2.71114 9.36778 2.625 9.22139 2.625 9.0625V5.75389L0.657939 4.62986C0.521625 4.55196 0.4375 4.407 0.4375 4.25C0.4375 4.093 0.521625 3.94804 0.657939 3.87014L6.78294 0.370144ZM3.5 6.25389V8.80507L6.5625 10.5065V8.00389L3.5 6.25389ZM7.4375 8.00389V10.5065L10.5 8.80507V6.25389L7.4375 8.00389ZM7 7.24611L12.2432 4.25L7 1.25389L1.75681 4.25L7 7.24611Z"
      />
    </svg>
  );
};

export default Completed;
