import AccordionService from './AccordionService';
import ServiceContent from './service/ServiceContent';

const Services = () => {
  return (
    <div className="flex flex-col gap-6">
      <AccordionService
        serviceType="Access"
        activeNumber={2}
        defaultExpand={true}
      >
        <ServiceContent
          status={true}
          serviceType="dia"
          graniteMangaged
          carrier="Comcast"
          serviceId="83611000121011182"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="broadband"
          graniteMangaged
          carrier="Time Warner"
          serviceId="836110001210111256"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
      </AccordionService>
      <AccordionService serviceType="Manage Network Services" activeNumber={6}>
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230019"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230020"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230032"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230022"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230023"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="edgeBoot"
          graniteMangaged
          carrier="AT&T"
          serviceId="2045230024"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
      </AccordionService>
      <AccordionService serviceType="Mobility" activeNumber={5}>
        <ServiceContent
          status={true}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="T-Mobile"
          serviceId="9377431225"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="T-Mobile"
          serviceId="9377431212"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="T-Mobile"
          serviceId="9377431213"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="AT&T"
          serviceId="9379881102"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="Verizon"
          serviceId="7148827734"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="wireless_mobility"
          graniteMangaged
          carrier="Verizon"
          serviceId="7147431214"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="wireless_mobility"
          graniteMangaged={false}
          carrier="Verizon"
          serviceId="714743125"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="wireless_mobility"
          graniteMangaged={false}
          carrier="Verizon"
          serviceId="714743125"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
      </AccordionService>
      <AccordionService serviceType="VOIP" activeNumber={5}>
        <ServiceContent
          status={true}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975608"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975609"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975610"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975611"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975612"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="hosted_pbx"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975613"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="hosted_pbx"
          graniteMangaged={false}
          carrier="Granite"
          serviceId="5085975614"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="hosted_pbx"
          graniteMangaged={false}
          carrier="Granite"
          serviceId="5085975615"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
      </AccordionService>
      <AccordionService serviceType="POTS" activeNumber={5}>
        <ServiceContent
          status={true}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975608"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975609"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975610"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975611"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={true}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975612"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="pots"
          graniteMangaged
          carrier="Granite"
          serviceId="5085975613"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="pots"
          graniteMangaged={false}
          carrier="Granite"
          serviceId="5085975614"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
        <ServiceContent
          status={false}
          serviceType="pots"
          graniteMangaged={false}
          carrier="Granite"
          serviceId="5085975615"
          vendor="Time Warner"
          serviceIdentifier="8361100012101725"
          completionDate="10/10/23"
        />
      </AccordionService>
    </div>
  );
};

export default Services;
