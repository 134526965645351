interface ProductInfoProps {
  name: string;
  price: number;
}

const ProductInfo = ({ name, price }: ProductInfoProps) => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);

  return (
    <div className="item flex flex-grow flex-col justify-center gap-2">
      <div className="text-slate-50 text-xs font-bold text-content-base-default">
        {name}
      </div>
      <div className="flex gap-1 text-xs font-semibold">
        <span className="text-content-base-default">{formattedPrice}</span>
        <span className="opacity-50">x 1</span>
      </div>
    </div>
  );
};

export default ProductInfo;
