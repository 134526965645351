import { observer } from 'mobx-react-lite';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import { useAccessCheckoutContext } from 'screens/AccessExpressV3/context/AccessCheckoutProvider';
import { useModal } from 'hooks/useModal';
import { useCallback, useMemo } from 'react';
import { accessReviewOrderDummyData } from 'screens/AccessExpressV3/utils';
import { AccessReviewOrderModal } from '../../ReviewOrderModal/AccessReviewOrderModal';
import { AccessReviewOrderForm } from 'api/accessexpressv3/schemas/AccessCheckoutSchemas';

const CheckoutFooter = observer(() => {
  const cart = useCartContext();
  const { currentIndex, locations, isCheckoutFormValid, onSubmit, form } =
    useAccessCheckoutContext();
  const { isOpen, open, close } = useModal();

  const disablePlaceOrder = currentIndex !== locations.length - 1;

  const formData = useMemo(() => {
    return {
      locations: form.getValues().locations,
      ...accessReviewOrderDummyData,
    };
  }, [form]);

  const handleConfirmation = ({ order_name }: AccessReviewOrderForm) => {
    onSubmit({ ...form.getValues(), order_name });
  };

  const handleClose = useCallback(() => {
    close();
    cart.open();
  }, [cart, close]);

  const handlePlaceOrderClick = async () => {
    const isValid = await isCheckoutFormValid();
    if (!isValid) {
      return;
    }
    cart.close();
    open();
  };

  return (
    <div className="flex flex-col gap-4">
      <GraniteButton
        variant="tertiary"
        form="access-checkout-form"
        type="button"
        onClick={handlePlaceOrderClick}
        disabled={disablePlaceOrder}
      >
        Place order
      </GraniteButton>
      <GraniteButton variant="secondary" onClick={() => cart.close()}>
        Cancel
      </GraniteButton>
      <AccessReviewOrderModal
        onConfirmation={handleConfirmation}
        isOpen={isOpen}
        close={handleClose}
        formData={formData}
      />
    </div>
  );
});

export default CheckoutFooter;
