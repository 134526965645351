const InProgress = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.3125 6C0.3125 2.85994 2.85994 0.3125 6 0.3125C9.14006 0.3125 11.6875 2.85994 11.6875 6C11.6875 9.14006 9.14006 11.6875 6 11.6875C2.85994 11.6875 0.3125 9.14006 0.3125 6ZM6 1.1875C3.34319 1.1875 1.1875 3.34319 1.1875 6C1.1875 8.65681 3.34319 10.8125 6 10.8125C8.65681 10.8125 10.8125 8.65681 10.8125 6C10.8125 3.34319 8.65681 1.1875 6 1.1875Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.0625C6.24162 2.0625 6.4375 2.25838 6.4375 2.5V6H8.625C8.86662 6 9.0625 6.19588 9.0625 6.4375C9.0625 6.67912 8.86662 6.875 8.625 6.875H6C5.75838 6.875 5.5625 6.67912 5.5625 6.4375V2.5C5.5625 2.25838 5.75838 2.0625 6 2.0625Z"
        />
      </g>
    </svg>
  );
};

export default InProgress;
