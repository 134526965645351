import { useEffect, forwardRef } from 'react';
import { ChevronBack, ChevronForward } from 'react-ionicons';

import useLocalStorageState from 'hooks/useLocalStorageState';

interface NavbarExpandButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

const NavbarExpandButton = forwardRef<HTMLDivElement, NavbarExpandButtonProps>(
  ({ isExpanded, onClick }, ref) => (
    <div className="absolute -right-3 top-20" ref={ref}>
      <div className="absolute -top-[6px] left-[12px] h-[38px] w-[9px] overflow-hidden">
        <div className="absolute -top-[10px] left-0 box-content h-[14px] w-[15px] rounded-full border-4 border-[#262636]"></div>
        <div className="absolute left-0 top-[28px] box-content h-[14px] w-[15px] rounded-full border-4 border-[#262636]"></div>
      </div>
      <div className="relative flex h-7 w-7 items-center justify-center rounded-full bg-[#262636]">
        <div
          className="product-tour-my-company-navbar-toggle flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full bg-[#333345] text-[#7E77B3]"
          onClick={onClick}
        >
          {isExpanded ? (
            <ChevronBack color="currentColor" width="10px" height="10px" />
          ) : (
            <ChevronForward color="currentColor" width="10px" height="10px" />
          )}
        </div>
      </div>
    </div>
  ),
);
NavbarExpandButton.displayName = 'NavbarExpandButton';

export default NavbarExpandButton;

export const useNavbarExpandButton = () => {
  const [isNavbarExpanded, setIsNavbarExpanded] = useLocalStorageState<boolean>(
    'navBarExpanded',
    true,
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;

      if (
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        (activeElement as HTMLElement).isContentEditable
      ) {
        return;
      }

      if (event.key === ']') {
        setIsNavbarExpanded((prevState) => !prevState);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isNavbarExpanded, setIsNavbarExpanded };
};
