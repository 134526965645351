import React from 'react';

import CartItem from './CartItem';
import { ICartItem } from '../../../CartProvider';

interface CartItemListProps {
  items: ICartItem[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onQuantityChange: (id: number, newQuantity: number) => void;
}

const CartItemList: React.FC<CartItemListProps> = ({
  items,
  onEdit,
  onDelete,
  onQuantityChange,
}) => {
  return (
    <div className="flex flex-col gap-4">
      {items.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          onEdit={() => onEdit(item.id)}
          onDelete={() => onDelete(item.id)}
          onQuantityChange={(newQty) => onQuantityChange(item.id, newQty)}
        />
      ))}
    </div>
  );
};

export default CartItemList;
