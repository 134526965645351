import { useState, useMemo } from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import {
  LocationReponse,
  OrderItemResponse,
  OrderResponse,
} from 'api/accessexpressv3/schemas/OrdersSchemas';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
} from 'components/Table/Table.styles';

type OrderDetailsType = {
  productCount: number;
  streetAddress: string;
  fullAddress: string;
  location: LocationReponse;
  products: OrderItemResponse[];
};

export const useOrderTable = (data?: OrderResponse) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'index', desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  const accessProductsToColumns = useMemo(() => {
    return Array.from(
      new Set(
        data?.locations?.flatMap((item) =>
          item.order_items.map((product) => product.subtype),
        ),
      ),
    ).filter((item) => ['Broadband', 'DIA'].includes(item));
  }, [data]);

  const transformedData = useMemo(() => {
    return data?.locations?.map((item) => ({
      productCount: item.order_items.length,
      streetAddress: item.address?.address_line_1,
      fullAddress: `${item.address?.city}, ${item.address?.state} ${item.address?.zip}`,
      location: item,
      products: item.order_items,
    }));
  }, [data]);

  const columns: ColumnDef<OrderDetailsType>[] = useMemo(
    () => [
      {
        id: 'index',
        header: '#',
        accessorKey: 'index',
        enableSorting: true,
        sortingFn: (a: { index: number }, b: { index: number }) =>
          a.index - b.index,
        cell: (cellCtx: { row: { index: number } }) => (
          <span className="text-content-base-subdued">
            {cellCtx.row.index + 1}
          </span>
        ),
      },
      {
        id: 'address',
        header: 'Address',
        accessorFn: (row: OrderDetailsType) =>
          [row.streetAddress, row.fullAddress].join(', '),
        enableHiding: false,
        meta: {
          isAddress: true,
        },
        enableSorting: false,
        cell: (cellCtx) => {
          return (
            <StackedCell className="w-full">
              <StackedCellMainContent>
                {cellCtx.row.original.streetAddress}
              </StackedCellMainContent>
              <StackedCellSubtitle>
                {cellCtx.row.original.fullAddress}
              </StackedCellSubtitle>
            </StackedCell>
          );
        },
      },
      ...accessProductsToColumns.map((type) => ({
        id: type,
        meta: {
          isProduct: true,
        },
        header: type.charAt(0).toUpperCase() + type.slice(1),
        accessorFn: (row: OrderDetailsType) => {
          const products = row.products.filter(
            (product) => product.subtype === type,
          );
          if (products.length) {
            return (
              <div className="flex items-start justify-start gap-2">
                {products.map((product) => {
                  // TODO: mimic ProductTooltip impementation from OpenQuote/QuoteDetails
                  return <p key={product.id}>{product.subtype}</p>;
                })}
              </div>
            );
          } else {
            return (
              <span className="text-sm font-medium text-content-base-subdued">
                N/A
              </span>
            );
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cell: (info: any) => <span>{info.getValue()}</span>,
      })),
    ],
    [accessProductsToColumns],
  );

  const table = useReactTable({
    data: transformedData ?? [],
    // @ts-expect-error err
    columns: columns ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    enableMultiSort: false,
    sortDescFirst: false,
  });

  return { table, sorting, columnFilters, columnVisibility };
};
