import { Label, SelectWrapper } from './Select.styles';
import Select, { GroupBase, StylesConfig } from 'react-select';
import theme from '../../shared/theme/custom-theme';
import { ErrorText, InputDetail } from 'components/Common/Common.styles';
import { ReactNode, useState } from 'react';
import type { Props } from 'react-select/base';
import { CustomDropdownIndicator } from './CustomDropdownIndicator';
import { CustomOption } from './CustomOption';
import { CustomMultiValueRemove } from './CustomMultiValueRemove';
import { CustomMultiValue } from './CustomMultiValue';
import clsx from 'clsx';
import CustomPlaceholder from './CustomPlaceholder';

export type OptionType = {
  value: string;
  label: string;
  subvalue?: string;
};

declare module 'react-select/base' {
  // TS marks the following generics as unused, but they're necessary to
  // augment the base Props properly
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    label?: string;
    error?: string;
    info?: string;
    width?: number | string;
    className?: string;
    rightContent?: ReactNode;
  }
}

const InfoComponent = ({
  selectedValue,
}: {
  selectedValue: unknown;
}): ReactNode | null => {
  if (
    selectedValue &&
    selectedValue instanceof Object &&
    'infoComponent' in selectedValue
  ) {
    return selectedValue.infoComponent as ReactNode;
  }
  return null;
};

// Disabling ref forwarding as it breaks types,
// See https://github.com/JedWatson/react-select/discussions/5141 for some leads
// on how to get it back

export const GraniteSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  error,
  width,
  info,
  className,
  isClearable = false,
  classNames,
  components,
  rightContent,
  overrideStyles,
  variant = 'default',
  ...props
}: Partial<Props<Option, IsMulti, Group>> & {
  overrideStyles?: StylesConfig<Option, IsMulti, Group>;
  variant?: 'default' | 'blue';
}) => {
  const [inputValue, setInputValue] = useState('');

  const scrollbarStyle = {
    scrollbar: {
      width: '10px',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--stroke-base-subdued)',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'var(--background-base-surface-1)',
    },
  };

  const defaultStyles: StylesConfig<Option, IsMulti, Group> = {
    menu: (provided) => ({
      ...provided,
      width: width || '100%',
      marginTop: 0,
      border: 0,
      padding: 0,
      borderRadius: 0,
      background: 'rgb(var(--input-background-unfilled))',
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
      ...scrollbarStyle,
    }),
    control: (provided, state) => {
      const getBorderColor = () => {
        if (state.isFocused) {
          return 'rgb(var(--input-stroke-focus))';
        }
        return error
          ? 'rgb(var(--input-stroke-error))'
          : 'rgb(var(--input-stroke-filled))';
      };

      const getBorder = () => {
        if (state.menuIsOpen) {
          if (props.menuPlacement === 'top') return '0 0 4px 4px';
          return '4px 4px 0 0';
        }
        return 4;
      };

      return {
        ...provided,
        height: 48,
        width: width || '100%',
        backgroundColor: 'rgb(var(--input-background-filled))',
        border: `1px solid ${getBorderColor()}`,
        fontSize: theme.text.size.lg,
        fontWeight: theme.fontWeights.medium,
        borderRadius: getBorder(),
        padding: '0 15px',
        '&:hover': {
          borderColor: 'rgb(var(--input-stroke-hover))',
          backgroundColor: 'rgb(var(--input-background-hover))',
          borderWidth: '1px',
        },
        '&:active': {
          borderColor: 'rgb(var(--input-stroke-focus))',
          backgroundColor: 'rgb(var(--input-background-focus))',
        },
        '&:focus': {
          borderWidth: '2px',
          borderColor: 'rgb(var(--input-stroke-focus))',
          backgroundColor: 'rgb(var(--input-background-focus))',
          outline: 'none',
        },
        '&:disabled': {
          borderColor: 'rgb(var(--input-stroke-disabled))',
          backgroundColor: 'rgb(var(--input-background-disabled))',
        },
      };
    },
    singleValue: (provided, props) => ({
      ...provided,
      color: error
        ? 'rgb(var(--input-content-error))'
        : props.isDisabled
          ? 'rgb(var(--content-input-disabled))'
          : 'rgb(var(--content-base-default))',
    }),
    multiValue: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '32px',
      padding: '0px 8px 0px 12px',
      borderRadius: '32px',
      border: '1px solid var(--stroke-base-subdued)',
      backgroundColor: 'var(--stroke-base-subdued)',
      fontSize: '16px',
      color: 'rgb(var(--content-base-default))',
      maxWidth: 'calc(100% - 12px)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      marginRight: '8px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: '40px',
      overflowY: 'auto',
      gap: '5px',
      ...scrollbarStyle,
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '100%',
    }),
  };

  const blueVariantStyles: Partial<StylesConfig<Option, IsMulti, Group>> = {
    control: (provided, state) => {
      const getBorderColor = () => {
        if (state.isFocused) {
          return '#6C9DFF';
        }
        return error
          ? 'rgb(var(--input-stroke-error))'
          : 'rgb(var(--input-stroke-filled))';
      };
      return {
        ...defaultStyles.control?.(provided, state),
        backgroundColor: 'rgb(var(--black))',
        border: `1px solid ${getBorderColor()}`,
        '&:hover': {
          borderColor: '#6C9DFF',
        },
        '&:active': {
          borderColor: '#6C9DFF',
        },
        '&:focus': {
          borderColor: '#6C9DFF',
        },
        '&:disabled': {
          borderColor: 'rgb(var(--input-stroke-disabled))',
          backgroundColor: 'rgb(var(--input-background-disabled))',
        },
      };
    },
    menu: (provided, props) => ({
      ...defaultStyles.menu?.(provided, props),
      backgroundColor: 'rgb(var(--black))',
      opacity: 1,
      zIndex: 9999999,
    }),
    menuList: (provided, props) => ({
      ...defaultStyles.menuList?.(provided, props),
      backgroundColor: 'rgb(var(--black))',
    }),
  };

  const mergedStyles = {
    ...defaultStyles,
    ...(variant === 'blue' ? blueVariantStyles : {}),
    ...overrideStyles,
  };

  const selectId = `react-select-${label}`;

  return (
    <SelectWrapper className={clsx(className, error && 'error')}>
      {label && <Label htmlFor={selectId}>{label}</Label>}
      {rightContent && (
        <span className="absolute right-1 !mb-4 ml-2">{rightContent}</span>
      )}
      <Select
        {...props}
        isClearable={isClearable}
        styles={mergedStyles}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: CustomDropdownIndicator,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          Option: (props) => <CustomOption {...props} variant={variant} />,
          MultiValueRemove: CustomMultiValueRemove,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          MultiValue: CustomMultiValue,
          Placeholder: CustomPlaceholder,
          ...components,
        }}
        classNames={{
          menuList: () =>
            props.menuPlacement === 'top'
              ? 'rounded-tl-md rounded-tr-md border-r border-t border-l border-input-stroke-filled'
              : 'rounded-bl-md rounded-br-md border-r border-b border-l border-input-stroke-filled',
          control: (state) =>
            state.isDisabled ? '!bg-input-background-disabled' : '',
          valueContainer: () => 'scrollbar-thin',
          ...classNames,
        }}
        unstyled
        hideSelectedOptions={false}
        inputId={selectId}
        blurInputOnSelect={!props.isMulti}
        closeMenuOnSelect={!props.isMulti}
        inputValue={props.inputValue ?? inputValue}
        onInputChange={
          props.onInputChange
            ? props.onInputChange
            : (value, { action }) => {
                if (action === 'set-value' && props.isMulti) setInputValue('');
                if (['input-change', 'menu-close'].includes(action)) {
                  setInputValue(value);
                }
              }
        }
      />
      {info && (
        <InputDetail className="text-content-base-default">{info}</InputDetail>
      )}
      <InfoComponent selectedValue={props.value} />
      <ErrorText className="text-input-content-error">{error}</ErrorText>
    </SelectWrapper>
  );
};
