import React, { FC } from 'react';

import {
  Breakpoints,
  useContainerBreakpoint,
} from 'hooks/useContainerBreakpoint';

export interface ResponsiveContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  breakpoints: Breakpoints;
  children: React.ReactNode;
}

export const ResponsiveContainer: FC<ResponsiveContainerProps> = ({
  breakpoints,
  className = '',
  children,
  ...props
}) => {
  const [containerRef, currentBreakpoint] = useContainerBreakpoint(breakpoints);
  const combinedClassName = `${className} ${currentBreakpoint}`.trim();

  return (
    <div ref={containerRef} className={combinedClassName} {...props}>
      {children}
    </div>
  );
};
