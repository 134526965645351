import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Add, Remove, TrashOutline } from 'react-ionicons';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ICartItem } from 'screens/AccessExpressV3/CartProvider';
import ImagePlaceholder from '../ImagePlaceholder';
import ProductInfo from '../ProductInfo';

interface CartItemProps {
  item: ICartItem;
  onEdit: () => void;
  onDelete: () => void;
  onQuantityChange: (newQuantity: number) => void;
}

const CartItem = observer(
  ({ item, onEdit, onDelete, onQuantityChange }: CartItemProps) => {
    return (
      <div className="flex flex-col gap-6 overflow-hidden">
        <div className="flex flex-col">
          <div className="flex items-stretch gap-2 rounded bg-[#28283a] p-2">
            <div className="flex flex-grow flex-col gap-2">
              <div className="flex flex-row">
                <Tag>{item.category}</Tag>
              </div>
              <div className="flex gap-3">
                <div>
                  <ImagePlaceholder />
                </div>
                <ProductInfo name={item.name} price={item.price} />
              </div>
            </div>
            <CartActions
              onEdit={onEdit}
              onDecrement={() => onQuantityChange(item.quantity - 1)}
              onIncrement={() => onQuantityChange(item.quantity + 1)}
              onDelete={onDelete}
              quantity={item.quantity}
            />
          </div>
          {item.outOfStock && <ShippingStatus />}
        </div>
      </div>
    );
  },
);

export default CartItem;

const Tag: FC<PropsWithChildren> = ({ children }) => (
  <div
    className={clsx(
      'flex items-center justify-center rounded-lg bg-[#6C9DFF14] px-2 py-1',
    )}
  >
    <span className="text-xs font-semibold text-[#6C9DFF]">{children}</span>
  </div>
);

interface QuantityCounterProps {
  onIncrement: () => void;
  onDecrement: () => void;
  quantity: number;
}

const QuantityCounter = ({
  onIncrement,
  onDecrement,
  quantity,
}: QuantityCounterProps) => (
  <div className="flex h-6 w-full rounded border border-button-stroke-secondary-default text-button-content-secondary-default">
    <div
      className="flex h-full w-6 flex-none cursor-pointer items-center justify-center border-r border-button-stroke-secondary-default"
      onClick={onDecrement}
    >
      <Remove color="currentColor" width="12px" height="12px" />
    </div>
    <div className="flex h-full flex-grow items-center justify-center text-xs font-bold">
      {quantity}
    </div>
    <div
      className="flex h-full w-6 flex-none cursor-pointer items-center justify-center border-l border-button-stroke-secondary-default"
      onClick={onIncrement}
    >
      <Add color="currentColor" width="12px" height="12px" />
    </div>
  </div>
);

interface CartActionsProps {
  onEdit: () => void;
  onDecrement: () => void;
  onIncrement: () => void;
  onDelete: () => void;
  quantity: number;
}

const CartActions = ({
  onEdit,
  onDecrement,
  onIncrement,
  onDelete,
  quantity,
}: CartActionsProps) => (
  <div className="flex-non flex w-[72px] flex-col items-end justify-between">
    <div className="flex items-center gap-2">
      <GraniteButton
        size="small"
        variant="ghost"
        onClick={onEdit}
        className="hover:!border-accent-primary hover:!text-accent-primary"
      >
        Edit
      </GraniteButton>
      <div className="cursor-pointer text-red-400" onClick={onDelete}>
        <TrashOutline color="currentColor" width="16px" height="16px" />
      </div>
    </div>
    <QuantityCounter
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      quantity={quantity}
    />
  </div>
);

const ShippingStatus = () => (
  <div className="flex items-center gap-2 rounded-bl rounded-br p-2">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7408 4.31191L9.74084 1.56191C9.66701 1.5213 9.58411 1.5 9.49984 1.5C9.41558 1.5 9.33268 1.5213 9.25884 1.56191L4.25884 4.31191C4.18045 4.35508 4.11508 4.41849 4.06955 4.49553C4.02402 4.57257 4 4.66042 4 4.74991C4 4.83941 4.02402 4.92726 4.06955 5.0043C4.11508 5.08134 4.18045 5.14475 4.25884 5.18791L8.99984 7.79541V13.1549L7.48184 12.3199L6.99984 13.1954L9.25884 14.4379C9.33268 14.4785 9.41558 14.4998 9.49984 14.4998C9.58411 14.4998 9.66701 14.4785 9.74084 14.4379L14.7408 11.6879C14.8193 11.6448 14.8847 11.5814 14.9302 11.5043C14.9758 11.4273 14.9998 11.3394 14.9998 11.2499V4.74991C14.9998 4.66041 14.9758 4.57255 14.9302 4.4955C14.8847 4.41846 14.8193 4.35506 14.7408 4.31191ZM9.49984 2.57091L13.4623 4.74991L9.49984 6.92891L5.53734 4.74991L9.49984 2.57091ZM13.9998 10.9544L9.99984 13.1544V7.79491L13.9998 5.59491V10.9544Z"
        fill="#6C9DFF"
      />
      <path d="M5 8H1V7H5V8ZM6 12H2V11H6V12ZM7 10H3V9H7V10Z" fill="#6C9DFF" />
    </svg>
    <span className="text-xs font-medium text-[#6b9dfe]">
      Will ship once restocked
    </span>
  </div>
);
