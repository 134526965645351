import { useState, useEffect, useRef, RefObject } from 'react';

export interface Breakpoint {
  min?: number;
  max?: number;
}

export type Breakpoints = Record<string, Breakpoint>;

export function useContainerBreakpoint(
  breakpoints: Breakpoints,
): [RefObject<HTMLDivElement>, string] {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('');

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver((entries) => {
      if (entries.length === 0) return;
      const width = entries[0].contentRect.width;
      let newBreakpoint = '';

      Object.entries(breakpoints).forEach(([key, { min, max }]) => {
        if (
          (min === undefined || width >= min) &&
          (max === undefined || width <= max)
        ) {
          newBreakpoint = key;
        }
      });

      if (newBreakpoint !== currentBreakpoint) {
        setCurrentBreakpoint(newBreakpoint);
      }
    });

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [breakpoints, currentBreakpoint]);

  return [containerRef, currentBreakpoint];
}
