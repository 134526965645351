import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { useDebouncedState } from '../../shared/hooks/useDebouncedState';
import { useQuery } from 'react-query';
import { clsx } from 'clsx';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { getPriorTicketsNoc } from 'api/nocexpress/api';
import escapeRegExp from 'lodash.escaperegexp';

interface TicketSearchDropdownProps {
  onTicketSelected: (ticketNumber?: string) => void;
  error?: string;
  configurationIds?: string;
  className?: string;
}

export const TicketSearchDropdown: React.FC<
  TicketSearchDropdownProps & React.ComponentProps<typeof GraniteInput>
> = ({
  onTicketSelected,
  error,
  configurationIds,
  className,
  ...inputProps
}) => {
  const anchorEltRef = useRef<HTMLDivElement>(null);
  const popperElementRef = useRef<HTMLDivElement>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [shouldRefetchOnFocus, setShouldRefetchOnFocus] = useState(true);

  const { value: debouncedInputValue } = useDebouncedState(
    inputProps.value as string,
    300,
  );

  useEffect(() => {
    if (!isMenuVisible) return;

    const handleScrollOrResize = () => {
      setIsMenuVisible(false);
    };

    window.addEventListener('scroll', handleScrollOrResize);
    window.addEventListener('resize', handleScrollOrResize);

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize);
      window.removeEventListener('resize', handleScrollOrResize);
    };
  }, [isMenuVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        anchorEltRef.current &&
        popperElementRef.current &&
        !anchorEltRef.current.contains(event.target as Node) &&
        !popperElementRef.current.contains(event.target as Node)
      ) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const {
    data: ticketResults,
    isLoading,
    refetch,
  } = useQuery(
    ['ticket-search', debouncedInputValue],
    () => getPriorTicketsNoc(String(debouncedInputValue), configurationIds),
    {
      enabled:
        !!(String(debouncedInputValue).trim().length > 0) &&
        !!configurationIds &&
        configurationIds !== 'mock_identifier',
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setIsMenuVisible(data.length > 0);
      },
    },
  );

  const { styles, attributes } = usePopper(
    anchorEltRef.current,
    popperElementRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: 'clippingParents',
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-start'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 2],
          },
        },
      ],
    },
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    setShouldRefetchOnFocus(true);

    inputProps.onChange?.({
      ...e,
      target: {
        ...e.target,
        value: input,
      },
    });
  };

  const handleTicketSelect = (ticketNumber: string) => {
    setShouldRefetchOnFocus(false);
    inputProps.onChange?.({
      target: {
        value: ticketNumber,
      },
    } as React.ChangeEvent<HTMLInputElement>);

    onTicketSelected(ticketNumber);
    setIsMenuVisible(false);
  };

  const handleInputFocus = () => {
    setIsMenuVisible(true);
    if (shouldRefetchOnFocus && inputProps.value) {
      refetch();
    }
  };

  return (
    <Fragment>
      <div className={clsx('group relative', className)} ref={anchorEltRef}>
        <GraniteInput
          {...inputProps}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          style={{
            borderRadius: isMenuVisible ? '4px 4px 0px 0px' : '4px',
          }}
          error={error}
        />

        {isMenuVisible && (isLoading || ticketResults) && (
          <div
            className="search-result-container"
            ref={popperElementRef}
            style={{
              width: anchorEltRef.current?.clientWidth ?? -2,
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {isLoading && (
              <div className="flex flex-col gap-2 rounded-b bg-input-background-unfilled px-4 py-3">
                Loading...
              </div>
            )}

            {ticketResults && ticketResults.length > 0 && (
              <Fragment>
                {ticketResults.map((ticket) => (
                  <div
                    key={ticket.id}
                    className="search-result-row"
                    onClick={() =>
                      handleTicketSelect(ticket.id as unknown as string)
                    }
                    role="option"
                    tabIndex={0}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleTicketSelect(ticket.id as unknown as string);
                      }
                    }}
                  >
                    <div className=" [&>mark]:bg-transparent [&>mark]:text-content-accent-default">
                      {highlightMatch(
                        String(ticket.id),
                        inputProps.value as string,
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            )}

            {ticketResults && ticketResults.length === 0 && !isLoading && (
              <div className="flex flex-col gap-2 rounded-b bg-input-background-unfilled px-4 py-3">
                <p className="font-light text-content-base-subdued">
                  No results
                </p>
              </div>
            )}
          </div>
        )}
        {error && <ErrorSubtext error={error} />}
      </div>
    </Fragment>
  );
};

export const highlightMatch = (
  ticketId: string,
  searchTerm: string,
): ReactElement => {
  if (!ticketId || !searchTerm) {
    return <Fragment>{ticketId || ''}</Fragment>;
  }

  const parts = ticketId
    .split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig'))
    .map((part, i) =>
      part?.toLowerCase() === searchTerm?.toLowerCase() ? (
        <mark role="mark" key={i}>
          {part}
        </mark>
      ) : (
        part
      ),
    );

  return <Fragment>{parts}</Fragment>;
};
