import { observer } from 'mobx-react-lite';
import NavigationTabs from './BaseComponents/OrderServices/NavigationTabs';
import Search from './BaseComponents/OrderServices/Search';
import { Outlet, useLocation } from 'react-router-dom';
import Sort from './BaseComponents/OrderServices/Sort';
// import Filters from './BaseComponents/OrderServices/Filters';
import LocalNav from './BaseComponents/OrderServices/LocalNav';
import clsx from 'clsx';
import { useCartContext } from './CartProvider';
import { AnimatePresence, motion } from 'framer-motion';

const OrderServices = observer(() => {
  const { pathname } = useLocation();
  const cart = useCartContext();

  return (
    <div className="relative my-0 flex flex-1 grow flex-col p-4 pb-2 sm:px-8 sm:pt-8">
      <h1 className="mb-6 text-4xl font-bold leading-9 text-white">
        Order services
      </h1>
      <div className="">
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:gap-0">
          <div className="flex-1">
            <NavigationTabs />
            <div className="-mt-[1px] flex border-b border-neutral-700/50 lg:hidden" />
          </div>
          <div className="mb-4 flex flex-col-reverse items-end gap-6 xs:flex-row xs:self-end">
            <>
              <div className="flex gap-2 self-start xs:self-end">
                {/* commented for now */}
                {/* <Filters /> */}
                <Sort />
              </div>
              <Search />
            </>
          </div>
        </div>
        <div className="-mt-[1px]  flex border-b border-neutral-700/50" />
      </div>
      <div
        className={clsx(
          'border-b border-neutral-700/50 transition-all duration-700',
          {
            'h-auto py-4 opacity-100': pathname.includes('mobility'),
            'pointer-events-none h-0 opacity-0': !pathname.includes('mobility'),
          },
        )}
      >
        <LocalNav />
      </div>
      <Outlet />
      <AnimatePresence>
        {!!cart.isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute inset-0 z-10 h-full w-full cursor-pointer bg-background-base-surface-1/50"
            onClick={() => cart.close()}
          />
        )}
      </AnimatePresence>
    </div>
  );
});

export default OrderServices;
