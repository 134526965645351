import { IconProps } from './types';

const CmdIcon = ({
  color = 'currentColor',
  width = '24',
  height = '24',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 9 8"
    fill="none"
  >
    <g clipPath="url(#clip0_4329_11564)">
      <path
        d="M5.83333 2.6665H3.16666V5.33317H5.83333M5.83333 2.6665V5.33317M5.83333 2.6665H6.83333C7.03111 2.6665 7.22445 2.60786 7.3889 2.49797C7.55335 2.38809 7.68152 2.23191 7.75721 2.04919C7.83289 1.86646 7.8527 1.6654 7.81411 1.47141C7.77553 1.27743 7.68029 1.09925 7.54043 0.959398C7.40058 0.819545 7.2224 0.724304 7.02842 0.685719C6.83444 0.647134 6.63337 0.666937 6.45064 0.742625C6.26792 0.818312 6.11174 0.946485 6.00186 1.11093C5.89197 1.27538 5.83333 1.46872 5.83333 1.6665V2.6665ZM5.83333 5.33317L6.83333 5.3335C7.03111 5.3335 7.22445 5.39215 7.3889 5.50203C7.55335 5.61192 7.68152 5.76809 7.75721 5.95082C7.83289 6.13355 7.8527 6.33461 7.81411 6.5286C7.77553 6.72258 7.68029 6.90076 7.54043 7.04061C7.40058 7.18046 7.2224 7.2757 7.02842 7.31429C6.83444 7.35288 6.63337 7.33307 6.45064 7.25738C6.26792 7.1817 6.11174 7.05352 6.00186 6.88908C5.89197 6.72463 5.83333 6.53129 5.83333 6.3335V5.33317ZM3.16699 5.3335H2.16699C1.96921 5.3335 1.77587 5.39215 1.61142 5.50203C1.44697 5.61192 1.3188 5.76809 1.24311 5.95082C1.16743 6.13355 1.14762 6.33461 1.18621 6.5286C1.22479 6.72258 1.32003 6.90076 1.45989 7.04061C1.59974 7.18046 1.77792 7.2757 1.9719 7.31429C2.16588 7.35288 2.36695 7.33307 2.54968 7.25738C2.7324 7.1817 2.88858 7.05352 2.99846 6.88908C3.10834 6.72463 3.16699 6.53129 3.16699 6.3335V5.3335ZM3.16699 2.6665H2.16699C1.96921 2.6665 1.77587 2.60786 1.61142 2.49797C1.44697 2.38809 1.3188 2.23191 1.24311 2.04919C1.16743 1.86646 1.14762 1.6654 1.18621 1.47141C1.22479 1.27743 1.32003 1.09925 1.45989 0.959398C1.59974 0.819545 1.77792 0.724304 1.9719 0.685719C2.16588 0.647134 2.36695 0.666937 2.54968 0.742625C2.7324 0.818312 2.88858 0.946485 2.99846 1.11093C3.10834 1.27538 3.16699 1.46872 3.16699 1.6665V2.6665Z"
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4329_11564">
        <rect width="8" height="8" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CmdIcon;
