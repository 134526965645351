import React, { ComponentProps, forwardRef, ReactNode, useState } from 'react';
import { GraniteLabel } from '../Label/GraniteLabel';
import { clsx } from 'clsx';
import { ErrorSubtext } from '../../ErrorSubtext/ErrorSubtext';

export interface GraniteInputProps extends ComponentProps<'input'> {
  className?: string;
  label?: string;
  subtitle?: string;
  error?: string | boolean;
  suffix?: ReactNode;
  variant?: 'primary' | 'ghost' | 'outlined' | 'blue';
  innerInputClassName?: string;
  outerInputClassName?: string;
  rightContent?: React.ReactNode;
}

export const GraniteInput = forwardRef<HTMLInputElement, GraniteInputProps>(
  function GraniteInput(
    {
      className,
      label,
      subtitle,
      suffix,
      error,
      variant = 'primary',
      innerInputClassName,
      outerInputClassName,
      rightContent,
      style,
      ...props
    },
    ref,
  ) {
    const [suffixWidth, setSuffixWidth] = useState(0);

    const variantToClassName = {
      primary: 'granite-input-primary',
      ghost: '',
      outlined: 'granite-input-outlined',
      blue: 'granite-input-blue',
    }[variant];

    return (
      <GraniteLabel
        label={label}
        subtitle={subtitle}
        className={clsx(className, 'relative')}
        rightContent={rightContent}
      >
        <div className={clsx(outerInputClassName, 'relative grid w-full')}>
          <input
            aria-label={label}
            {...props}
            type="text"
            ref={ref}
            className={clsx(
              'granite-input',
              variantToClassName,
              error && 'error',
              innerInputClassName,
            )}
            style={{
              ...(suffix && { paddingRight: `${suffixWidth + 20}px` }),
              ...style,
            }}
          />
          {suffix && (
            <span
              ref={(ref) => setSuffixWidth(ref?.offsetWidth || 0)}
              className="absolute right-4 top-3 text-base font-light text-content-base-subdued"
            >
              {suffix}
            </span>
          )}
        </div>
        {error && <ErrorSubtext error={error} />}
      </GraniteLabel>
    );
  },
);
