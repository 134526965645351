import clsx from 'clsx';
import { FC } from 'react';

export interface AttributeHeaderProps {
  title: string;
  directionMessage?: string;
  attributeClassName?: string;
}

const AttributeHeader: FC<AttributeHeaderProps> = ({
  title,
  directionMessage,
  attributeClassName,
}) => {
  return (
    <div
      className={clsx('px-6', attributeClassName, {
        'mb-2 space-y-0.5': !!directionMessage?.length,
        'mb-4': !directionMessage?.length,
      })}
    >
      <h2 className="font-semibold text-white">{title}</h2>
      <p className="text-sm text-neutral-500">{directionMessage}</p>
    </div>
  );
};

export default AttributeHeader;
