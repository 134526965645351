import { ReactNode } from 'react';
import { Placement } from 'react-joyride';

import { TokenUserPermissions } from 'api/users/schemas/Users';
import { TourSection } from 'api/producttour/schemas';

export interface StepDetail {
  step: number;
  pathname?: string;
  target: string | HTMLElement;
  className?: string;
  content?: string | ReactNode;
  spotlightPadding?: number;
  spotLightRadius?: number;
  disableScrolling?: boolean;
  title?: string | ReactNode;
  placement?: Placement;
  permissions: TokenUserPermissions[] | null;
  section: TourSection | null;
  subSection: string | null;
  userDropDownOpen?: boolean;
  menuSection: string | null;
  returnTo?: string;
  menuFocus?: boolean;
}

export enum TourSteps {
  WELCOME_MODAL_STEP = 0,
  LAST_STEP = 29,
  REVISIT_LATER_STEP = 30,
}

export const getStepDetails = (): StepDetail[] => [
  {
    step: TourSteps.WELCOME_MODAL_STEP,
    target: 'body',
    placement: 'center' as Placement,
    className: 'custom-modal-step',
    section: null,
    subSection: null,
    permissions: null,
    menuSection: null,
  },

  // My Company - Granite360 Dashboard (Dashboard)
  {
    step: 1,
    content: `Welcome to Granite360’s home dashboard! You can quickly return to your dashboard by clicking the Granite360 logo in the top left corner of the navigation bar or by navigating to the “Dashboard” section in the “My company” menu item.`,
    target: '.product-tour-my-company-dashboard',
    placement: 'right',
    pathname: '/',
    section: 'Dashboard',
    subSection: 'Granite360 Dashboard',
    permissions: [],
    menuSection: 'My company',
    menuFocus: true,
  },
  {
    step: 2,
    content: `Expand or collapse your navigation bar to customize your view of G360. The navigation bar is how you can easily switch between features, view your notifications, and update your user profile.`,
    target: '.product-tour-my-company-navbar-toggle',
    placement: 'right',
    pathname: '/',
    section: 'Dashboard',
    subSection: 'Granite360 Dashboard',
    permissions: [],
    menuSection: 'My company',
  },
  {
    step: 3,
    content: `Access the product tour for all sections of G360 anytime by clicking your user icon in the bottom left corner and selecting “Product tour.”`,
    target: '.product-tour-menu',
    placement: 'right-end',
    pathname: '/',
    section: 'Dashboard',
    subSection: 'Granite360 Dashboard',
    permissions: [],
    userDropDownOpen: true,
    menuSection: 'My company',
  },

  // My Company - User Management (User Management - Company Admin only)
  // TODO: roles for Company Admin, Super Admin, Granite Internal.
  {
    step: 4,
    title: `User Management`,
    content: `The User Management page is where Company Admins can invite new members, manage invitations, and control permissions for existing members.`,
    target: '.product-tour-my-company-user-management',
    placement: 'right',
    pathname: '/users',
    section: 'Dashboard',
    subSection: 'User Management',
    permissions: [TokenUserPermissions.USER_MANAGEMENT],
    menuSection: 'My company',
    menuFocus: true,
  },
  {
    step: 5,
    title: `Inviting users`,
    content: `Invite new users from your organization by clicking the “Invite user” button and completing the required info. Revoke or resend invitations in the Pending invitations tab.`,
    target: '.product-tour-my-company-inviting-users',
    placement: 'bottom-end',
    pathname: '/users',
    section: 'Dashboard',
    subSection: 'User Management',
    permissions: [TokenUserPermissions.USER_MANAGEMENT],
    menuSection: 'My company',
  },
  {
    step: 6,
    title: `Editing & deleting users`,
    content: `Update user details, edit permissions or remove existing users by clicking into their account and selecting “Edit user.”`,
    target: '.product-tour-my-company-edit-and-delete-users',
    placement: 'top',
    pathname: '/users',
    section: 'Dashboard',
    subSection: 'User Management',
    permissions: [TokenUserPermissions.USER_MANAGEMENT],
    menuSection: 'My company',
  },

  // Inventory - My services (Dashboard)

  {
    step: 7,
    title: `My services`,
    content: `Welcome to the Inventory page! This is where you can view all of your current and historical services.`,
    target: '.product-tour-inventory',
    placement: 'right',
    pathname: '/inventory',
    section: 'Inventory',
    subSection: 'My services',
    permissions: [TokenUserPermissions.INVENTORY_READ],
    menuSection: 'Inventory',
    menuFocus: true,
  },

  {
    step: 8,
    title: `My services`,
    content: `View key metrics & KPIs and swap between “Service view” and “Account view” to adjust the inventory table between itemized services versus itemized locations. Click into any row on the table to view a detailed page of that location or service.`,
    target: '.product-tour-inventory-my-services-table',
    placement: 'top',
    pathname: '/inventory',
    section: 'Inventory',
    subSection: 'My services',
    permissions: [TokenUserPermissions.INVENTORY_READ],
    menuSection: 'Inventory',
  },

  {
    step: 9,
    title: `My services`,
    content: `To adjust what columns display by default, click “Customize” to toggle on or off specific categories. Drag and drop the categories to rearrange the order that they display on the table.`,
    target: '.product-tour-customize-button',
    placement: 'top',
    pathname: '/inventory',
    section: 'Inventory',
    subSection: 'My services',
    permissions: [TokenUserPermissions.INVENTORY_READ],
    menuSection: 'Inventory',
  },

  // TechExpress - Open a ticket
  {
    step: 10,
    title: `Open ticket`,
    content: `Welcome to TechExpress! This is where you can easily request a dispatch for same day, next day, or a future date.`,
    target: '.product-tour-tech-express-open-ticket',
    placement: 'right',
    pathname: '/tech-express/let-us-help',
    section: 'TechExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.TECH_EXPRESS_WRITE],
    menuSection: 'TechExpress',
    menuFocus: true,
  },
  {
    step: 11,
    title: `Open ticket`,
    content: `In the “Get started” section, you’ll provide the reason for dispatch & general dispatch details, including the address & requested date and time. You can also select a template to quickly populate your ticket with a predetermined scope of work, if available.`,
    target: '.tech-product-tour-step-1',
    placement: 'bottom-start',
    pathname: '/tech-express/let-us-help',
    section: 'TechExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.TECH_EXPRESS_WRITE],
    menuSection: 'TechExpress',
  },
  {
    step: 12,
    title: `Open ticket`,
    content: `Next, you’ll add more specific details regarding the dispatch request, such network or equipment descriptions, testing contact information, and any non-standard tool requirements.`,
    target: '.tech-product-tour-step-2',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help/review',
    section: 'TechExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.TECH_EXPRESS_WRITE],
    menuSection: 'TechExpress',
  },
  {
    step: 13,
    content: `At any time during the ticket creation process, you can save your progress as a draft and return to it later from the “My tickets” dashboard.`,
    target: '.tech-product-tour-step-3',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help',
    section: 'TechExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.TECH_EXPRESS_WRITE],
    menuSection: 'TechExpress',
  },
  {
    step: 14,
    content: `Lastly, you’ll review your ticket & submit your dispatch request. You can revisit your ticket and track progress at any point in time through the TechExpress Dashboard or Ticket Details pages.`,
    target: '.tech-product-tour-step-4',
    placement: 'bottom-end',
    pathname: '/tech-express/let-us-help',
    section: 'TechExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.TECH_EXPRESS_WRITE],
    menuSection: 'TechExpress',
  },

  // TechExpress - View & manage tickets
  {
    step: 15,
    title: `Dashboard`,
    content: `Welcome to the TechExpress dashboard! Here, you can view all active and completed dispatch requests, as well as create new dispatch requests.`,
    target: '.product-tour-tech-express-my-tickets',
    placement: 'right',
    pathname: '/tech-express',
    section: 'TechExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.TECH_EXPRESS_READ],
    menuSection: 'TechExpress',
    menuFocus: true,
  },
  {
    step: 16,
    title: `Dashboard`,
    content: `Utilize the search or filter functions to quickly locate a ticket or customize the default columns by clicking “Customize” above the table.`,
    target: '.product-tour-tech-express-search-filter',
    placement: 'bottom-start',
    pathname: '/tech-express',
    section: 'TechExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.TECH_EXPRESS_READ],
    menuSection: 'TechExpress',
  },
  {
    step: 17,
    title: `Manage tickets`,
    content: `Click a ticket on the dashboard to view or edit a ticket. In the Activity section, you can communicate directly with Granite’s Network Integration team by adding a note to the ticket.`,
    target: '.product-tour-tech-express-ticket-details',
    placement: 'left-start',
    pathname: '/tech-express/1',
    section: 'TechExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.TECH_EXPRESS_READ],
    menuSection: 'TechExpress',
    returnTo: '/tech-express',
  },
  {
    step: 18,
    title: `Manage tickets`,
    content: `Finally, to edit or cancel a ticket, select a ticket and scroll to the bottom of the page. Tickets can be edited or canceled prior to the technician’s dispatch. See here for terms & conditions.`,
    target: '.edit-ticket-product-tour',
    placement: 'right-end',
    pathname: '/tech-express/1',
    section: 'TechExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.TECH_EXPRESS_READ],
    menuSection: 'TechExpress',
    returnTo: '/tech-express',
  },

  // TechExpress - Create & manage templates
  {
    step: 19,
    title: `Templates`,
    content: `Create new templates by clicking “Templates” on the navigation bar or from the TechExpress Dashboard then selecting “Create new template” in the righthand corner.`,
    target: '.product-tour-tech-express-templates-create',
    placement: 'bottom-end',
    pathname: '/tech-express/templates',
    section: 'TechExpress',
    subSection: 'Create & manage templates',
    permissions: [TokenUserPermissions.TECH_EXPRESS_MANAGE],
    menuSection: 'TechExpress',
  },
  {
    step: 20,
    title: `Templates`,
    content: `Only Company Admins can create new templates but all users within your company can apply templates to their TechExpress tickets to quickly populate the ticket with the template’s predetermined scope of work.`,
    target: '.product-tour-tech-express-templates-create',
    placement: 'right-end',
    pathname: '/tech-express/templates',
    section: 'TechExpress',
    subSection: 'Create & manage templates',
    permissions: [TokenUserPermissions.TECH_EXPRESS_MANAGE],
    menuSection: 'TechExpress',
  },
  {
    step: 21,
    title: `Templates`,
    content: `Add templates details, name your template & save so that other users within your company can access the template when opening TechExpress tickets.`,
    target: '.product-tour-tech-express-templates',
    placement: 'right',
    pathname: '/tech-express/templates/details',
    section: 'TechExpress',
    subSection: 'Create & manage templates',
    permissions: [TokenUserPermissions.TECH_EXPRESS_MANAGE],
    menuSection: 'TechExpress',
    menuFocus: true,
  },

  // NOCExpress - Open a ticket
  {
    step: 22,
    title: 'Open ticket',
    content:
      'NOCExpress is where you can open repair tickets for your Granite services. You’ll need to identify the location & service that needs attention to begin your repair request.',
    target: '.product-tour-noc-open-find-location',
    pathname: '/noc-express/open-ticket',
    placement: 'bottom',
    section: 'NOCExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.NOC_EXPRESS_WRITE],
    menuSection: 'NOCExpress',
  },
  {
    step: 23,
    title: 'Open ticket',
    content:
      'Add details describing the issue and any testing information or attachments that are applicable. Once ready, click the “Submit” button to submit your new ticket. A Granite representative will be automatically assigned to investigate.',
    target: '.product-tour-noc-open-add-details',
    pathname: '/noc-express/open-ticket/details',
    placement: 'bottom',
    section: 'NOCExpress',
    subSection: 'Open a ticket',
    permissions: [TokenUserPermissions.NOC_EXPRESS_WRITE],
    menuSection: 'NOCExpress',
  },

  // NOCExpress - View & manage tickets
  {
    step: 24,
    title: 'Manage tickets',
    content:
      'This is your NOCExpress dashboard. Here, you can view a variety of metrics related to your company’s active repair tickets. Swap to the “Repair tickets” tab to view a complete history of all your company’s current and past repair tickets.',
    target: '.product-tour-noc-dashboard',
    pathname: '/noc-express?tab=dashboard',
    placement: 'bottom',
    section: 'NOCExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.NOC_EXPRESS_READ],
    menuSection: 'NOCExpress',
  },
  {
    step: 25,
    title: 'Manage tickets',
    content:
      'Utilize the search or filter functions to quickly locate a ticket or customize the default columns by clicking “Customize” above the table.',
    target: '.product-tour-noc-index',
    pathname: '/noc-express',
    placement: 'bottom',
    section: 'NOCExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.NOC_EXPRESS_READ],
    menuSection: 'NOCExpress',
  },
  {
    step: 26,
    title: 'Manage tickets',
    content:
      'Click into a ticket to view and manage it. Visit the “Activity” section of the ticket to communicate with the assigned Granite representative or check for recent ticket updates.',
    target: '.product-tour-noc-activity',
    pathname: '/noc-express/1',
    placement: 'left-start',
    section: 'NOCExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.NOC_EXPRESS_READ],
    menuSection: 'NOCExpress',
    returnTo: '/noc-express',
  },
  {
    step: 27,
    title: 'Manage tickets',
    content:
      'Edit ticket details or close tickets by clicking into a ticket and navigating to the bottom of the page. ',
    target: '.product-tour-noc-edit',
    pathname: '/noc-express/1',
    placement: 'top-start',
    section: 'NOCExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.NOC_EXPRESS_READ],
    menuSection: 'NOCExpress',
    returnTo: '/noc-express',
  },

  {
    step: 28,
    title: 'Manage tickets',
    content: `You can escalate tickets when they require attention from a higher level of support. After a ticket has been open for at least two hours, click the "Escalate" button and choose the reason for escalation that best fits your situation.`,
    target: '.product-tour-noc-escalate',
    pathname: '/noc-express/1',
    placement: 'bottom',
    section: 'NOCExpress',
    subSection: 'View & manage tickets',
    permissions: [TokenUserPermissions.NOC_EXPRESS_READ],
    menuSection: 'NOCExpress',
    returnTo: '/noc-express',
  },

  // last step
  {
    step: TourSteps.LAST_STEP,
    target: '.product-tour-menu',
    title: 'Thanks for using Granite360!',
    content:
      'Access this product tour anytime by clicking in your account drop down and selecting “Product tour.”',
    placement: 'right-end',
    // pathname: '/',
    className: 'mt-26',
    section: null,
    subSection: null,
    permissions: null,
    userDropDownOpen: true,
    menuSection: null,
  },
  // if user closes the modal at any time during the tour
  {
    step: TourSteps.REVISIT_LATER_STEP,
    target: '.product-tour-menu',
    title: 'Want to revisit later?',
    content:
      'Access this product tour anytime by clicking in your account drop down and selecting “Product tour.”',
    placement: 'right-end',
    // pathname: '/',
    className: 'mt-26',
    section: null,
    subSection: null,
    permissions: null,
    userDropDownOpen: true,
    menuSection: null,
  },
];
