import { ReactNode } from 'react';
import { ReviewOrderAccordion } from './ReviewOrderAccordion';
import clsx from 'clsx';

const PriceBreakdown = ({
  children,
  info,
  title,
  className,
}: {
  children: ReactNode;
  info: number | ReactNode;
  title: string;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col gap-4 rounded bg-background-base-surface-1 px-6 py-4',
        className,
      )}
    >
      <ReviewOrderAccordion title={title} info={info}>
        {children}
      </ReviewOrderAccordion>
    </div>
  );
};

export default PriceBreakdown;
