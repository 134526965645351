const GlowingBorder = () => {
  return (
    <>
      {/* Gradient Border */}
      <div
        className="pointer-events-none absolute inset-0 rounded-[4px] p-[2px]"
        style={{
          background:
            'linear-gradient(107deg, #7E77B3 0.54%, #8989FF 25.25%, #6C9DFF 49.85%, #78CFDA 74.65%, #9FF4A9 99.35%)',
          WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          maskComposite: 'exclude',
          WebkitMaskComposite: 'xor',
        }}
      />

      {/* Glow Effect */}
      <div
        className="pointer-events-none absolute inset-0 rounded-[4px]"
        style={{
          boxShadow: `
            0 0 16px 4px rgba(126, 119, 179, 0.24), /* #7E77B3 */
            0 0 16px 4px rgba(137, 137, 255, 0.24), /* #8989FF */
            0 0 16px 4px rgba(108, 157, 255, 0.24), /* #6C9DFF */
            0 0 16px 4px rgba(120, 207, 218, 0.24), /* #78CFDA */
            0 0 16px 4px rgba(159, 244, 169, 0.24)  /* #9FF4A9 */
          `,
          filter: 'blur(10px)',
          opacity: 0.24,
        }}
      />
    </>
  );
};

export default GlowingBorder;
