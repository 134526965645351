import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { useProductTourContext } from 'context/ProductTourContext';
import { TourSectionWithHierarchy } from '../types';
import { Checkbox } from '../icons';

export interface Item {
  name: string;
  section: string;
}

interface ProgressListProps {
  items: TourSectionWithHierarchy[];
}

const calculateCompletedCount = (items: TourSectionWithHierarchy[]) =>
  items.reduce(
    (acc, item) =>
      acc +
      (item.items?.reduce(
        (subAcc, subSection) =>
          subAcc + (subSection.status === 'Completed' ? 1 : 0),
        0,
      ) || 0),
    0,
  );

const ProgressList = observer(({ items }: ProgressListProps) => {
  const productTour = useProductTourContext();
  const completedCount = useRef<number>(0);
  const [shine, setShine] = useState(false);

  useEffect(() => {
    const newCompletedCount = calculateCompletedCount(items);
    if (completedCount.current < newCompletedCount) {
      completedCount.current = newCompletedCount;
      setShine(true);
      const timer = setTimeout(() => setShine(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [items]);

  return (
    <div
      className={clsx('shine-effect relative space-y-4 p-8', shine && 'shine')}
    >
      {items.map((props: TourSectionWithHierarchy, index) => (
        <div
          key={props.title}
          className={clsx(productTour.isLastStep && 'animate-once-bounce')}
          style={
            productTour.isLastStep
              ? { animationDelay: 500 + index * 200 + 'ms' }
              : {}
          }
        >
          {<Section {...props} />}
        </div>
      ))}
    </div>
  );
});

const Section = ({
  title,
  status,
  isActive,
  items,
}: TourSectionWithHierarchy) => (
  <div key={title} className="flex flex-col">
    <div
      className={clsx(
        'flex transform flex-row gap-2 transition-transform duration-300 ease-out',
        status === 'Completed' && 'animate-once-bounce',
      )}
    >
      <div>
        <div className="m-0.5 h-5 w-5 rounded-full border border-input-stroke-unfilled bg-input-background-unfilled">
          <div
            className={clsx(
              '-m-[3px] transition-opacity duration-150 ease-out',
              status === 'Completed' ? 'opacity-100' : 'opacity-0',
            )}
          >
            <Checkbox />
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'text-base font-medium',
          status === 'Completed' || isActive
            ? 'text-content-accent-default'
            : 'text-content-base-default',
        )}
      >
        Explore {title}
      </div>
    </div>
    <div
      className={clsx(
        'flex flex-col gap-2 overflow-hidden transition-all duration-[500ms] ease-in-out',
        isActive ? 'max-h-[150px] pt-4 opacity-100' : 'max-h-0 opacity-0',
      )}
    >
      {items?.map(SubSection)}
    </div>
  </div>
);

const SubSection = ({ title, status, isActive }: TourSectionWithHierarchy) => (
  <div
    key={title}
    className={clsx(
      'flex transform flex-row gap-2 transition-transform duration-300 ease-out',
      status === 'Completed' && 'animate-once-bounce',
    )}
  >
    <div
      className={clsx(
        'mx-2 my-2 h-2 w-2 flex-none rounded-full',
        status === 'Completed'
          ? 'bg-content-accent-default'
          : 'bg-content-base-subdued',
      )}
    ></div>
    <div
      className={clsx(
        status === 'Completed' || isActive
          ? 'text-content-accent-default'
          : 'text-content-base-default',
      )}
    >
      {title}
    </div>
  </div>
);

export default ProgressList;
