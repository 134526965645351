import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

interface DropdownProps {
  label: ReactNode;
  children: ReactNode;
  triggerClassName?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const Dropdown: FC<DropdownProps> = ({
  children,
  label,
  triggerClassName,
  isOpen,
  setIsOpen,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [setIsOpen]);

  return (
    <div className="relative flex" ref={dropdownRef}>
      <GraniteButton
        variant="unstyled"
        className={triggerClassName}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {label}
      </GraniteButton>
      {isOpen && (
        <div className="absolute left-0 top-9 z-10 w-[198px] space-y-1 rounded-lg bg-[#262636] bg-opacity-75 p-3 shadow-elevation3 backdrop-blur">
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

interface DropdownItemProps {
  label: ReactNode;
  onClick: () => void;
  active: boolean;
  children?: ReactNode;
}

export const DropdownItem: FC<DropdownItemProps> = ({
  active,
  label,
  onClick,
  children,
}) => {
  return (
    <div>
      <div
        className={clsx(
          'cursor-pointer whitespace-nowrap rounded p-2 text-xs',
          {
            'text-neutral-500 hover:bg-[#2B2B3C]': !active,
            'bg-[#333346] text-white': active,
          },
        )}
        onClick={onClick}
      >
        {label}
      </div>
      {children}
    </div>
  );
};
