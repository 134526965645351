import { createPaginatedResponseSchema } from 'api/common-schemas';
import { z } from 'zod';
import { AddressSchema } from './AccessCheckoutSchemas';
import { nullToUndefined } from 'shared/util/schema-utils';

export const AddressResponseSchema = AddressSchema._def.schema.extend({
  billing_type: z.number().transform(nullToUndefined).nullish(),
  invoice_style: z.string().transform(nullToUndefined).nullish(),
  invoice_delivery_method: z.number().transform(nullToUndefined).nullish(),
  local_contact_phone: z.string().transform(nullToUndefined).nullish(),
  id: z.string().transform(nullToUndefined).nullish(),
});

export const OrderItemResponseSchema = z.object({
  id: z.string(),
  type: z.string(),
  subtype: z.string(),
  pim_price_id: z.string().nullable(),
  item_details: z.any(),
  price: z.number().nullable(),
  quantity: z.number(),
  location_id: z.string(),
  status: z.string(),
  total_price: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
});

export type OrderItemResponse = z.infer<typeof OrderItemResponseSchema>;

export const LocationResponseSchema = z.object({
  id: z.string(),
  address: AddressResponseSchema.nullable(),
  order_items: z.array(OrderItemResponseSchema),
  status: z.string(),
  total_amount: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
});

export type LocationReponse = z.infer<typeof LocationResponseSchema>;

export const OrderResponseSchema = z.object({
  id: z.string(),
  order_name: z.string().nullable(),
  order_number: z
    .union([z.string(), z.coerce.string(), z.coerce.number()])
    .nullable(),
  user_id: z.string(),
  group_id: z.string(),
  billing_address: AddressSchema.nullish(),
  total_amount: z.number(),
  status: z.string().nullable(),
  requester: z.string().nullable(),
  customer_name: z.string().nullable(),
  company_id: z.string().nullable(),
  cw_parent_macnum: z.string().nullable(),
  grandparent_macnum: z.string().nullable(),
  locations: z.array(LocationResponseSchema).nullish(),
  updated_at: z.string(),
  created_at: z.string(),
});

export type OrderResponse = z.infer<typeof OrderResponseSchema>;

export const OrdersFilterSearchParamsSchema = z
  .object({
    // filters
    search: z.string(),
    requester: z.string(),
    start_date: z.string(),
    end_date: z.string(),
    // sorting
    customer_name: z.string(),
    order_number: z.string(),
    updated_at: z.string(),
    created_at: z.string(),
    // pagination
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type OrdersFilterSearchParams = z.infer<
  typeof OrdersFilterSearchParamsSchema
>;

export const OrdersAPIResponseSchema =
  createPaginatedResponseSchema(OrderResponseSchema);

export type OrdersIndexAPIResponse = z.infer<typeof OrdersAPIResponseSchema>;
