import { FC, useEffect, useMemo, useState } from 'react';
import Attributes from '..';
import Pricing from './Pricing';
import {
  MobilityProduct,
  VariantsChildrenType,
  VariantsType,
} from 'screens/AccessExpressV3/accessExpress.types';
import Variant, { VariantTitleBasedOnProductDesc } from './Variant';

export type SeletedAttributes = {
  'choose-variant': VariantsType | null;
  'choose-pricing-option': VariantsChildrenType | null;
};

interface MobilityAttributesProps
  extends Pick<MobilityProduct, 'variants' | 'productDesc'> {
  onAddToCart: (selected: SeletedAttributes) => void;
}

const MobilityAttributes: FC<MobilityAttributesProps> = ({
  variants,
  onAddToCart,
  productDesc,
}) => {
  const [selected, setSelected] = useState<SeletedAttributes>({
    'choose-variant': null,
    'choose-pricing-option': null,
  });

  const _onAddToCart = () => onAddToCart(selected);

  const showVariant = useMemo(() => {
    if (
      variants.length === 1 &&
      VariantTitleBasedOnProductDesc[productDesc ?? 'null'].length === 0
    )
      return false;
    else return true;
  }, [variants, productDesc]);

  useEffect(() => {
    if (!showVariant) {
      setSelected((prev) => ({ ...prev, 'choose-variant': variants[0] }));
    }
  }, [showVariant, variants]);

  return (
    <>
      <Attributes
        productDesc={productDesc}
        onAddToCart={_onAddToCart}
        attributes={[
          ...(showVariant
            ? [
                {
                  id: 'choose-variant',
                  element: (
                    <Variant
                      productDesc={productDesc}
                      variants={variants}
                      selected={selected['choose-variant']}
                      setSelected={(variant) =>
                        setSelected((prev) => ({
                          ...prev,
                          'choose-variant': variant,
                          'choose-pricing-option': null,
                        }))
                      }
                    />
                  ),
                },
              ]
            : []),
          {
            id: 'choose-pricing-option',
            element: (
              <Pricing
                variants={
                  (selected['choose-variant']?.children ||
                    variants[0]?.children) ??
                  []
                }
                selected={selected['choose-pricing-option']}
                setSelected={(variant) =>
                  setSelected((prev) => ({
                    ...prev,
                    'choose-pricing-option': variant,
                  }))
                }
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default MobilityAttributes;
