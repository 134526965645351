const ImagePlaceholder = ({ color = '#475569' }: { color?: string }) => {
  return (
    <div className="rounded border border-[#475569] border-opacity-40">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="56"
        viewBox="0 0 47 57"
        fill="none"
      >
        <g opacity="0.38">
          <line
            y1="-0.55"
            x2="70.8048"
            y2="-0.55"
            transform="matrix(0.625689 0.780073 -0.862181 0.506601 1.38428 1.54688)"
            stroke={color}
            strokeWidth="1.1"
          />
          <line
            y1="-0.55"
            x2="70.8048"
            y2="-0.55"
            transform="matrix(0.62569 -0.780072 0.86218 0.506602 1.38428 56.7793)"
            stroke={color}
            strokeWidth="1.1"
          />
        </g>
      </svg>
    </div>
  );
};

export default ImagePlaceholder;
