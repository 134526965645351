import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OptionType } from 'components/Select/Select';
import Divider from 'components/Divider';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';
import SpeedMultiSelect from './SpeedMultiSelect';
import { toJS } from 'mobx';

const LocationFooter = observer(() => {
  const cart = useCartContext();
  const navigate = useNavigate();
  const [selectedSpeeds, setSelectedSpeeds] =
    useState<MultiValue<OptionType>>();

  const handleSpeedChange = (options: MultiValue<OptionType>) => {
    setSelectedSpeeds(options);
  };

  const isDIAProductSelected = cart.selectedAccessProduct?.title === 'DIA';

  const handleFindServices = () => {
    if (cart.selectedAccessProduct) {
      navigate(
        `access/product/${
          cart.selectedAccessProduct.id
        }/${cart.selectedAccessProduct.category?.toLowerCase()}`,
        {
          state: {
            speeds: selectedSpeeds?.map((speed) => speed.value) ?? [],
            addresses: toJS(cart.locations),
          },
        },
      );
    }
  };

  return (
    <div className="flex flex-col gap-8">
      {isDIAProductSelected && (
        <div className="flex flex-col gap-8">
          <Divider />
          <SpeedMultiSelect
            value={selectedSpeeds}
            onChange={handleSpeedChange}
          />
        </div>
      )}
      <div className="flex w-full flex-row gap-4">
        <GraniteButton
          variant="secondary"
          onClick={() => cart.close()}
          className="w-full"
        >
          Back
        </GraniteButton>
        <GraniteButton
          variant="tertiary"
          onClick={handleFindServices}
          className="w-full"
          disabled={!cart.selectedAccessProduct || cart.locations.length === 0}
        >
          Find services
        </GraniteButton>
      </div>
    </div>
  );
});

export default LocationFooter;
