import { observer } from 'mobx-react-lite';

import CheckoutCard from './CheckoutCard';
import { useCartContext } from 'screens/AccessExpressV3/CartProvider';

const CheckoutBody = observer(() => {
  const cart = useCartContext();

  return (
    <>
      <CheckoutCard
        items={cart.items}
        savings="$75.00"
        subtotal="$300.00"
        discount="-$25.00"
        taxes="$24.50"
        shipping="$5.00"
        total="$304.50"
      />
    </>
  );
});

export default CheckoutBody;
