import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Business, ConstructSharp, Wifi } from 'react-ionicons';
import { ReactComponent as Footsteps } from '../../assets/icon/footsteps.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInventoryAccount } from './InventoryAccountContext';
import { add } from 'date-fns';

const LocationHeader = () => {
  const { setMode } = useInventoryAccount();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="mb-8 mt-12 flex justify-between gap-2 rounded bg-blue-800 px-6 py-7 shadow-elevation1">
      <div className="flex items-center gap-4 text-white">
        <Business color="currentColor" width="24px" height="24px" />
        <p className="text-xl font-bold">456 Oak Avenue, Afton, ME 22344</p>
      </div>
      <div className="flex gap-4">
        <GraniteButton
          variant="secondary"
          size="large"
          onClick={() =>
            navigate('/access-express/open-quote/add-configurations', {
              state: {
                // ONLY FOR TEST DUMMY DATA!!!
                addressValue: {
                  city: 'Murfreesboro',
                  md_status: 'success',
                  state: 'TN',
                  street: '2976 S Church St',
                  zip: '37127-8351',
                },
              },
            })
          }
        >
          <Wifi width="24px" height="24px" />
          Order services
        </GraniteButton>
        <GraniteButton
          variant="secondary"
          size="large"
          onClick={() =>
            navigate('/tech-express/let-us-help', {
              state: {
                cameFrom: 'inventory',
                // ONLY FOR TEST DUMMY DATA!!!
                data: {
                  getStartedQuestionnaire: {
                    accessTime: {
                      scheduling_type: 'Hard Start',
                      start_date: add(new Date(), { days: 1 }),
                    },
                    dispatchDate: add(new Date(), { days: 1 }),
                    site: {
                      address_line_1: '2976 S Church Street',
                      address_line_2: null,
                      city: 'Murfreesboro',
                      id: 3568999,
                      name: '04683730 - 040157 - Murfreesboro, TN [121232]',
                      parent_macnum: 'Valvoline',
                      parent_name: '03479292',
                      state: 'TN',
                      zip: '37127',
                    },
                    content: {
                      locationName: 'test',
                      locationNumber: 'test number',
                      type: 'single',
                    },
                  },
                },
              },
            })
          }
        >
          <Footsteps />
          Request dispatch
        </GraniteButton>
        <GraniteButton
          variant="secondary"
          size="large"
          onClick={() => {
            navigate('services', {
              state: pathname.includes('services')
                ? { from: 'services' }
                : null,
            });
            setMode('edit');
          }}
        >
          <ConstructSharp width="24px" height="24px" color="inherit" />
          Open repair ticket
        </GraniteButton>
      </div>
    </div>
  );
};

export default LocationHeader;
