import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAccessProduct } from '../utils';
import ProductSummary from '../BaseComponents/ProductSummary';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import AccessCustomizableOptions from './AccessCustomizableOptions';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { useCartContext } from '../CartProvider';
import ExpandOptionsButton from './ExpandOptionsButton';
import UnavailableServiceBanner from './UnavailableServiceBanner';
import OfferCard from './OfferCard/OfferCard';
import { SiteAddress } from 'api/addresssearch/schema';
import DIALoading from './DIALoading';

const AccessProductDetails = () => {
  const { id, category } = useParams();
  const location = useLocation();
  const { speeds, addresses } =
    (location.state as { speeds: string[]; addresses: SiteAddress[] }) || {};
  const _getProduct = () => getAccessProduct({ id: id as string });
  const { data, isLoading } = useQuery(['access_products', id], _getProduct);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const cart = useCartContext();

  const [expand, setExpand] = useState(false);

  const offerCardsRef = useRef<HTMLDivElement>(null);
  const [offerCardsHeight, setOfferCardsHeight] = useState(0);

  const onExpand = () => setExpand((prev) => !prev);

  const calculateOfferCardsHeight = () => {
    if (offerCardsRef.current) {
      setOfferCardsHeight(offerCardsRef.current.clientHeight);
    }
  };

  useEffect(() => {
    calculateOfferCardsHeight();

    window.addEventListener('resize', calculateOfferCardsHeight);
    return () => {
      window.removeEventListener('resize', calculateOfferCardsHeight);
    };
  }, [data, expand]);

  const handleAddToCart = (tag: string) => {
    cart.addItem({
      id: id ? Number(id + 10) : 0,
      category: 'Access',
      subCategory: data?.offerings.find((item) => item.tag === 'Best offer')
        ?.carrier,
      name: 'Service Selection',
      price: 1300,
      quantity: 1,
    });
    setSelectedOffer(tag);
  };

  const bestOffer = data?.offerings.find(
    (offering) => offering.tag === 'Best offer',
  );

  const premiumChoice = data?.offerings.find(
    (offering) => offering.tag === 'Premium choice',
  );

  const lowestPrice = data?.offerings.find(
    (offering) => offering.tag === 'Lowest price',
  );

  const noCuratedOptions = data?.offerings.length === 0;

  const serviceSummaryData = data
    ? {
        id: data?.id,
        type: data?.type,
        ip_type: '',
        bandwidth_download: '100',
        bandwidth_upload: '100',
      }
    : undefined;

  const isMobile = window.innerWidth < 768;

  // TODO: For testing purposes
  console.log({ speeds, addresses });

  return (
    <div className="relative my-0 flex flex-1 grow flex-col gap-8 bg-black p-4 sm:p-8">
      <ProductSummary product={data!} isAccessProduct isLoading={isLoading} />
      {noCuratedOptions && data.id && <UnavailableServiceBanner id={data.id} />}
      <DIALoading loading={isLoading} show={category === 'dia'}>
        <div className={clsx('h-auto w-full')}>
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: expand ? 0 : 1 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            className="w-full"
          >
            <div
              className="grid w-full grid-cols-1 md:grid-cols-2"
              ref={offerCardsRef}
            >
              <div className="md:col-span-2">
                <OfferCard
                  tag="Best offer"
                  offering={bestOffer}
                  isLoading={isLoading}
                  selectedOffer={selectedOffer}
                  onAddToCart={handleAddToCart}
                  serviceSummaryData={serviceSummaryData}
                />
              </div>
              <OfferCard
                tag="Premium choice"
                offering={premiumChoice}
                isLoading={isLoading}
                selectedOffer={selectedOffer}
                onAddToCart={handleAddToCart}
                serviceSummaryData={serviceSummaryData}
              />
              <OfferCard
                tag="Lowest price"
                offering={lowestPrice}
                isLoading={isLoading}
                selectedOffer={selectedOffer}
                onAddToCart={handleAddToCart}
                serviceSummaryData={serviceSummaryData}
              />
            </div>
          </motion.div>
          <div className="relative">
            {!noCuratedOptions && (
              <ExpandOptionsButton
                onExpand={onExpand}
                expand={expand}
                expandHeight={isMobile ? 540 : offerCardsHeight}
              />
            )}
            <AnimatePresence>
              {expand && (
                <motion.div
                  key="other-options-content"
                  initial={{ scaleY: 0, opacity: 0, y: 50 }}
                  animate={{ scaleY: 1, opacity: 1, y: 0 }}
                  exit={{ scaleY: 0, opacity: 0, y: 50 }}
                  transition={{ duration: 0.4, ease: 'easeInOut' }}
                  className="absolute bottom-[45%] w-full origin-bottom"
                  style={{
                    height: isMobile ? 500 : offerCardsHeight - 40,
                  }}
                >
                  <AccessCustomizableOptions expand={expand} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </DIALoading>
      <div className="flex gap-2 self-end">
        <GraniteButton variant="secondary" size="medium">
          Previous address
        </GraniteButton>
        <GraniteButton variant="tertiary" size="medium">
          Next address
        </GraniteButton>
      </div>
    </div>
  );
};

export default AccessProductDetails;
