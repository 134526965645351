import { Search } from 'react-ionicons';
import { Command } from 'cmdk';

import { SearchShortcutIcon } from './SearchShortcutIcon';

interface InputSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export const InputSearch = ({ value, onChange }: InputSearchProps) => {
  return (
    <div className="relative flex flex-none items-center justify-between border-b border-b-stroke-base-subdued">
      <div className="absolute left-4 flex h-6 w-6 items-center justify-center text-content-base-default">
        <Search color="currentColor" width="16px" height="16px" />
      </div>
      <div className="absolute right-4 flex items-center gap-2 text-content-accent-alt">
        <SearchShortcutIcon />
      </div>
      <Command.Input
        autoFocus
        placeholder="Search"
        value={value}
        onValueChange={onChange}
        className="relative h-[58px] w-full !bg-transparent px-4 pl-12 pr-[60px] text-sm text-input-content-filled !outline-none placeholder:text-input-content-unfilled"
      />
    </div>
  );
};

export default InputSearch;
