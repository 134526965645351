import { SiteAddressSchema } from 'api/addresssearch/schema';
import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

const speedOptions = ['Standard', 'TwoBusinessDay', 'Overnight'] as const;

export const SpeedEnum = z.enum(speedOptions);
export type Speed = z.infer<typeof SpeedEnum>;

export const BaseCheckoutFormSchema = z.object({
  site: SiteAddressSchema,
  sameShippingAddress: z.boolean(),
  name: z
    .string()
    .trim()
    .nonempty('Required')
    .max(50, 'Only 50 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  business_name: z.string().nonempty('Required'),
  phone_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  shipping_preference: z.string(),
  speed: SpeedEnum,
});

const ShippingAddressSchema = z.object({
  address_line_1: z.string().nonempty('Required'),
  unit: z.string().nonempty('Required'),
  city: z.string().nonempty('Required'),
  state: OptionLabelValueSchema,
  zip_code: z.string().nonempty('Required'),
});

const CheckoutFormSchemaFalseSameShippingAddressSchema =
  BaseCheckoutFormSchema.extend({
    sameShippingAddress: z.literal(false),
    ...ShippingAddressSchema.shape,
  });

const CheckoutFormSchemaTrueSameShippingAddressSchema =
  BaseCheckoutFormSchema.extend({
    sameShippingAddress: z.literal(true),
    address_line_1: z.string().optional(),
    unit: z.string().optional(),
    city: z.string().optional(),
    state: OptionLabelValueSchema.optional(),
    zip_code: z.string().optional(),
  });

export const CheckoutFormSchema = z.discriminatedUnion('sameShippingAddress', [
  CheckoutFormSchemaFalseSameShippingAddressSchema,
  CheckoutFormSchemaTrueSameShippingAddressSchema,
]);

export type CheckoutForm = z.infer<typeof CheckoutFormSchema>;
