import clsx from 'clsx';
import { Search } from 'react-ionicons';

import { useModal } from 'hooks/useModal';
import { useHotkey } from 'hooks/useHotkey';
import SearchModal from 'components/SearchModal/';
import { SearchShortcutIcon } from 'components/SearchModal/SearchShortcutIcon';

const SearchButton = ({ isNavbarExpanded }: { isNavbarExpanded: boolean }) => {
  const { open, close, isOpen } = useModal();

  useHotkey('k', () => open(), { ctrl: true, meta: true });

  return (
    <>
      <div
        className={
          'flex h-12 flex-none cursor-pointer overflow-hidden rounded bg-[#333346] px-4 py-3 text-input-content-unfilled hover:text-input-content-hover'
        }
        onClick={open}
      >
        <div className="relative flex min-w-[166px] items-center gap-2">
          <div
            className={clsx(
              'flex h-6 w-6 flex-none items-center justify-center text-content-base-default transition-transform duration-500 ease-out',
              !isNavbarExpanded && '-translate-x-1',
            )}
          >
            <Search color="currentColor" width="16px" height="16px" />
          </div>
          <span
            className={clsx(
              'flex-grow text-sm transition-opacity duration-300 ease-in-out group-hover:text-input-content-hover',
              !isNavbarExpanded && 'opacity-0',
            )}
          >
            Search
          </span>
          <span className="flex-none">
            <SearchShortcutIcon className="!bg-[#3b3c50] text-content-base-subdued" />
          </span>
        </div>
      </div>
      <SearchModal isOpen={isOpen} close={close} />
    </>
  );
};

export default SearchButton;
