import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OpenOutline } from 'react-ionicons';
import { ReactComponent as StarlinkLogo } from 'assets/images/starlink.svg';
import { ReactComponent as StarlinkProduct } from 'assets/images/starlink_product.svg';
import { useEffect, useState } from 'react';

const Starlink = () => {
  const [shine, setShine] = useState(false);

  useEffect(() => {
    const triggerShine = () => {
      setShine(true);
      setTimeout(() => setShine(false), 5000);
    };

    triggerShine();
    const interval = setInterval(triggerShine, 15000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex flex-col justify-between xl:flex-row">
      <div className="flex flex-col gap-3">
        <div className="relative flex gap-2">
          <h1 className={clsx('text-4xl font-bold text-content-base-default')}>
            Starlink
          </h1>
          <StarlinkLogo className="absolute left-[170px] top-[-35px]" />
        </div>
        <p className="flex flex-col justify-center font-bold">
          <span className="flex h-[38px] gap-1">
            <span className="text-[28px] text-content-base-default">$130</span>
            <span className="self-center text-xl text-content-base-subdued">
              /month
            </span>
          </span>
          <span className="text-xs">+0 service activation fee</span>
        </p>
        <LearnMoreButton className="hidden xl:flex" />
      </div>
      <div className="flex items-start justify-between gap-8 pb-3">
        <StarlinkProduct />
        <div className={clsx('p-8')}>
          <div
            className={clsx(
              'shine-effect flex flex-col items-center justify-center gap-2',
              shine && 'shine',
            )}
          >
            <div
              className={clsx(
                'flex h-6 items-center justify-center gap-1 rounded text-center text-destructive',
                'w-[94px]',
                'mb-1 bg-[#94A3B814]',
              )}
            >
              <p className="text-xs font-bold text-content-base-subdued">
                Bundles with
              </p>
            </div>
            <p className="text-base font-bold text-content-base-subdued">
              50-500G Data Plan
            </p>
            <p className="text-base font-bold text-content-base-subdued">
              edgebootPro
            </p>
          </div>
        </div>
      </div>
      <LearnMoreButton className="mt-3 flex xl:hidden" />
    </div>
  );
};

const LearnMoreButton = ({ className }: { className: string }) => {
  return (
    <GraniteButton
      variant="tertiary"
      size="medium"
      className={clsx('w-full max-w-[180px] !cursor-pointer', className)}
    >
      Learn more <OpenOutline width="18px" height="18px" color="inherit" />
    </GraniteButton>
  );
};

export default Starlink;
