import { arrayMove } from 'react-sortable-hoc';
import LocationsList from './LocationsList';
import { SiteAddress } from 'api/addresssearch/schema';

const Locations = ({
  locations,
  onChange,
}: {
  locations: Array<SiteAddress>;
  onChange: (locations: Array<SiteAddress>) => void;
}) => {
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newLocations = arrayMove([...locations], oldIndex, newIndex);
      onChange(newLocations);
    }
  };

  const handleChange = (index: number, value?: SiteAddress) => {
    const updatedLocations = [...locations];
    if (value === undefined) {
      updatedLocations.splice(index, 1);
    } else {
      updatedLocations[index] = value;
    }
    onChange(updatedLocations);
  };

  return (
    <div className="access-locations-wrapper">
      <LocationsList
        items={locations}
        onChange={handleChange}
        onSortEnd={onSortEnd}
        helperClass="z-[9999] !pointer-events-auto"
        distance={1}
        axis="y"
        lockAxis="y"
        useDragHandle
      />
    </div>
  );
};

export default Locations;
