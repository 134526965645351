import { Warning } from 'react-ionicons';
import { ConstructionTooltip } from './ConstructionTooltip';
import { useContructionTooltip } from './useConstructionTooltip';

const ConstructionBanner = ({ text }: { text: string }) => {
  const { triggerEltRef, isVisible, handleEnter, handleLeave } =
    useContructionTooltip();

  return (
    <>
      <div
        ref={triggerEltRef}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        className="pointer-events-auto z-40 flex items-center gap-2 rounded border border-status-warning-default bg-[#F9924714] p-2"
      >
        <Warning width="24px" height="24px" color="#F99247" />
        <span className="text-base font-bold text-status-warning-default">
          {text}
        </span>
      </div>
      <ConstructionTooltip
        triggerEltRef={triggerEltRef}
        isVisible={isVisible}
      />
    </>
  );
};

export default ConstructionBanner;
