import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

export const ConstructionTooltip = ({
  triggerEltRef,
  isVisible,
}: {
  triggerEltRef: React.RefObject<HTMLDivElement>;
  isVisible: boolean;
}) => {
  const popperEltRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (popperEltRef.current) {
      setPopperElement(popperEltRef.current);
    }
  }, [isVisible]);

  const { styles, attributes } = usePopper(
    triggerEltRef.current,
    popperElement,
    {
      placement: 'top',
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
        { name: 'preventOverflow', options: { boundary: 'clippingParents' } },
        { name: 'hide' },
      ],
    },
  );

  if (!isVisible) return null;

  return (
    <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
      <div className="max-w-[328px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
        <p className="text-base font-bold text-content-base-default">
          Construction likely
        </p>
        <p className="mt-1 text-sm font-medium">
          This service will likely require construction, which could lead to
          longer installation times and additional charges.
        </p>
      </div>
    </div>
  );
};
