import clsx from 'clsx';
import { FC, ReactNode, useEffect, useState } from 'react';
import type { DeviceAnimationProps } from './DeviceAnimation';
import BorderBeam from './BorderBeam';

interface DIALoadingProps extends DeviceAnimationProps {
  children: ReactNode;
  loading: boolean;
  show: boolean;
}

const DIALoading: FC<DIALoadingProps> = ({
  children,
  show,
  loading,
  ...rest
}) => {
  const [removeFromDOM, setRemoveFromDOM] = useState(false);

  useEffect(() => {
    if (!loading)
      setTimeout(() => {
        setRemoveFromDOM(true);
      }, 900);
    else setRemoveFromDOM(false);
  }, [loading]);

  if (!show) return children;

  return (
    <div className="relative">
      <div
        className={clsx('absolute inset-0 duration-700 ', {
          'h-[617px] opacity-100 ': loading,
          'h-0 opacity-0': !loading,
        })}
      >
        {!removeFromDOM && <BorderBeam {...rest} />}
      </div>
      <div
        className={clsx('duration-700', {
          'opacity-0': loading,
          'opacity-100': !loading,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default DIALoading;
