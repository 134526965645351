import { PageTitle } from 'screens/OpenQuote/BaseComponents/PageTitle';
import AccessBackButton from '../BaseComponents/AccessBackButton';
import FormSection from 'components/FormSection';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import Divider from 'components/Divider';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMemo, useState } from 'react';
import ServiceAddressFormSection from './ServiceAddressFormSection';
import { useQuery } from 'react-query';
import { getAccessParentAccounts } from 'api/accessexpressv3/api';

import { InformationCircle } from 'react-ionicons';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { getInvoiceStyleOptions } from 'screens/LetUsHelp/GetStarted/CreateChildAccount/utils';
import { PropsValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import {
  billinTypeOptions,
  invoiceDeliveryOptions,
} from 'shared/constants/options';
import { useAccessCheckoutContext } from '../context/AccessCheckoutProvider';

const AccessCheckout = () => {
  const navigate = useNavigate();
  const [parentAccountOptions, setParentAccountOptions] = useState<
    { label: string; value: string; subvalue?: string }[]
  >([]);

  const { form, locations, goToNext, goToPrevious, currentIndex, onSubmit } =
    useAccessCheckoutContext();

  const totalLocations = locations.length;
  const currentLocation = useMemo(
    () => locations[currentIndex],
    [currentIndex, locations],
  );

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    resetField,
  } = form;

  const [
    currentSiteId,
    currentBillingType,
    currentInvoiceStyle,
    currentInvoiceDeliveryMethod,
    currentParentAccount,
  ] = watch([
    `locations.${currentIndex}.cw_site_id`,
    `locations.${currentIndex}.billing_type`,
    `locations.${currentIndex}.invoice_style`,
    `locations.${currentIndex}.invoice_delivery_method`,
    `locations.${currentIndex}.parent_account`,
  ]);

  useQuery(['access-parent-accounts'], () => getAccessParentAccounts(), {
    enabled: !currentSiteId,
    onSuccess: (data) => {
      setParentAccountOptions(
        data.map((item) => {
          const parts = item.name
            ? item.name.split('-').map((part) => part.trim())
            : ['', ''];
          return {
            label: parts[0],
            value: parts[0],
            subvalue: parts[1],
          };
        }),
      );
    },
  });

  const onGoBack = () => navigate(-1);

  return (
    <div className="flex w-full flex-col gap-6 bg-background-base-surface-0 p-6">
      <header className="flex justify-between">
        <div className="flex flex-col items-start gap-3">
          <AccessBackButton onClick={onGoBack} />
          <PageTitle title="Checkout" />
        </div>
        <div className="self-end rounded-[50px] bg-[rgba(151,150,243,0.16)] px-4 py-1">
          <span className="text-base font-bold text-[#9796F3]">
            {`${currentIndex + 1} of ${totalLocations}`}
          </span>
        </div>
      </header>
      <form
        id="access-checkout-form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-8 rounded bg-gradient-to-b from-[rgba(71,71,104,0.08)] to-[rgba(51,51,80,0.08)] p-6"
      >
        <ServiceAddressFormSection location={currentLocation} />
        {!currentSiteId && (
          <>
            <div className="flex items-center gap-2 rounded bg-[#9796F314] p-4 ring-1 ring-purple-300">
              <InformationCircle color={'rgba(151, 150, 243, 1)'} />
              <p className="font-bold text-white">
                This address is not associated with an existing account. Create
                a new account by completing the below fields.
              </p>
            </div>
            <FormSection gridClassName="relative grid-cols-1 md:grid-cols-2">
              <GraniteInput
                label="Location name"
                variant="blue"
                className="col-span-1"
                innerInputClassName="!w-full !box-border"
                {...register(`locations.${currentIndex}.location_name`)}
                error={errors.locations?.[currentIndex]?.location_name?.message}
              />
              <GraniteSelect
                {...register(`locations.${currentIndex}.parent_account`)}
                options={parentAccountOptions}
                label="Parent account"
                onChange={(selectedOption: OptionType | null) => {
                  if (selectedOption) {
                    setValue(
                      `locations.${currentIndex}.parent_account`,
                      selectedOption,
                    );
                  }
                }}
                value={currentParentAccount}
                error={
                  errors.locations?.[currentIndex]?.parent_account?.message
                }
                className="col-span-1"
                variant="blue"
              />
            </FormSection>
            <FormSection gridClassName="relative grid-cols-1 md:grid-cols-3">
              <GraniteSelect
                {...register(`locations.${currentIndex}.billing_type`)}
                options={billinTypeOptions}
                label="Billing type"
                onChange={(selectedOption: OptionType | null) => {
                  if (selectedOption) {
                    setValue(
                      `locations.${currentIndex}.billing_type`,
                      selectedOption,
                    );
                    resetField(`locations.${currentIndex}.invoice_style`);
                  }
                }}
                value={currentBillingType}
                error={errors.locations?.[currentIndex]?.billing_type?.message}
                className="col-span-1"
                variant="blue"
              />
              <GraniteSelect
                {...register(`locations.${currentIndex}.invoice_style`)}
                options={
                  currentBillingType
                    ? getInvoiceStyleOptions(currentBillingType)
                    : []
                }
                label="Invoice style"
                onChange={(selectedOption: OptionType | null) => {
                  if (selectedOption) {
                    setValue(
                      `locations.${currentIndex}.invoice_style`,
                      selectedOption,
                    );
                  }
                }}
                value={currentInvoiceStyle || null}
                error={errors.locations?.[currentIndex]?.invoice_style?.message}
                className="col-span-1"
                variant="blue"
              />
              <GraniteSelect
                {...register(
                  `locations.${currentIndex}.invoice_delivery_method`,
                )}
                options={invoiceDeliveryOptions}
                label="Invoice delivery method"
                onChange={(selectedOption: OptionType | null) => {
                  if (selectedOption) {
                    setValue(
                      `locations.${currentIndex}.invoice_delivery_method`,
                      selectedOption,
                    );
                  }
                }}
                value={
                  currentInvoiceDeliveryMethod as unknown as PropsValue<OptionType>
                }
                error={errors.locations?.[currentIndex]?.invoice_style?.message}
                className="col-span-1"
                variant="blue"
              />
            </FormSection>
          </>
        )}
        <Divider />
        <FormSection
          title="Location details"
          gridClassName="relative grid-cols-1 md:grid-cols-2"
        >
          <GraniteInput
            label="Business name"
            variant="blue"
            className="col-span-1"
            innerInputClassName="!w-full !box-border"
            {...register(`locations.${currentIndex}.business_name`)}
            error={errors.locations?.[currentIndex]?.business_name?.message}
          />
          <GraniteInput
            label="Suite/unit/flr # (optional)"
            placeholder="Suite 1"
            className="col-span-1"
            variant="blue"
            {...register(`locations.${currentIndex}.address_line_2`)}
            error={errors.locations?.[currentIndex]?.address_line_2?.message}
          />
        </FormSection>
        <Divider />
        <FormSection
          title="Local contact details"
          gridClassName="relative grid-cols-1 md:grid-cols-2"
        >
          <GraniteInput
            {...register(`locations.${currentIndex}.local_contact_name`)}
            label="Name"
            error={
              errors.locations?.[currentIndex]?.local_contact_name?.message
            }
            variant="blue"
            className="col-span-1"
            innerInputClassName="!w-full !box-border"
          />
          <PhoneNumberInput
            name={`locations.${currentIndex}.local_contact_phone`}
            control={control}
            label="Phone number"
            variant="blue"
            className="col-span-1"
            innerInputClassName="!w-full !box-border"
            error={
              errors.locations?.[currentIndex]?.local_contact_phone?.message
            }
          />
        </FormSection>
      </form>
      {totalLocations > 1 && (
        <div className="flex gap-2 self-end">
          <GraniteButton
            className="w-full min-w-[175px]"
            size="medium"
            variant="secondary"
            onClick={goToPrevious}
            disabled={currentIndex === 0}
          >
            Previous address
          </GraniteButton>
          <GraniteButton
            className="w-full"
            size="medium"
            variant="tertiary"
            onClick={goToNext}
            disabled={currentIndex === totalLocations - 1}
          >
            Next address
          </GraniteButton>
        </div>
      )}
    </div>
  );
};

export default AccessCheckout;
