import { FC, useMemo } from 'react';
import CardList from '../CardList';
import {
  CommonAttributesProps,
  VariantsChildrenType,
} from 'screens/AccessExpressV3/accessExpress.types';
import { formatMobilityPrice } from 'screens/AccessExpressV3/utils';

interface PricingProps extends CommonAttributesProps {
  variants: VariantsChildrenType[];
  selected: VariantsChildrenType | null;
  setSelected: (v: VariantsChildrenType) => void;
}

const Pricing: FC<PricingProps> = ({
  handleStep,
  step,
  variants,
  selected,
  setSelected,
}) => {
  const onSelect = (item: VariantsChildrenType) => {
    handleStep && handleStep(step ?? 1);
    setSelected(item);
  };

  const options = useMemo(
    () =>
      variants.map((variant) => {
        let title = 'Purchase';
        if (variant.productGroupDesc === 'FINANCED')
          title = `Finance - ${variant.contractTerm_Months} months`;
        return {
          id: variant.id,
          title: `${title}`,
          body: `$${formatMobilityPrice(variant?.originalPrice)} ${
            variant.productGroupDesc === 'FINANCED' ? '/month' : ''
          }`,
          item: { ...variant },
        };
      }),
    [variants],
  );

  return (
    <>
      <CardList
        title="Choose pricing option"
        selected={selected?.id || null}
        onClick={(item) => onSelect(item as VariantsChildrenType)}
        options={options}
      />
    </>
  );
};

export default Pricing;
