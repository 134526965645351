import clsx from 'clsx';
import Checkbox from 'components/Checkbox';
import {
  RadioButtonInput,
  StyledRadioButton,
} from 'components/RadioButton/RadioButton.styles';
import { FC } from 'react';
import Tag, { TagProps } from 'components/Tag';

interface CardSelectProps {
  wrapperClassName?: string;
  title: string;
  body?: string;
  type: 'radio' | 'checkbox';
  disabled?: boolean;
  tags?: TagProps[];
  selected: boolean;
  onSelect: (prev: boolean) => void;
  variant?: 'default' | 'blue';
}

const CardSelect: FC<CardSelectProps> = ({
  wrapperClassName,
  title,
  body,
  type,
  disabled,
  tags,
  selected,
  onSelect,
  variant = 'default',
}) => {
  return (
    <div className="relative">
      {disabled && (
        <div className="absolute inset-0 z-10 rounded-md bg-neutral-900/50" />
      )}
      <div
        className={clsx(
          'group peer flex w-full flex-col gap-1 rounded-md bg-background-base-surface-0 p-4 transition-all',
          'cursor-pointer border border-blue-900 focus:border-neutral-500 focus:ring-2  focus:ring-offset-2 focus:ring-offset-blue-900',
          wrapperClassName,
          {
            'text-teal-400 ring-2 ring-teal-400':
              selected && variant === 'default',
            'ring-1 ring-neutral-500': !selected,
            'ring-acctext-accent-primary text-accent-primary ring-2':
              selected && variant === 'blue',
            'focus:ring-teal-400': variant === 'default',
            'focus:ring-accent-primary': variant === 'blue',
          },
        )}
        {...(!disabled && {
          tabIndex: 1,
          role: 'button',
          onClick: () => {
            onSelect(!selected);
          },
          onKeyDown: (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              onSelect(!selected);
            }
          },
        })}
      >
        <div className="flex justify-between gap-2">
          <p
            className={clsx('break-all font-semibold', {
              'text-teal-400': selected && variant === 'default',
              'text-white': !selected,
              'text-accent-primary': selected && variant === 'default',
              'group-hover:text-teal-400': variant === 'default',
              'group-hover:text-accent-primary': variant === 'blue',
            })}
          >
            {title}
          </p>
          {type === 'radio' && (
            <>
              <RadioButtonInput
                checked={selected}
                readOnly
                className="hidden"
                disabled={disabled}
              />
              <StyledRadioButton
                checked={selected}
                className="!mr-0"
                disabled={disabled}
                variant={variant === 'blue' ? 'blue' : 'primary'}
              />
            </>
          )}
          {type === 'checkbox' && (
            <Checkbox
              checked={selected}
              disabled={disabled}
              variant={variant}
            />
          )}
        </div>
        {body || tags?.length ? (
          <div className="flex flex-col gap-2">
            {body && <p className="text-xs font-semibold">{body}</p>}
            {!!tags?.length && (
              <div className="flex w-full flex-wrap gap-1">
                {tags?.map((tag, index) => <Tag {...tag} key={index} />)}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardSelect;
