import { apiClient } from 'api/apiClient';
import { AccessCheckoutRequest } from './schemas/AccessCheckoutSchemas';
import { ParentAccountsResponse } from 'api/techexpress/schemas/ParentAccountsResponse';
import {
  OrderResponse,
  OrdersAPIResponseSchema,
  OrdersFilterSearchParams,
  OrdersFilterSearchParamsSchema,
  OrdersIndexAPIResponse,
} from './schemas/OrdersSchemas';
import { prepareSortingParams } from 'api/common-schemas';

//orders
export const postOrder = async (body: AccessCheckoutRequest) => {
  const response = await apiClient.post(`/api/v1/orders`, body);

  return response.data;
};

export const getAccessParentAccounts = async (): Promise<
  ParentAccountsResponse[]
> => {
  const response = await apiClient.get('/api/v1/orders/parents');
  return response.data?.data;
};

const mapOrderColumns = {
  customer_name_order: 'customer_name',
  requester_order: 'requester',
  order_number_order: 'order_number',
  updated_at_order: 'updated_at',
  created_at_order: 'created_at',
};

export const getOrdersIndex = async (
  params: OrdersFilterSearchParams = {},
): Promise<OrdersIndexAPIResponse> => {
  const newQueryParams = prepareSortingParams(
    params,
    mapOrderColumns,
    OrdersFilterSearchParamsSchema,
  );

  const response = await apiClient.get('/api/v1/orders', {
    params: {
      ...newQueryParams,
      status: 'order_submitted',
    },
  });
  return OrdersAPIResponseSchema.parse(response.data);
};

export const getOrderDetails = async (id: string): Promise<OrderResponse> => {
  const response = await apiClient.get<OrderResponse>(`/api/v1/orders/${id}`);
  return response.data;
};
