import { FC, useMemo } from 'react';

import { ProductType } from '../../accessExpress.types';
import media from 'assets/images/media.svg';

export interface ProductProps {
  price_type: 'from' | 'starting' | 'monthly';
  onClick: (p: ProductType) => void;
  product: ProductType;
}

const Product: FC<ProductProps> = ({ product, price_type, onClick }) => {
  const showFromPrice = useMemo(
    () => !!product.from_price && product.monthly_price && product.months,
    [product.from_price, product.monthly_price, product.months],
  );
  const showPrice = useMemo(
    () => product.monthly_price && product.months,
    [product.monthly_price, product.months],
  );

  return (
    <div
      onClick={() => onClick(product)}
      className="flex h-[229px] w-full transform-gpu cursor-pointer flex-col items-center justify-end gap-8 rounded bg-[#1D1D2A] p-4 transition-all duration-300 ease-in-out hover:bg-[#1F1F2D] hover:shadow-custom-dual"
    >
      {/* Product */}
      <div className="h-28 w-28 overflow-hidden rounded ">
        <img
          className="h-full w-full object-contain"
          src={product.img ?? media}
          alt=""
        />
      </div>
      <div className="w-full self-start">
        {product.manufacturer && (
          <p className="text-xs font-semibold text-neutral-500">
            {product.manufacturer}
          </p>
        )}
        <p
          className="truncate whitespace-nowrap font-bold text-white"
          title={product.title}
        >
          {product.title}
        </p>
        <p className="mt-1 text-xs font-semibold text-neutral-500">
          {product.fullPriceInfo ? (
            product.fullPriceInfo
          ) : (
            <>
              {price_type === 'from' &&
                showFromPrice &&
                `From $${product.from_price} or $${product.monthly_price}/mo. per month for ${product.months} mo.`}
              {price_type === 'starting' &&
                showPrice &&
                `Starting at $${product.monthly_price}/mo. per month for ${product.months} mo.`}
              {price_type === 'monthly' &&
                `$${product.monthly_price}/mo. per month for ${product.months} mo.`}
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Product;
