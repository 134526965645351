import { FC, PropsWithChildren } from 'react';
import { ArrowDown, ArrowUp } from 'react-ionicons';
import { EnterIcon } from './icons';

const Footer = () => {
  return (
    <div className="flex flex-none flex-row gap-8 border-t border-t-stroke-base-subdued pt-6 text-content-base-subdued">
      <div className="gap flex flex-row gap-2.5">
        <div className="flex gap-[5px]">
          <IconWrapper>
            <ArrowUp width="22px" height="22px" color="currentColor" />
          </IconWrapper>
          <IconWrapper>
            <ArrowDown width="22px" height="22px" color="currentColor" />
          </IconWrapper>
        </div>
        to navigate
      </div>
      <div className="gap flex flex-row gap-2.5">
        <IconWrapper>
          <EnterIcon />
        </IconWrapper>
        to select
      </div>
      <div className="gap flex flex-row gap-2.5">
        <IconWrapper>
          <span className="text-[11px] font-bold leading-none">esc</span>
        </IconWrapper>
        to dismiss
      </div>
    </div>
  );
};

const IconWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex h-[26px] w-[26px] items-center justify-center rounded-[5px] bg-background-base-surface-3 text-content-accent-alt">
      {children}
    </div>
  );
};

export default Footer;
