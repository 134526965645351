import ProductSummary from '../BaseComponents/ProductSummary';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import media from 'assets/images/media_large.svg';
import { getProduct } from '../utils';
import MobilityAttributes, { SeletedAttributes } from './Attributes/Mobility';
import { useCartContext } from '../CartProvider';

const ProductDetails = () => {
  const { id } = useParams();

  const _getProduct = () => getProduct({ id: id as string });

  const { data, isLoading } = useQuery(['products', id], _getProduct, {
    refetchOnWindowFocus: false,
  });

  const cart = useCartContext();

  const onAddToCart = (selected: SeletedAttributes) => {
    if (data && !!selected['choose-pricing-option'])
      // !TODO- Temporary added to Cart
      cart.addItem({
        id: data.productId ?? 0,
        category: 'Mobility',
        name: data.title,
        price: selected['choose-pricing-option']?.originalPrice ?? 0,
        quantity: 1,
      });
  };

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader />
      </div>
    );

  if (!data)
    return (
      <div className="flex flex-1 grow flex-col gap-12 bg-black p-4 sm:p-8 ">
        Product not found!
      </div>
    );

  return (
    <div className="relative flex flex-1 grow flex-col gap-12 bg-black p-4 sm:p-8">
      {data && <ProductSummary product={data} />}
      <div className="flex flex-1 flex-col items-center justify-between lg:flex-row">
        <div className="mb-4 lg:mb-0 xl:ml-20">
          <img
            src={data?.img ?? media}
            className="object-contain md:max-h-[550px] md:max-w-[460px]"
          />
        </div>
        {data?.varients && (
          <MobilityAttributes
            productDesc={data.productDescType ?? null}
            variants={data.varients}
            onAddToCart={onAddToCart}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
