import { OptionType } from '../components/Select/Select';
import { useCallback, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';

export interface UseFilterForTableProps<T extends string> {
  queryParamKey: T;
  defaultValue?: MultiValue<OptionType>;
  onAfterChange?: (values: MultiValue<OptionType>) => void;
}

export interface UseFilterForTableReturn<T extends string> {
  clearFilter: () => void;
  onChange: (values: MultiValue<OptionType>) => void;
  value: MultiValue<OptionType>;
  queryParamFilter?: { [K in T]: string };
}

export const useFilterForTable = <QPKey extends string>({
  queryParamKey,
  defaultValue,
  onAfterChange,
}: UseFilterForTableProps<QPKey>): UseFilterForTableReturn<QPKey> => {
  const [filter, setFilter] = useState<MultiValue<OptionType>>(
    defaultValue ?? [],
  );
  const queryParamFilter = useMemo(() => {
    return filter.length > 0
      ? ({
          [queryParamKey]: filter
            .map((v) => v.value)
            .filter((v) => v)
            .join(','),
        } as { [K in QPKey]: string })
      : undefined;
  }, [filter, queryParamKey]);

  const handleChange = useCallback(
    (values: MultiValue<OptionType>) => {
      setFilter(values);
      onAfterChange?.(values);
    },
    [onAfterChange],
  );

  const clearFilter = useCallback(() => handleChange([]), [handleChange]);

  return {
    clearFilter,
    onChange: handleChange,
    value: filter,
    queryParamFilter,
  };
};
