import clsx from 'clsx';
import React from 'react';

const CartContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={clsx(
        'flex flex-grow flex-col',
        'vertical-scrollbar overflow-x-hidden !scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued',
      )}
    >
      {children}
    </div>
  );
};

export default CartContent;
