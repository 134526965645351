import { FC } from 'react';

interface GradientBorderProps {
  direction: string;
  className: string;
}

export const GradientBorder: FC<GradientBorderProps> = ({
  direction,
  className,
}) => {
  return (
    <div
      className={className}
      style={{
        background: `linear-gradient(${direction}, rgba(48, 67, 89, 1), rgba(126, 119, 179, 1), rgba(137, 137, 255, 1), rgba(108, 157, 255, 1), rgba(120, 207, 218, 1), rgba(159, 244, 169, 1), rgba(255, 255, 255, 0.12))`,
        maskImage: `linear-gradient(black, black) padding-box, linear-gradient(${direction}, rgba(48, 67, 89, 1), rgba(126, 119, 179, 1), rgba(137, 137, 255, 1), rgba(108, 157, 255, 1), rgba(120, 207, 218, 1), rgba(159, 244, 169, 1), rgba(255, 255, 255, 0.12)) border-box`,
        WebkitMaskComposite: 'destination-in',
        maskComposite: 'intersect',
        boxShadow: '0px 0px 6.2px rgba(120, 207, 218, 1)',
      }}
    />
  );
};

export default GradientBorder;
