import { GraniteSelect, OptionType } from 'components/Select/Select';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { ShortPagination } from 'components/Table/ShortPagination';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { PaginableRequest } from 'api/common-schemas';
import { getSubGroup } from './utils';
import Loader from 'components/Loader';
import { SubGroupType } from './accounts.types';
import { Control, useController } from 'react-hook-form';
import { EditUserForm, InviteUserForm } from 'screens/InviteMember/form-schema';
import { SingleValue } from 'react-select';
import SubGroup from './SubGroup';
import clsx from 'clsx';
import Divider from 'components/Divider';
import DisabledToolTip from './DisabledToolTip';

interface UserAccountsProps {
  control: Control<EditUserForm> | Control<InviteUserForm>;
}

export const accountOptions = [
  { label: 'Select accounts', value: 'select-accounts' },
  { label: 'All accounts', value: 'all-accounts' },
];

const UserAccounts: FC<UserAccountsProps> = ({ control }) => {
  const {
    field: { onChange: onSubGroupChange, value: subGroups },
    fieldState: { error },
  } = useController({
    control: control as Control<EditUserForm>,
    name: 'sub_groups',
  });

  const {
    field: { onChange: onAccountChange, value: accountSelect },
    fieldState: { error: accountError },
  } = useController({
    control: control as Control<EditUserForm>,
    name: 'account',
  });

  const {
    field: { value: roles },
  } = useController({
    control: control as Control<EditUserForm>,
    name: 'roles',
  });

  const _getAccounts = (args: PaginableRequest) => getSubGroup(args);

  const onSelect = useCallback(
    (option: SingleValue<OptionType>) => {
      onAccountChange(option);
      if (option?.value === 'all-accounts') onSubGroupChange([]);
    },
    [onAccountChange, onSubGroupChange],
  );

  useEffect(() => {
    if (roles?.some((role) => role.value === 'company-admin'))
      onSelect(accountOptions[1]);
  }, [roles, onSelect]);

  const onClick = (subGroupId: SubGroupType['id']) => {
    let _value = subGroups ?? [];

    if (_value.includes(subGroupId))
      _value = _value.filter((id) => id !== subGroupId);
    else _value.push(subGroupId);
    onSubGroupChange(_value);
  };

  const {
    data,
    isFetchingData,
    isLoading,
    pageCount,
    paginationState: { pageIndex },
    onPaginationChange,
  } = usePaginatedTable(_getAccounts, {}, ['user-account-access'], {
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!data.length && !isFetchingData && !isLoading) {
      onAccountChange(accountOptions[1]);
    }
  }, [data.length, onAccountChange, isFetchingData, isLoading]);

  const isAllAccountsSelected = useMemo(() => {
    return accountSelect?.value === accountOptions[1].value;
  }, [accountSelect?.value]);

  if (!data.length) return null;

  return (
    <>
      <Divider className="w-full" />
      <div className="w-full">
        <DisabledToolTip
          show={roles?.some((role) => role.value === 'company-admin')}
          className="flex flex-col gap-2"
          offset={[0, -20]}
          placement="top-start"
        >
          <GraniteSelect
            label="Accounts"
            value={accountSelect}
            options={accountOptions}
            onChange={onSelect}
            error={accountError?.message}
            isDisabled={
              roles?.some((role) => role.value === 'company-admin') ||
              data.length === 1
            }
          />
          {!isAllAccountsSelected && (
            <p>
              This user will have access to all locations within the selected
              account(s).{' '}
            </p>
          )}
        </DisabledToolTip>
        {!isAllAccountsSelected && (
          <div className="mt-8 flex w-full flex-col">
            {isFetchingData && <Loader className="h-40" />}
            {data && !isFetchingData && (
              <div
                className={clsx(
                  'mt-2 grid w-full grid-cols-1 gap-4 rounded p-0.5 xl:grid-cols-2',
                  { 'ring-1 ring-red-500': !!error?.message },
                )}
              >
                {data.map((subGroup) => (
                  <SubGroup
                    key={subGroup.id}
                    {...subGroup}
                    checked={
                      subGroups?.includes(subGroup.id) ||
                      accountSelect?.value === accountOptions[1].value
                    }
                    disabled={accountSelect?.value === accountOptions[1].value}
                    showDisabledTooltip={roles?.some(
                      (role) => role.value === 'company-admin',
                    )}
                    onClick={() => onClick(subGroup.id)}
                  />
                ))}
              </div>
            )}
            {!isLoading && data && (
              <div className="mt-6 self-end">
                <ShortPagination
                  currentPage={pageIndex + 1}
                  onPageChange={(page) => {
                    onPaginationChange({ pageIndex: page - 1, pageSize: 20 });
                  }}
                  totalPages={pageCount}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserAccounts;
