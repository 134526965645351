import { FC } from 'react';
import AttributeHeader, { AttributeHeaderProps } from './AttributeHeader';
import CardSelect from 'components/CardSelect/CardSelect';

interface CardListProps extends AttributeHeaderProps {
  onClick: (item: unknown) => void;
  options: { id: string; title: string; body?: string; item?: unknown }[];
  selected: string | null;
}

const CardList: FC<CardListProps> = ({
  onClick,
  options,
  selected,
  ...attributeHeaderProps
}) => {
  return (
    <>
      <AttributeHeader {...attributeHeaderProps} />
      <div className="grid grid-cols-1 gap-4 px-6">
        {options.map((option, index) => (
          <CardSelect
            variant="blue"
            key={index}
            title={option.title}
            body={option.body}
            type="radio"
            selected={selected === option.id}
            onSelect={() => {
              onClick(option.item || option.id);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default CardList;
