import { ComponentProps, Fragment } from 'react';
import {
  CANCELLED,
  COMPLETE,
  ExtendedTicket,
  ON_HOLD,
  PENDING_CUSTOMER,
  PENDING_TECH_ASSIGNMENT,
  TECH_ASSIGNED,
  TECH_CHECKED_IN,
} from '../../api/techexpress/schema';
import { clsx } from 'clsx';
import { DetailField } from './TicketDetailUtil';
import format from 'date-fns/format';

const getTicketBgByStatus = (status?: string) => {
  return {
    [PENDING_TECH_ASSIGNMENT]: 'bg-ticket-status-pendingtechassignment',
    [TECH_ASSIGNED]: 'bg-ticket-status-techassigned',
    [TECH_CHECKED_IN]: 'bg-ticket-status-techcheckedin',
    [COMPLETE]: 'bg-ticket-status-complete',
    [CANCELLED]: 'bg-ticket-status-cancelled',
    [PENDING_CUSTOMER]: 'bg-ticket-status-pendingcustomer',
    [ON_HOLD]: 'bg-ticket-status-onhold',
    '': 'transparent',
  }[status ?? ''];
};

export interface TicketDetailMetaProps extends ComponentProps<'div'> {
  ticket?: ExtendedTicket;
}

export const TicketDetailMeta = ({
  ticket,
  className,
}: TicketDetailMetaProps) => {
  const ticketStatusBg = getTicketBgByStatus(ticket?.status_name);

  return (
    <div className={clsx('col-span-full', className)}>
      <div className="col-span-full flex flex-wrap items-center justify-between gap-4 rounded-t bg-background-base-surface-2 px-6 py-4 last:rounded-b">
        <div className="flex items-center gap-3 text-xl font-bold text-content-base-default">
          <span
            className={clsx(
              'h-[16px] w-[16px] flex-none rounded-full',
              ticketStatusBg,
            )}
          />
          <div>
            <div>{ticket?.status_name}</div>
          </div>
        </div>
        <div className="flex gap-x-12 gap-y-6 max-xs:flex-col">
          {/*TODO: Need to figure out which field has the tech assigned*/}
          {/*<DetailField description="Tech assigned" />*/}
          {ticket?.status_name &&
            ticket.status_name !== COMPLETE &&
            ticket.status_name !== ON_HOLD && (
              <DetailField
                description="Last update"
                value={
                  ticket.last_updated &&
                  format(ticket.last_updated, 'MM/dd/yyyy')
                }
              />
            )}

          {ticket?.status_name && ticket.status_name === COMPLETE && (
            <DetailField
              description="Completed"
              value={
                ticket.date_resolved &&
                format(ticket.date_resolved, 'MM/dd/yyyy')
              }
            />
          )}
          {ticket?.status_name && ticket.status_name === CANCELLED && (
            <DetailField
              description="Cancelled"
              value={
                ticket.date_resolved &&
                format(ticket.date_resolved, 'MM/dd/yyyy')
              }
            />
          )}

          {/* TODO: is `date_entered` the ticket open date? */}
          <DetailField
            description="Opened"
            value={
              ticket?.date_entered && format(ticket?.date_entered, 'MM/dd/yyyy')
            }
          />
          <DetailField
            description="Customer ticket #"
            value={ticket?.customer_ticket_number}
          />
        </div>
      </div>
      {ticket?.status_name === 'On hold' ||
      ticket?.status_name === 'Pending customer' ? (
        <div className="rounded-b bg-background-base-surface-3 px-6 py-4">
          {ticket.status_name === 'On hold' ? (
            <Fragment>
              <div className="text-base font-bold text-content-base-subdued">
                Reason for on hold
              </div>
              <div className="text-base font-bold text-content-base-default">
                Check your recent chat messages for reasons why this has been
                marked on hold.
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="text-base font-bold text-content-base-subdued">
                Reason for pending customer
              </div>
              <div className="text-base font-bold text-content-base-default">
                Check your recent chat messages from Granite to resolve any
                issues.
              </div>
            </Fragment>
          )}
        </div>
      ) : null}
    </div>
  );
};
