import { z } from 'zod';
import { nullToUndefined } from '../../shared/util/schema-utils';

export const SiteAddressSchema = z.object({
  name: z.string(),
  address_line_1: z.string(),
  address_line_2: z.string().transform(nullToUndefined).nullish(),
  macnum: z.string().optional(),
  parent_macnum: z.string().transform(nullToUndefined).nullish(),
  parent_name: z.string().transform(nullToUndefined).nullish(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  id: z.number(),
});
export const SearchAddressResponseSchema = z.array(SiteAddressSchema);

export type SiteAddress = z.infer<typeof SiteAddressSchema>;
export type SearchAddressResponse = z.infer<typeof SearchAddressResponseSchema>;
