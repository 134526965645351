import styled from 'styled-components';

export const ProductsToLocationsTable = styled.div`
  display: grid;
  grid-template-columns: 40px 158px minmax(100px, 250px);
  grid-auto-columns: auto;
  column-gap: 48px;
  grid-template-rows: auto;
  grid-auto-rows: 56px 1px;
`;

export const TableRow = styled.div`
  display: grid !important;
  background-color: var(--background_base_surface-3_subdued) !important;
  grid-column: 1 / -1;
  grid-template-columns: auto 1fr auto 107px 107px;
  column-gap: 48px;
  border-radius: 4px;
`;
