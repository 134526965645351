import { useRef, useState } from 'react';

export const useContructionTooltip = () => {
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => setIsVisible(true);
  const handleLeave = () => setIsVisible(false);

  return { triggerEltRef, isVisible, handleEnter, handleLeave };
};
