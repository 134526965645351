export const billinTypeOptions = [
  {
    label: 'Bill to Parent account',
    value: '4',
  },
  {
    label: 'Bill to this account',
    value: '5',
  },
];

export const invoiceDeliveryOptions = [
  {
    label: 'Paper only',
    value: '1',
  },
  {
    label: 'Paper & email',
    value: '2',
  },
  {
    label: 'Email only',
    value: '3',
  },
];
