import {
  ReactNode,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { CaretUp, CaretDown } from 'react-ionicons';

export interface CollapsibleSectionRef {
  toggle: () => void;
}

interface CollapsibleSectionProps {
  label: string;
  isWide: boolean;
  isCollapsible?: boolean;
  children: ReactNode;
  defaultExpanded?: boolean;
  headerSuffix?: ReactNode;
}

const CollapsibleSection = forwardRef<
  CollapsibleSectionRef,
  CollapsibleSectionProps
>(
  (
    {
      label,
      children,
      isWide,
      defaultExpanded = true,
      isCollapsible = true,
      headerSuffix,
    },
    ref,
  ) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleToggleClick = useCallback(() => {
      if (!isCollapsible) return;
      setIsExpanded((prev) => !prev);
    }, [isCollapsible]);

    useImperativeHandle(ref, () => ({ toggle: handleToggleClick }), [
      handleToggleClick,
    ]);
    const contentRef = useRef<HTMLDivElement>(null);

    return (
      <div className="flex flex-col gap-2" ref={wrapperRef}>
        <div
          className={clsx('-my-2 flex w-[198px] flex-row justify-between py-2')}
        >
          <div
            className={clsx(
              'flex flex-row gap-2',
              isCollapsible && 'cursor-pointer',
            )}
            onClick={handleToggleClick}
          >
            <span
              className={clsx(
                'text-xs font-bold transition-all duration-300 ease-out',
                !isWide && 'pl-1.5',
              )}
            >
              {label}
            </span>
            <div
              className={clsx(
                'relative flex h-4 w-4 items-start justify-between transition-opacity duration-300 ease-out',
                isWide ? 'opacity-100' : 'opacity-0',
                isCollapsible ? 'visible' : 'invisible',
              )}
            >
              <div
                className={clsx(
                  'absolute transition-all duration-300 ease-out',
                  !isExpanded && 'opacity-0',
                )}
              >
                <CaretUp width="16px" height="16px" color="currentColor" />
              </div>
              <div
                className={clsx(
                  'absolute transition-all duration-300 ease-out',
                  isExpanded && 'opacity-0',
                )}
              >
                <CaretDown width="16px" height="16px" color="currentColor" />
              </div>
            </div>
          </div>

          <div>{headerSuffix}</div>
        </div>
        <div
          className={clsx(
            'overflow-hidden transition-all duration-[400ms] ease-in-out',
            isExpanded ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0',
          )}
        >
          <div ref={contentRef} className="flex flex-col gap-1">
            {children}
          </div>
        </div>
      </div>
    );
  },
);

CollapsibleSection.displayName = 'CollapsibleSection';

export default CollapsibleSection;
