import clsx from 'clsx';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import IconButton from 'components/IconButton';
import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import Divider from 'components/Divider';
import {
  OfferingType,
  ServiceSummaryData,
  TagType,
} from 'screens/AccessExpressV3/accessExpress.types';
import { AccessServiceSummary } from '../AccessServiceSummary';
import DetailsItem from './DetailsItem';
import { getServiceActivationFee } from 'screens/SelectServices/utils';

const CuratedOfferDetails = ({
  tag,
  isSelected,
  onAddToCart,
  offering,
  serviceSummaryData,
}: {
  tag: TagType;
  isSelected: boolean;
  onAddToCart: (tag: string) => void;
  offering: OfferingType;
  serviceSummaryData?: ServiceSummaryData;
}) => {
  const [expand, setExpand] = useState(false);

  const onExpand = () => setExpand((prev) => !prev);
  const data = useMemo(
    () => ({
      ...serviceSummaryData,
      term: '1 Year',
      formal_broadband_pricing: {
        total_mrc: Number(offering.price.replace(/\D/g, '')),
        circuit: 275,
        modem: 275,
        ip: 275,
        internet_access_recovery: 275,
      },
    }),
    [offering.price, serviceSummaryData],
  );

  //@ts-expect-error err
  const serviceActivationFee = getServiceActivationFee(data);

  return (
    <div className="flex flex-col justify-between xl:flex-row">
      <div className="flex flex-col gap-3">
        <h1
          className={clsx(
            'text-4xl font-bold text-content-base-default',
            tag !== 'Best offer' && '!text-2xl',
          )}
        >
          {offering.carrier}
        </h1>
        <p className="flex flex-col justify-center font-bold">
          <span className="flex h-[38px] items-center gap-1">
            <span className="text-[28px] text-content-base-default">
              {offering.price}
            </span>
            <span className="self-center text-xl text-content-base-subdued">
              /month
            </span>
            <IconButton
              icon={'chevronDown'}
              className={clsx({ 'rotate-180': expand })}
              buttonType="ghost"
              color="currentColor"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onExpand();
              }}
            />
          </span>
          <span className="text-xs">
            +{serviceActivationFee} service activation fee
          </span>
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0, opacity: 0, marginTop: 0 }}
            animate={
              expand
                ? { height: 'auto', opacity: 1, marginTop: `8px` }
                : { height: 0, opacity: 0, marginTop: 0 }
            }
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 15,
            }}
          >
            <Divider className="my-4" />
            <AccessServiceSummary
              //@ts-expect-error err
              data={data}
              hideHeaderDetails={true}
            />
          </motion.div>
        </p>
        <AddToCartButton
          isSelected={isSelected}
          onAddToCart={onAddToCart}
          tag={tag}
          className="hidden xl:block"
        />
      </div>
      <div className="mt-5 flex flex-col justify-start">
        {offering.details.map((row, rowIndex) => (
          <div key={rowIndex} className="flex flex-col gap-2 md:flex-row">
            {row.map((item, index) => {
              const showConstructionWarning =
                offering.construction && item.id === 'construction';

              return (
                <DetailsItem
                  key={index}
                  {...item}
                  showConstructionWarning={showConstructionWarning}
                />
              );
            })}
          </div>
        ))}
      </div>
      <AddToCartButton
        isSelected={isSelected}
        onAddToCart={onAddToCart}
        tag={tag}
        className="mt-3 block xl:hidden"
      />
    </div>
  );
};

export default CuratedOfferDetails;

const AddToCartButton = ({
  isSelected,
  onAddToCart,
  tag,
  className,
}: {
  isSelected: boolean;
  onAddToCart: (tag: string) => void;
  tag: TagType;
  className?: string;
}) => (
  <GraniteButton
    variant="tertiary"
    size="medium"
    onClick={() => !isSelected && onAddToCart(tag)}
    className={clsx('w-full max-w-[256px] !cursor-pointer', className)}
  >
    {isSelected ? 'Added to cart' : 'Add to cart'}
  </GraniteButton>
);
