import React, { createContext, useContext, useState, useCallback } from 'react';
import { ProductDetails } from 'screens/SelectServices/utils';

interface SingleServiceManagerContextType {
  pinnedService: ProductDetails | null;
  cartState: { pinned: boolean; unpinned: boolean };
  disabledSummary: { pinned: boolean; unpinned: boolean };
  pinService: () => void;
  unpinService: () => void;
  addToCart: (isPinned: boolean) => void;
  removeFromCart: (isPinned: boolean) => void;
  isUnpinning: boolean;
  selectedValues: Record<string, string>;
  pinnedSelectedValues: Record<string, string>;
  updateSelectedValues: (
    newValues: Record<string, string>,
    isPinned: boolean,
  ) => void;
  resetSelectedValues: (isPinned: boolean) => void;
}

const SingleServiceManagerContext = createContext<
  SingleServiceManagerContextType | undefined
>(undefined);

export const SingleServiceManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isUnpinning, setIsUnpinning] = useState(false);
  const [pinnedService, setPinnedService] = useState<ProductDetails | null>(
    null,
  );
  const [cartState, setCartState] = useState({
    pinned: false,
    unpinned: false,
  });
  const [disabledSummary, setDisabledSummary] = useState({
    pinned: false,
    unpinned: false,
  });

  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    {},
  );
  const [pinnedSelectedValues, setPinnedSelectedValues] = useState<
    Record<string, string>
  >({});

  const pinService = useCallback(() => {
    //@ts-expect-error err
    setPinnedService({ ...pinnedService });
    setPinnedSelectedValues(selectedValues);
  }, [pinnedService, selectedValues]);

  const unpinService = useCallback(() => {
    setIsUnpinning(true);
    setTimeout(() => {
      setSelectedValues(pinnedSelectedValues);
      setPinnedService(null);
      setIsUnpinning(false);
    }, 400);
  }, [pinnedSelectedValues]);

  const addToCart = useCallback((isPinned: boolean) => {
    setCartState((prev) => ({
      ...prev,
      [isPinned ? 'pinned' : 'unpinned']: true,
    }));
    setDisabledSummary((prev) => ({
      ...prev,
      [isPinned ? 'unpinned' : 'pinned']: true,
    }));
  }, []);

  const removeFromCart = useCallback((isPinned: boolean) => {
    setCartState((prev) => ({
      ...prev,
      [isPinned ? 'pinned' : 'unpinned']: false,
    }));
    setDisabledSummary((prev) => ({
      ...prev,
      [isPinned ? 'unpinned' : 'pinned']: false,
    }));
  }, []);

  const updateSelectedValues = useCallback(
    (newValues: Record<string, string>, isPinned: boolean) => {
      if (isPinned) {
        setPinnedSelectedValues((prev) => ({ ...prev, ...newValues }));
      } else {
        setSelectedValues((prev) => ({ ...prev, ...newValues }));
      }
    },
    [],
  );

  const resetSelectedValues = useCallback((isPinned: boolean) => {
    if (isPinned) {
      setPinnedSelectedValues({});
    } else {
      setSelectedValues({});
    }
  }, []);

  return (
    <SingleServiceManagerContext.Provider
      value={{
        pinnedService,
        cartState,
        disabledSummary,
        pinService,
        unpinService,
        addToCart,
        removeFromCart,
        isUnpinning,
        selectedValues,
        pinnedSelectedValues,
        updateSelectedValues,
        resetSelectedValues,
      }}
    >
      {children}
    </SingleServiceManagerContext.Provider>
  );
};

export const useSingleServiceManager = () => {
  const context = useContext(SingleServiceManagerContext);
  if (!context) {
    throw new Error(
      'useSingleServiceManager must be used within a SingleServiceManagerProvider',
    );
  }
  return context;
};
