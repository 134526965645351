import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import NavigationTabs from './NavigationTabs';
import LocationHeader from './LocationHeader';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FaArrowRight } from 'react-icons/fa';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { dummyData } from './services/utils';

interface InventoryAccountContextProps {
  mode: 'view' | 'edit';
  setMode: (mode: 'view' | 'edit') => void;
  selected: string[];
  onSelect: (value: string, checked: boolean) => void;
}

interface InventoryAccountProviderProps {
  children: React.ReactNode;
}

const InventoryAccountContext = createContext<InventoryAccountContextProps>({
  mode: 'view',
  setMode: () => {},
  selected: [],
  onSelect: () => {},
});

export const InventoryAccountProvider: React.FC<
  InventoryAccountProviderProps
> = ({ children }) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [selected, setSelected] = useState<string[]>([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSelect = (value: string, checked: boolean) =>
    setSelected((prev) => {
      if (checked) return [...prev, value];
      return prev.filter((item) => item !== value);
    });

  const onGoBack = () => {
    setMode('view');
    if (state?.from !== 'services') navigate(-1);
  };

  return (
    <InventoryAccountContext.Provider
      value={{ mode, setMode, selected, onSelect }}
    >
      <LocationHeader />
      <div
        className={clsx('transition-all duration-700', {
          'mb-8 h-10 opacity-100': mode === 'view',
          'h-0 opacity-0': mode === 'edit',
        })}
      >
        <NavigationTabs />
      </div>
      <div
        className={clsx(
          'fixed inset-0 z-[99] cursor-pointer bg-blue-900 bg-opacity-[0.85] transition-all duration-700',
          {
            'pointer-events-none opacity-0': mode === 'view',
            'opacity-100': mode === 'edit',
          },
        )}
        onMouseDown={onGoBack}
      />
      <div className={clsx('relative', { 'z-[99]': mode === 'edit' })}>
        {children}
        {mode === 'edit' && !!selected.length && (
          <div className="fixed bottom-0 z-[999] -ml-8 flex h-28 w-full items-center bg-[linear-gradient(0deg,rgba(25,25,37,0.7),rgba(34,39,49,0))] p-10">
            <GraniteButton
              size="large"
              onClick={() => {
                navigate('/noc-express/open-ticket/details', {
                  state: { data: dummyData },
                });
              }}
            >
              Open repair for ({selected.length}) services <FaArrowRight />
            </GraniteButton>
          </div>
        )}
      </div>
    </InventoryAccountContext.Provider>
  );
};

export const useInventoryAccount = () => {
  const context = useContext(InventoryAccountContext);
  if (!context) {
    throw new Error(
      'useInventoryAccount must be used within a InventoryAccountProvider',
    );
  }
  return context;
};
