import { useEffect, useMemo, useState } from 'react';
import Joyride, { Step } from 'react-joyride';
import { observer } from 'mobx-react-lite';
import { useProductTourContext } from 'context/ProductTourContext';

import { CustomToolTip } from './CustomTooltip';
import ProgressTracker from './ProgressTracker';
import CustomBeacon from './CustomBeacon';
import { useAuthUser } from 'hooks/useAuthUser';
import { useLocation, useNavigate } from 'react-router-dom';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useFeatureFlags } from 'feature-flags';
import ConfettiOverlay from './ConfettiOverlay';

const ProductTour: React.FC = observer(() => {
  const productTour = useProductTourContext();
  const { roles } = useAuthUser();
  const { flags } = useFeatureFlags();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      flags.PRODUCT_TOUR_ENABLED &&
      !roles.includes(TokenUserRoles.PROSPECT)
    ) {
      productTour.setTourEnabled(navigate);
    }
  }, [flags.PRODUCT_TOUR_ENABLED, navigate, productTour, roles]);

  const formattedSteps: Step[] = useMemo(
    () =>
      productTour.steps.map((step) => ({
        content: step.content,
        placement: step.placement,
        styles: {
          spotlight: {
            borderRadius: step.spotLightRadius || 8,
          },
        },
        floaterProps: {
          disableAnimation: true,
        },
        offset: -8,
        target: step.target,
        spotlightPadding: step.spotlightPadding || 0,
        pathname: productTour.running ? step.pathname : undefined,
        tooltipComponent: () => (
          <CustomToolTip
            title={step.title}
            subtitle={step.content}
            step={step.step}
            className={step.className}
          />
        ),
        beaconComponent: CustomBeacon,
      })),
    [productTour.running, productTour.steps],
  );

  useEffect(() => {
    const fullPath = `${location.pathname}${location.search}`;

    if (productTour.requestState.load.isLoaded) {
      productTour.startTourIfNeeded(fullPath);
    }
  }, [location, productTour, productTour.requestState.load.isLoaded]);

  const [showConfetti, setShowConfetti] = useState<boolean | null>(null);

  useEffect(() => {
    if (showConfetti !== false) {
      if (productTour.requestState.load.isLoaded) {
        setShowConfetti(productTour.isFirstRun);
      }
    }
  }, [
    productTour.requestState.load.isLoaded,
    productTour.isFirstRun,
    showConfetti,
  ]);

  return (
    <>
      {productTour.running && (
        <>
          {showConfetti && <ConfettiOverlay />}
          <div className="fixed bottom-8 right-8 z-[200] w-[314px] rounded bg-background-base-surface-1 shadow-lg">
            {!(productTour.isLastStep && !productTour.isLastActionableStep) && (
              <ProgressTracker />
            )}
          </div>
          <Joyride
            steps={formattedSteps}
            run={true}
            continuous
            showSkipButton
            stepIndex={productTour.currentStepNumber}
            styles={{
              options: {
                arrowColor: '#222231',
              },
            }}
          />
        </>
      )}
    </>
  );
});

export default ProductTour;
