import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import Home from 'screens/Home';
import Missing from './screens/Missing/Missing';
import { ProtectedDefaultLayout } from './layouts/DefaultLayout/ProtectedDefaultLayout';
import TechExpress from 'screens/TechExpress/TechExpress';
import { TicketDetail } from './screens/TicketDetail/TicketDetail';
import UserManagement from 'screens/UserManagement';
import UserDetails from 'screens/UserDetails';
import { LetUsHelpRoutes } from './screens/LetUsHelp/WizardProvider/LetUsHelpWizard';
import EditProfile from 'screens/UserProfile/EditProfile';
import { AxiosInterceptor } from './components/AxiosInterceptor/AxiosInterceptor';
import { InviteUser } from './screens/InviteMember/InviteUser';
import { OpenQuoteRoutes } from './screens/OpenQuote/Wizard/OpenQuoteWizard';
import { TokenUserPermissions } from './api/users/schemas/Users';
import { EditUser } from './screens/InviteMember/EditUser';
import { NOCExpress } from 'screens/NOCExpress/NOCExpress';
import { SessionExpired } from 'screens/SessionExpired';
import { SignUp } from 'screens/SignUp';
import { OpenNetOpsTicketRoutes } from 'screens/OpenNetOpsTicket/WizardProvider/OpenNOCTicketWizard';
import { TicketDetails } from 'screens/NOCExpress/TicketDetails/TicketDetails';
import { NotificationsPage } from 'screens/NotificationsPage/NotificationsPage';
import { NotificationPreferencesPage } from 'screens/NotificationsPage/NotificationPreferences/NotificationPreferencesPage';
import Components from 'screens/Components';
import Mobility from 'screens/Mobility/Mobility';
import { ProductTourContextProvider } from 'context/ProductTourContext';
import { Templates } from 'screens/TechExpress/Templates';
import { EditTemplateRoutes } from 'screens/TechExpress/Templates/WizardProvider/EditTemplateWizard';
import { TemplatePage } from 'screens/TechExpress/Templates/TemplatePage';
import Resources from 'screens/Resources';
import { FEATURE_FLAG, useFeatureFlags } from 'feature-flags';
import UploadQRCode from 'screens/OpenQuote/UploadQRCode/UploadQRCode';
import AccessExpress from 'screens/AccessExpress/AccessExpress';
import OktaAuth, {
  AccessToken,
  IDToken,
  toRelativeUrl,
} from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { PageLoader } from 'components/PageLoader';
import { SelectCompany } from 'screens/SelectCompany';
import { PendingVerification } from 'screens/PendingVerification';
import { signupGraniteUser } from 'api/auth/auth';
import AlertConfiguration from 'screens/NOCExpress/Alerting/AlertConfiguration/AlertConfiguration';
import Inventory from 'screens/Inventory/Inventory';
import RolesPermissions from 'screens/RolesPermissions';
import Roles from 'screens/RolesPermissions/Roles';
import Permissions from 'screens/RolesPermissions/Permissions';
import Role from 'screens/RolesPermissions/Roles/details/Role';
import CreatePermission from 'screens/RolesPermissions/Permissions/Form/CreatePermission';
import EditPermission from 'screens/RolesPermissions/Permissions/Form/EditPermission';
import DeletePermission from 'screens/RolesPermissions/Permissions/DeletePermission';
import CreateRole from 'screens/RolesPermissions/Roles/Form/CreateRole';
import EditRole from 'screens/RolesPermissions/Roles/Form/EditRole';
import DeleteRole from 'screens/RolesPermissions/Roles/DeleteRole';
import User from 'screens/UserDetails/User';
import Settings from 'screens/UserSettings';
import ApiKeys from 'screens/UserSettings/ApiKeys';
import ApiKey from 'screens/UserSettings/ApiKey';
import InvitationErrorModal from 'screens/InvitationError/InvitationError';
import { G360Token } from 'api/auth/schema';
import DeleteKey from 'screens/UserSettings/DeleteKey';
import CreateKey from 'screens/UserSettings/CreateKey';
import RegenerateKey from 'screens/UserSettings/RegenerateKey';
import { AcceptInvitation } from 'screens/AcceptInvitation';
import { AuthProvider } from 'authContext';
import { PermissionProtectedRoute } from 'components/Permission/PermissionProtectedRoute';
import { LoginCallback } from 'login-callback';
import InventoryAccount from 'screens/InventoryAccount';
import InventoryOverview from 'screens/InventoryAccount/overview';
import InventoryServices from 'screens/InventoryAccount/services';
import InventoryActivities from 'screens/InventoryAccount/activities';
import Companies from 'screens/Companies';
import OrderServices from 'screens/AccessExpressV3/OrderServices';
import MobilityV3 from 'screens/AccessExpressV3/Services/MobilityV3';
import Checkout from 'screens/AccessExpressV3/Checkout/Checkout';
import ProductDetails from 'screens/AccessExpressV3/ProductDetails';
import AccessExpressV3 from 'screens/AccessExpressV3';
import AccessProductDetails from 'screens/AccessExpressV3/AccessProductDetails/AccessProductDetails';
import Access from 'screens/AccessExpressV3/Services/Access';
import { SingleServiceManagerProvider } from 'context/SingleServiceManagerContext';
import AccessCheckout from 'screens/AccessExpressV3/AccessCheckout/AccessCheckout';
import { OrdersIndex } from 'screens/AccessExpressV3/OrdersIndex';
import OrderDetails from 'screens/AccessExpressV3/OrderDetails/OrderDetails';

const IS_UAT = process.env.REACT_APP_CURRENT_ENV === 'uat';
const JIRA_COLLECTOR_URL = process.env.REACT_APP_JIRA_COLLECTOR_PLUGIN_URL;
const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

if (!OKTA_ISSUER || !OKTA_CLIENT_ID) {
  throw new Error(
    'Missing Okta configuration: REACT_APP_OKTA_ISSUER or REACT_APP_OKTA_CLIENT_ID is not set',
  );
}

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/auth/callback',
  scopes: ['openid', 'profile', 'offline_access', 'email'],
});

interface FlagProtectedRouteProps {
  flag: FEATURE_FLAG;
}

const FlagProtectedRoute = ({ flag }: FlagProtectedRouteProps) => {
  const { flags } = useFeatureFlags();
  if (!flags?.[flag]) return <Navigate to="/" />;
  return <Outlet />;
};

/**
 * RouterMiddleware is a wrapper component of context providers required in the
 * application that require being under the router context.
 *
 * This function wraps the context providers so that we don't have to nest them
 * in the app routes more than once.
 */
const RouterMiddleware = () => (
  <AxiosInterceptor>
    <ScrollRestoration />
    <Outlet />
  </AxiosInterceptor>
);

export const applicationRouter = [
  { path: '/', element: <Home /> },
  {
    path: 'tech-express',
    element: (
      <PermissionProtectedRoute
        permission={TokenUserPermissions.TECH_EXPRESS_READ}
      />
    ),
    children: [
      { index: true, element: <TechExpress /> },
      ...LetUsHelpRoutes,
      {
        path: 'templates',
        element: (
          <PermissionProtectedRoute
            permission={TokenUserPermissions.TECH_EXPRESS_MANAGE}
          />
        ),
        children: [
          { index: true, element: <Templates /> },
          ...EditTemplateRoutes,
          {
            path: ':templateId',
            children: [
              { index: true, element: <TemplatePage /> },
              ...EditTemplateRoutes,
            ],
          },
        ],
      },
      { path: ':ticketId', element: <TicketDetail /> },
    ],
  },
  {
    path: 'access-express',
    element: (
      <PermissionProtectedRoute
        permission={[TokenUserPermissions.ACCESS_EXPRESS_READ]}
      />
    ),
    children: [
      { index: true, element: <AccessExpress /> },
      ...OpenQuoteRoutes,
      {
        path: 'v3',
        element: <FlagProtectedRoute flag="ACCESS_EXPRESS_V3" />,
        children: [
          {
            index: true,
            element: <OrdersIndex />,
          },
          {
            element: <OrderDetails />,
            path: ':id',
          },
          {
            element: <AccessExpressV3 />,
            children: [
              {
                path: 'order-services',
                element: <OrderServices />,
                children: [
                  {
                    path: 'mobility',
                    element: (
                      <FlagProtectedRoute flag="ACCESS_EXPRESS_V3_MOBILITY" />
                    ),
                    children: [{ index: true, element: <MobilityV3 /> }],
                  },
                  { path: 'access', element: <Access /> },
                ],
              },
              {
                path: 'product/:id',
                element: (
                  <FlagProtectedRoute flag="ACCESS_EXPRESS_V3_MOBILITY" />
                ),
                children: [{ index: true, element: <ProductDetails /> }],
              },
              {
                path: 'access/product/:id/:category',
                element: (
                  <SingleServiceManagerProvider>
                    <AccessProductDetails />
                  </SingleServiceManagerProvider>
                ),
              },
              { path: 'checkout/access', element: <AccessCheckout /> },
              { path: 'checkout', element: <Checkout /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'profile',
    element: <User />,
    children: [
      { index: true, element: <UserDetails /> },
      {
        path: 'edit',
        element: <EditProfile />,
      },
      {
        path: 'settings',
        element: <FlagProtectedRoute flag="DEVELOPER_MODE" />,
        children: [
          {
            element: (
              <PermissionProtectedRoute
                permission={TokenUserPermissions.API_KEYS_MANAGE}
              />
            ),
            children: [
              {
                element: <Settings />,
                children: [
                  {
                    path: '',
                    element: <ApiKeys />,
                    children: [
                      { path: 'create', element: <CreateKey /> },
                      {
                        path: 'delete/:client_id/:name',
                        element: <DeleteKey />,
                      },
                    ],
                  },
                  {
                    path: ':client_id',
                    element: <ApiKey />,
                    children: [
                      {
                        path: 'delete/:name',
                        element: <DeleteKey navigateTo="/profile/settings" />,
                      },
                      { path: 'regenerate/:name', element: <RegenerateKey /> },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/users',
    element: (
      <PermissionProtectedRoute
        permission={TokenUserPermissions.USER_MANAGEMENT}
      />
    ),
    children: [
      { path: '', element: <UserManagement /> },
      {
        element: <User withTabs={false} />,
        children: [{ path: ':id', element: <UserDetails /> }],
      },
      { path: 'edit/:id', element: <EditUser /> },
      { path: 'invite-user', element: <InviteUser /> },
      {
        path: 'roles',
        element: (
          <PermissionProtectedRoute
            // permission={TokenUserPermissions.ROLE_MANAGE}
            onlyForSuperAdmin
          />
        ),
        children: [
          {
            element: <RolesPermissions />,
            children: [
              {
                path: '',
                element: <Roles />,
                children: [
                  { path: 'create', element: <CreateRole /> },
                  { path: 'delete/:id/:name', element: <DeleteRole /> },
                ],
              },
              {
                path: 'permissions',
                element: <Permissions />,
                children: [
                  { path: 'create', element: <CreatePermission /> },
                  { path: 'edit/:id', element: <EditPermission /> },
                  { path: 'delete/:id/:name', element: <DeletePermission /> },
                ],
              },
            ],
          },
          { path: ':id', element: <Role /> },
          {
            path: 'edit/:id',
            element: <EditRole />,
            children: [
              {
                path: 'delete/:name',
                element: <DeleteRole navigateTo="/users/roles" />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/companies',
    element: <PermissionProtectedRoute forGraniteUser />,
    children: [{ path: '', element: <Companies /> }],
  },
  {
    path: 'components',
    element: <PermissionProtectedRoute onlyForSuperAdmin />,
    children: [{ index: true, element: <Components /> }],
  },
  {
    path: 'notifications',
    children: [
      { index: true, element: <NotificationsPage /> },
      { path: 'preferences', element: <NotificationPreferencesPage /> },
    ],
  },

  {
    path: 'noc-express',
    element: (
      <PermissionProtectedRoute
        permission={TokenUserPermissions.NOC_EXPRESS_READ}
      />
    ),
    children: [
      { index: true, element: <NOCExpress /> },
      { path: ':id', element: <TicketDetails /> },
      {
        path: 'alert-configuration/:alertId',
        element: <FlagProtectedRoute flag="ALERTING" />,
        children: [{ index: true, element: <AlertConfiguration /> }],
      },
      ...OpenNetOpsTicketRoutes,
    ],
  },
  {
    path: 'resources',
    element: <FlagProtectedRoute flag="RESOURCES_ENABLED" />,
    children: [
      {
        element: (
          <PermissionProtectedRoute
            permission={TokenUserPermissions.RESOURCES_READ}
          />
        ),
        children: [
          {
            index: true,
            element: <Resources />,
          },
        ],
      },
    ],
  },
  {
    path: 'inventory',
    element: (
      <PermissionProtectedRoute
        permission={TokenUserPermissions.INVENTORY_READ}
      />
    ),
    children: [
      { index: true, element: <Inventory /> },
      {
        path: 'account/:account_number',
        element: <FlagProtectedRoute flag="INVENTORY_ACCOUNT" />,
        children: [
          {
            element: <InventoryAccount />,
            children: [
              { index: true, element: <InventoryOverview /> },
              { path: 'services', element: <InventoryServices /> },
              { path: 'activity', element: <InventoryActivities /> },
            ],
          },
        ],
      },
    ],
  },
  { path: '*', element: <Missing /> },
];

const protectedApplicationRouter = createBrowserRouter([
  {
    element: <RouterMiddleware />,
    children: [
      {
        children: [
          { path: '/error', element: <InvitationErrorModal /> },
          {
            path: '/signup',
            element: (
              <AuthProvider>
                <SignUp />
              </AuthProvider>
            ),
          },
          { path: '/auth/select-company', element: <SelectCompany /> },
          {
            path: '/auth/callback',
            element: <LoginCallback loadingElement={<PageLoader />} />,
          },
          {
            path: '/auth/pending-verification',
            element: <PendingVerification />,
          },
          {
            path: '/invitation/:id',
            element: <AcceptInvitation />,
          },
        ],
      },
      {
        element: (
          <ProductTourContextProvider>
            <ProtectedDefaultLayout />
          </ProductTourContextProvider>
        ),
        children: applicationRouter,
      },
    ],
  },
  { path: '/session-expired', element: <SessionExpired /> },
  {
    path: '/mobility',
    children: [{ index: true, element: <Mobility /> }],
  },
  {
    element: <RouterMiddleware />,
    children: [
      {
        children: [
          {
            path: '/upload-qr',
            element: <UploadQRCode />,
          },
        ],
      },
      {
        element: <ProtectedDefaultLayout />,
        children: applicationRouter,
      },
    ],
  },
]);

const AppRouter: React.FC = () => {
  const restoreOriginalUri = async (
    oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    try {
      const { claims } = (await oktaAuth.tokenManager.get(
        'idToken',
      )) as IDToken;
      const g360Token: G360Token | undefined = JSON.parse(
        localStorage.getItem('g360-token-storage') ?? '{}',
      );
      if (g360Token) {
        if (g360Token.user_id !== claims.sub) {
          localStorage.removeItem('g360-token-storage');
        } else {
          return window.location.replace(
            toRelativeUrl(originalUri || '/', window.location.origin),
          );
        }
      }
      const isGraniteAccount = claims.email?.split('@')[1] === 'granitenet.com';
      switch (claims.onboarding_status) {
        case 'MIGRATING':
        case 'PROSPECT':
        case 'COMPLETED':
          sessionStorage.setItem('originalUri', originalUri);
          window.location.replace(
            toRelativeUrl('/auth/select-company', window.location.origin),
          );
          break;

        case 'PENDING':
          window.location.replace(
            toRelativeUrl('/auth/pending-verification', window.location.origin),
          );
          break;

        default:
          if (!isGraniteAccount) {
            window.location.replace(
              toRelativeUrl('/signup', window.location.origin),
            );
          } else {
            const { accessToken } = (await oktaAuth.tokenManager.get(
              'accessToken',
            )) as AccessToken;
            await signupGraniteUser(accessToken);
            window.location.replace(
              toRelativeUrl('/auth/select-company', window.location.origin),
            );
          }
          break;
      }
    } catch (error) {
      oktaAuth.signOut();
    }
  };

  useEffect(() => {
    if (!IS_UAT || !JIRA_COLLECTOR_URL) return;
    const script = document.createElement('script');
    script.src = JIRA_COLLECTOR_URL;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <RouterProvider router={protectedApplicationRouter} />
    </Security>
  );
};

export default AppRouter;
