import React, { createContext, useContext, useMemo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { flow, makeAutoObservable, runInAction } from 'mobx';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
  getProductTourProgress,
  resetProductTourProgress,
  upsertProductTourProgress,
} from 'api/producttour/api';
import {
  ProductTourResponse,
  TourSection,
  TourStatus,
} from 'api/producttour/schemas';
import { getStepDetails, TourSteps } from 'components/ProductTour/steps';
import {
  StepDetailWithData,
  TourSectionWithHierarchy,
} from 'components/ProductTour/types';
import { hasPermission } from 'hooks/usePermissions';

interface RequestState {
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
}

export type ProductTourMode = 'Full' | 'Section' | 'SubSection';

export class ProductTourStore {
  private enabled = false;
  private navigateFunction?: NavigateFunction;
  private _steps: StepDetailWithData[] = getStepDetails();
  openModalFunction?: () => void;

  prevStepNumber = -1;
  currentStepNumber = 0;
  mode: ProductTourMode = 'Full';
  fromModal = false;
  running = false;
  isLoading = false;
  error: string | null = null;

  requestState: Record<string, RequestState> = {
    load: { isLoading: false, error: null, isLoaded: false },
    save: { isLoading: false, error: null, isLoaded: false },
    reset: { isLoading: false, error: null, isLoaded: false },
  };

  constructor() {
    makeAutoObservable(this);
  }

  // Initialization
  init() {
    this.loadProgress();
  }

  setOpenModalFunction(openModalFunction: () => void) {
    this.openModalFunction = openModalFunction;
  }

  // Getters
  get steps() {
    return this._steps;
  }

  // TODO: remove this as it duplicates the currentStep getter
  get stepDetails() {
    return this._steps[this.currentStepNumber];
  }

  get currentStep() {
    return this._steps.find((step) => step.step === this.currentStepNumber);
  }

  getStep(step?: number) {
    return step === undefined
      ? this.currentStep
      : this._steps.find((s) => s.step === step);
  }

  getLastStepInSection(stepNumber?: number) {
    const step = this.getStep(stepNumber);
    return this._steps.findLast(({ section }) => section === step?.section);
  }
  getLastStepInSubSection(stepNumber?: number) {
    const step = this.getStep(stepNumber);
    return this._steps.findLast(
      ({ section, subSection }) =>
        section === step?.section && subSection === step?.subSection,
    );
  }
  getLastStepInFullTour() {
    return this._steps[TourSteps.LAST_STEP - 1];
  }
  getLastStep(stepNumber?: number) {
    switch (this.mode) {
      case 'Section':
        return this.getLastStepInSection(stepNumber);
      case 'SubSection':
        return this.getLastStepInSubSection(stepNumber);
      case 'Full':
    }
  }

  getFirstStepInSection(stepNumber?: number) {
    const step = this.getStep(stepNumber);
    return this._steps.find(({ section }) => section === step?.section);
  }
  getFirstStepInSubSection(stepNumber?: number) {
    const step = this.getStep(stepNumber);
    return this._steps.find(
      ({ section, subSection }) =>
        section === step?.section && subSection === step?.subSection,
    );
  }
  getFirstStepInFullTour() {
    return this._steps[TourSteps.WELCOME_MODAL_STEP + 1];
  }
  getFirstStep(stepNumber?: number) {
    switch (this.mode) {
      case 'Section':
        return this.getFirstStepInSection(stepNumber);
      case 'SubSection':
        return this.getFirstStepInSubSection(stepNumber);
      case 'Full':
    }
  }

  get isLastActionableStep() {
    return this.currentStepNumber < TourSteps.LAST_STEP;
  }

  get isLastStep() {
    return this.currentStep === this.getLastStep();
  }

  setTourEnabled(navigateFunction: NavigateFunction) {
    if (!this.enabled) {
      this.enabled = true;
      this.navigateFunction = navigateFunction;
      this.init();
    }
  }

  // Navigation
  navigateIfNeeded() {
    const stepDetails = this.stepDetails;
    if (stepDetails?.pathname) {
      this.navigateFunction?.(stepDetails.pathname, {
        state: { fromMenu: stepDetails.menuSection },
      });
    }
  }

  startTourIfNeeded(fullPath: string) {
    if (this.running || !this.requestState.load.isLoaded) return;

    const stepDetails = this._steps.find((step) => step.pathname === fullPath);
    if (!stepDetails) return;

    const subSectionSteps = this._steps.filter(
      (step) =>
        step.section === stepDetails.section &&
        step.subSection === stepDetails.subSection,
    );
    const notPlayed = subSectionSteps.every((step) => !step.data?.status);
    if (!notPlayed) return;

    const stepNumber = this.getFirstStepInSubSection(stepDetails.step)?.step;
    if (stepNumber) {
      setTimeout(() => {
        runInAction(() => {
          this.startTour(stepNumber, 'SubSection');
        });
      }, 1000);
    }
  }

  setStep(step: number) {
    this.prevStepNumber = this.currentStepNumber;
    this.currentStepNumber = step;
  }

  // Tour Management
  startTour(step: number = 0, mode: ProductTourMode, fromModal = false) {
    this.mode = mode;
    this.setStep(step);
    this.running = true;
    this.fromModal = fromModal;
    this.navigateIfNeeded();
  }

  endTour() {
    const hasOnlyOneStepLeft =
      this._steps.filter(
        (step) => step.section && step.data?.status !== 'Completed',
      ).length === 1;

    if (this.isLastActionableStep) {
      this.setCompletedIfNotCompleted();
    } else {
      this.setSkippedIfNotCompleted();
    }

    if (hasOnlyOneStepLeft) {
      this.setStep(TourSteps.LAST_STEP);
      return;
    }

    if (this.fromModal) {
      this.openModalFunction?.();
    }

    const returnTo =
      (this.currentStepNumber === TourSteps.REVISIT_LATER_STEP &&
        this.steps[this.prevStepNumber].returnTo) ||
      this.currentStep?.returnTo;

    if (returnTo) {
      this.navigateFunction?.(returnTo);
    }

    this.setStep(0);
    this.mode = 'Full';
    this.running = false;
    this.fromModal = false;
  }

  setCompletedIfNotCompleted() {
    if (this.currentStep?.data?.status !== 'Completed') {
      this.updateStep(this.currentStepNumber, 'Completed');
    }
  }

  setSkippedIfNotCompleted() {
    if (this.currentStep?.data?.status !== 'Completed') {
      this.updateStep(this.currentStepNumber, 'Skipped');
    }
  }

  async resetTour() {
    await this.resetProgress();
    window.location.reload();
  }

  nextStep() {
    this.setCompletedIfNotCompleted();

    this.setStep(this.currentStepNumber + 1);
    this.navigateIfNeeded();
  }

  prevStep() {
    this.setStep(Math.max(1, this.currentStepNumber - 1));
    this.navigateIfNeeded();
  }

  skipStep() {
    this.setSkippedIfNotCompleted();

    const nextStep =
      this._steps.find(
        (step) =>
          step.step > this.currentStepNumber &&
          ((this.mode === 'Section' &&
            step.section === this.currentStep?.section &&
            step.subSection !== this.currentStep.subSection) ||
            this.mode === 'Full'),
      )?.step ?? TourSteps.REVISIT_LATER_STEP;

    this.setStep(nextStep);

    this.navigateIfNeeded();
  }

  get isFirstRun() {
    return this.steps.every((step) => !step.data);
  }

  showLastStep() {
    this.setSkippedIfNotCompleted();
    this.setStep(TourSteps.REVISIT_LATER_STEP);
  }

  // Progress Management
  updateStep(step: number, status: TourStatus) {
    const stepDetails = this._steps.find((s) => s.step === step);

    if (stepDetails) {
      stepDetails.data = stepDetails.data
        ? { ...stepDetails.data, status }
        : { status };

      if (stepDetails.section) {
        this.updateProgress(stepDetails.section, step, status);
      }
    }
  }

  applyLoadedProgress(stepsData: ProductTourResponse) {
    const stepsDataMap = mapValues(
      groupBy(stepsData, 'step'),
      (steps) => steps[0],
    );
    this._steps = this._steps
      .map((step) => ({
        ...step,
        data: stepsDataMap[step.step],
      }))
      .filter(
        (step) =>
          step.permissions === null ||
          step.permissions.length === 0 ||
          hasPermission({ permission: step.permissions }),
      );
  }

  getSectionsStatuses(currentSections?: boolean): TourSectionWithHierarchy[] {
    const groupedBySection = groupBy(
      this._steps.filter(
        (step) =>
          step.section &&
          step.subSection &&
          (currentSections
            ? this.mode === 'Full' ||
              (this.mode === 'Section' &&
                step.section === this.currentStep?.section) ||
              (this.mode === 'SubSection' &&
                step.section === this.currentStep?.section &&
                step.subSection === this.currentStep?.subSection)
            : true),
      ),
      'section',
    );

    const result = Object.entries(groupedBySection).map(
      ([section, subSteps]) => {
        const items = Object.entries(groupBy(subSteps, 'subSection')).map(
          ([subSection, steps]) => ({
            isActive: steps.some(
              (step) => step.step === this.currentStepNumber,
            ),
            title: subSection,
            steps,
            step: Math.min(...steps.map((step) => step.step)),
            status: this._computeStatus(steps.map((step) => step.data?.status)),
          }),
        );
        return {
          isActive: items.some((item) => item.isActive),
          title: section,
          items,
          step: Math.min(...items.map((item) => item.step)),
          status: this._computeStatus(items.map((item) => item.status)),
        };
      },
    );

    return result;
  }

  private _computeStatus(statuses: (TourStatus | undefined)[]): TourStatus {
    if (statuses.every((status) => status === 'Completed')) return 'Completed';
    if (
      statuses.some(
        (status) => status === 'In Progress' || status === 'Completed',
      )
    ) {
      return 'In Progress';
    }
    return 'Not Started';
  }

  // API Calls
  resetProgress = flow(function* (this: ProductTourStore) {
    this._setRequestState('reset', true, null);
    try {
      yield resetProductTourProgress();
    } catch {
      this.error = 'Failed to reset product tour progress';
    } finally {
      this.isLoading = false;
    }
  });

  updateProgress = flow(function* (
    this: ProductTourStore,
    section: TourSection,
    step: number,
    status: TourStatus,
  ) {
    const stepData = this._steps.find((s) => s.step === step)?.data;

    if (stepData) {
      this._setRequestState('save', true, null);
      try {
        yield upsertProductTourProgress({ ...stepData, section, step, status });
      } catch {
        this.error = 'Failed to update product tour progress';
      } finally {
        this.isLoading = false;
      }
    }
  });

  loadProgress = flow(function* (this: ProductTourStore) {
    this._setRequestState('load', true, null, false);
    try {
      const response: ProductTourResponse = yield getProductTourProgress();
      this.applyLoadedProgress(response);
      this._setRequestState('load', false, null, true);
    } catch {
      this.error = 'Failed to load product tour progress';
      this._setRequestState('load', false, this.error, false);
    } finally {
      this.isLoading = false;
    }
  });

  private _setRequestState(
    action: keyof typeof this.requestState,
    isLoading: boolean,
    error: string | null,
    isLoaded?: boolean,
  ) {
    this.requestState[action].isLoading = isLoading;
    this.requestState[action].error = error;
    if (isLoaded !== undefined) {
      this.requestState[action].isLoaded = isLoaded;
    }
  }
}

// Context
const ProductTourContext = createContext<ProductTourStore | null>(null);

export const ProductTourContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const store = useMemo(() => new ProductTourStore(), []);
  return (
    <ProductTourContext.Provider value={store}>
      {children}
    </ProductTourContext.Provider>
  );
};

export const useProductTourContext = () => {
  const context = useContext(ProductTourContext);
  if (!context) {
    throw new Error(
      'useProductTourStore must be used within a ProductTourProvider',
    );
  }
  return context;
};
