import { clsx } from 'clsx';

export interface Tab {
  title: string;
  onClick: () => void;
  active: boolean;
  className?: string;
}

export interface TabsProps {
  variant?: 'underline-granite' | 'pill-accent-primary';
  tabs: Tab[];
  className?: string;
  size?: 'medium' | 'large';
  withBorder?: boolean;
}

export const Tabs = ({
  variant = 'underline-granite',
  tabs,
  className,
  size = 'large',
  withBorder = true,
}: TabsProps) => {
  return (
    <div className={clsx('flex', className)}>
      {tabs.map((t, i) => (
        <button
          key={i}
          className={clsx(
            'tab',
            variant,
            t.active && 'active',
            size,
            t?.className,
          )}
          onClick={t.onClick}
        >
          {t.title}
        </button>
      ))}
      {withBorder && (
        <div className="grow border-b-2 border-b-stroke-base-default" />
      )}
    </div>
  );
};
