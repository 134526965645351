import { GroupBase, OptionProps } from 'react-select';
import Checkbox from 'components/Checkbox/Checkbox';
import { OptionType } from './Select';
import clsx from 'clsx';

export const CustomOption = <
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  isSelected,
  isMulti,
  isFocused,
  innerProps,
  data,
  variant,
}: OptionProps<Option, IsMulti, Group> & { variant?: 'default' | 'blue' }) => {
  return (
    <div
      className={
        'flex flex-col items-start text-left duration-200 hover:scale-[1.01] hover:bg-background-base-surface-2 hover:transition-transform'
      }
      {...innerProps}
    >
      <div
        className={clsx(
          'flex w-full items-center p-2.5',
          data.subvalue && 'px-[15px] py-1.5',
        )}
      >
        {isMulti && <Checkbox label="" checked={isSelected} className="mr-2" />}
        <div className="flex-grow">
          <div className="text-[16px] font-[300] text-input-content-filled">
            {children}
          </div>
          {data.subvalue && (
            <div className="text-sm text-content-base-subdued">
              {data.subvalue}
            </div>
          )}
        </div>
        {!isMulti && isSelected && (
          <CheckMark
            className={clsx('ml-2', isFocused ? 'text-input-stroke-focus' : '')}
            variant={variant}
          />
        )}
      </div>
    </div>
  );
};

const CheckMark = ({
  className,
  variant,
}: {
  className?: string;
  variant?: 'default' | 'blue';
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('h-6 w-6', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9939 5.43533C20.3056 5.7081 20.3372 6.18192 20.0644 6.49364L9.56443 18.4936C9.42774 18.6499 9.23242 18.7424 9.02496 18.7494C8.8175 18.7563 8.61645 18.6769 8.46967 18.5301L3.96967 14.0301C3.67678 13.7372 3.67678 13.2623 3.96967 12.9694C4.26256 12.6765 4.73744 12.6765 5.03033 12.9694L8.96347 16.9026L18.9356 5.50589C19.2083 5.19416 19.6822 5.16257 19.9939 5.43533Z"
        fill={variant === 'blue' ? '#6C9DFF' : '#82F0FF'}
      />
    </svg>
  );
};
