import { FC, HTMLAttributes, ReactNode, useRef, useState } from 'react';
import { Repeat } from 'react-ionicons';
import { usePopper } from 'react-popper';
import { formatMobilityPrice } from '../utils';

interface PricingProps {
  mrc: Omit<CostsProps, 'type' | 'tooltipMessage'>;
  nrc: Omit<CostsProps, 'type' | 'tooltipMessage'>;
}

const Pricing: FC<PricingProps> = ({ mrc, nrc }) => {
  if (mrc.price === undefined && nrc.price === undefined) return null;
  return (
    <div className="flex gap-8">
      {mrc.price !== undefined && (
        <Costs {...mrc} type="MRC" tooltipMessage="Monthly recurring charge" />
      )}
      {nrc.price !== undefined && (
        <Costs {...nrc} type="NRC" tooltipMessage="Non-recurring charge" />
      )}
    </div>
  );
};

export default Pricing;

interface CostsProps {
  type: 'NRC' | 'MRC';
  pricingConfirmed: boolean;
  price?: number;
  tooltipMessage: string;
}

const Costs: FC<CostsProps> = ({
  price,
  pricingConfirmed,
  type,
  tooltipMessage,
}) => {
  return (
    <CostsTooltip message={tooltipMessage}>
      <div className="flex items-end gap-1">
        <div className="flex flex-col items-end">
          <p className="text-sm font-bold text-neutral-600">{type}</p>
          <p>
            {!pricingConfirmed && (
              <span className="mr-2 text-xs text-neutral-500">Starting at</span>
            )}
            <span className="text-xl font-bold text-white">
              ${formatMobilityPrice(price || 0)}
            </span>
          </p>
        </div>
        {type === 'MRC' && (
          <span className="mb-1.5 text-[#6C9DFF]">
            <Repeat width="16px" height="16px" color="currentColor" />
          </span>
        )}
      </div>
    </CostsTooltip>
  );
};

interface CostsTooltipProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  message: string;
}

const CostsTooltip: FC<CostsTooltipProps> = ({
  children,
  message,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const referenceRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    },
  );
  return (
    <div
      {...props}
      ref={referenceRef}
      onMouseEnter={() => {
        setVisible(true);
        forceUpdate?.();
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <div
        ref={popperRef}
        style={styles.popper}
        {...attributes.popper}
        className={`pointer-events-none z-[100] w-max transition-opacity duration-150 ${
          visible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="relative rounded bg-background-base-surface-0 p-5 text-sm shadow-elevation3">
          {message}
          <div className="absolute -top-1 right-1/3 scale-x-[0.84] transform">
            <div className="h-[17px] w-[17px] rotate-45 bg-background-base-surface-0" />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
