import { BasePagination, PaginableRequest } from 'api/common-schemas';
import {
  AccessProductGridType,
  AccessProductType,
  BaseResponseMobilityAPI,
  DeviceGroupType,
  DeviceType,
  MobilityProduct,
  MobilityProductTypes,
  ProductType,
  QuestionnaireType,
  QuestionsType,
  VariantsType,
} from './accessExpress.types';
import Ipad from 'assets/images/Ipad.svg';
import Dia from 'assets/images/dia_product.svg';
import Starlink from 'assets/images/starlink_product.svg';
import GraniteMns from 'assets/images/granite_mns.png';

import {
  BriefcaseOutline,
  BuildOutline,
  ReceiptOutline,
  SpeedometerOutline,
  TimeOutline,
  WarningOutline,
  WifiOutline,
} from 'react-ionicons';
import { apiClient } from 'api/apiClient';
import { ProductOfferingType } from 'screens/SelectServices/schemas';
import { OrderResponse } from 'api/accessexpressv3/schemas/OrdersSchemas';

export const questionnairesStepOne = [
  {
    type: 'access_services',
    title: 'Access services',
    body: 'Broadband, DIA, GRID',
    tags: ['Access'],
    disabled: true,
  },
  {
    type: 'mobility_services',
    title: 'Mobility services',
    body: 'Wireless routers, smart phones, tablets',
    tags: ['Mobility'],
    disabled: false,
  },
  {
    type: 'voice_services',
    title: 'Voice services',
    body: 'EPOTS, Hosted PBX, POTS',
    tags: ['Voice'],
    disabled: true,
  },
  {
    type: 'not_sure',
    title: 'I’m not sure',
    body: 'Not sure? Let us show you the way',
    tags: [],
    disabled: true,
  },
];

export const questionnairesStepTwo: QuestionnaireType[] = [
  {
    type: 'fixed_connectivity',
    title: 'Fixed connectivity',
    body: 'Modems, wireless IoT',
    disabled: true,
  },
  {
    type: 'personal_connectivity',
    title: 'Personal connectivity',
    body: 'SIMs, smart phones, tablets, hotspots, and more ',
    disabled: false,
  },
  {
    type: 'accessories',
    title: 'Accessories',
    body: 'Adapters, cases, screen protectors, antennas, batteries, and more',
    disabled: true,
  },
  {
    type: 'sims',
    title: 'SIMs',
    body: 'SIM cards for voice and data connectivity',
    disabled: true,
  },
];

export const getQuestions = async (
  services: string[],
): Promise<QuestionsType> => {
  return new Promise((resolve) => {
    console.log(services);
    resolve({
      title: 'What type of device are you looking for?',
      questions: questionnairesStepTwo,
    });
  });
};

const getSubgroupByType = (type?: string): string => {
  if (!type) return 'DEVICE';
  return (
    Object.keys(DeviceGroupType).find((group) =>
      DeviceGroupType[group as keyof typeof DeviceGroupType].includes(
        decodeURIComponent(type) as DeviceType,
      ),
    ) ?? 'DEVICE'
  );
};

export const getMobilityProducts = async (args?: {
  type: string;
}): Promise<BaseResponseMobilityAPI<ProductType[]>> => {
  const subgroup = getSubgroupByType(args?.type);

  const response = await apiClient.get<
    BaseResponseMobilityAPI<MobilityProduct[]>
  >(
    `/api/v1/marketplace/products/all?subgroup=${subgroup}${
      args?.type ? `&type=${args.type}` : ''
    }`,
  );

  return {
    ...response.data,
    data: response.data.data.map((product) => ({
      id: product.graniteId,
      title: product.graniteId,
      img: product.images[0]?.url ?? null,
      manufacturer: product.graniteId.trim().split(/\s+/)[0],
      stock: product.productQty,
      content: product.description,
      createdDate: product.createdDate,
      productDescType: product.productDesc,
    })),
  };
};

export const getProduct = async (args: {
  id: string;
}): Promise<ProductType> => {
  const { id } = args;

  const response = await apiClient.get<
    BaseResponseMobilityAPI<MobilityProduct>
  >(`/api/v1/marketplace/products/detail?alias=${encodeURIComponent(id)}`);
  const { mrc, nrc } = findLowestPricesForMobilityProduct(
    response.data.data.variants,
  );
  return {
    id: response.data.data.graniteId,
    title: response.data.data.graniteId,
    img: response.data.data.images[0]?.url ?? null,
    manufacturer: response.data.data.graniteId.trim().split(/\s+/)[0],
    stock: response.data.data.productQty,
    content: response.data.data.description,
    createdDate: response.data.data.createdDate,
    varients: response.data.data.variants,
    productId: response.data.data.productId,
    productDescType: response.data.data.productDesc,
    mrc: mrc ?? undefined,
    nrc: nrc ?? undefined,
  };
};

export const getAccessProduct = async (args: {
  id: string;
}): Promise<AccessProductType> => {
  const { id } = args;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const product = dummyAccessProducts.find((p) => p.id === id);
      if (product) {
        resolve(product);
      } else {
        reject(new Error(`Product with id ${id} not found`));
      }
    }, 5000);
  });
};

export const reviewOrderDummyData = {
  site: '12419 Shelbyville Rd, Middletown, KY, 40243',
  productType: 'Mobility',
  products: [
    {
      id: 1,
      manufacturer: 'Apple',
      title: `Apple iPad Mini 128GB Purple (7th Gen)`,
      from_price: 649,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
    {
      id: 2,
      manufacturer: 'Apple',
      title: `iPad Mini`,
      from_price: 649.0,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
    {
      id: 3,
      manufacturer: 'Apple',
      title: `iPad Mini`,
      from_price: 649.0,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
    {
      id: 4,
      manufacturer: 'Apple',
      title: `iPad Mini`,
      from_price: 649.0,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
    {
      id: 5,
      manufacturer: 'Apple',
      title: `iPad Mini`,
      from_price: 649,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
    {
      id: 6,
      manufacturer: 'Apple',
      title: `iPad Mini`,
      from_price: 649.0,
      monthly_price: 41.58,
      months: '3',
      img: Ipad,
    },
  ],
  locations: [
    '123 Main Street, Bridgewater, MA 02324',
    '105 Jerry Dove Drive, Florence, SC 29501',
    '184 Griffin Street, Gilbert, AZ 85233',
    '1341 Poplar Street, Chicago, IL 60606',
    '612 Shadowmar Drive, New Orleans, LA 70115',
  ],
  services: ['Broadband', 'DIA', 'Mobility'],
  nrc: [
    {
      name: 'Activation fee',
      price: 100,
    },
    {
      name: 'Equipment',
      price: 100,
    },
    {
      name: 'Shipping',
      price: 100,
    },
  ],
  rc: [
    {
      name: 'Broadband connection charge',
      price: 100,
    },
    {
      name: 'DIA connection charge',
      price: 100,
    },
    {
      name: 'Router',
      price: 100,
    },
    {
      name: 'IP block',
      price: 100,
    },
  ],
};

export const accessReviewOrderDummyData = {
  services: ['Broadband', 'DIA'],
  nrc: [
    {
      name: 'Activation fee',
      price: 100,
    },
    {
      name: 'Equipment',
      price: 100,
    },
    {
      name: 'Shipping',
      price: 100,
    },
  ],
  rc: [
    {
      name: 'Broadband connection charge',
      price: 100,
    },
    {
      name: 'DIA connection charge',
      price: 100,
    },
    {
      name: 'Router',
      price: 100,
    },
    {
      name: 'IP block',
      price: 100,
    },
  ],
};

const dummyAccessProducts: AccessProductType[] = [
  {
    id: '1',
    type: 'broadband',
    title: 'Broadband',
    content:
      'Broadband services provide high-speed internet access, enabling connectivity to the internet for activities like browsing, streaming, downloading, and more. Order fiber or cable to deliver fast and reliable internet connectivity to your business.',
    offerings: [
      {
        tag: 'Best offer',
        price: '$1300',
        carrier: 'Spectrum',
        construction: true,
        details: [
          [
            { icon: BriefcaseOutline, text: 'Spectrum' },
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [
            { icon: TimeOutline, text: '28 day install avg' },
            {
              id: 'construction',
              icon: WarningOutline,
              text: '$25,000 est. construction',
            },
          ],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
      {
        tag: 'Premium choice',
        price: '$1300',
        carrier: 'Altice',
        details: [
          [
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [{ icon: TimeOutline, text: '28 day install avg' }],
          [{ icon: BuildOutline, text: '$0 est. construction' }],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
      {
        tag: 'Lowest price',
        price: '$1300',
        carrier: 'Comcast',
        details: [
          [
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [{ icon: TimeOutline, text: '28 day install avg' }],
          [{ icon: BuildOutline, text: '$0 est. construction' }],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
    ],
  },
  {
    id: '2',
    type: 'broadband',
    title: 'Broadband',
    content:
      'Broadband services provide high-speed internet access, enabling connectivity to the internet for activities like browsing, streaming, downloading, and more. Order fiber or cable to deliver fast and reliable internet connectivity to your business.',
    offerings: [
      {
        tag: 'Best offer',
        price: '$1300',
        carrier: 'Spectrum',
        details: [
          [
            { icon: BriefcaseOutline, text: 'Spectrum' },
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [
            { icon: TimeOutline, text: '28 day install avg' },
            { icon: BuildOutline, text: '$0 est. construction' },
          ],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
      {
        tag: 'Premium choice',
        price: '$1300',
        carrier: 'Altice',
        details: [
          [
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [{ icon: TimeOutline, text: '28 day install avg' }],
          [{ icon: BuildOutline, text: '$0 est. construction' }],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
    ],
  },
  {
    id: '3',
    type: 'broadband',
    title: 'Broadband',
    content:
      'Broadband services provide high-speed internet access, enabling connectivity to the internet for activities like browsing, streaming, downloading, and more. Order fiber or cable to deliver fast and reliable internet connectivity to your business.',
    offerings: [
      {
        tag: 'Best offer',
        price: '$1300',
        carrier: 'Spectrum',
        details: [
          [
            { icon: BriefcaseOutline, text: 'Spectrum' },
            { icon: SpeedometerOutline, text: '100/20 Mb/s' },
            { icon: ReceiptOutline, text: '3 years' },
          ],
          [
            { icon: TimeOutline, text: '28 day install avg' },
            { icon: BuildOutline, text: '$0 est. construction' },
          ],
          [{ icon: WifiOutline, text: 'On-net connectivity' }],
        ],
      },
    ],
  },
  {
    id: '4',
    type: 'broadband',
    title: 'Broadband',
    content:
      'Broadband services provide high-speed internet access, enabling connectivity to the internet for activities like browsing, streaming, downloading, and more. Order fiber or cable to deliver fast and reliable internet connectivity to your business.',
    offerings: [],
  },
];

export const dummyOfferSettings = {
  address: '9400 Dunkirk Ln N, Maple Grove, MN 55311-1220',
  id: 125,
  type: 'broadband',
  offerings: [
    {
      type: 'broadband',
      bandwidth_download: '1.0 GBPS',
      bandwidth_upload: '1.0 GBPS',
      formal_broadband_pricing: {
        circuit: 230.99,
        id: 'a6e219fe-2478-4c6e-8372-3dc94f25ca46',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 248.35000000000002,
      },
      id: 'a6e219fe-2478-4c6e-8372-3dc94f25ca46',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
    {
      type: 'broadband',
      bandwidth_download: '2.0 GBPS',
      bandwidth_upload: '2.0 GBPS',
      formal_broadband_pricing: {
        circuit: 294.99,
        id: '04fec15b-cfd3-44ef-96fa-24670dfdea3c',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 312.35,
      },
      id: '04fec15b-cfd3-44ef-96fa-24670dfdea3c',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
    {
      type: 'broadband',
      bandwidth_download: '600 MBPS',
      bandwidth_upload: '600 MBPS',
      formal_broadband_pricing: {
        circuit: 160.99,
        id: '1b6f448b-82ad-462e-a423-8519d0e77584',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 178.35000000000002,
      },
      id: '1b6f448b-82ad-462e-a423-8519d0e77584',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
    {
      type: 'broadband',
      bandwidth_download: '300 MBPS',
      bandwidth_upload: '300 MBPS',
      formal_broadband_pricing: {
        circuit: 98.99,
        id: '29ec18c7-1b3b-4013-82f2-edf43c70322d',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 116.35,
      },
      id: '29ec18c7-1b3b-4013-82f2-edf43c70322d',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
    {
      type: 'broadband',
      bandwidth_download: '250 MBPS',
      bandwidth_upload: '250 MBPS',
      formal_broadband_pricing: {
        circuit: 93.99,
        id: '8c3a852d-3333-49f9-a01e-70c550e09a54',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 111.35,
      },
      id: '8c3a852d-3333-49f9-a01e-70c550e09a54',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
    {
      type: 'broadband',
      bandwidth_download: '100 MBPS',
      bandwidth_upload: '100 MBPS',
      formal_broadband_pricing: {
        circuit: 90.99,
        id: '05ccba3e-2334-4230-852e-21926f6e93fb',
        internet_access_recovery: 4.37,
        ip: 0,
        modem: 12.99,
        service_activation: 0,
        total_mrc: 108.35,
      },
      id: '05ccba3e-2334-4230-852e-21926f6e93fb',
      ip_type: 'DYNAMIC',
      mns_enhanced: false,
      mns_essential: false,
      ip_block: '/30',
      ordered: false,
      priority: 0,
      product_type: 'Broadband',
      provider: 'Consolidated',
      qty: 1,
      term: '3 YR',
    },
  ] as unknown as ProductOfferingType,
};

const AccessImages = {
  Broadband: Dia,
  DIA: Dia,
  'Granite MNS': GraniteMns,
  Starlink: Starlink,
};

export const getAccessProductsGrid = async (
  args: PaginableRequest & { search?: string; sort_order?: 'asc' | 'desc' },
): Promise<BasePagination<ProductType>> => {
  const { page = 1, page_size = 12, search, sort_order } = args;

  const response = await apiClient.get<{
    data: AccessProductGridType[];
    page: number;
    page_size: number;
    total: number;
  }>(
    `/api/v1/access?page=${page}&page_size=${page_size}${
      search ? `&search=${search}` : ''
    }${sort_order ? `&sort_field=name&sort_order=${sort_order}` : ''}`,
  );

  const res = {
    data: response.data.data.map((product) => ({
      id: `${product.id}`,
      title: product.name,
      fullPriceInfo: product.price_info,
      img: AccessImages[product.category],
      category: product.category,
    })),
    metadata: {
      page: response.data.page,
      page_size: response.data.page_size,
      total_items: response.data.total,
      total_pages: Math.ceil(response.data.total / response.data.page_size),
    },
  };
  return res;
};

// export const getAccessProductsGrid = async (
//   args: PaginableRequest,
// ): Promise<BasePagination<ProductType>> => {
//   const { page = 1, page_size = 12 } = args;

//   const total_items = dummyProductsAccessGrid.length;
//   const total_pages = Math.ceil(total_items / page_size);

//   // Calculate start and end indexes
//   const startIndex = (page - 1) * page_size;
//   const endIndex = startIndex + page_size;

//   // Slice the array to get the correct page of products
//   const paginatedData = dummyProductsAccessGrid.slice(startIndex, endIndex);

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         data: paginatedData,
//         metadata: {
//           page,
//           page_size,
//           total_items,
//           total_pages,
//         },
//       });
//     }, 1000);
//   });
// };

// const dummyProductsAccessGrid: ProductType[] = [
//   {
//     id: '1',
//     title: `Broadband`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '2',
//     title: `DIA`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '3',
//     title: `MNS Essentials`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '4',
//     title: `MNS Enhanced`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '1',
//     title: `Broadband`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '2',
//     title: `DIA`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '3',
//     title: `MNS Essentials`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '4',
//     title: `MNS Enhanced`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '1',
//     title: `Broadband`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '2',
//     title: `DIA`,
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Dia,
//   },
//   {
//     id: '3',
//     title: `MNS Essentials`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '4',
//     title: `MNS Enhanced`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
//   {
//     id: '5',
//     title: `MNS Custom`,
//     manufacturer: 'Granite',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: GraniteMns,
//   },
//   {
//     id: '6',
//     title: `Starlink Bundle`,
//     manufacturer: 'Starlink',
//     from_price: '649',
//     monthly_price: '41.58',
//     months: '36',
//     img: Starlink,
//   },
// ];

export const getMobilityProductTypes = async (): Promise<
  BaseResponseMobilityAPI<MobilityProductTypes>
> => {
  const response = await apiClient.get('/api/v1/marketplace/products/types');
  return response.data;
};

export const formatMobilityPrice = (price?: number) =>
  price !== undefined ? price.toFixed(2) : '0.00';

const findLowestPricesForMobilityProduct = (variants: VariantsType[]) => {
  return variants.reduce(
    (acc, variant) => {
      variant.children.forEach((child) => {
        if (child.productGroupDesc === 'PURCHASED') {
          acc.nrc =
            acc.nrc !== null
              ? Math.min(acc.nrc, child.originalPrice)
              : child.originalPrice;
        } else if (child.productGroupDesc === 'FINANCED') {
          acc.mrc =
            acc.mrc !== null
              ? Math.min(acc.mrc, child.originalPrice)
              : child.originalPrice;
        }
      });
      return acc;
    },
    { nrc: null as number | null, mrc: null as number | null },
  );
};

export const checkoutDummyAddresses = [
  {
    account_name: '04683713',
    address_line_1: '12419 Shelbyville Rd',
    address_line_2: '12',
    city: 'Middletown',
    id: 73585,
    macnum: '04683713',
    name: '04683713 - 40132',
    parent_macnum: '03479292',
    parent_name: 'Valvoline',
    site_name: '40132',
    state: 'KY',
    zip: '40243',
    cw_site_id: '001233689',
  },
  {
    account_name: '04683717',
    address_line_1: '1861 Gardner Lane NW',
    address_line_2: null,
    city: 'Corydon',
    id: 73588,
    macnum: '04683717',
    name: '04683717 - 40136',
    parent_macnum: '03479292',
    parent_name: 'Valvoline',
    site_name: '40136',
    state: 'IN',
    zip: '47112',
    cw_site_id: '001233688',
  },
  {
    account_name: '04683730',
    address_line_1: '2976 S Church Street',
    address_line_2: null,
    city: 'Murfreesboro',
    id: 73603,
    macnum: '04683730',
    name: '04683730 - 40157',
    parent_macnum: '03479292',
    parent_name: 'Valvoline',
    site_name: '40157',
    state: 'TN',
    zip: '37127',
    cw_site_id: '001233687',
  },
  {
    account_name: '04683731',
    address_line_1: '7065 E US Hwy 36',
    address_line_2: null,
    city: 'Avon',
    id: 73604,
    macnum: '04683731',
    name: '04683731 - 40158',
    parent_macnum: '03479292',
    parent_name: 'Valvoline',
    site_name: '40158',
    state: 'IN',
    zip: '46123',
    cw_site_id: '001233686',
  },
  {
    account_name: '04683752',
    address_line_1: '10229 Watson Road',
    address_line_2: null,
    city: 'Sappington',
    id: 73624,
    macnum: '04683752',
    name: '04683752 - 50009',
    parent_macnum: '03479292',
    parent_name: 'Valvoline',
    site_name: '50009',
    state: 'MO',
    zip: '63127',
    cw_site_id: null,
  },
];

export const checkoutOrderItems = [
  {
    type: 'Access',
    subtype: 'Broadband',
    pim_price_id: 'PIM1234',
    quantity: 2,
    location_id: 'loc-001',
    item_details: {
      detail1: 'value1',
      detail2: 'value2',
    },
    price: 49.99,
  },
  {
    type: 'Mobility',
    subtype: 'Phone',
    pim_price_id: 'PIM5678',
    quantity: 1,
    location_id: 'loc-001',
    item_details: {
      model: 'XYZ',
      color: 'black',
    },
    price: 99.99,
  },
  {
    type: 'Sims',
    subtype: 'Standard',
    pim_price_id: 'PIM9012',
    quantity: 1,
    location_id: 'loc-001',
    item_details: {
      carrier: 'ABC',
    },
    price: 19.99,
  },
];

export const getDistinctAccessOrderedServices = (data?: OrderResponse) => {
  if (!data) return '';
  const orderedProducts = new Set();

  data.locations?.forEach((location) => {
    location.order_items.forEach((item) => {
      if (['Broadband', 'DIA'].includes(item.subtype))
        orderedProducts.add(item.subtype);
    });
  });

  return Array.from(orderedProducts).join(', ');
};
