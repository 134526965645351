import { useState, useRef, ReactNode } from 'react';
import { motion } from 'framer-motion';

interface AccordionProps {
  title: (isOpen: boolean) => ReactNode;
  children: ReactNode;
}

const Accordion = ({ title, children }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex flex-col">
      <div
        className="flex w-full cursor-pointer items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title(isOpen)}
      </div>

      <motion.div
        initial={{ height: 0, opacity: 0, marginTop: 0 }}
        animate={
          isOpen
            ? { height: 'auto', opacity: 1, marginTop: '10px' }
            : { height: 0, opacity: 0, marginTop: 0 }
        }
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="overflow-hidden"
      >
        <div
          ref={contentRef}
          className="flex flex-col gap-2 rounded bg-gradient-to-b from-[rgba(71,71,104,0.32)] to-[rgba(51,51,80,0.32)] p-2"
        >
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default Accordion;
