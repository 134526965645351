import clsx from 'clsx';
import { ConstructionTooltip } from '../ConstructionBanner/ConstructionTooltip';
import { useContructionTooltip } from '../ConstructionBanner/useConstructionTooltip';

const DetailsItem = ({
  icon: Icon,
  text,
  showConstructionWarning,
}: {
  icon: React.ElementType;
  text: string;
  showConstructionWarning?: boolean;
}) => {
  const { triggerEltRef, isVisible, handleEnter, handleLeave } =
    useContructionTooltip();

  const iconColor = showConstructionWarning ? '#F99247' : '#94A3B8';
  const textClassnames = showConstructionWarning
    ? 'text-status-warning-default'
    : 'text-content-base-subdued';

  return (
    <>
      <p
        ref={triggerEltRef}
        onMouseEnter={showConstructionWarning ? handleEnter : undefined}
        onMouseLeave={showConstructionWarning ? handleLeave : undefined}
        className="m-0 flex h-8 items-center gap-1 whitespace-nowrap p-0 text-sm"
      >
        <Icon color={iconColor} width="16px" height="16px" />
        <span className={clsx('font-bold', textClassnames)}>{text}</span>
      </p>
      {showConstructionWarning && (
        <ConstructionTooltip
          triggerEltRef={triggerEltRef}
          isVisible={isVisible}
        />
      )}
    </>
  );
};

export default DetailsItem;
