import clsx from 'clsx';
import GradientBorder from './GradientBorder';
import DeviceAnimation, { DeviceAnimationProps } from './DeviceAnimation';
import { FC, useEffect, useState } from 'react';

const BorderBeam: FC<DeviceAnimationProps> = ({ tierTwo }) => {
  const [animationState, setAnimationState] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });

  useEffect(() => {
    const triggerAnimations = () => {
      setAnimationState((prev) => ({ ...prev, top: true }));
      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, top: false })),
        1600,
      );

      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, right: true })),
        1200,
      );
      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, right: false })),
        3200,
      );

      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, bottom: true })),
        2470,
      );
      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, bottom: false })),
        4800,
      );

      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, left: true })),
        3650,
      );
      setTimeout(
        () => setAnimationState((prev) => ({ ...prev, left: false })),
        5400,
      );
    };

    triggerAnimations();

    const interval = setInterval(() => triggerAnimations(), 4820);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="-m-2">
      <div className="relative flex h-[588px] w-full items-center justify-center">
        <div
          className="flex h-[calc(100%-16px)] w-[calc(100%-16px)] items-center justify-center"
          style={{
            background: `linear-gradient(106.98deg, rgba(126, 119, 179, 0.04) 1.18%, rgba(137, 137, 255, 0.04) 24.7%, rgba(108, 157, 255, 0.04) 48.12%, rgba(120, 207, 218, 0.04) 71.74%, rgba(159, 244, 169, 0.04) 95.26%)`,
          }}
        >
          <DeviceAnimation tierTwo={tierTwo} />
        </div>
        {/* Top */}
        <div className="absolute left-2 right-2 top-0 flex h-4 items-center overflow-hidden">
          <div className="h-px w-full bg-stroke-base-subdued" />
          <div
            className={`${
              animationState.top
                ? 'translate-x-[calc(100%+10px)]'
                : '-translate-x-[206px]'
            } absolute left-0 right-0 top-1/2 transform-gpu transition-all duration-[1500ms] ease-linear`}
          >
            <GradientBorder
              className={clsx('', {
                ' h-0.5 w-[201px]': animationState.top,
              })}
              direction="90deg"
            />
          </div>
        </div>

        {/* Right */}
        <div className="absolute bottom-2 right-0 top-2 flex w-4 justify-center overflow-hidden">
          <div className="h-full w-px bg-stroke-base-subdued" />
          <div
            className={`${
              animationState.right
                ? 'translate-y-[calc(100%+10px)]'
                : '-translate-y-[calc(100%-290px)]'
            } absolute bottom-0 top-0 transform-gpu transition-all duration-[1500ms] ease-linear `}
          >
            <GradientBorder
              className={clsx('', {
                ' h-[201px] w-0.5': animationState.right,
              })}
              direction="-180deg"
            />
          </div>
        </div>

        {/* Bottom */}
        <div className="absolute bottom-0 left-2 right-2 flex h-4 items-center overflow-hidden">
          <div className="h-px w-full bg-stroke-base-subdued" />
          <div
            className={`${
              animationState.bottom
                ? 'translate-x-[-211px]'
                : 'translate-x-[calc(100%+10px)]'
            } absolute left-0 right-0 top-1/2 transform-gpu transition-all duration-[1500ms] ease-linear`}
          >
            <GradientBorder
              className={clsx('', {
                ' h-0.5 w-[201px]': animationState.bottom,
              })}
              direction="-90deg"
            />
          </div>
        </div>

        {/* Left */}
        <div className="absolute bottom-2 left-0 top-2 flex w-4 justify-center overflow-hidden">
          <div className="h-full w-px bg-stroke-base-subdued" />
          <div
            className={`${
              animationState.left
                ? 'translate-y-[-211px]'
                : 'translate-y-[calc(100%+10px)]'
            } absolute bottom-0 left-1/2 top-0 h-full transform-gpu transition-all duration-[1500ms] ease-linear`}
          >
            <GradientBorder
              className={clsx('', {
                ' h-[201px] w-0.5': animationState.left,
              })}
              direction="0deg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BorderBeam;
