import {
  useRef,
  useState,
  useCallback,
  FC,
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ProductDescType } from 'screens/AccessExpressV3/accessExpress.types';

type AttributeType = {
  id: string;
  element: ReactNode;
};

interface AttributesProps {
  productDesc: ProductDescType;
  attributes: AttributeType[];
  onAddToCart: () => void;
}

const Attributes: FC<AttributesProps> = ({ attributes, onAddToCart }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selected, setSelected] = useState<string[]>([attributes[0]?.id]);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, attributes.length);
  }, [attributes]);

  const handleStep = useCallback(
    (step: number, config: { scrollTo?: boolean } = { scrollTo: true }) => {
      if (step >= 0 && step < attributes.length) {
        if (attributes[step + 1]?.id) {
          setSelected((prev) => {
            if (!prev.includes(attributes[step + 1].id)) {
              setCurrentStep(step);
              if (config.scrollTo) {
                slowScrollTo(sectionRefs.current[step + 1], 600);
              }
              return [...prev, attributes[step + 1].id];
            }
            return prev;
          });
        }
      }
    },
    [attributes],
  );

  const handleNext = useCallback(() => {
    handleStep(currentStep + 1);
  }, [currentStep, handleStep]);

  const handlePrev = useCallback(() => {
    handleStep(currentStep - 1);
  }, [currentStep, handleStep]);

  const slowScrollTo = (target: HTMLElement | null, duration: number = 800) => {
    if (!target) return;

    // Find the closest scrollable parent
    const scrollableParent = target.closest('.overflow-y-auto') as HTMLElement;
    if (!scrollableParent) return;

    const startY = scrollableParent.scrollTop;
    const targetY = target.offsetTop; // Scroll directly to the element's top
    const distance = targetY - startY;
    const startTime = performance.now();

    function scrollStep(timestamp: number) {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1); // Normalize to [0,1]
      const easeOutQuad = progress * (2 - progress); // Easing function

      scrollableParent.scrollTop = startY + distance * easeOutQuad;

      if (elapsed < duration) {
        requestAnimationFrame(scrollStep);
      }
    }

    requestAnimationFrame(scrollStep);
  };

  return (
    <div className="relative w-full rounded bg-transparent p-[1px] lg:w-[330px]">
      <div
        className="pointer-events-none absolute inset-0"
        style={{
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: 'inherit',
          padding: '1px',
          background:
            'linear-gradient(138.31deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 99.4%)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          maskComposite: 'exclude',
          WebkitMaskComposite: 'xor',
        }}
      />
      <div
        style={{
          background:
            'linear-gradient(180deg, rgba(13, 18, 25, 0) 63.81%, #0D1219 124.81%)',
        }}
        className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full rounded shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
      />
      <div
        className="relative flex h-[calc(100vh-206px)]  w-full self-start rounded   lg:h-[calc(100vh-270px)] xl:h-[calc(100vh-206px)]"
        style={{
          background:
            'radial-gradient(100% 359.18% at 0% 0%, rgba(184, 184, 184, 0.096) 0%, rgba(87, 87, 87, 0.032) 100%)',
        }}
      >
        <div className="flex h-full w-full flex-col py-8 ">
          <div className="flex gap-1 px-6">
            {attributes.map((step) => (
              <div
                key={step.id}
                className={clsx(
                  'h-1.5 w-full rounded-sm',
                  selected.includes(step.id)
                    ? 'bg-accent-primary'
                    : 'bg-neutral-700',
                )}
              />
            ))}
          </div>
          <div className="relative flex flex-grow flex-col gap-9 overflow-hidden overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-stroke-base-subdued">
            {attributes.map((att, index) => (
              <div
                key={att.id}
                ref={(el) => (sectionRefs.current[index] = el)}
                className={clsx(
                  'w-full flex-shrink-0 pt-14 ',
                  selected.includes(att.id)
                    ? 'relative z-10'
                    : 'pointer-events-none',
                  index === attributes.length - 1
                    ? 'h-full '
                    : 'xl:min-h-[calc(100%-166px)]',
                )}
              >
                {cloneElement(
                  isValidElement(att.element) ? (
                    att.element
                  ) : (
                    <>{att.element}</>
                  ),
                  { handleNext, handlePrev, handleStep, step: index },
                )}
                {index === attributes.length - 1 && (
                  <div className="px-6">
                    <GraniteButton
                      onClick={onAddToCart}
                      variant="tertiary"
                      className="mt-8 w-full"
                    >
                      Add to Cart
                    </GraniteButton>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attributes;
