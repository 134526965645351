import clsx from 'clsx';
import Loader from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const ConfirmationModal = ({
  isOpen,
  close,
  question,
  description,
  confirmLabel,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  close: () => void;
  question: string;
  description: string;
  confirmLabel: string;
  onConfirm: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="max-w-[700px]">
      <div className="flex w-full flex-col items-start justify-start rounded-[8px] bg-background-base-surface-2 p-8">
        <div className="mb-8 flex flex-col items-start justify-start gap-2">
          <h1 className="text-2xl font-bold text-content-base-default">
            {question}
          </h1>
          <p className="text-base font-bold text-content-base-subdued">
            {description}
          </p>
        </div>
        <div className="flex items-start justify-start gap-4">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="destructive"
            onClick={onConfirm}
            className={clsx(isLoading && 'loading-button')}
          >
            {confirmLabel}
            {isLoading && <Loader animationClassname="!w-3 !h-3" />}
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
