import { MultiValue } from 'react-select';

import { GraniteSelect, OptionType } from 'components/Select/Select';

export const DIA_SPEED_OPTIONS: OptionType[] = [
  { value: '5M', label: '5 Mb/s' },
  { value: '10M', label: '10 Mb/s' },
  { value: '20M', label: '20 Mb/s' },
  { value: '50M', label: '50 Mb/s' },
  { value: '100M', label: '100 Mb/s' },
  { value: '200M', label: '200 Mb/s' },
  { value: '300M', label: '300 Mb/s' },
  { value: '400M', label: '400 Mb/s' },
  { value: '500M', label: '500 Mb/s' },
  { value: '600M', label: '600 Mb/s' },
  { value: '700M', label: '700 Mb/s' },
  { value: '800M', label: '800 Mb/s' },
  { value: '900M', label: '900 Mb/s' },
  { value: '1G', label: '1 Gb/s' },
];

interface SpeedSelectorProps {
  value: MultiValue<OptionType> | undefined;
  onChange: (speeds: MultiValue<OptionType>) => void;
}

const SpeedMultiSelect = ({ value, onChange }: SpeedSelectorProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="font-bold">Speed requirements</div>
        <div className="text-sm">Select which speeds you’re interested in</div>
      </div>
      <GraniteSelect
        options={DIA_SPEED_OPTIONS}
        onChange={onChange}
        isMulti
        value={value}
        variant="blue"
        placeholder="Select"
        menuPlacement="top"
      />
    </div>
  );
};

export default SpeedMultiSelect;
