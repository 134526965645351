import React from 'react';

type Status =
  | 'success'
  | 'informative'
  | 'danger'
  | 'caution'
  | 'neutral'
  | 'custom';
type Style = 'default' | 'subdued';
type ContentType =
  | { type: 'label'; label: string }
  | { type: 'label+icon'; label: string; icon: React.ReactNode };

type StandardTagProps = {
  status: Exclude<Status, 'custom'>;
  style: Style;
  content: ContentType;
  className?: never;
};

type CustomTagProps = {
  status: 'custom';
  className: string;
  content: ContentType;
  style?: never;
};

export type TagProps = StandardTagProps | CustomTagProps;

const colorSchemes = {
  success: {
    default: {
      background: 'bg-status-success-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-success-subdued',
      text: 'text-content-base-default',
    },
  },
  informative: {
    default: {
      background: 'bg-status-info-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-info-subdued',
      text: 'text-content-base-default',
    },
  },
  danger: {
    default: {
      background: 'bg-status-error-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-error-subdued',
      text: 'text-content-base-default',
    },
  },
  caution: {
    default: {
      background: 'bg-status-warning-default',
      text: 'text-content-flip-default',
    },
    subdued: {
      background: 'bg-status-warning-subdued',
      text: 'text-content-base-default',
    },
  },
  neutral: {
    default: {
      background: 'bg-background-base-surface-3',
      text: 'text-content-base-default',
    },
    subdued: {
      background: 'bg-blue-900',
      text: 'text-white',
    },
  },
};

const Tag: React.FC<TagProps> = ({ content, status, style, className }) => {
  const classNames =
    status === 'custom'
      ? className
      : `${colorSchemes[status][style].background} ${colorSchemes[status][style].text}`;

  return (
    <div
      className={`flex h-6 items-center justify-center rounded-[8px] px-2 py-2 text-xs font-bold ${classNames}`}
    >
      {content.type === 'label' ? (
        <span>{content.label}</span>
      ) : (
        <>
          <span className="mr-1">{content.icon}</span>
          <span>{content.label}</span>
        </>
      )}
    </div>
  );
};

export default Tag;
