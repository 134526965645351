import clsx from 'clsx';
import { motion } from 'framer-motion';
import { OfferSettings } from './OfferSettings';
import { dummyOfferSettings } from '../utils';
import Divider from 'components/Divider/Divider';
import { useSingleServiceManager } from 'context/SingleServiceManagerContext';

interface AccessCustomizableOptionsProps {
  expand: boolean;
}

const AccessCustomizableOptions = ({
  expand,
}: AccessCustomizableOptionsProps) => {
  const { pinnedService, cartState, isUnpinning } = useSingleServiceManager();
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={
        expand
          ? {
              opacity: 1,
              height: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }
          : { height: 0, opacity: 0, display: 'none' }
      }
      transition={{ duration: 0.1, ease: 'easeInOut' }}
      className="rounded rounded-t-none border border-t-0 border-[#47556980]"
    >
      <OfferSettings
        //@ts-expect-error err
        data={dummyOfferSettings}
        classname={clsx(cartState.unpinned && 'added-to-cart-service')}
      />
      <div className="!my-4 mx-4 hidden w-1 sm:block">
        <Divider layout="vertical" className="h-full" variant="dotted" />
      </div>
      {pinnedService ? (
        <div
          className={clsx(
            'relative flex w-1/2 flex-col rounded outline-none outline outline-1 transition-all',
            isUnpinning ? 'animate-poof' : 'animate-slide-in-left-to-right',
            cartState.pinned && 'added-to-cart-service',
          )}
        >
          <OfferSettings
            //@ts-expect-error err
            data={dummyOfferSettings}
            isPinnedSummary={true}
            classname="!w-full"
          />
        </div>
      ) : (
        <div className="w-0 sm:w-1/2" />
      )}
    </motion.div>
  );
};

export default AccessCustomizableOptions;
