import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import ServiceTag, { ServiceTagProps } from './ServiceTag';
import clsx from 'clsx';
import GraniteLogo from './GraniteLogo';
import { AddCircleOutline, RemoveCircleOutline } from 'react-ionicons';
import Checkbox from 'components/Checkbox';
import { useInventoryAccount } from 'screens/InventoryAccount/InventoryAccountContext';
import { FaArrowRight } from 'react-icons/fa';
import { dummyData } from '../utils';
import { useNavigate } from 'react-router-dom';
import DisabledToolTip from './DisabledTooltip';

interface ServiceContentProps extends ServiceTagProps {
  status: boolean;
  graniteMangaged: boolean;
  carrier: string;
  serviceId: string;
  vendor: string;
  serviceIdentifier: string;
  completionDate: string;
  endDate?: string;
}

const ServiceContent: FC<ServiceContentProps> = ({
  serviceType,
  status,
  graniteMangaged,
  carrier,
  serviceId,
  vendor,
  serviceIdentifier,
  completionDate,
  endDate,
}) => {
  const { mode, onSelect, selected } = useInventoryAccount();
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();

  const onExpand = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setExpand((prev) => !prev);
  };

  const onOpenRepairTicket = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/noc-express/open-ticket/details', {
      state: { data: dummyData },
    });
  };

  return (
    <div
      className={clsx(
        'group flex flex-col gap-4 rounded border p-4  hover:border-teal-400',
        {
          'border-neutral-700/50 bg-blue-700': status,
          'border-neutral-700 bg-neutral-900': !status,
        },
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="flex justify-between gap-2">
        <div className="flex items-center">
          <div
            className={clsx('overflow-hidden transition-all duration-700', {
              'w-7 opacity-100': mode === 'edit',
              'w-0 opacity-0': mode === 'view',
            })}
          >
            <DisabledToolTip show={!status} offset={[8, 12]}>
              <div
                className={clsx('w-5', {
                  'pointer-events-none cursor-not-allowed': !status,
                })}
                {...(status && {
                  onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelect(serviceId, !selected.includes(serviceId));
                  },
                })}
              >
                <Checkbox
                  checked={selected.includes(serviceId)}
                  disabled={!status}
                  readOnly
                />
              </div>
            </DisabledToolTip>
          </div>
          <div className="flex items-center gap-2">
            <ServiceTag serviceType={serviceType} />
            <span className="h-4 w-[1px] bg-neutral-700/50" />
            <p className="font-bold text-white">
              {carrier} - {serviceId}
            </p>
            <span onClick={onExpand} className="-mt-0.5">
              {expand ? (
                <RemoveCircleOutline
                  color="currentColor"
                  width="24px"
                  height="24px"
                />
              ) : (
                <AddCircleOutline
                  color="currentColor"
                  width="24px"
                  height="24px"
                />
              )}
            </span>
          </div>
        </div>
        <div className="flex gap-6">
          {/* TODO:comment until we have Service details page designed */}
          {/* <div className="hidden items-center gap-2 text-xs font-bold text-white group-hover:flex">
            Go to service <FaArrowRight />{' '}
          </div> */}
          {mode === 'edit' && status && (
            <div
              className="flex items-center gap-2 text-xs font-bold text-white group-hover:flex sm:hidden"
              onClick={onOpenRepairTicket}
            >
              Open repair ticket <FaArrowRight />{' '}
            </div>
          )}
          {graniteMangaged && <GraniteLogo />}
          <Status status={status} />
        </div>
      </div>

      {expand && (
        <div className="flex flex-wrap gap-2">
          <Content title="Status" value={<Status status={status} />} />
          <Content
            title="Granite provided"
            value={graniteMangaged ? 'Yes' : 'No'}
          />
          <Content title="Vendor" value={vendor} />
          <Content title="Service identifier" value={serviceIdentifier} />
          <Content title="Completion date" value={completionDate} />
          <Content title="End date" value={endDate} />
        </div>
      )}
    </div>
  );
};

export default ServiceContent;

const Status = ({ status }: { status: boolean }) => (
  <div className="flex items-center gap-2 font-bold text-white">
    <span
      className={clsx('block h-2.5 w-2.5 rounded-full', {
        'bg-green-400': status,
        'bg-red-500': !status,
      })}
    />{' '}
    {status ? 'Active' : 'Inactive'}
  </div>
);

const Content = ({ title, value }: { title: string; value?: ReactNode }) => {
  return (
    <div className="flex min-w-[180px] flex-col gap-1 font-bold">
      <p>{title}</p>
      <div className={clsx({ 'text-white': value })}>{value || 'N/A'}</div>
    </div>
  );
};
