import { useEffect } from 'react';

type HotkeyOptions = {
  ctrl?: boolean;
  meta?: boolean;
  shift?: boolean;
  alt?: boolean;
};

export const useHotkey = (
  key: string,
  callback: (e: KeyboardEvent) => void,
  options?: HotkeyOptions,
) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key?.toLowerCase() !== key?.toLowerCase()) return;

      // For ctrl and meta, if both are true in options, trigger if either is pressed.
      if (options?.ctrl && options?.meta) {
        if (!(e.ctrlKey || e.metaKey)) return;
      } else {
        if (options?.ctrl && !e.ctrlKey) return;
        if (options?.meta && !e.metaKey) return;
      }

      if (options?.shift && !e.shiftKey) return;
      if (options?.alt && !e.altKey) return;

      e.preventDefault();
      callback(e);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [key, callback, options]);
};
