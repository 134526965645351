import { GraniteInput } from 'components/V2/Input/GraniteInput';
import React, { useState } from 'react';
import { SearchOutline } from 'react-ionicons';
import { useSearchParams } from 'react-router-dom';

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(
    decodeURIComponent(searchParams.get('search') ?? ''),
  );

  const onSearch = (value: string) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        if (value.length === 0) newParams.delete('search');
        else newParams.set('search', encodeURIComponent(value));
        return newParams;
      },
      { replace: true },
    );
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') onSearch(e.currentTarget.value);
  };

  return (
    <div className="relative w-full">
      <span className="absolute left-4 top-4 z-10 text-white">
        <SearchOutline color="currentColor" width="16px" height="16px" />
      </span>
      <GraniteInput
        placeholder="Search..."
        innerInputClassName="!pl-10 !bg-transparent"
        variant="blue"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={onEnter}
      />
    </div>
  );
};

export default Search;
