export type QuestionnaireType = {
  type: string;
  title: string;
  body: string;
  tags?: string[];
  disabled?: boolean;
};

export type QuestionsType = {
  title: string;
  questions: QuestionnaireType[];
};

export type ProductType = {
  id: string;
  title: string;
  img: string | null;
  manufacturer?: string;
  from_price?: string;
  monthly_price?: string;
  months?: string;
  mrc?: number;
  nrc?: number;
  content?: string;
  stock?: number;
  business?: boolean;
  createdDate?: string;
  fullPriceInfo?: string;
  varients?: VariantsType[];
  productId?: number;
  productDescType?: ProductDescType;
  category?: AccessProductGridType['category'];
};

export type ServiceSummaryData = {
  id: string;
  type?: string;
  ip_type: string;
  bandwidth_download: string;
  bandwidth_upload: string;
};

export type TagType = 'Best offer' | 'Premium choice' | 'Lowest price';

export type OfferingType = {
  tag: TagType;
  price: string;
  carrier: string;
  construction?: boolean;
  details: {
    id?: string;
    icon: React.ElementType;
    text: string;
  }[][];
};

export type AccessProductType = {
  id: string;
  type: string;
  title: string;
  content: string;
  offerings: OfferingType[] | [];
};
export type CommonAttributesProps = {
  handleNext?: () => void;
  handlePrev?: () => void;
  handleStep?: (step: number, config?: { scrollTo?: boolean }) => void;
  step?: number;
};

// Mobility API Response
export interface BaseResponseMobilityAPI<T> {
  data: T;
  hasData: boolean;
  isError: boolean;
  errors: unknown[];
  message: string;
  count?: number;
}

export type ProductDescType =
  | 'WIRELESS ROUTER'
  | 'SIM CARD'
  | 'TABLET'
  | 'SMART PHONE'
  | 'BASIC CELL PHONE'
  | 'HOTSPOT'
  | 'MODEM'
  | 'UNIVERSAL'
  | null;

export type MobilityProductTypes = {
  productTypeDesc: 'EQUIPMENT';
  productDetail1Desc: 'ACCESSORY' | 'DEVICE';
  productDesc: ProductDescType;
  children: [];
};

export type MobilityProduct = {
  productQty: number;
  productDesc: ProductDescType;
  productDetail1Desc: MobilityProductTypes['productDetail1Desc'];
  variants: VariantsType[];
  images: {
    productImageId: number;
    productId: number;
    fileName: string;
    url: string;
    sortOrder: number;
    createdDate: string;
    modifiedDate: string;
  }[];
  compatibleESims: boolean;
  productId: number;
  graniteId: string;
  featured: boolean;
  onSale: boolean;
  bestSeller: boolean;
  description: string;
  createdDate: string;
  modifiedDate: string;
  inPIMData: boolean;
};

export type VariantsType = {
  id: string | null;
  children: VariantsChildrenType[];
  maxPurchasePrice: number;
  minPurchasePrice: number;
  productAlias: string;
  productCode: string;
  productCodeDescription: string;
};

export type VariantsChildrenType = {
  contractTerm_Months: number | null;
  costCode: string;
  id: string;
  licenseIncluded: boolean;
  msrp: null;
  originalPrice: number;
  pimProductId: number;
  pimProductPricingId: number;
  pricingId: number;
  productGroupDesc: 'PURCHASED' | 'FINANCED';
  standardRate: number;
  utilitiesId: string;
};

export type AccessProductGridType = {
  category: 'Broadband' | 'DIA' | 'Granite MNS' | 'Starlink';
  description: string;
  id: number;
  image_path: string;
  image_url: string;
  name: string;
  price_info: string;
};

export enum DeviceType {
  basicPhone = 'BASIC CELL PHONE', // * DEVICE
  smartPhone = 'SMART PHONE', // * DEVICE
  tablet = 'TABLET', // * DEVICE
  hotspot = 'HOTSPOT', // * DEVICE
  sim = 'SIM CARD', // * ACCESSORY
  modem = 'MODEM', // * DEVICE
  wirelessRouter = 'WIRELESS ROUTER', // * DEVICE
}

export const DeviceGroupType = {
  DEVICE: [
    DeviceType.basicPhone,
    DeviceType.smartPhone,
    DeviceType.tablet,
    DeviceType.hotspot,
    DeviceType.modem,
    DeviceType.wirelessRouter,
  ],
  ACCESSORY: [DeviceType.sim],
};
