import IconButton from 'components/IconButton';
import { motion } from 'framer-motion';
import { Dispatch, FC, SetStateAction } from 'react';

interface BeamTrackerProps {
  currentPage: number;
  totalPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  onChangePageHistory: (page: number) => void;
}

const BeamTracker: FC<BeamTrackerProps> = ({
  currentPage,
  setCurrentPage,
  onChangePageHistory,
  totalPage,
}) => {
  return (
    <div className="group relative mt-3.5">
      <div className="flex h-[13px] items-center">
        <div className="h-[3px] w-full bg-neutral-700/50">
          <motion.div
            className="h-full bg-[#6C9DFF]"
            initial={false}
            animate={{ width: `${((currentPage + 1) / totalPage) * 100}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>
      </div>
      {totalPage > 1 ? (
        <motion.div
          className="absolute right-0 top-1/2 hidden -translate-y-1/2 gap-2 bg-blue-900 group-hover:flex"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
          }}
          transition={{
            type: 'spring',
            mass: 1,
            stiffness: 256,
            damping: 24,
          }}
        >
          <IconButton
            icon="chevronBack"
            buttonType="secondary"
            onClick={() =>
              setCurrentPage((prev) => {
                onChangePageHistory(Math.max(0, prev - 1));
                return Math.max(0, prev - 1);
              })
            }
          />
          <IconButton
            icon="chevronForward"
            buttonType="secondary"
            onClick={() =>
              setCurrentPage((prev) => {
                onChangePageHistory(Math.min(totalPage - 1, prev + 1));
                return Math.min(totalPage - 1, prev + 1);
              })
            }
          />
        </motion.div>
      ) : null}
    </div>
  );
};

export default BeamTracker;
